@charset "UTF-8";
/* font */
@font-face {
  font-family: "NanumGothic";
  font-style: normal;
  font-weight: 400;
  src: url(./font/NanumGothic.otf) format("opentype");
}
@font-face {
  font-family: "NanumGothic";
  font-style: normal;
  font-weight: 700;
  src: url(./font/NanumGothicBold.otf) format("opentype");
}
@font-face {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  src: url(./font/NotoSansCJKkr-Regular.otf) format("opentype");
}
@font-face {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  src: url(./font/NotoSansCJKkr-Bold.otf) format("opentype");
}
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  src: url(./font/Oswald-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  src: url(./font/Oswald-Bold.ttf) format("truetype");
}
i.--icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
}
i.--icon.star-gold {
  background-image: url("./svg/star_gold.svg");
}
i.--icon.star-silver {
  background-image: url("./svg/star_silver.svg");
}
i.--icon.point-gold {
  background-image: url("./svg/point_gold.svg");
}
i.--icon.point-silver {
  background-image: url("./svg/point_silver.svg");
}
i.--icon.cash {
  background-image: url("./svg/cash.svg");
}
i.--icon.star {
  background-image: url("./svg/i-star.svg");
}
i.--icon.friend {
  background-image: url("./svg/i-friend.svg");
}
i.--icon.onechat {
  background-image: url("./svg/i-onechat.svg");
}
i.--icon.userinfo {
  background-image: url("./svg/i-userinfo.svg");
}
i.--icon.message {
  background-image: url("./svg/Icon ionic-ios-mail.svg");
}
i.--icon.close {
  background-image: url("./svg/close.svg");
}
i.--icon.header-i.sports {
  background-image: url("./svg/tab header/Icon ionic-ios-basketball-2.svg");
}
i.--icon.header-i.minigame {
  background-image: url("./svg/tab header/Icon ionic-ios-basketball-1.svg");
}
i.--icon.header-i.community {
  background-image: url("./svg/tab header/Icon ionic-ios-basketball.svg");
}
i.--icon.header-i.rank {
  background-image: url("./svg/tab header/Icon ionic-ios-rank.svg");
}
i.--icon.header-i.chat {
  background-image: url("./svg/tab header/Icon ionic-ios-chatbox.svg");
}
i.--icon.header-i.market {
  background-image: url("./svg/tab header/Icon ionic-ios-cart.svg");
}
i.--icon.header-i.home {
  background-image: url("./svg/tab header/Icon ionic-ios-home.svg");
}
i.--icon.header-i.customer_support {
  background-image: url("./svg/tab header/Icon ionic-md-help-circle.svg");
}
i.--icon.header-i.my_page {
  background-image: url("./svg/tab header/Icon ionic-md-help-circle-1.svg");
}

@font-face {
  font-family: "icomoon";
  src: url("./font/icomoon.eot?dpqw3b");
  src: url("./font/icomoon.eot?dpqw3b#iefix") format("embedded-opentype"), url("./font/icomoon.ttf?dpqw3b") format("truetype"), url("./font/icomoon.woff?dpqw3b") format("woff"), url("./font/icomoon.svg?dpqw3b#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i[class*="--i"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: var(--base-font-color);
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

i.--icon.star.on:before {
  content: "\e92a";
  color: var(--theme);
}
i.--icon.fold:before {
  content: "\e925";
}
i.--icon.calendar:before {
  content: "\e926";
}
i.--icon.user:before {
  content: "\e927";
}
i.--icon.paste:before {
  content: "\e928";
}
i.--icon.write:before {
  content: "\e929";
}
i.--icon.arrow-left:before {
  content: "\e900";
}
i.--icon.arrow-right:before {
  content: "\e901";
}
i.--icon.base-000:before {
  content: "\e902";
}
i.--icon.base-001:before {
  content: "\e903";
}
i.--icon.base-011:before {
  content: "\e904";
}
i.--icon.base-111:before {
  content: "\e905";
}
i.--icon.base-010:before {
  content: "\e906";
}
i.--icon.base-100:before {
  content: "\e907";
}
i.--icon.base-101:before {
  content: "\e908";
}
i.--icon.base-110:before {
  content: "\e909";
}
i.--icon.arrow-down:before {
  content: "\e90a";
}
i.--icon.arrow-bold-left:before {
  content: "\e910";
}
i.--icon.arrow-bold-right:before {
  content: "\e911";
}
i.--icon.lo {
  display: inline-block;
  width: 1em;
  height: 1em;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
i.--icon.lo.chat-bubble {
  background-image: url("./svg/Icon ionic-ios-chatbubbles.svg");
}
i.--icon.lo.chat-bubble.active {
  background-image: url("./svg/Icon ionic-ios-chatbubbles active.svg");
}
i.--icon.lo.radio {
  background-image: url("./svg/radio.svg");
}
i.--icon.lo.radio.active {
  background-image: url("./svg/radio active.svg");
}

i.--i.sports.soccer:before {
  content: "\e92b";
}
i.--i.sports.baseball:before {
  content: "\e90b";
}
i.--i.sports.basketball:before {
  content: "\e90c";
}
i.--i.sports.volleyball:before {
  content: "\e90d";
}
i.--i.sports.hockey:before {
  content: "\e90e";
}
i.--i.sports.football:before {
  content: "\e90f";
}
i.--i.sports.UFC:before {
  content: "\e912";
}
i.--i.sports.Golf:before {
  content: "\e913";
}
i.--i.sports.Dart:before {
  content: "\e914";
}
i.--i.sports.Rugby:before {
  content: "\e915";
}
i.--i.sports.Motor:before {
  content: "\e916";
}
i.--i.sports.Boxing:before {
  content: "\e917";
}
i.--i.sports.Beach.Vollyball:before {
  content: "\e918";
}
i.--i.sports.Beachsoccer:before {
  content: "\e919";
}
i.--i.sports.Cycling:before {
  content: "\e91a";
}
i.--i.sports.Waterball:before {
  content: "\e91b";
}
i.--i.sports.Snooker:before {
  content: "\e91c";
}
i.--i.sports.Badminton:before {
  content: "\e91d";
}
i.--i.sports.Cricket:before {
  content: "\e91e";
}
i.--i.sports.Tabletennis:before {
  content: "\e91f";
}
i.--i.sports.Tennis:before {
  content: "\e920";
}
i.--i.sports.Footbal:before {
  content: "\e921";
}
i.--i.sports.Floorball:before {
  content: "\e922";
}
i.--i.sports.Handball:before {
  content: "\e923";
}
i.--i.sports.Rugbyunion:before {
  content: "\e924";
}

i.--rank {
  display: inline-block;
  width: 1em;
  height: 1em;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
i.--rank.level-1 {
  background-image: url("./svg/rank/Rank_ (1).svg");
}
i.--rank.level-2 {
  background-image: url("./svg/rank/Rank_ (2).svg");
}
i.--rank.level-3 {
  background-image: url("./svg/rank/Rank_ (3).svg");
}
i.--rank.level-4 {
  background-image: url("./svg/rank/Rank_ (4).svg");
}
i.--rank.level-5 {
  background-image: url("./svg/rank/Rank_ (5).svg");
}
i.--rank.level-6 {
  background-image: url("./svg/rank/Rank_ (6).svg");
}
i.--rank.level-7 {
  background-image: url("./svg/rank/Rank_ (7).svg");
}
i.--rank.level-8 {
  background-image: url("./svg/rank/Rank_ (8).svg");
}
i.--rank.level-9 {
  background-image: url("./svg/rank/Rank_ (9).svg");
}
i.--rank.level-10 {
  background-image: url("./svg/rank/Rank_ (10).svg");
}
i.--rank.level-11 {
  background-image: url("./svg/rank/Rank_ (11).svg");
}
i.--rank.level-12 {
  background-image: url("./svg/rank/Rank_ (12).svg");
}
i.--rank.level-13 {
  background-image: url("./svg/rank/Rank_ (13).svg");
}
i.--rank.level-14 {
  background-image: url("./svg/rank/Rank_ (14).svg");
}
i.--rank.level-15 {
  background-image: url("./svg/rank/Rank_ (15).svg");
}
i.--rank.level-16 {
  background-image: url("./svg/rank/Rank_ (16).svg");
}
i.--rank.level-17 {
  background-image: url("./svg/rank/Rank_ (17).svg");
}
i.--rank.level-18 {
  background-image: url("./svg/rank/Rank_ (18).svg");
}
i.--rank.level-19 {
  background-image: url("./svg/rank/Rank_ (19).svg");
}
i.--rank.level-20 {
  background-image: url("./svg/rank/Rank_ (20).svg");
}
i.--rank.admin-1 {
  background-image: url("./svg/rank/Rank_master.svg");
}
i.--rank.admin-2 {
  background-image: url("./svg/rank/Rank_master2.svg");
}

:root {
  --base-bg: #FFFFFF;
  --base-font: #555555;
  --shadow-inset: #FFFFFF57;
  --shadow-outline: #0000009C;
  --bs-body-color: #555555;
  --theme: #FFCC33 ;
  --theme2: #FF9D00 ;
  --red: #FF3068 ;
  --pink: #ED5680 ;
  --sky: #3FCBE6 ;
  --blue: #0088FF ;
  --green: #15857E ;
  --light-green: #22D900 ;
  --yellow: #FFEE00 ;
  --line: #E2A63F ;
  --white: #FFFFFF ;
  --black: #000000 ;
  --gray: #B4B4B4 ;
  --dark: #444444 ;
  --grayblue: #7B94C1 ;
  --disabled: #F5F5F5 ;
  --default: #F3F3F3 ;
  --danger: #FF004E ;
  --large: #DB2828 ;
  --medium: #2185D0 ;
  --odd: #2185D0 ;
  --even: #DB2828 ;
  --small: #22DC00 ;
  --gb-odd: rgba(45, 212, 255, 0.7490196078) ;
  --gb-even: rgba(255, 0, 0, 0.8509803922) ;
  --gb-under: rgba(45, 212, 255, 0.7490196078) ;
  --gb-over: rgba(255, 0, 0, 0.8509803922) ;
  --l3e: #DB2828 ;
  --l4o: #2185D0 ;
  --r3o: #0059b1 ;
  --r4e: #9c0000 ;
  --default-font: #555555 ;
  --default-font2: #9394AD ;
  --title: #333333 ;
  --content: #838483 ;
  --backoffice-table-row-hover: #c4d6ff ;
  --lottery-red: #D92027 ;
  --lottery-navy: #004071 ;
  --super-chat-room-background-color: #FFCC33 ;
  --super-chat-room-background-color2: #F3F3F3 ;
  --select-chat-tab: #FFCC33 ;
  --select-sports-tab: #FF9D00 ;
  --layout-border-color: #D6D6D6 ;
  --chat-user-strong: #FF9D00 ;
  --chat-msg-p: #555555 ;
  --chat-input-default: #AAAAAA ;
  --minigame-tab-default: #9394AD ;
  --minigame-tab-select: #000000 ;
  --minigame-tab-select-border: #FF9D00 ;
  --minigame-pick-bar-default-color: #C0C3CB ;
  --minigame-pick-contents-bg: #171717 ;
  --minigame-pick-contents-inner-bg: #342100 ;
  --powerball-cell-border-color: #C3DCF9 ;
  --powerball-cell-tab-font-color: #788CA3 ;
  --powerball-cell-tab-underbar-color: #0062DD ;
  --powerball-cell-title-bg: #E0E1E2 ;
  --powerball-cell-border: #F2F2F2 ;
  --powerball-cell-border-bold: #E9E9E9 ;
  --powerball-cell-odd-color: #0780FB ;
  --powerball-cell-right3-color: #0059b1 ;
  --powerball-cell-even-color: #FF0000 ;
  --powerball-cell-right4-color: #9c0000 ;
  --powerball-cell-small-color: #0BA81A ;
  --powerball-table-result-color: #788CA3 ;
  --game-board-timer-color: #FFF99F ;
  --game-board-result-header-color: #FFF99F ;
  --game-board-result-dark-color: #241A0C ;
  --game-board-result-red: rgba(255, 159, 214, 0.8509803922) ;
  --game-board-result-blue: rgba(191, 245, 255, 0.7490196078) ;
  --game-board-result-green: rgba(133, 214, 87, 0.8509803922) ;
  --board-free-color: #5A36E8 ;
  --board-humor-color: #000000 ;
  --board-picture-color: #007E7E ;
  --board-video-color: #FF191A ;
  --board-sports-color: #FF832A ;
  --footer-font: #808080 ;
  --color-0: #000000;
  --color-1: #111111;
  --color-2: #222222;
  --color-3: #333333;
  --color-4: #444444;
  --color-5: #555555;
  --color-6: #666666;
  --color-7: #777777;
  --color-8: #888888;
  --color-9: #999999;
  --color-A: #AAAAAA;
  --color-B: #BBBBBB;
  --color-C: #CCCCCC;
  --color-D: #DDDDDD;
  --color-E: #EEEEEE;
  --color-F: #FFFFFF;
  --header-z: 10000;
  --modal-z: 1010;
  --alert-z: 2000;
}

.--color-theme {
  color: var(--theme) !important;
}

.--bg-theme {
  background-color: var(--theme) !important;
}

.--color-theme2 {
  color: var(--theme2) !important;
}

.--bg-theme2 {
  background-color: var(--theme2) !important;
}

.--color-red {
  color: var(--red) !important;
}

.--bg-red {
  background-color: var(--red) !important;
}

.--color-pink {
  color: var(--pink) !important;
}

.--bg-pink {
  background-color: var(--pink) !important;
}

.--color-sky {
  color: var(--sky) !important;
}

.--bg-sky {
  background-color: var(--sky) !important;
}

.--color-blue {
  color: var(--blue) !important;
}

.--bg-blue {
  background-color: var(--blue) !important;
}

.--color-green {
  color: var(--green) !important;
}

.--bg-green {
  background-color: var(--green) !important;
}

.--color-light-green {
  color: var(--light-green) !important;
}

.--bg-light-green {
  background-color: var(--light-green) !important;
}

.--color-yellow {
  color: var(--yellow) !important;
}

.--bg-yellow {
  background-color: var(--yellow) !important;
}

.--color-line {
  color: var(--line) !important;
}

.--bg-line {
  background-color: var(--line) !important;
}

.--color-white {
  color: var(--white) !important;
}

.--bg-white {
  background-color: var(--white) !important;
}

.--color-black {
  color: var(--black) !important;
}

.--bg-black {
  background-color: var(--black) !important;
}

.--color-gray {
  color: var(--gray) !important;
}

.--bg-gray {
  background-color: var(--gray) !important;
}

.--color-dark {
  color: var(--dark) !important;
}

.--bg-dark {
  background-color: var(--dark) !important;
}

.--color-grayblue {
  color: var(--grayblue) !important;
}

.--bg-grayblue {
  background-color: var(--grayblue) !important;
}

.--color-disabled {
  color: var(--disabled) !important;
}

.--bg-disabled {
  background-color: var(--disabled) !important;
}

.--color-default {
  color: var(--default) !important;
}

.--bg-default {
  background-color: var(--default) !important;
}

.--color-danger {
  color: var(--danger) !important;
}

.--bg-danger {
  background-color: var(--danger) !important;
}

.--color-large {
  color: var(--large) !important;
}

.--bg-large {
  background-color: var(--large) !important;
}

.--color-medium {
  color: var(--medium) !important;
}

.--bg-medium {
  background-color: var(--medium) !important;
}

.--color-odd {
  color: var(--odd) !important;
}

.--bg-odd {
  background-color: var(--odd) !important;
}

.--color-even {
  color: var(--even) !important;
}

.--bg-even {
  background-color: var(--even) !important;
}

.--color-small {
  color: var(--small) !important;
}

.--bg-small {
  background-color: var(--small) !important;
}

.--color-gb-odd {
  color: var(--gb-odd) !important;
}

.--bg-gb-odd {
  background-color: var(--gb-odd) !important;
}

.--color-gb-even {
  color: var(--gb-even) !important;
}

.--bg-gb-even {
  background-color: var(--gb-even) !important;
}

.--color-gb-under {
  color: var(--gb-under) !important;
}

.--bg-gb-under {
  background-color: var(--gb-under) !important;
}

.--color-gb-over {
  color: var(--gb-over) !important;
}

.--bg-gb-over {
  background-color: var(--gb-over) !important;
}

.--color-l3e {
  color: var(--l3e) !important;
}

.--bg-l3e {
  background-color: var(--l3e) !important;
}

.--color-l4o {
  color: var(--l4o) !important;
}

.--bg-l4o {
  background-color: var(--l4o) !important;
}

.--color-r3o {
  color: var(--r3o) !important;
}

.--bg-r3o {
  background-color: var(--r3o) !important;
}

.--color-r4e {
  color: var(--r4e) !important;
}

.--bg-r4e {
  background-color: var(--r4e) !important;
}

.--color-default-font {
  color: var(--default-font) !important;
}

.--bg-default-font {
  background-color: var(--default-font) !important;
}

.--color-default-font2 {
  color: var(--default-font2) !important;
}

.--bg-default-font2 {
  background-color: var(--default-font2) !important;
}

.--color-title {
  color: var(--title) !important;
}

.--bg-title {
  background-color: var(--title) !important;
}

.--color-content {
  color: var(--content) !important;
}

.--bg-content {
  background-color: var(--content) !important;
}

.--color-0 {
  color: var(--color-0) !important;
}

.--bg-0 {
  background-color: var(--color-0) !important;
}

.--color-1 {
  color: var(--color-1) !important;
}

.--bg-1 {
  background-color: var(--color-1) !important;
}

.--color-2 {
  color: var(--color-2) !important;
}

.--bg-2 {
  background-color: var(--color-2) !important;
}

.--color-3 {
  color: var(--color-3) !important;
}

.--bg-3 {
  background-color: var(--color-3) !important;
}

.--color-4 {
  color: var(--color-4) !important;
}

.--bg-4 {
  background-color: var(--color-4) !important;
}

.--color-5 {
  color: var(--color-5) !important;
}

.--bg-5 {
  background-color: var(--color-5) !important;
}

.--color-6 {
  color: var(--color-6) !important;
}

.--bg-6 {
  background-color: var(--color-6) !important;
}

.--color-7 {
  color: var(--color-7) !important;
}

.--bg-7 {
  background-color: var(--color-7) !important;
}

.--color-8 {
  color: var(--color-8) !important;
}

.--bg-8 {
  background-color: var(--color-8) !important;
}

.--color-9 {
  color: var(--color-9) !important;
}

.--bg-9 {
  background-color: var(--color-9) !important;
}

.--color-A {
  color: var(--color-A) !important;
}

.--bg-A {
  background-color: var(--color-A) !important;
}

.--color-B {
  color: var(--color-B) !important;
}

.--bg-B {
  background-color: var(--color-B) !important;
}

.--color-C {
  color: var(--color-C) !important;
}

.--bg-C {
  background-color: var(--color-C) !important;
}

.--color-D {
  color: var(--color-D) !important;
}

.--bg-D {
  background-color: var(--color-D) !important;
}

.--color-E {
  color: var(--color-E) !important;
}

.--bg-E {
  background-color: var(--color-E) !important;
}

.--color-F {
  color: var(--color-F) !important;
}

.--bg-F {
  background-color: var(--color-F) !important;
}

.--radius-1 {
  border-radius: 1px;
}

.--radius-2 {
  border-radius: 2px;
}

.--radius-3 {
  border-radius: 3px;
}

.--radius-4 {
  border-radius: 4px;
}

.--radius-5 {
  border-radius: 5px;
}

.--radius-6 {
  border-radius: 6px;
}

.--radius-7 {
  border-radius: 7px;
}

.--radius-8 {
  border-radius: 8px;
}

.--radius-9 {
  border-radius: 9px;
}

.--radius-10 {
  border-radius: 10px;
}

.--radius-11 {
  border-radius: 11px;
}

.--radius-12 {
  border-radius: 12px;
}

.--radius-13 {
  border-radius: 13px;
}

.--radius-14 {
  border-radius: 14px;
}

.--radius-15 {
  border-radius: 15px;
}

.--radius-16 {
  border-radius: 16px;
}

.--radius-17 {
  border-radius: 17px;
}

.--radius-18 {
  border-radius: 18px;
}

.--radius-19 {
  border-radius: 19px;
}

.--radius-20 {
  border-radius: 20px;
}

.--radius-21 {
  border-radius: 21px;
}

.--radius-22 {
  border-radius: 22px;
}

.--radius-23 {
  border-radius: 23px;
}

.--radius-24 {
  border-radius: 24px;
}

.--radius-25 {
  border-radius: 25px;
}

.--radius-26 {
  border-radius: 26px;
}

.--radius-27 {
  border-radius: 27px;
}

.--radius-28 {
  border-radius: 28px;
}

.--radius-29 {
  border-radius: 29px;
}

.--radius-30 {
  border-radius: 30px;
}

.--radius-31 {
  border-radius: 31px;
}

.--radius-32 {
  border-radius: 32px;
}

.--radius-33 {
  border-radius: 33px;
}

.--radius-34 {
  border-radius: 34px;
}

.--radius-35 {
  border-radius: 35px;
}

.--radius-36 {
  border-radius: 36px;
}

.--radius-37 {
  border-radius: 37px;
}

.--radius-38 {
  border-radius: 38px;
}

.--radius-39 {
  border-radius: 39px;
}

.--radius-40 {
  border-radius: 40px;
}

.--radius-41 {
  border-radius: 41px;
}

.--radius-42 {
  border-radius: 42px;
}

.--radius-43 {
  border-radius: 43px;
}

.--radius-44 {
  border-radius: 44px;
}

.--radius-45 {
  border-radius: 45px;
}

.--radius-46 {
  border-radius: 46px;
}

.--radius-47 {
  border-radius: 47px;
}

.--radius-48 {
  border-radius: 48px;
}

.--radius-49 {
  border-radius: 49px;
}

.--radius-50 {
  border-radius: 50px;
}

.--radius-51 {
  border-radius: 51px;
}

.--radius-52 {
  border-radius: 52px;
}

.--radius-53 {
  border-radius: 53px;
}

.--radius-54 {
  border-radius: 54px;
}

.--radius-55 {
  border-radius: 55px;
}

.--radius-56 {
  border-radius: 56px;
}

.--radius-57 {
  border-radius: 57px;
}

.--radius-58 {
  border-radius: 58px;
}

.--radius-59 {
  border-radius: 59px;
}

.--radius-60 {
  border-radius: 60px;
}

.--radius-61 {
  border-radius: 61px;
}

.--radius-62 {
  border-radius: 62px;
}

.--radius-63 {
  border-radius: 63px;
}

.--radius-64 {
  border-radius: 64px;
}

.--radius-65 {
  border-radius: 65px;
}

.--radius-66 {
  border-radius: 66px;
}

.--radius-67 {
  border-radius: 67px;
}

.--radius-68 {
  border-radius: 68px;
}

.--radius-69 {
  border-radius: 69px;
}

.--radius-70 {
  border-radius: 70px;
}

.--radius-71 {
  border-radius: 71px;
}

.--radius-72 {
  border-radius: 72px;
}

.--radius-73 {
  border-radius: 73px;
}

.--radius-74 {
  border-radius: 74px;
}

.--radius-75 {
  border-radius: 75px;
}

.--radius-76 {
  border-radius: 76px;
}

.--radius-77 {
  border-radius: 77px;
}

.--radius-78 {
  border-radius: 78px;
}

.--radius-79 {
  border-radius: 79px;
}

.--radius-80 {
  border-radius: 80px;
}

.--radius-81 {
  border-radius: 81px;
}

.--radius-82 {
  border-radius: 82px;
}

.--radius-83 {
  border-radius: 83px;
}

.--radius-84 {
  border-radius: 84px;
}

.--radius-85 {
  border-radius: 85px;
}

.--radius-86 {
  border-radius: 86px;
}

.--radius-87 {
  border-radius: 87px;
}

.--radius-88 {
  border-radius: 88px;
}

.--radius-89 {
  border-radius: 89px;
}

.--radius-90 {
  border-radius: 90px;
}

.--radius-91 {
  border-radius: 91px;
}

.--radius-92 {
  border-radius: 92px;
}

.--radius-93 {
  border-radius: 93px;
}

.--radius-94 {
  border-radius: 94px;
}

.--radius-95 {
  border-radius: 95px;
}

.--radius-96 {
  border-radius: 96px;
}

.--radius-97 {
  border-radius: 97px;
}

.--radius-98 {
  border-radius: 98px;
}

.--radius-99 {
  border-radius: 99px;
}

.--radius-100 {
  border-radius: 100px;
}

i[class*=fa-],
i[class*=fas-] {
  color: inherit;
}

.d-block {
  display: block;
}

.d-inblock {
  display: inline-block;
}

.d-flex {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
}

.d-inflex {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
}

.d-grid {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
}

.d-ingrid {
  display: inline-grid;
  display: -ms-inline-grid;
  display: -moz-inline-grid;
}

.--fl-content-between {
  justify-content: space-between;
}

.--fl-content-around {
  justify-content: space-around;
}

.--fl-content-center {
  justify-content: center;
}

.--fl-content-stretch {
  justify-content: stretch;
}

.--fl-content-start {
  justify-content: flex-start;
}

.--fl-content-end {
  justify-content: flex-end;
}

.--fl-items-stretch {
  align-items: stretch;
}

.--fl-items-center {
  align-items: center;
}

.--fl-items-start {
  align-items: flex-start;
}

.--fl-items-end {
  align-items: flex-end;
}

.--fl-between-stretch {
  justify-content: space-between;
  align-items: stretch;
}

.--fl-between-center {
  justify-content: space-between;
  align-items: center;
}

.--fl-between-start {
  justify-content: space-between;
  align-items: flex-start;
}

.--fl-between-end {
  justify-content: space-between;
  align-items: flex-end;
}

.--fl-around-stretch {
  justify-content: space-around;
  align-items: stretch;
}

.--fl-around-center {
  justify-content: space-around;
  align-items: center;
}

.--fl-around-start {
  justify-content: space-around;
  align-items: flex-start;
}

.--fl-around-end {
  justify-content: space-around;
  align-items: flex-end;
}

.--fl-center-stretch {
  justify-content: center;
  align-items: stretch;
}

.--fl-center-center {
  justify-content: center;
  align-items: center;
}

.--fl-center-start {
  justify-content: center;
  align-items: flex-start;
}

.--fl-center-end {
  justify-content: center;
  align-items: flex-end;
}

.--fl-stretch-stretch {
  justify-content: stretch;
  align-items: stretch;
}

.--fl-stretch-center {
  justify-content: stretch;
  align-items: center;
}

.--fl-stretch-start {
  justify-content: stretch;
  align-items: flex-start;
}

.--fl-stretch-end {
  justify-content: stretch;
  align-items: flex-end;
}

.--fl-start-stretch {
  justify-content: flex-start;
  align-items: stretch;
}

.--fl-start-center {
  justify-content: flex-start;
  align-items: center;
}

.--fl-start-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.--fl-start-end {
  justify-content: flex-start;
  align-items: flex-end;
}

.--fl-end-stretch {
  justify-content: flex-end;
  align-items: stretch;
}

.--fl-end-center {
  justify-content: flex-end;
  align-items: center;
}

.--fl-end-start {
  justify-content: flex-end;
  align-items: flex-start;
}

.--fl-end-end {
  justify-content: flex-end;
  align-items: flex-end;
}

.--fl-direction-col {
  flex-direction: column;
}

.--fl-direction-row {
  flex-direction: row;
}

.--fl-wrap {
  flex-wrap: wrap;
}

.--fl-revers-wrap {
  flex-wrap: wrap-reverse;
}

.--fl-wrap.--fl-wrap-col-1 > * {
  width: calc(100% / 1);
}
.--fl-wrap.--fl-wrap-col-2 > * {
  width: calc(100% / 2);
}
.--fl-wrap.--fl-wrap-col-3 > * {
  width: calc(100% / 3);
}
.--fl-wrap.--fl-wrap-col-4 > * {
  width: calc(100% / 4);
}
.--fl-wrap.--fl-wrap-col-5 > * {
  width: calc(100% / 5);
}
.--fl-wrap.--fl-wrap-col-6 > * {
  width: calc(100% / 6);
}
.--fl-wrap.--fl-wrap-col-7 > * {
  width: calc(100% / 7);
}
.--fl-wrap.--fl-wrap-col-8 > * {
  width: calc(100% / 8);
}
.--fl-wrap.--fl-wrap-col-9 > * {
  width: calc(100% / 9);
}
.--fl-wrap.--fl-wrap-col-10 > * {
  width: calc(100% / 10);
}
.--fl-wrap.--fl-wrap-col-11 > * {
  width: calc(100% / 11);
}
.--fl-wrap.--fl-wrap-col-12 > * {
  width: calc(100% / 12);
}

.--grid-col-1 {
  grid-template-columns: repeat(1, 1fr);
}

.--grid-col-2 {
  grid-template-columns: repeat(2, 1fr);
}

.--grid-col-3 {
  grid-template-columns: repeat(3, 1fr);
}

.--grid-col-4 {
  grid-template-columns: repeat(4, 1fr);
}

.--grid-col-5 {
  grid-template-columns: repeat(5, 1fr);
}

.--grid-col-6 {
  grid-template-columns: repeat(6, 1fr);
}

.--grid-col-7 {
  grid-template-columns: repeat(7, 1fr);
}

.--grid-col-8 {
  grid-template-columns: repeat(8, 1fr);
}

.--grid-col-9 {
  grid-template-columns: repeat(9, 1fr);
}

.--grid-col-10 {
  grid-template-columns: repeat(10, 1fr);
}

.--grid-col-11 {
  grid-template-columns: repeat(11, 1fr);
}

.--grid-col-12 {
  grid-template-columns: repeat(12, 1fr);
}

@media (max-width: 757px) {
  .--grid-col-1-mo {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .--grid-col-2-mo {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .--grid-col-3-mo {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  .--grid-col-4-mo {
    grid-template-columns: repeat(4, 1fr) !important;
  }

  .--grid-col-5-mo {
    grid-template-columns: repeat(5, 1fr) !important;
  }

  .--grid-col-6-mo {
    grid-template-columns: repeat(6, 1fr) !important;
  }

  .--grid-col-7-mo {
    grid-template-columns: repeat(7, 1fr) !important;
  }

  .--grid-col-8-mo {
    grid-template-columns: repeat(8, 1fr) !important;
  }

  .--grid-col-9-mo {
    grid-template-columns: repeat(9, 1fr) !important;
  }

  .--grid-col-10-mo {
    grid-template-columns: repeat(10, 1fr) !important;
  }

  .--grid-col-11-mo {
    grid-template-columns: repeat(11, 1fr) !important;
  }

  .--grid-col-12-mo {
    grid-template-columns: repeat(12, 1fr) !important;
  }
}
.--gap-1 {
  grid-gap: calc(1rem / 10);
}

.--gap-2 {
  grid-gap: calc(2rem / 10);
}

.--gap-3 {
  grid-gap: calc(3rem / 10);
}

.--gap-4 {
  grid-gap: calc(4rem / 10);
}

.--gap-5 {
  grid-gap: calc(5rem / 10);
}

.--gap-6 {
  grid-gap: calc(6rem / 10);
}

.--gap-7 {
  grid-gap: calc(7rem / 10);
}

.--gap-8 {
  grid-gap: calc(8rem / 10);
}

.--gap-9 {
  grid-gap: calc(9rem / 10);
}

.--gap-10 {
  grid-gap: calc(10rem / 10);
}

.--gap-11 {
  grid-gap: calc(11rem / 10);
}

.--gap-12 {
  grid-gap: calc(12rem / 10);
}

.--gap-13 {
  grid-gap: calc(13rem / 10);
}

.--gap-14 {
  grid-gap: calc(14rem / 10);
}

.--gap-15 {
  grid-gap: calc(15rem / 10);
}

.--gap-16 {
  grid-gap: calc(16rem / 10);
}

.--gap-17 {
  grid-gap: calc(17rem / 10);
}

.--gap-18 {
  grid-gap: calc(18rem / 10);
}

.--gap-19 {
  grid-gap: calc(19rem / 10);
}

.--gap-20 {
  grid-gap: calc(20rem / 10);
}

.--gap-21 {
  grid-gap: calc(21rem / 10);
}

.--gap-22 {
  grid-gap: calc(22rem / 10);
}

.--gap-23 {
  grid-gap: calc(23rem / 10);
}

.--gap-24 {
  grid-gap: calc(24rem / 10);
}

.--gap-25 {
  grid-gap: calc(25rem / 10);
}

.--gap-26 {
  grid-gap: calc(26rem / 10);
}

.--gap-27 {
  grid-gap: calc(27rem / 10);
}

.--gap-28 {
  grid-gap: calc(28rem / 10);
}

.--gap-29 {
  grid-gap: calc(29rem / 10);
}

.--gap-30 {
  grid-gap: calc(30rem / 10);
}

.--gap-31 {
  grid-gap: calc(31rem / 10);
}

.--gap-32 {
  grid-gap: calc(32rem / 10);
}

.--gap-33 {
  grid-gap: calc(33rem / 10);
}

.--gap-34 {
  grid-gap: calc(34rem / 10);
}

.--gap-35 {
  grid-gap: calc(35rem / 10);
}

.--gap-36 {
  grid-gap: calc(36rem / 10);
}

.--gap-37 {
  grid-gap: calc(37rem / 10);
}

.--gap-38 {
  grid-gap: calc(38rem / 10);
}

.--gap-39 {
  grid-gap: calc(39rem / 10);
}

.--gap-40 {
  grid-gap: calc(40rem / 10);
}

.--gap-41 {
  grid-gap: calc(41rem / 10);
}

.--gap-42 {
  grid-gap: calc(42rem / 10);
}

.--gap-43 {
  grid-gap: calc(43rem / 10);
}

.--gap-44 {
  grid-gap: calc(44rem / 10);
}

.--gap-45 {
  grid-gap: calc(45rem / 10);
}

.--gap-46 {
  grid-gap: calc(46rem / 10);
}

.--gap-47 {
  grid-gap: calc(47rem / 10);
}

.--gap-48 {
  grid-gap: calc(48rem / 10);
}

.--gap-49 {
  grid-gap: calc(49rem / 10);
}

.--gap-50 {
  grid-gap: calc(50rem / 10);
}

.--gap-51 {
  grid-gap: calc(51rem / 10);
}

.--gap-52 {
  grid-gap: calc(52rem / 10);
}

.--gap-53 {
  grid-gap: calc(53rem / 10);
}

.--gap-54 {
  grid-gap: calc(54rem / 10);
}

.--gap-55 {
  grid-gap: calc(55rem / 10);
}

.--gap-56 {
  grid-gap: calc(56rem / 10);
}

.--gap-57 {
  grid-gap: calc(57rem / 10);
}

.--gap-58 {
  grid-gap: calc(58rem / 10);
}

.--gap-59 {
  grid-gap: calc(59rem / 10);
}

.--gap-60 {
  grid-gap: calc(60rem / 10);
}

.--gap-61 {
  grid-gap: calc(61rem / 10);
}

.--gap-62 {
  grid-gap: calc(62rem / 10);
}

.--gap-63 {
  grid-gap: calc(63rem / 10);
}

.--gap-64 {
  grid-gap: calc(64rem / 10);
}

.--gap-65 {
  grid-gap: calc(65rem / 10);
}

.--gap-66 {
  grid-gap: calc(66rem / 10);
}

.--gap-67 {
  grid-gap: calc(67rem / 10);
}

.--gap-68 {
  grid-gap: calc(68rem / 10);
}

.--gap-69 {
  grid-gap: calc(69rem / 10);
}

.--gap-70 {
  grid-gap: calc(70rem / 10);
}

.--gap-71 {
  grid-gap: calc(71rem / 10);
}

.--gap-72 {
  grid-gap: calc(72rem / 10);
}

.--gap-73 {
  grid-gap: calc(73rem / 10);
}

.--gap-74 {
  grid-gap: calc(74rem / 10);
}

.--gap-75 {
  grid-gap: calc(75rem / 10);
}

.--gap-76 {
  grid-gap: calc(76rem / 10);
}

.--gap-77 {
  grid-gap: calc(77rem / 10);
}

.--gap-78 {
  grid-gap: calc(78rem / 10);
}

.--gap-79 {
  grid-gap: calc(79rem / 10);
}

.--gap-80 {
  grid-gap: calc(80rem / 10);
}

.--gap-81 {
  grid-gap: calc(81rem / 10);
}

.--gap-82 {
  grid-gap: calc(82rem / 10);
}

.--gap-83 {
  grid-gap: calc(83rem / 10);
}

.--gap-84 {
  grid-gap: calc(84rem / 10);
}

.--gap-85 {
  grid-gap: calc(85rem / 10);
}

.--gap-86 {
  grid-gap: calc(86rem / 10);
}

.--gap-87 {
  grid-gap: calc(87rem / 10);
}

.--gap-88 {
  grid-gap: calc(88rem / 10);
}

.--gap-89 {
  grid-gap: calc(89rem / 10);
}

.--gap-90 {
  grid-gap: calc(90rem / 10);
}

.--gap-91 {
  grid-gap: calc(91rem / 10);
}

.--gap-92 {
  grid-gap: calc(92rem / 10);
}

.--gap-93 {
  grid-gap: calc(93rem / 10);
}

.--gap-94 {
  grid-gap: calc(94rem / 10);
}

.--gap-95 {
  grid-gap: calc(95rem / 10);
}

.--gap-96 {
  grid-gap: calc(96rem / 10);
}

.--gap-97 {
  grid-gap: calc(97rem / 10);
}

.--gap-98 {
  grid-gap: calc(98rem / 10);
}

.--gap-99 {
  grid-gap: calc(99rem / 10);
}

.--gap-100 {
  grid-gap: calc(100rem / 10);
}

.align-left {
  text-align: left !important;
}

.align-center {
  text-align: center !important;
}

.align-right {
  text-align: right !important;
}

.pre-line {
  white-space: pre-line;
}

.--bold {
  font-weight: bold !important;
}

.--normal {
  font-weight: normal !important;
}

.--lighter {
  font-weight: lighter !important;
}

.--relative {
  position: relative;
}

.--pointer {
  cursor: pointer;
}

.--underline {
  text-decoration: underline;
}

.--hidden {
  display: none !important;
}

.--inherit {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  text-align: inherit;
}

.--ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.--disabled {
  user-select: none;
  pointer-events: none;
}

label * {
  user-select: none;
}

.form-checkbox {
  color: var(--theme);
  pointer-events: none;
}
.form-checkbox input[type=checkbox] {
  outline: none;
  -webkit-appearance: none;
}

.form-radio {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
}

::placeholder {
  color: #9394AD;
}

input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.link {
  color: #0088FF !important;
}

.btn {
  cursor: pointer;
}
.btn.btn-theme {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--theme);
  border: 1px solid var(--theme);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: #555555;
  transition-duration: 0.2s;
}
.btn.btn-theme:hover {
  background-color: transparent;
  color: var(--theme);
}
.btn.btn-theme:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-theme {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--theme);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--theme);
  transition-duration: 0.2s;
}
.btn.btn-outline-theme:hover {
  background-color: var(--theme);
  color: var(--color-F);
}
.btn.btn-outline-theme:active {
  transform: translateY(0.2rem);
}
.btn.btn-theme2 {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--theme2);
  border: 1px solid var(--theme2);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-theme2:hover {
  background-color: transparent;
  color: var(--theme2);
}
.btn.btn-theme2:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-theme2 {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--theme2);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--theme2);
  transition-duration: 0.2s;
}
.btn.btn-outline-theme2:hover {
  background-color: var(--theme2);
  color: var(--color-F);
}
.btn.btn-outline-theme2:active {
  transform: translateY(0.2rem);
}
.btn.btn-red {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--red);
  border: 1px solid var(--red);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-red:hover {
  background-color: transparent;
  color: var(--red);
}
.btn.btn-red:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-red {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--red);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--red);
  transition-duration: 0.2s;
}
.btn.btn-outline-red:hover {
  background-color: var(--red);
  color: var(--color-F);
}
.btn.btn-outline-red:active {
  transform: translateY(0.2rem);
}
.btn.btn-pink {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--pink);
  border: 1px solid var(--pink);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-pink:hover {
  background-color: transparent;
  color: var(--pink);
}
.btn.btn-pink:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-pink {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--pink);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--pink);
  transition-duration: 0.2s;
}
.btn.btn-outline-pink:hover {
  background-color: var(--pink);
  color: var(--color-F);
}
.btn.btn-outline-pink:active {
  transform: translateY(0.2rem);
}
.btn.btn-sky {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--sky);
  border: 1px solid var(--sky);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-sky:hover {
  background-color: transparent;
  color: var(--sky);
}
.btn.btn-sky:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-sky {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--sky);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--sky);
  transition-duration: 0.2s;
}
.btn.btn-outline-sky:hover {
  background-color: var(--sky);
  color: var(--color-F);
}
.btn.btn-outline-sky:active {
  transform: translateY(0.2rem);
}
.btn.btn-blue {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--blue);
  border: 1px solid var(--blue);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-blue:hover {
  background-color: transparent;
  color: var(--blue);
}
.btn.btn-blue:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-blue {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--blue);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--blue);
  transition-duration: 0.2s;
}
.btn.btn-outline-blue:hover {
  background-color: var(--blue);
  color: var(--color-F);
}
.btn.btn-outline-blue:active {
  transform: translateY(0.2rem);
}
.btn.btn-green {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--green);
  border: 1px solid var(--green);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-green:hover {
  background-color: transparent;
  color: var(--green);
}
.btn.btn-green:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-green {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--green);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--green);
  transition-duration: 0.2s;
}
.btn.btn-outline-green:hover {
  background-color: var(--green);
  color: var(--color-F);
}
.btn.btn-outline-green:active {
  transform: translateY(0.2rem);
}
.btn.btn-light-green {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--light-green);
  border: 1px solid var(--light-green);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-light-green:hover {
  background-color: transparent;
  color: var(--light-green);
}
.btn.btn-light-green:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-light-green {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--light-green);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--light-green);
  transition-duration: 0.2s;
}
.btn.btn-outline-light-green:hover {
  background-color: var(--light-green);
  color: var(--color-F);
}
.btn.btn-outline-light-green:active {
  transform: translateY(0.2rem);
}
.btn.btn-yellow {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--yellow);
  border: 1px solid var(--yellow);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-yellow:hover {
  background-color: transparent;
  color: var(--yellow);
}
.btn.btn-yellow:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-yellow {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--yellow);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--yellow);
  transition-duration: 0.2s;
}
.btn.btn-outline-yellow:hover {
  background-color: var(--yellow);
  color: var(--color-F);
}
.btn.btn-outline-yellow:active {
  transform: translateY(0.2rem);
}
.btn.btn-line {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--line);
  border: 1px solid var(--line);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-line:hover {
  background-color: transparent;
  color: var(--line);
}
.btn.btn-line:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-line {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--line);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--line);
  transition-duration: 0.2s;
}
.btn.btn-outline-line:hover {
  background-color: var(--line);
  color: var(--color-F);
}
.btn.btn-outline-line:active {
  transform: translateY(0.2rem);
}
.btn.btn-white {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--white);
  border: 1px solid var(--white);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--color-0);
  transition-duration: 0.2s;
}
.btn.btn-white:hover {
  background-color: transparent;
  color: var(--color-0);
}
.btn.btn-white:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-white {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--white);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--color-0);
  transition-duration: 0.2s;
}
.btn.btn-outline-white:hover {
  background-color: var(--white);
  color: var(--color-F);
}
.btn.btn-outline-white:active {
  transform: translateY(0.2rem);
}
.btn.btn-black {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--black);
  border: 1px solid var(--black);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-black:hover {
  background-color: transparent;
  color: var(--black);
}
.btn.btn-black:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-black {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--black);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--black);
  transition-duration: 0.2s;
}
.btn.btn-outline-black:hover {
  background-color: var(--black);
  color: var(--color-F);
}
.btn.btn-outline-black:active {
  transform: translateY(0.2rem);
}
.btn.btn-gray {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--gray);
  border: 1px solid var(--gray);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-gray:hover {
  background-color: transparent;
  color: var(--gray);
}
.btn.btn-gray:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-gray {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--gray);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--gray);
  transition-duration: 0.2s;
}
.btn.btn-outline-gray:hover {
  background-color: var(--gray);
  color: var(--color-F);
}
.btn.btn-outline-gray:active {
  transform: translateY(0.2rem);
}
.btn.btn-dark {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--dark);
  border: 1px solid var(--dark);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-dark:hover {
  background-color: transparent;
  color: var(--dark);
}
.btn.btn-dark:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-dark {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--dark);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--dark);
  transition-duration: 0.2s;
}
.btn.btn-outline-dark:hover {
  background-color: var(--dark);
  color: var(--color-F);
}
.btn.btn-outline-dark:active {
  transform: translateY(0.2rem);
}
.btn.btn-grayblue {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--grayblue);
  border: 1px solid var(--grayblue);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-grayblue:hover {
  background-color: transparent;
  color: var(--grayblue);
}
.btn.btn-grayblue:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-grayblue {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--grayblue);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--grayblue);
  transition-duration: 0.2s;
}
.btn.btn-outline-grayblue:hover {
  background-color: var(--grayblue);
  color: var(--color-F);
}
.btn.btn-outline-grayblue:active {
  transform: translateY(0.2rem);
}
.btn.btn-disabled {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--disabled);
  border: 1px solid var(--disabled);
  border-radius: 0rem;
  cursor: default !important;
  pointer-events: none !important;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-disabled:hover {
  background-color: transparent;
  color: var(--disabled);
}
.btn.btn-disabled:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-disabled {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--disabled);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--color-0);
  transition-duration: 0.2s;
}
.btn.btn-outline-disabled:hover {
  background-color: var(--disabled);
  color: var(--color-F);
}
.btn.btn-outline-disabled:active {
  transform: translateY(0.2rem);
}
.btn.btn-default {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--default);
  border: 1px solid var(--default);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: #555555;
  border-color: #D6D6D6;
  transition-duration: 0.2s;
}
.btn.btn-default:hover {
  background-color: transparent;
  color: var(--color-0);
}
.btn.btn-default:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-default {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--default);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--color-0);
  transition-duration: 0.2s;
}
.btn.btn-outline-default:hover {
  background-color: var(--default);
  color: var(--color-F);
}
.btn.btn-outline-default:active {
  transform: translateY(0.2rem);
}
.btn.btn-danger {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--danger);
  border: 1px solid var(--danger);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: white;
  transition-duration: 0.2s;
}
.btn.btn-danger:hover {
  background-color: transparent;
  color: var(--danger);
}
.btn.btn-danger:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-danger {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--danger);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--danger);
  transition-duration: 0.2s;
}
.btn.btn-outline-danger:hover {
  background-color: var(--danger);
  color: var(--color-F);
}
.btn.btn-outline-danger:active {
  transform: translateY(0.2rem);
}
.btn.btn-large {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--large);
  border: 1px solid var(--large);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-large:hover {
  background-color: transparent;
  color: var(--large);
}
.btn.btn-large:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-large {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--large);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--large);
  transition-duration: 0.2s;
}
.btn.btn-outline-large:hover {
  background-color: var(--large);
  color: var(--color-F);
}
.btn.btn-outline-large:active {
  transform: translateY(0.2rem);
}
.btn.btn-medium {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--medium);
  border: 1px solid var(--medium);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-medium:hover {
  background-color: transparent;
  color: var(--medium);
}
.btn.btn-medium:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-medium {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--medium);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--medium);
  transition-duration: 0.2s;
}
.btn.btn-outline-medium:hover {
  background-color: var(--medium);
  color: var(--color-F);
}
.btn.btn-outline-medium:active {
  transform: translateY(0.2rem);
}
.btn.btn-odd {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--odd);
  border: 1px solid var(--odd);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-odd:hover {
  background-color: transparent;
  color: var(--odd);
}
.btn.btn-odd:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-odd {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--odd);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--odd);
  transition-duration: 0.2s;
}
.btn.btn-outline-odd:hover {
  background-color: var(--odd);
  color: var(--color-F);
}
.btn.btn-outline-odd:active {
  transform: translateY(0.2rem);
}
.btn.btn-even {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--even);
  border: 1px solid var(--even);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-even:hover {
  background-color: transparent;
  color: var(--even);
}
.btn.btn-even:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-even {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--even);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--even);
  transition-duration: 0.2s;
}
.btn.btn-outline-even:hover {
  background-color: var(--even);
  color: var(--color-F);
}
.btn.btn-outline-even:active {
  transform: translateY(0.2rem);
}
.btn.btn-small {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--small);
  border: 1px solid var(--small);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-small:hover {
  background-color: transparent;
  color: var(--small);
}
.btn.btn-small:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-small {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--small);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--small);
  transition-duration: 0.2s;
}
.btn.btn-outline-small:hover {
  background-color: var(--small);
  color: var(--color-F);
}
.btn.btn-outline-small:active {
  transform: translateY(0.2rem);
}
.btn.btn-gb-odd {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--gb-odd);
  border: 1px solid var(--gb-odd);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-gb-odd:hover {
  background-color: transparent;
  color: var(--gb-odd);
}
.btn.btn-gb-odd:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-gb-odd {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--gb-odd);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--gb-odd);
  transition-duration: 0.2s;
}
.btn.btn-outline-gb-odd:hover {
  background-color: var(--gb-odd);
  color: var(--color-F);
}
.btn.btn-outline-gb-odd:active {
  transform: translateY(0.2rem);
}
.btn.btn-gb-even {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--gb-even);
  border: 1px solid var(--gb-even);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-gb-even:hover {
  background-color: transparent;
  color: var(--gb-even);
}
.btn.btn-gb-even:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-gb-even {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--gb-even);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--gb-even);
  transition-duration: 0.2s;
}
.btn.btn-outline-gb-even:hover {
  background-color: var(--gb-even);
  color: var(--color-F);
}
.btn.btn-outline-gb-even:active {
  transform: translateY(0.2rem);
}
.btn.btn-gb-under {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--gb-under);
  border: 1px solid var(--gb-under);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-gb-under:hover {
  background-color: transparent;
  color: var(--gb-under);
}
.btn.btn-gb-under:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-gb-under {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--gb-under);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--gb-under);
  transition-duration: 0.2s;
}
.btn.btn-outline-gb-under:hover {
  background-color: var(--gb-under);
  color: var(--color-F);
}
.btn.btn-outline-gb-under:active {
  transform: translateY(0.2rem);
}
.btn.btn-gb-over {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--gb-over);
  border: 1px solid var(--gb-over);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-gb-over:hover {
  background-color: transparent;
  color: var(--gb-over);
}
.btn.btn-gb-over:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-gb-over {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--gb-over);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--gb-over);
  transition-duration: 0.2s;
}
.btn.btn-outline-gb-over:hover {
  background-color: var(--gb-over);
  color: var(--color-F);
}
.btn.btn-outline-gb-over:active {
  transform: translateY(0.2rem);
}
.btn.btn-l3e {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--l3e);
  border: 1px solid var(--l3e);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-l3e:hover {
  background-color: transparent;
  color: var(--l3e);
}
.btn.btn-l3e:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-l3e {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--l3e);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--l3e);
  transition-duration: 0.2s;
}
.btn.btn-outline-l3e:hover {
  background-color: var(--l3e);
  color: var(--color-F);
}
.btn.btn-outline-l3e:active {
  transform: translateY(0.2rem);
}
.btn.btn-l4o {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--l4o);
  border: 1px solid var(--l4o);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-l4o:hover {
  background-color: transparent;
  color: var(--l4o);
}
.btn.btn-l4o:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-l4o {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--l4o);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--l4o);
  transition-duration: 0.2s;
}
.btn.btn-outline-l4o:hover {
  background-color: var(--l4o);
  color: var(--color-F);
}
.btn.btn-outline-l4o:active {
  transform: translateY(0.2rem);
}
.btn.btn-r3o {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--r3o);
  border: 1px solid var(--r3o);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-r3o:hover {
  background-color: transparent;
  color: var(--r3o);
}
.btn.btn-r3o:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-r3o {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--r3o);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--r3o);
  transition-duration: 0.2s;
}
.btn.btn-outline-r3o:hover {
  background-color: var(--r3o);
  color: var(--color-F);
}
.btn.btn-outline-r3o:active {
  transform: translateY(0.2rem);
}
.btn.btn-r4e {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--r4e);
  border: 1px solid var(--r4e);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-r4e:hover {
  background-color: transparent;
  color: var(--r4e);
}
.btn.btn-r4e:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-r4e {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--r4e);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--r4e);
  transition-duration: 0.2s;
}
.btn.btn-outline-r4e:hover {
  background-color: var(--r4e);
  color: var(--color-F);
}
.btn.btn-outline-r4e:active {
  transform: translateY(0.2rem);
}
.btn.btn-default-font {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--default-font);
  border: 1px solid var(--default-font);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-default-font:hover {
  background-color: transparent;
  color: var(--default-font);
}
.btn.btn-default-font:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-default-font {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--default-font);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--default-font);
  transition-duration: 0.2s;
}
.btn.btn-outline-default-font:hover {
  background-color: var(--default-font);
  color: var(--color-F);
}
.btn.btn-outline-default-font:active {
  transform: translateY(0.2rem);
}
.btn.btn-default-font2 {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--default-font2);
  border: 1px solid var(--default-font2);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-default-font2:hover {
  background-color: transparent;
  color: var(--default-font2);
}
.btn.btn-default-font2:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-default-font2 {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--default-font2);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--default-font2);
  transition-duration: 0.2s;
}
.btn.btn-outline-default-font2:hover {
  background-color: var(--default-font2);
  color: var(--color-F);
}
.btn.btn-outline-default-font2:active {
  transform: translateY(0.2rem);
}
.btn.btn-title {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--title);
  border: 1px solid var(--title);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-title:hover {
  background-color: transparent;
  color: var(--title);
}
.btn.btn-title:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-title {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--title);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--title);
  transition-duration: 0.2s;
}
.btn.btn-outline-title:hover {
  background-color: var(--title);
  color: var(--color-F);
}
.btn.btn-outline-title:active {
  transform: translateY(0.2rem);
}
.btn.btn-content {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--content);
  border: 1px solid var(--content);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.btn.btn-content:hover {
  background-color: transparent;
  color: var(--content);
}
.btn.btn-content:active {
  transition-duration: 0.1s;
  transform: translateY(0.2rem);
}
.btn.btn-outline-content {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--content);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: var(--content);
  transition-duration: 0.2s;
}
.btn.btn-outline-content:hover {
  background-color: var(--content);
  color: var(--color-F);
}
.btn.btn-outline-content:active {
  transform: translateY(0.2rem);
}
.btn.--btn-ht-1 {
  height: 0.1rem;
}
.btn.--btn-wt-1 {
  width: 0.1rem;
}
.btn.--btn-ht-2 {
  height: 0.2rem;
}
.btn.--btn-wt-2 {
  width: 0.2rem;
}
.btn.--btn-ht-3 {
  height: 0.3rem;
}
.btn.--btn-wt-3 {
  width: 0.3rem;
}
.btn.--btn-ht-4 {
  height: 0.4rem;
}
.btn.--btn-wt-4 {
  width: 0.4rem;
}
.btn.--btn-ht-5 {
  height: 0.5rem;
}
.btn.--btn-wt-5 {
  width: 0.5rem;
}
.btn.--btn-ht-6 {
  height: 0.6rem;
}
.btn.--btn-wt-6 {
  width: 0.6rem;
}
.btn.--btn-ht-7 {
  height: 0.7rem;
}
.btn.--btn-wt-7 {
  width: 0.7rem;
}
.btn.--btn-ht-8 {
  height: 0.8rem;
}
.btn.--btn-wt-8 {
  width: 0.8rem;
}
.btn.--btn-ht-9 {
  height: 0.9rem;
}
.btn.--btn-wt-9 {
  width: 0.9rem;
}
.btn.--btn-ht-10 {
  height: 1rem;
}
.btn.--btn-wt-10 {
  width: 1rem;
}
.btn.--btn-ht-11 {
  height: 1.1rem;
}
.btn.--btn-wt-11 {
  width: 1.1rem;
}
.btn.--btn-ht-12 {
  height: 1.2rem;
}
.btn.--btn-wt-12 {
  width: 1.2rem;
}
.btn.--btn-ht-13 {
  height: 1.3rem;
}
.btn.--btn-wt-13 {
  width: 1.3rem;
}
.btn.--btn-ht-14 {
  height: 1.4rem;
}
.btn.--btn-wt-14 {
  width: 1.4rem;
}
.btn.--btn-ht-15 {
  height: 1.5rem;
}
.btn.--btn-wt-15 {
  width: 1.5rem;
}
.btn.--btn-ht-16 {
  height: 1.6rem;
}
.btn.--btn-wt-16 {
  width: 1.6rem;
}
.btn.--btn-ht-17 {
  height: 1.7rem;
}
.btn.--btn-wt-17 {
  width: 1.7rem;
}
.btn.--btn-ht-18 {
  height: 1.8rem;
}
.btn.--btn-wt-18 {
  width: 1.8rem;
}
.btn.--btn-ht-19 {
  height: 1.9rem;
}
.btn.--btn-wt-19 {
  width: 1.9rem;
}
.btn.--btn-ht-20 {
  height: 2rem;
}
.btn.--btn-wt-20 {
  width: 2rem;
}
.btn.--btn-ht-21 {
  height: 2.1rem;
}
.btn.--btn-wt-21 {
  width: 2.1rem;
}
.btn.--btn-ht-22 {
  height: 2.2rem;
}
.btn.--btn-wt-22 {
  width: 2.2rem;
}
.btn.--btn-ht-23 {
  height: 2.3rem;
}
.btn.--btn-wt-23 {
  width: 2.3rem;
}
.btn.--btn-ht-24 {
  height: 2.4rem;
}
.btn.--btn-wt-24 {
  width: 2.4rem;
}
.btn.--btn-ht-25 {
  height: 2.5rem;
}
.btn.--btn-wt-25 {
  width: 2.5rem;
}
.btn.--btn-ht-26 {
  height: 2.6rem;
}
.btn.--btn-wt-26 {
  width: 2.6rem;
}
.btn.--btn-ht-27 {
  height: 2.7rem;
}
.btn.--btn-wt-27 {
  width: 2.7rem;
}
.btn.--btn-ht-28 {
  height: 2.8rem;
}
.btn.--btn-wt-28 {
  width: 2.8rem;
}
.btn.--btn-ht-29 {
  height: 2.9rem;
}
.btn.--btn-wt-29 {
  width: 2.9rem;
}
.btn.--btn-ht-30 {
  height: 3rem;
}
.btn.--btn-wt-30 {
  width: 3rem;
}
.btn.--btn-ht-31 {
  height: 3.1rem;
}
.btn.--btn-wt-31 {
  width: 3.1rem;
}
.btn.--btn-ht-32 {
  height: 3.2rem;
}
.btn.--btn-wt-32 {
  width: 3.2rem;
}
.btn.--btn-ht-33 {
  height: 3.3rem;
}
.btn.--btn-wt-33 {
  width: 3.3rem;
}
.btn.--btn-ht-34 {
  height: 3.4rem;
}
.btn.--btn-wt-34 {
  width: 3.4rem;
}
.btn.--btn-ht-35 {
  height: 3.5rem;
}
.btn.--btn-wt-35 {
  width: 3.5rem;
}
.btn.--btn-ht-36 {
  height: 3.6rem;
}
.btn.--btn-wt-36 {
  width: 3.6rem;
}
.btn.--btn-ht-37 {
  height: 3.7rem;
}
.btn.--btn-wt-37 {
  width: 3.7rem;
}
.btn.--btn-ht-38 {
  height: 3.8rem;
}
.btn.--btn-wt-38 {
  width: 3.8rem;
}
.btn.--btn-ht-39 {
  height: 3.9rem;
}
.btn.--btn-wt-39 {
  width: 3.9rem;
}
.btn.--btn-ht-40 {
  height: 4rem;
}
.btn.--btn-wt-40 {
  width: 4rem;
}
.btn.--btn-ht-41 {
  height: 4.1rem;
}
.btn.--btn-wt-41 {
  width: 4.1rem;
}
.btn.--btn-ht-42 {
  height: 4.2rem;
}
.btn.--btn-wt-42 {
  width: 4.2rem;
}
.btn.--btn-ht-43 {
  height: 4.3rem;
}
.btn.--btn-wt-43 {
  width: 4.3rem;
}
.btn.--btn-ht-44 {
  height: 4.4rem;
}
.btn.--btn-wt-44 {
  width: 4.4rem;
}
.btn.--btn-ht-45 {
  height: 4.5rem;
}
.btn.--btn-wt-45 {
  width: 4.5rem;
}
.btn.--btn-ht-46 {
  height: 4.6rem;
}
.btn.--btn-wt-46 {
  width: 4.6rem;
}
.btn.--btn-ht-47 {
  height: 4.7rem;
}
.btn.--btn-wt-47 {
  width: 4.7rem;
}
.btn.--btn-ht-48 {
  height: 4.8rem;
}
.btn.--btn-wt-48 {
  width: 4.8rem;
}
.btn.--btn-ht-49 {
  height: 4.9rem;
}
.btn.--btn-wt-49 {
  width: 4.9rem;
}
.btn.--btn-ht-50 {
  height: 5rem;
}
.btn.--btn-wt-50 {
  width: 5rem;
}
.btn.--btn-ht-51 {
  height: 5.1rem;
}
.btn.--btn-wt-51 {
  width: 5.1rem;
}
.btn.--btn-ht-52 {
  height: 5.2rem;
}
.btn.--btn-wt-52 {
  width: 5.2rem;
}
.btn.--btn-ht-53 {
  height: 5.3rem;
}
.btn.--btn-wt-53 {
  width: 5.3rem;
}
.btn.--btn-ht-54 {
  height: 5.4rem;
}
.btn.--btn-wt-54 {
  width: 5.4rem;
}
.btn.--btn-ht-55 {
  height: 5.5rem;
}
.btn.--btn-wt-55 {
  width: 5.5rem;
}
.btn.--btn-ht-56 {
  height: 5.6rem;
}
.btn.--btn-wt-56 {
  width: 5.6rem;
}
.btn.--btn-ht-57 {
  height: 5.7rem;
}
.btn.--btn-wt-57 {
  width: 5.7rem;
}
.btn.--btn-ht-58 {
  height: 5.8rem;
}
.btn.--btn-wt-58 {
  width: 5.8rem;
}
.btn.--btn-ht-59 {
  height: 5.9rem;
}
.btn.--btn-wt-59 {
  width: 5.9rem;
}
.btn.--btn-ht-60 {
  height: 6rem;
}
.btn.--btn-wt-60 {
  width: 6rem;
}
.btn.--btn-ht-61 {
  height: 6.1rem;
}
.btn.--btn-wt-61 {
  width: 6.1rem;
}
.btn.--btn-ht-62 {
  height: 6.2rem;
}
.btn.--btn-wt-62 {
  width: 6.2rem;
}
.btn.--btn-ht-63 {
  height: 6.3rem;
}
.btn.--btn-wt-63 {
  width: 6.3rem;
}
.btn.--btn-ht-64 {
  height: 6.4rem;
}
.btn.--btn-wt-64 {
  width: 6.4rem;
}
.btn.--btn-ht-65 {
  height: 6.5rem;
}
.btn.--btn-wt-65 {
  width: 6.5rem;
}
.btn.--btn-ht-66 {
  height: 6.6rem;
}
.btn.--btn-wt-66 {
  width: 6.6rem;
}
.btn.--btn-ht-67 {
  height: 6.7rem;
}
.btn.--btn-wt-67 {
  width: 6.7rem;
}
.btn.--btn-ht-68 {
  height: 6.8rem;
}
.btn.--btn-wt-68 {
  width: 6.8rem;
}
.btn.--btn-ht-69 {
  height: 6.9rem;
}
.btn.--btn-wt-69 {
  width: 6.9rem;
}
.btn.--btn-ht-70 {
  height: 7rem;
}
.btn.--btn-wt-70 {
  width: 7rem;
}
.btn.--btn-ht-71 {
  height: 7.1rem;
}
.btn.--btn-wt-71 {
  width: 7.1rem;
}
.btn.--btn-ht-72 {
  height: 7.2rem;
}
.btn.--btn-wt-72 {
  width: 7.2rem;
}
.btn.--btn-ht-73 {
  height: 7.3rem;
}
.btn.--btn-wt-73 {
  width: 7.3rem;
}
.btn.--btn-ht-74 {
  height: 7.4rem;
}
.btn.--btn-wt-74 {
  width: 7.4rem;
}
.btn.--btn-ht-75 {
  height: 7.5rem;
}
.btn.--btn-wt-75 {
  width: 7.5rem;
}
.btn.--btn-ht-76 {
  height: 7.6rem;
}
.btn.--btn-wt-76 {
  width: 7.6rem;
}
.btn.--btn-ht-77 {
  height: 7.7rem;
}
.btn.--btn-wt-77 {
  width: 7.7rem;
}
.btn.--btn-ht-78 {
  height: 7.8rem;
}
.btn.--btn-wt-78 {
  width: 7.8rem;
}
.btn.--btn-ht-79 {
  height: 7.9rem;
}
.btn.--btn-wt-79 {
  width: 7.9rem;
}
.btn.--btn-ht-80 {
  height: 8rem;
}
.btn.--btn-wt-80 {
  width: 8rem;
}
.btn.--btn-ht-81 {
  height: 8.1rem;
}
.btn.--btn-wt-81 {
  width: 8.1rem;
}
.btn.--btn-ht-82 {
  height: 8.2rem;
}
.btn.--btn-wt-82 {
  width: 8.2rem;
}
.btn.--btn-ht-83 {
  height: 8.3rem;
}
.btn.--btn-wt-83 {
  width: 8.3rem;
}
.btn.--btn-ht-84 {
  height: 8.4rem;
}
.btn.--btn-wt-84 {
  width: 8.4rem;
}
.btn.--btn-ht-85 {
  height: 8.5rem;
}
.btn.--btn-wt-85 {
  width: 8.5rem;
}
.btn.--btn-ht-86 {
  height: 8.6rem;
}
.btn.--btn-wt-86 {
  width: 8.6rem;
}
.btn.--btn-ht-87 {
  height: 8.7rem;
}
.btn.--btn-wt-87 {
  width: 8.7rem;
}
.btn.--btn-ht-88 {
  height: 8.8rem;
}
.btn.--btn-wt-88 {
  width: 8.8rem;
}
.btn.--btn-ht-89 {
  height: 8.9rem;
}
.btn.--btn-wt-89 {
  width: 8.9rem;
}
.btn.--btn-ht-90 {
  height: 9rem;
}
.btn.--btn-wt-90 {
  width: 9rem;
}
.btn.--btn-ht-91 {
  height: 9.1rem;
}
.btn.--btn-wt-91 {
  width: 9.1rem;
}
.btn.--btn-ht-92 {
  height: 9.2rem;
}
.btn.--btn-wt-92 {
  width: 9.2rem;
}
.btn.--btn-ht-93 {
  height: 9.3rem;
}
.btn.--btn-wt-93 {
  width: 9.3rem;
}
.btn.--btn-ht-94 {
  height: 9.4rem;
}
.btn.--btn-wt-94 {
  width: 9.4rem;
}
.btn.--btn-ht-95 {
  height: 9.5rem;
}
.btn.--btn-wt-95 {
  width: 9.5rem;
}
.btn.--btn-ht-96 {
  height: 9.6rem;
}
.btn.--btn-wt-96 {
  width: 9.6rem;
}
.btn.--btn-ht-97 {
  height: 9.7rem;
}
.btn.--btn-wt-97 {
  width: 9.7rem;
}
.btn.--btn-ht-98 {
  height: 9.8rem;
}
.btn.--btn-wt-98 {
  width: 9.8rem;
}
.btn.--btn-ht-99 {
  height: 9.9rem;
}
.btn.--btn-wt-99 {
  width: 9.9rem;
}
.btn.--btn-ht-100 {
  height: 10rem;
}
.btn.--btn-wt-100 {
  width: 10rem;
}
.btn.--btn-ht-101 {
  height: 10.1rem;
}
.btn.--btn-wt-101 {
  width: 10.1rem;
}
.btn.--btn-ht-102 {
  height: 10.2rem;
}
.btn.--btn-wt-102 {
  width: 10.2rem;
}
.btn.--btn-ht-103 {
  height: 10.3rem;
}
.btn.--btn-wt-103 {
  width: 10.3rem;
}
.btn.--btn-ht-104 {
  height: 10.4rem;
}
.btn.--btn-wt-104 {
  width: 10.4rem;
}
.btn.--btn-ht-105 {
  height: 10.5rem;
}
.btn.--btn-wt-105 {
  width: 10.5rem;
}
.btn.--btn-ht-106 {
  height: 10.6rem;
}
.btn.--btn-wt-106 {
  width: 10.6rem;
}
.btn.--btn-ht-107 {
  height: 10.7rem;
}
.btn.--btn-wt-107 {
  width: 10.7rem;
}
.btn.--btn-ht-108 {
  height: 10.8rem;
}
.btn.--btn-wt-108 {
  width: 10.8rem;
}
.btn.--btn-ht-109 {
  height: 10.9rem;
}
.btn.--btn-wt-109 {
  width: 10.9rem;
}
.btn.--btn-ht-110 {
  height: 11rem;
}
.btn.--btn-wt-110 {
  width: 11rem;
}
.btn.--btn-ht-111 {
  height: 11.1rem;
}
.btn.--btn-wt-111 {
  width: 11.1rem;
}
.btn.--btn-ht-112 {
  height: 11.2rem;
}
.btn.--btn-wt-112 {
  width: 11.2rem;
}
.btn.--btn-ht-113 {
  height: 11.3rem;
}
.btn.--btn-wt-113 {
  width: 11.3rem;
}
.btn.--btn-ht-114 {
  height: 11.4rem;
}
.btn.--btn-wt-114 {
  width: 11.4rem;
}
.btn.--btn-ht-115 {
  height: 11.5rem;
}
.btn.--btn-wt-115 {
  width: 11.5rem;
}
.btn.--btn-ht-116 {
  height: 11.6rem;
}
.btn.--btn-wt-116 {
  width: 11.6rem;
}
.btn.--btn-ht-117 {
  height: 11.7rem;
}
.btn.--btn-wt-117 {
  width: 11.7rem;
}
.btn.--btn-ht-118 {
  height: 11.8rem;
}
.btn.--btn-wt-118 {
  width: 11.8rem;
}
.btn.--btn-ht-119 {
  height: 11.9rem;
}
.btn.--btn-wt-119 {
  width: 11.9rem;
}
.btn.--btn-ht-120 {
  height: 12rem;
}
.btn.--btn-wt-120 {
  width: 12rem;
}
.btn.--btn-ht-121 {
  height: 12.1rem;
}
.btn.--btn-wt-121 {
  width: 12.1rem;
}
.btn.--btn-ht-122 {
  height: 12.2rem;
}
.btn.--btn-wt-122 {
  width: 12.2rem;
}
.btn.--btn-ht-123 {
  height: 12.3rem;
}
.btn.--btn-wt-123 {
  width: 12.3rem;
}
.btn.--btn-ht-124 {
  height: 12.4rem;
}
.btn.--btn-wt-124 {
  width: 12.4rem;
}
.btn.--btn-ht-125 {
  height: 12.5rem;
}
.btn.--btn-wt-125 {
  width: 12.5rem;
}
.btn.--btn-ht-126 {
  height: 12.6rem;
}
.btn.--btn-wt-126 {
  width: 12.6rem;
}
.btn.--btn-ht-127 {
  height: 12.7rem;
}
.btn.--btn-wt-127 {
  width: 12.7rem;
}
.btn.--btn-ht-128 {
  height: 12.8rem;
}
.btn.--btn-wt-128 {
  width: 12.8rem;
}
.btn.--btn-ht-129 {
  height: 12.9rem;
}
.btn.--btn-wt-129 {
  width: 12.9rem;
}
.btn.--btn-ht-130 {
  height: 13rem;
}
.btn.--btn-wt-130 {
  width: 13rem;
}
.btn.--btn-ht-131 {
  height: 13.1rem;
}
.btn.--btn-wt-131 {
  width: 13.1rem;
}
.btn.--btn-ht-132 {
  height: 13.2rem;
}
.btn.--btn-wt-132 {
  width: 13.2rem;
}
.btn.--btn-ht-133 {
  height: 13.3rem;
}
.btn.--btn-wt-133 {
  width: 13.3rem;
}
.btn.--btn-ht-134 {
  height: 13.4rem;
}
.btn.--btn-wt-134 {
  width: 13.4rem;
}
.btn.--btn-ht-135 {
  height: 13.5rem;
}
.btn.--btn-wt-135 {
  width: 13.5rem;
}
.btn.--btn-ht-136 {
  height: 13.6rem;
}
.btn.--btn-wt-136 {
  width: 13.6rem;
}
.btn.--btn-ht-137 {
  height: 13.7rem;
}
.btn.--btn-wt-137 {
  width: 13.7rem;
}
.btn.--btn-ht-138 {
  height: 13.8rem;
}
.btn.--btn-wt-138 {
  width: 13.8rem;
}
.btn.--btn-ht-139 {
  height: 13.9rem;
}
.btn.--btn-wt-139 {
  width: 13.9rem;
}
.btn.--btn-ht-140 {
  height: 14rem;
}
.btn.--btn-wt-140 {
  width: 14rem;
}
.btn.--btn-ht-141 {
  height: 14.1rem;
}
.btn.--btn-wt-141 {
  width: 14.1rem;
}
.btn.--btn-ht-142 {
  height: 14.2rem;
}
.btn.--btn-wt-142 {
  width: 14.2rem;
}
.btn.--btn-ht-143 {
  height: 14.3rem;
}
.btn.--btn-wt-143 {
  width: 14.3rem;
}
.btn.--btn-ht-144 {
  height: 14.4rem;
}
.btn.--btn-wt-144 {
  width: 14.4rem;
}
.btn.--btn-ht-145 {
  height: 14.5rem;
}
.btn.--btn-wt-145 {
  width: 14.5rem;
}
.btn.--btn-ht-146 {
  height: 14.6rem;
}
.btn.--btn-wt-146 {
  width: 14.6rem;
}
.btn.--btn-ht-147 {
  height: 14.7rem;
}
.btn.--btn-wt-147 {
  width: 14.7rem;
}
.btn.--btn-ht-148 {
  height: 14.8rem;
}
.btn.--btn-wt-148 {
  width: 14.8rem;
}
.btn.--btn-ht-149 {
  height: 14.9rem;
}
.btn.--btn-wt-149 {
  width: 14.9rem;
}
.btn.--btn-ht-150 {
  height: 15rem;
}
.btn.--btn-wt-150 {
  width: 15rem;
}
.btn.--btn-ht-151 {
  height: 15.1rem;
}
.btn.--btn-wt-151 {
  width: 15.1rem;
}
.btn.--btn-ht-152 {
  height: 15.2rem;
}
.btn.--btn-wt-152 {
  width: 15.2rem;
}
.btn.--btn-ht-153 {
  height: 15.3rem;
}
.btn.--btn-wt-153 {
  width: 15.3rem;
}
.btn.--btn-ht-154 {
  height: 15.4rem;
}
.btn.--btn-wt-154 {
  width: 15.4rem;
}
.btn.--btn-ht-155 {
  height: 15.5rem;
}
.btn.--btn-wt-155 {
  width: 15.5rem;
}
.btn.--btn-ht-156 {
  height: 15.6rem;
}
.btn.--btn-wt-156 {
  width: 15.6rem;
}
.btn.--btn-ht-157 {
  height: 15.7rem;
}
.btn.--btn-wt-157 {
  width: 15.7rem;
}
.btn.--btn-ht-158 {
  height: 15.8rem;
}
.btn.--btn-wt-158 {
  width: 15.8rem;
}
.btn.--btn-ht-159 {
  height: 15.9rem;
}
.btn.--btn-wt-159 {
  width: 15.9rem;
}
.btn.--btn-ht-160 {
  height: 16rem;
}
.btn.--btn-wt-160 {
  width: 16rem;
}
.btn.--btn-ht-161 {
  height: 16.1rem;
}
.btn.--btn-wt-161 {
  width: 16.1rem;
}
.btn.--btn-ht-162 {
  height: 16.2rem;
}
.btn.--btn-wt-162 {
  width: 16.2rem;
}
.btn.--btn-ht-163 {
  height: 16.3rem;
}
.btn.--btn-wt-163 {
  width: 16.3rem;
}
.btn.--btn-ht-164 {
  height: 16.4rem;
}
.btn.--btn-wt-164 {
  width: 16.4rem;
}
.btn.--btn-ht-165 {
  height: 16.5rem;
}
.btn.--btn-wt-165 {
  width: 16.5rem;
}
.btn.--btn-ht-166 {
  height: 16.6rem;
}
.btn.--btn-wt-166 {
  width: 16.6rem;
}
.btn.--btn-ht-167 {
  height: 16.7rem;
}
.btn.--btn-wt-167 {
  width: 16.7rem;
}
.btn.--btn-ht-168 {
  height: 16.8rem;
}
.btn.--btn-wt-168 {
  width: 16.8rem;
}
.btn.--btn-ht-169 {
  height: 16.9rem;
}
.btn.--btn-wt-169 {
  width: 16.9rem;
}
.btn.--btn-ht-170 {
  height: 17rem;
}
.btn.--btn-wt-170 {
  width: 17rem;
}
.btn.--btn-ht-171 {
  height: 17.1rem;
}
.btn.--btn-wt-171 {
  width: 17.1rem;
}
.btn.--btn-ht-172 {
  height: 17.2rem;
}
.btn.--btn-wt-172 {
  width: 17.2rem;
}
.btn.--btn-ht-173 {
  height: 17.3rem;
}
.btn.--btn-wt-173 {
  width: 17.3rem;
}
.btn.--btn-ht-174 {
  height: 17.4rem;
}
.btn.--btn-wt-174 {
  width: 17.4rem;
}
.btn.--btn-ht-175 {
  height: 17.5rem;
}
.btn.--btn-wt-175 {
  width: 17.5rem;
}
.btn.--btn-ht-176 {
  height: 17.6rem;
}
.btn.--btn-wt-176 {
  width: 17.6rem;
}
.btn.--btn-ht-177 {
  height: 17.7rem;
}
.btn.--btn-wt-177 {
  width: 17.7rem;
}
.btn.--btn-ht-178 {
  height: 17.8rem;
}
.btn.--btn-wt-178 {
  width: 17.8rem;
}
.btn.--btn-ht-179 {
  height: 17.9rem;
}
.btn.--btn-wt-179 {
  width: 17.9rem;
}
.btn.--btn-ht-180 {
  height: 18rem;
}
.btn.--btn-wt-180 {
  width: 18rem;
}
.btn.--btn-ht-181 {
  height: 18.1rem;
}
.btn.--btn-wt-181 {
  width: 18.1rem;
}
.btn.--btn-ht-182 {
  height: 18.2rem;
}
.btn.--btn-wt-182 {
  width: 18.2rem;
}
.btn.--btn-ht-183 {
  height: 18.3rem;
}
.btn.--btn-wt-183 {
  width: 18.3rem;
}
.btn.--btn-ht-184 {
  height: 18.4rem;
}
.btn.--btn-wt-184 {
  width: 18.4rem;
}
.btn.--btn-ht-185 {
  height: 18.5rem;
}
.btn.--btn-wt-185 {
  width: 18.5rem;
}
.btn.--btn-ht-186 {
  height: 18.6rem;
}
.btn.--btn-wt-186 {
  width: 18.6rem;
}
.btn.--btn-ht-187 {
  height: 18.7rem;
}
.btn.--btn-wt-187 {
  width: 18.7rem;
}
.btn.--btn-ht-188 {
  height: 18.8rem;
}
.btn.--btn-wt-188 {
  width: 18.8rem;
}
.btn.--btn-ht-189 {
  height: 18.9rem;
}
.btn.--btn-wt-189 {
  width: 18.9rem;
}
.btn.--btn-ht-190 {
  height: 19rem;
}
.btn.--btn-wt-190 {
  width: 19rem;
}
.btn.--btn-ht-191 {
  height: 19.1rem;
}
.btn.--btn-wt-191 {
  width: 19.1rem;
}
.btn.--btn-ht-192 {
  height: 19.2rem;
}
.btn.--btn-wt-192 {
  width: 19.2rem;
}
.btn.--btn-ht-193 {
  height: 19.3rem;
}
.btn.--btn-wt-193 {
  width: 19.3rem;
}
.btn.--btn-ht-194 {
  height: 19.4rem;
}
.btn.--btn-wt-194 {
  width: 19.4rem;
}
.btn.--btn-ht-195 {
  height: 19.5rem;
}
.btn.--btn-wt-195 {
  width: 19.5rem;
}
.btn.--btn-ht-196 {
  height: 19.6rem;
}
.btn.--btn-wt-196 {
  width: 19.6rem;
}
.btn.--btn-ht-197 {
  height: 19.7rem;
}
.btn.--btn-wt-197 {
  width: 19.7rem;
}
.btn.--btn-ht-198 {
  height: 19.8rem;
}
.btn.--btn-wt-198 {
  width: 19.8rem;
}
.btn.--btn-ht-199 {
  height: 19.9rem;
}
.btn.--btn-wt-199 {
  width: 19.9rem;
}
.btn.--btn-ht-200 {
  height: 20rem;
}
.btn.--btn-wt-200 {
  width: 20rem;
}
.btn.--btn-ht-201 {
  height: 20.1rem;
}
.btn.--btn-wt-201 {
  width: 20.1rem;
}
.btn.--btn-ht-202 {
  height: 20.2rem;
}
.btn.--btn-wt-202 {
  width: 20.2rem;
}
.btn.--btn-ht-203 {
  height: 20.3rem;
}
.btn.--btn-wt-203 {
  width: 20.3rem;
}
.btn.--btn-ht-204 {
  height: 20.4rem;
}
.btn.--btn-wt-204 {
  width: 20.4rem;
}
.btn.--btn-ht-205 {
  height: 20.5rem;
}
.btn.--btn-wt-205 {
  width: 20.5rem;
}
.btn.--btn-ht-206 {
  height: 20.6rem;
}
.btn.--btn-wt-206 {
  width: 20.6rem;
}
.btn.--btn-ht-207 {
  height: 20.7rem;
}
.btn.--btn-wt-207 {
  width: 20.7rem;
}
.btn.--btn-ht-208 {
  height: 20.8rem;
}
.btn.--btn-wt-208 {
  width: 20.8rem;
}
.btn.--btn-ht-209 {
  height: 20.9rem;
}
.btn.--btn-wt-209 {
  width: 20.9rem;
}
.btn.--btn-ht-210 {
  height: 21rem;
}
.btn.--btn-wt-210 {
  width: 21rem;
}
.btn.--btn-ht-211 {
  height: 21.1rem;
}
.btn.--btn-wt-211 {
  width: 21.1rem;
}
.btn.--btn-ht-212 {
  height: 21.2rem;
}
.btn.--btn-wt-212 {
  width: 21.2rem;
}
.btn.--btn-ht-213 {
  height: 21.3rem;
}
.btn.--btn-wt-213 {
  width: 21.3rem;
}
.btn.--btn-ht-214 {
  height: 21.4rem;
}
.btn.--btn-wt-214 {
  width: 21.4rem;
}
.btn.--btn-ht-215 {
  height: 21.5rem;
}
.btn.--btn-wt-215 {
  width: 21.5rem;
}
.btn.--btn-ht-216 {
  height: 21.6rem;
}
.btn.--btn-wt-216 {
  width: 21.6rem;
}
.btn.--btn-ht-217 {
  height: 21.7rem;
}
.btn.--btn-wt-217 {
  width: 21.7rem;
}
.btn.--btn-ht-218 {
  height: 21.8rem;
}
.btn.--btn-wt-218 {
  width: 21.8rem;
}
.btn.--btn-ht-219 {
  height: 21.9rem;
}
.btn.--btn-wt-219 {
  width: 21.9rem;
}
.btn.--btn-ht-220 {
  height: 22rem;
}
.btn.--btn-wt-220 {
  width: 22rem;
}
.btn.--btn-ht-221 {
  height: 22.1rem;
}
.btn.--btn-wt-221 {
  width: 22.1rem;
}
.btn.--btn-ht-222 {
  height: 22.2rem;
}
.btn.--btn-wt-222 {
  width: 22.2rem;
}
.btn.--btn-ht-223 {
  height: 22.3rem;
}
.btn.--btn-wt-223 {
  width: 22.3rem;
}
.btn.--btn-ht-224 {
  height: 22.4rem;
}
.btn.--btn-wt-224 {
  width: 22.4rem;
}
.btn.--btn-ht-225 {
  height: 22.5rem;
}
.btn.--btn-wt-225 {
  width: 22.5rem;
}
.btn.--btn-ht-226 {
  height: 22.6rem;
}
.btn.--btn-wt-226 {
  width: 22.6rem;
}
.btn.--btn-ht-227 {
  height: 22.7rem;
}
.btn.--btn-wt-227 {
  width: 22.7rem;
}
.btn.--btn-ht-228 {
  height: 22.8rem;
}
.btn.--btn-wt-228 {
  width: 22.8rem;
}
.btn.--btn-ht-229 {
  height: 22.9rem;
}
.btn.--btn-wt-229 {
  width: 22.9rem;
}
.btn.--btn-ht-230 {
  height: 23rem;
}
.btn.--btn-wt-230 {
  width: 23rem;
}
.btn.--btn-ht-231 {
  height: 23.1rem;
}
.btn.--btn-wt-231 {
  width: 23.1rem;
}
.btn.--btn-ht-232 {
  height: 23.2rem;
}
.btn.--btn-wt-232 {
  width: 23.2rem;
}
.btn.--btn-ht-233 {
  height: 23.3rem;
}
.btn.--btn-wt-233 {
  width: 23.3rem;
}
.btn.--btn-ht-234 {
  height: 23.4rem;
}
.btn.--btn-wt-234 {
  width: 23.4rem;
}
.btn.--btn-ht-235 {
  height: 23.5rem;
}
.btn.--btn-wt-235 {
  width: 23.5rem;
}
.btn.--btn-ht-236 {
  height: 23.6rem;
}
.btn.--btn-wt-236 {
  width: 23.6rem;
}
.btn.--btn-ht-237 {
  height: 23.7rem;
}
.btn.--btn-wt-237 {
  width: 23.7rem;
}
.btn.--btn-ht-238 {
  height: 23.8rem;
}
.btn.--btn-wt-238 {
  width: 23.8rem;
}
.btn.--btn-ht-239 {
  height: 23.9rem;
}
.btn.--btn-wt-239 {
  width: 23.9rem;
}
.btn.--btn-ht-240 {
  height: 24rem;
}
.btn.--btn-wt-240 {
  width: 24rem;
}
.btn.--btn-ht-241 {
  height: 24.1rem;
}
.btn.--btn-wt-241 {
  width: 24.1rem;
}
.btn.--btn-ht-242 {
  height: 24.2rem;
}
.btn.--btn-wt-242 {
  width: 24.2rem;
}
.btn.--btn-ht-243 {
  height: 24.3rem;
}
.btn.--btn-wt-243 {
  width: 24.3rem;
}
.btn.--btn-ht-244 {
  height: 24.4rem;
}
.btn.--btn-wt-244 {
  width: 24.4rem;
}
.btn.--btn-ht-245 {
  height: 24.5rem;
}
.btn.--btn-wt-245 {
  width: 24.5rem;
}
.btn.--btn-ht-246 {
  height: 24.6rem;
}
.btn.--btn-wt-246 {
  width: 24.6rem;
}
.btn.--btn-ht-247 {
  height: 24.7rem;
}
.btn.--btn-wt-247 {
  width: 24.7rem;
}
.btn.--btn-ht-248 {
  height: 24.8rem;
}
.btn.--btn-wt-248 {
  width: 24.8rem;
}
.btn.--btn-ht-249 {
  height: 24.9rem;
}
.btn.--btn-wt-249 {
  width: 24.9rem;
}
.btn.--btn-ht-250 {
  height: 25rem;
}
.btn.--btn-wt-250 {
  width: 25rem;
}
.btn.--btn-ht-251 {
  height: 25.1rem;
}
.btn.--btn-wt-251 {
  width: 25.1rem;
}
.btn.--btn-ht-252 {
  height: 25.2rem;
}
.btn.--btn-wt-252 {
  width: 25.2rem;
}
.btn.--btn-ht-253 {
  height: 25.3rem;
}
.btn.--btn-wt-253 {
  width: 25.3rem;
}
.btn.--btn-ht-254 {
  height: 25.4rem;
}
.btn.--btn-wt-254 {
  width: 25.4rem;
}
.btn.--btn-ht-255 {
  height: 25.5rem;
}
.btn.--btn-wt-255 {
  width: 25.5rem;
}
.btn.--btn-ht-256 {
  height: 25.6rem;
}
.btn.--btn-wt-256 {
  width: 25.6rem;
}
.btn.--btn-ht-257 {
  height: 25.7rem;
}
.btn.--btn-wt-257 {
  width: 25.7rem;
}
.btn.--btn-ht-258 {
  height: 25.8rem;
}
.btn.--btn-wt-258 {
  width: 25.8rem;
}
.btn.--btn-ht-259 {
  height: 25.9rem;
}
.btn.--btn-wt-259 {
  width: 25.9rem;
}
.btn.--btn-ht-260 {
  height: 26rem;
}
.btn.--btn-wt-260 {
  width: 26rem;
}
.btn.--btn-ht-261 {
  height: 26.1rem;
}
.btn.--btn-wt-261 {
  width: 26.1rem;
}
.btn.--btn-ht-262 {
  height: 26.2rem;
}
.btn.--btn-wt-262 {
  width: 26.2rem;
}
.btn.--btn-ht-263 {
  height: 26.3rem;
}
.btn.--btn-wt-263 {
  width: 26.3rem;
}
.btn.--btn-ht-264 {
  height: 26.4rem;
}
.btn.--btn-wt-264 {
  width: 26.4rem;
}
.btn.--btn-ht-265 {
  height: 26.5rem;
}
.btn.--btn-wt-265 {
  width: 26.5rem;
}
.btn.--btn-ht-266 {
  height: 26.6rem;
}
.btn.--btn-wt-266 {
  width: 26.6rem;
}
.btn.--btn-ht-267 {
  height: 26.7rem;
}
.btn.--btn-wt-267 {
  width: 26.7rem;
}
.btn.--btn-ht-268 {
  height: 26.8rem;
}
.btn.--btn-wt-268 {
  width: 26.8rem;
}
.btn.--btn-ht-269 {
  height: 26.9rem;
}
.btn.--btn-wt-269 {
  width: 26.9rem;
}
.btn.--btn-ht-270 {
  height: 27rem;
}
.btn.--btn-wt-270 {
  width: 27rem;
}
.btn.--btn-ht-271 {
  height: 27.1rem;
}
.btn.--btn-wt-271 {
  width: 27.1rem;
}
.btn.--btn-ht-272 {
  height: 27.2rem;
}
.btn.--btn-wt-272 {
  width: 27.2rem;
}
.btn.--btn-ht-273 {
  height: 27.3rem;
}
.btn.--btn-wt-273 {
  width: 27.3rem;
}
.btn.--btn-ht-274 {
  height: 27.4rem;
}
.btn.--btn-wt-274 {
  width: 27.4rem;
}
.btn.--btn-ht-275 {
  height: 27.5rem;
}
.btn.--btn-wt-275 {
  width: 27.5rem;
}
.btn.--btn-ht-276 {
  height: 27.6rem;
}
.btn.--btn-wt-276 {
  width: 27.6rem;
}
.btn.--btn-ht-277 {
  height: 27.7rem;
}
.btn.--btn-wt-277 {
  width: 27.7rem;
}
.btn.--btn-ht-278 {
  height: 27.8rem;
}
.btn.--btn-wt-278 {
  width: 27.8rem;
}
.btn.--btn-ht-279 {
  height: 27.9rem;
}
.btn.--btn-wt-279 {
  width: 27.9rem;
}
.btn.--btn-ht-280 {
  height: 28rem;
}
.btn.--btn-wt-280 {
  width: 28rem;
}
.btn.--btn-ht-281 {
  height: 28.1rem;
}
.btn.--btn-wt-281 {
  width: 28.1rem;
}
.btn.--btn-ht-282 {
  height: 28.2rem;
}
.btn.--btn-wt-282 {
  width: 28.2rem;
}
.btn.--btn-ht-283 {
  height: 28.3rem;
}
.btn.--btn-wt-283 {
  width: 28.3rem;
}
.btn.--btn-ht-284 {
  height: 28.4rem;
}
.btn.--btn-wt-284 {
  width: 28.4rem;
}
.btn.--btn-ht-285 {
  height: 28.5rem;
}
.btn.--btn-wt-285 {
  width: 28.5rem;
}
.btn.--btn-ht-286 {
  height: 28.6rem;
}
.btn.--btn-wt-286 {
  width: 28.6rem;
}
.btn.--btn-ht-287 {
  height: 28.7rem;
}
.btn.--btn-wt-287 {
  width: 28.7rem;
}
.btn.--btn-ht-288 {
  height: 28.8rem;
}
.btn.--btn-wt-288 {
  width: 28.8rem;
}
.btn.--btn-ht-289 {
  height: 28.9rem;
}
.btn.--btn-wt-289 {
  width: 28.9rem;
}
.btn.--btn-ht-290 {
  height: 29rem;
}
.btn.--btn-wt-290 {
  width: 29rem;
}
.btn.--btn-ht-291 {
  height: 29.1rem;
}
.btn.--btn-wt-291 {
  width: 29.1rem;
}
.btn.--btn-ht-292 {
  height: 29.2rem;
}
.btn.--btn-wt-292 {
  width: 29.2rem;
}
.btn.--btn-ht-293 {
  height: 29.3rem;
}
.btn.--btn-wt-293 {
  width: 29.3rem;
}
.btn.--btn-ht-294 {
  height: 29.4rem;
}
.btn.--btn-wt-294 {
  width: 29.4rem;
}
.btn.--btn-ht-295 {
  height: 29.5rem;
}
.btn.--btn-wt-295 {
  width: 29.5rem;
}
.btn.--btn-ht-296 {
  height: 29.6rem;
}
.btn.--btn-wt-296 {
  width: 29.6rem;
}
.btn.--btn-ht-297 {
  height: 29.7rem;
}
.btn.--btn-wt-297 {
  width: 29.7rem;
}
.btn.--btn-ht-298 {
  height: 29.8rem;
}
.btn.--btn-wt-298 {
  width: 29.8rem;
}
.btn.--btn-ht-299 {
  height: 29.9rem;
}
.btn.--btn-wt-299 {
  width: 29.9rem;
}
.btn.--btn-ht-300 {
  height: 30rem;
}
.btn.--btn-wt-300 {
  width: 30rem;
}
.btn.--btn-ht-301 {
  height: 30.1rem;
}
.btn.--btn-wt-301 {
  width: 30.1rem;
}
.btn.--btn-ht-302 {
  height: 30.2rem;
}
.btn.--btn-wt-302 {
  width: 30.2rem;
}
.btn.--btn-ht-303 {
  height: 30.3rem;
}
.btn.--btn-wt-303 {
  width: 30.3rem;
}
.btn.--btn-ht-304 {
  height: 30.4rem;
}
.btn.--btn-wt-304 {
  width: 30.4rem;
}
.btn.--btn-ht-305 {
  height: 30.5rem;
}
.btn.--btn-wt-305 {
  width: 30.5rem;
}
.btn.--btn-ht-306 {
  height: 30.6rem;
}
.btn.--btn-wt-306 {
  width: 30.6rem;
}
.btn.--btn-ht-307 {
  height: 30.7rem;
}
.btn.--btn-wt-307 {
  width: 30.7rem;
}
.btn.--btn-ht-308 {
  height: 30.8rem;
}
.btn.--btn-wt-308 {
  width: 30.8rem;
}
.btn.--btn-ht-309 {
  height: 30.9rem;
}
.btn.--btn-wt-309 {
  width: 30.9rem;
}
.btn.--btn-ht-310 {
  height: 31rem;
}
.btn.--btn-wt-310 {
  width: 31rem;
}
.btn.--btn-ht-311 {
  height: 31.1rem;
}
.btn.--btn-wt-311 {
  width: 31.1rem;
}
.btn.--btn-ht-312 {
  height: 31.2rem;
}
.btn.--btn-wt-312 {
  width: 31.2rem;
}
.btn.--btn-ht-313 {
  height: 31.3rem;
}
.btn.--btn-wt-313 {
  width: 31.3rem;
}
.btn.--btn-ht-314 {
  height: 31.4rem;
}
.btn.--btn-wt-314 {
  width: 31.4rem;
}
.btn.--btn-ht-315 {
  height: 31.5rem;
}
.btn.--btn-wt-315 {
  width: 31.5rem;
}
.btn.--btn-ht-316 {
  height: 31.6rem;
}
.btn.--btn-wt-316 {
  width: 31.6rem;
}
.btn.--btn-ht-317 {
  height: 31.7rem;
}
.btn.--btn-wt-317 {
  width: 31.7rem;
}
.btn.--btn-ht-318 {
  height: 31.8rem;
}
.btn.--btn-wt-318 {
  width: 31.8rem;
}
.btn.--btn-ht-319 {
  height: 31.9rem;
}
.btn.--btn-wt-319 {
  width: 31.9rem;
}
.btn.--btn-ht-320 {
  height: 32rem;
}
.btn.--btn-wt-320 {
  width: 32rem;
}
.btn.--btn-ht-321 {
  height: 32.1rem;
}
.btn.--btn-wt-321 {
  width: 32.1rem;
}
.btn.--btn-ht-322 {
  height: 32.2rem;
}
.btn.--btn-wt-322 {
  width: 32.2rem;
}
.btn.--btn-ht-323 {
  height: 32.3rem;
}
.btn.--btn-wt-323 {
  width: 32.3rem;
}
.btn.--btn-ht-324 {
  height: 32.4rem;
}
.btn.--btn-wt-324 {
  width: 32.4rem;
}
.btn.--btn-ht-325 {
  height: 32.5rem;
}
.btn.--btn-wt-325 {
  width: 32.5rem;
}
.btn.--btn-ht-326 {
  height: 32.6rem;
}
.btn.--btn-wt-326 {
  width: 32.6rem;
}
.btn.--btn-ht-327 {
  height: 32.7rem;
}
.btn.--btn-wt-327 {
  width: 32.7rem;
}
.btn.--btn-ht-328 {
  height: 32.8rem;
}
.btn.--btn-wt-328 {
  width: 32.8rem;
}
.btn.--btn-ht-329 {
  height: 32.9rem;
}
.btn.--btn-wt-329 {
  width: 32.9rem;
}
.btn.--btn-ht-330 {
  height: 33rem;
}
.btn.--btn-wt-330 {
  width: 33rem;
}
.btn.--btn-ht-331 {
  height: 33.1rem;
}
.btn.--btn-wt-331 {
  width: 33.1rem;
}
.btn.--btn-ht-332 {
  height: 33.2rem;
}
.btn.--btn-wt-332 {
  width: 33.2rem;
}
.btn.--btn-ht-333 {
  height: 33.3rem;
}
.btn.--btn-wt-333 {
  width: 33.3rem;
}
.btn.--btn-ht-334 {
  height: 33.4rem;
}
.btn.--btn-wt-334 {
  width: 33.4rem;
}
.btn.--btn-ht-335 {
  height: 33.5rem;
}
.btn.--btn-wt-335 {
  width: 33.5rem;
}
.btn.--btn-ht-336 {
  height: 33.6rem;
}
.btn.--btn-wt-336 {
  width: 33.6rem;
}
.btn.--btn-ht-337 {
  height: 33.7rem;
}
.btn.--btn-wt-337 {
  width: 33.7rem;
}
.btn.--btn-ht-338 {
  height: 33.8rem;
}
.btn.--btn-wt-338 {
  width: 33.8rem;
}
.btn.--btn-ht-339 {
  height: 33.9rem;
}
.btn.--btn-wt-339 {
  width: 33.9rem;
}
.btn.--btn-ht-340 {
  height: 34rem;
}
.btn.--btn-wt-340 {
  width: 34rem;
}
.btn.--btn-ht-341 {
  height: 34.1rem;
}
.btn.--btn-wt-341 {
  width: 34.1rem;
}
.btn.--btn-ht-342 {
  height: 34.2rem;
}
.btn.--btn-wt-342 {
  width: 34.2rem;
}
.btn.--btn-ht-343 {
  height: 34.3rem;
}
.btn.--btn-wt-343 {
  width: 34.3rem;
}
.btn.--btn-ht-344 {
  height: 34.4rem;
}
.btn.--btn-wt-344 {
  width: 34.4rem;
}
.btn.--btn-ht-345 {
  height: 34.5rem;
}
.btn.--btn-wt-345 {
  width: 34.5rem;
}
.btn.--btn-ht-346 {
  height: 34.6rem;
}
.btn.--btn-wt-346 {
  width: 34.6rem;
}
.btn.--btn-ht-347 {
  height: 34.7rem;
}
.btn.--btn-wt-347 {
  width: 34.7rem;
}
.btn.--btn-ht-348 {
  height: 34.8rem;
}
.btn.--btn-wt-348 {
  width: 34.8rem;
}
.btn.--btn-ht-349 {
  height: 34.9rem;
}
.btn.--btn-wt-349 {
  width: 34.9rem;
}
.btn.--btn-ht-350 {
  height: 35rem;
}
.btn.--btn-wt-350 {
  width: 35rem;
}
.btn.--btn-ht-351 {
  height: 35.1rem;
}
.btn.--btn-wt-351 {
  width: 35.1rem;
}
.btn.--btn-ht-352 {
  height: 35.2rem;
}
.btn.--btn-wt-352 {
  width: 35.2rem;
}
.btn.--btn-ht-353 {
  height: 35.3rem;
}
.btn.--btn-wt-353 {
  width: 35.3rem;
}
.btn.--btn-ht-354 {
  height: 35.4rem;
}
.btn.--btn-wt-354 {
  width: 35.4rem;
}
.btn.--btn-ht-355 {
  height: 35.5rem;
}
.btn.--btn-wt-355 {
  width: 35.5rem;
}
.btn.--btn-ht-356 {
  height: 35.6rem;
}
.btn.--btn-wt-356 {
  width: 35.6rem;
}
.btn.--btn-ht-357 {
  height: 35.7rem;
}
.btn.--btn-wt-357 {
  width: 35.7rem;
}
.btn.--btn-ht-358 {
  height: 35.8rem;
}
.btn.--btn-wt-358 {
  width: 35.8rem;
}
.btn.--btn-ht-359 {
  height: 35.9rem;
}
.btn.--btn-wt-359 {
  width: 35.9rem;
}
.btn.--btn-ht-360 {
  height: 36rem;
}
.btn.--btn-wt-360 {
  width: 36rem;
}
.btn.--btn-ht-361 {
  height: 36.1rem;
}
.btn.--btn-wt-361 {
  width: 36.1rem;
}
.btn.--btn-ht-362 {
  height: 36.2rem;
}
.btn.--btn-wt-362 {
  width: 36.2rem;
}
.btn.--btn-ht-363 {
  height: 36.3rem;
}
.btn.--btn-wt-363 {
  width: 36.3rem;
}
.btn.--btn-ht-364 {
  height: 36.4rem;
}
.btn.--btn-wt-364 {
  width: 36.4rem;
}
.btn.--btn-ht-365 {
  height: 36.5rem;
}
.btn.--btn-wt-365 {
  width: 36.5rem;
}
.btn.--btn-ht-366 {
  height: 36.6rem;
}
.btn.--btn-wt-366 {
  width: 36.6rem;
}
.btn.--btn-ht-367 {
  height: 36.7rem;
}
.btn.--btn-wt-367 {
  width: 36.7rem;
}
.btn.--btn-ht-368 {
  height: 36.8rem;
}
.btn.--btn-wt-368 {
  width: 36.8rem;
}
.btn.--btn-ht-369 {
  height: 36.9rem;
}
.btn.--btn-wt-369 {
  width: 36.9rem;
}
.btn.--btn-ht-370 {
  height: 37rem;
}
.btn.--btn-wt-370 {
  width: 37rem;
}
.btn.--btn-ht-371 {
  height: 37.1rem;
}
.btn.--btn-wt-371 {
  width: 37.1rem;
}
.btn.--btn-ht-372 {
  height: 37.2rem;
}
.btn.--btn-wt-372 {
  width: 37.2rem;
}
.btn.--btn-ht-373 {
  height: 37.3rem;
}
.btn.--btn-wt-373 {
  width: 37.3rem;
}
.btn.--btn-ht-374 {
  height: 37.4rem;
}
.btn.--btn-wt-374 {
  width: 37.4rem;
}
.btn.--btn-ht-375 {
  height: 37.5rem;
}
.btn.--btn-wt-375 {
  width: 37.5rem;
}
.btn.--btn-ht-376 {
  height: 37.6rem;
}
.btn.--btn-wt-376 {
  width: 37.6rem;
}
.btn.--btn-ht-377 {
  height: 37.7rem;
}
.btn.--btn-wt-377 {
  width: 37.7rem;
}
.btn.--btn-ht-378 {
  height: 37.8rem;
}
.btn.--btn-wt-378 {
  width: 37.8rem;
}
.btn.--btn-ht-379 {
  height: 37.9rem;
}
.btn.--btn-wt-379 {
  width: 37.9rem;
}
.btn.--btn-ht-380 {
  height: 38rem;
}
.btn.--btn-wt-380 {
  width: 38rem;
}
.btn.--btn-ht-381 {
  height: 38.1rem;
}
.btn.--btn-wt-381 {
  width: 38.1rem;
}
.btn.--btn-ht-382 {
  height: 38.2rem;
}
.btn.--btn-wt-382 {
  width: 38.2rem;
}
.btn.--btn-ht-383 {
  height: 38.3rem;
}
.btn.--btn-wt-383 {
  width: 38.3rem;
}
.btn.--btn-ht-384 {
  height: 38.4rem;
}
.btn.--btn-wt-384 {
  width: 38.4rem;
}
.btn.--btn-ht-385 {
  height: 38.5rem;
}
.btn.--btn-wt-385 {
  width: 38.5rem;
}
.btn.--btn-ht-386 {
  height: 38.6rem;
}
.btn.--btn-wt-386 {
  width: 38.6rem;
}
.btn.--btn-ht-387 {
  height: 38.7rem;
}
.btn.--btn-wt-387 {
  width: 38.7rem;
}
.btn.--btn-ht-388 {
  height: 38.8rem;
}
.btn.--btn-wt-388 {
  width: 38.8rem;
}
.btn.--btn-ht-389 {
  height: 38.9rem;
}
.btn.--btn-wt-389 {
  width: 38.9rem;
}
.btn.--btn-ht-390 {
  height: 39rem;
}
.btn.--btn-wt-390 {
  width: 39rem;
}
.btn.--btn-ht-391 {
  height: 39.1rem;
}
.btn.--btn-wt-391 {
  width: 39.1rem;
}
.btn.--btn-ht-392 {
  height: 39.2rem;
}
.btn.--btn-wt-392 {
  width: 39.2rem;
}
.btn.--btn-ht-393 {
  height: 39.3rem;
}
.btn.--btn-wt-393 {
  width: 39.3rem;
}
.btn.--btn-ht-394 {
  height: 39.4rem;
}
.btn.--btn-wt-394 {
  width: 39.4rem;
}
.btn.--btn-ht-395 {
  height: 39.5rem;
}
.btn.--btn-wt-395 {
  width: 39.5rem;
}
.btn.--btn-ht-396 {
  height: 39.6rem;
}
.btn.--btn-wt-396 {
  width: 39.6rem;
}
.btn.--btn-ht-397 {
  height: 39.7rem;
}
.btn.--btn-wt-397 {
  width: 39.7rem;
}
.btn.--btn-ht-398 {
  height: 39.8rem;
}
.btn.--btn-wt-398 {
  width: 39.8rem;
}
.btn.--btn-ht-399 {
  height: 39.9rem;
}
.btn.--btn-wt-399 {
  width: 39.9rem;
}
.btn.--btn-ht-400 {
  height: 40rem;
}
.btn.--btn-wt-400 {
  width: 40rem;
}
.btn.--btn-ht-401 {
  height: 40.1rem;
}
.btn.--btn-wt-401 {
  width: 40.1rem;
}
.btn.--btn-ht-402 {
  height: 40.2rem;
}
.btn.--btn-wt-402 {
  width: 40.2rem;
}
.btn.--btn-ht-403 {
  height: 40.3rem;
}
.btn.--btn-wt-403 {
  width: 40.3rem;
}
.btn.--btn-ht-404 {
  height: 40.4rem;
}
.btn.--btn-wt-404 {
  width: 40.4rem;
}
.btn.--btn-ht-405 {
  height: 40.5rem;
}
.btn.--btn-wt-405 {
  width: 40.5rem;
}
.btn.--btn-ht-406 {
  height: 40.6rem;
}
.btn.--btn-wt-406 {
  width: 40.6rem;
}
.btn.--btn-ht-407 {
  height: 40.7rem;
}
.btn.--btn-wt-407 {
  width: 40.7rem;
}
.btn.--btn-ht-408 {
  height: 40.8rem;
}
.btn.--btn-wt-408 {
  width: 40.8rem;
}
.btn.--btn-ht-409 {
  height: 40.9rem;
}
.btn.--btn-wt-409 {
  width: 40.9rem;
}
.btn.--btn-ht-410 {
  height: 41rem;
}
.btn.--btn-wt-410 {
  width: 41rem;
}
.btn.--btn-ht-411 {
  height: 41.1rem;
}
.btn.--btn-wt-411 {
  width: 41.1rem;
}
.btn.--btn-ht-412 {
  height: 41.2rem;
}
.btn.--btn-wt-412 {
  width: 41.2rem;
}
.btn.--btn-ht-413 {
  height: 41.3rem;
}
.btn.--btn-wt-413 {
  width: 41.3rem;
}
.btn.--btn-ht-414 {
  height: 41.4rem;
}
.btn.--btn-wt-414 {
  width: 41.4rem;
}
.btn.--btn-ht-415 {
  height: 41.5rem;
}
.btn.--btn-wt-415 {
  width: 41.5rem;
}
.btn.--btn-ht-416 {
  height: 41.6rem;
}
.btn.--btn-wt-416 {
  width: 41.6rem;
}
.btn.--btn-ht-417 {
  height: 41.7rem;
}
.btn.--btn-wt-417 {
  width: 41.7rem;
}
.btn.--btn-ht-418 {
  height: 41.8rem;
}
.btn.--btn-wt-418 {
  width: 41.8rem;
}
.btn.--btn-ht-419 {
  height: 41.9rem;
}
.btn.--btn-wt-419 {
  width: 41.9rem;
}
.btn.--btn-ht-420 {
  height: 42rem;
}
.btn.--btn-wt-420 {
  width: 42rem;
}
.btn.--btn-ht-421 {
  height: 42.1rem;
}
.btn.--btn-wt-421 {
  width: 42.1rem;
}
.btn.--btn-ht-422 {
  height: 42.2rem;
}
.btn.--btn-wt-422 {
  width: 42.2rem;
}
.btn.--btn-ht-423 {
  height: 42.3rem;
}
.btn.--btn-wt-423 {
  width: 42.3rem;
}
.btn.--btn-ht-424 {
  height: 42.4rem;
}
.btn.--btn-wt-424 {
  width: 42.4rem;
}
.btn.--btn-ht-425 {
  height: 42.5rem;
}
.btn.--btn-wt-425 {
  width: 42.5rem;
}
.btn.--btn-ht-426 {
  height: 42.6rem;
}
.btn.--btn-wt-426 {
  width: 42.6rem;
}
.btn.--btn-ht-427 {
  height: 42.7rem;
}
.btn.--btn-wt-427 {
  width: 42.7rem;
}
.btn.--btn-ht-428 {
  height: 42.8rem;
}
.btn.--btn-wt-428 {
  width: 42.8rem;
}
.btn.--btn-ht-429 {
  height: 42.9rem;
}
.btn.--btn-wt-429 {
  width: 42.9rem;
}
.btn.--btn-ht-430 {
  height: 43rem;
}
.btn.--btn-wt-430 {
  width: 43rem;
}
.btn.--btn-ht-431 {
  height: 43.1rem;
}
.btn.--btn-wt-431 {
  width: 43.1rem;
}
.btn.--btn-ht-432 {
  height: 43.2rem;
}
.btn.--btn-wt-432 {
  width: 43.2rem;
}
.btn.--btn-ht-433 {
  height: 43.3rem;
}
.btn.--btn-wt-433 {
  width: 43.3rem;
}
.btn.--btn-ht-434 {
  height: 43.4rem;
}
.btn.--btn-wt-434 {
  width: 43.4rem;
}
.btn.--btn-ht-435 {
  height: 43.5rem;
}
.btn.--btn-wt-435 {
  width: 43.5rem;
}
.btn.--btn-ht-436 {
  height: 43.6rem;
}
.btn.--btn-wt-436 {
  width: 43.6rem;
}
.btn.--btn-ht-437 {
  height: 43.7rem;
}
.btn.--btn-wt-437 {
  width: 43.7rem;
}
.btn.--btn-ht-438 {
  height: 43.8rem;
}
.btn.--btn-wt-438 {
  width: 43.8rem;
}
.btn.--btn-ht-439 {
  height: 43.9rem;
}
.btn.--btn-wt-439 {
  width: 43.9rem;
}
.btn.--btn-ht-440 {
  height: 44rem;
}
.btn.--btn-wt-440 {
  width: 44rem;
}
.btn.--btn-ht-441 {
  height: 44.1rem;
}
.btn.--btn-wt-441 {
  width: 44.1rem;
}
.btn.--btn-ht-442 {
  height: 44.2rem;
}
.btn.--btn-wt-442 {
  width: 44.2rem;
}
.btn.--btn-ht-443 {
  height: 44.3rem;
}
.btn.--btn-wt-443 {
  width: 44.3rem;
}
.btn.--btn-ht-444 {
  height: 44.4rem;
}
.btn.--btn-wt-444 {
  width: 44.4rem;
}
.btn.--btn-ht-445 {
  height: 44.5rem;
}
.btn.--btn-wt-445 {
  width: 44.5rem;
}
.btn.--btn-ht-446 {
  height: 44.6rem;
}
.btn.--btn-wt-446 {
  width: 44.6rem;
}
.btn.--btn-ht-447 {
  height: 44.7rem;
}
.btn.--btn-wt-447 {
  width: 44.7rem;
}
.btn.--btn-ht-448 {
  height: 44.8rem;
}
.btn.--btn-wt-448 {
  width: 44.8rem;
}
.btn.--btn-ht-449 {
  height: 44.9rem;
}
.btn.--btn-wt-449 {
  width: 44.9rem;
}
.btn.--btn-ht-450 {
  height: 45rem;
}
.btn.--btn-wt-450 {
  width: 45rem;
}
.btn.--btn-ht-451 {
  height: 45.1rem;
}
.btn.--btn-wt-451 {
  width: 45.1rem;
}
.btn.--btn-ht-452 {
  height: 45.2rem;
}
.btn.--btn-wt-452 {
  width: 45.2rem;
}
.btn.--btn-ht-453 {
  height: 45.3rem;
}
.btn.--btn-wt-453 {
  width: 45.3rem;
}
.btn.--btn-ht-454 {
  height: 45.4rem;
}
.btn.--btn-wt-454 {
  width: 45.4rem;
}
.btn.--btn-ht-455 {
  height: 45.5rem;
}
.btn.--btn-wt-455 {
  width: 45.5rem;
}
.btn.--btn-ht-456 {
  height: 45.6rem;
}
.btn.--btn-wt-456 {
  width: 45.6rem;
}
.btn.--btn-ht-457 {
  height: 45.7rem;
}
.btn.--btn-wt-457 {
  width: 45.7rem;
}
.btn.--btn-ht-458 {
  height: 45.8rem;
}
.btn.--btn-wt-458 {
  width: 45.8rem;
}
.btn.--btn-ht-459 {
  height: 45.9rem;
}
.btn.--btn-wt-459 {
  width: 45.9rem;
}
.btn.--btn-ht-460 {
  height: 46rem;
}
.btn.--btn-wt-460 {
  width: 46rem;
}
.btn.--btn-ht-461 {
  height: 46.1rem;
}
.btn.--btn-wt-461 {
  width: 46.1rem;
}
.btn.--btn-ht-462 {
  height: 46.2rem;
}
.btn.--btn-wt-462 {
  width: 46.2rem;
}
.btn.--btn-ht-463 {
  height: 46.3rem;
}
.btn.--btn-wt-463 {
  width: 46.3rem;
}
.btn.--btn-ht-464 {
  height: 46.4rem;
}
.btn.--btn-wt-464 {
  width: 46.4rem;
}
.btn.--btn-ht-465 {
  height: 46.5rem;
}
.btn.--btn-wt-465 {
  width: 46.5rem;
}
.btn.--btn-ht-466 {
  height: 46.6rem;
}
.btn.--btn-wt-466 {
  width: 46.6rem;
}
.btn.--btn-ht-467 {
  height: 46.7rem;
}
.btn.--btn-wt-467 {
  width: 46.7rem;
}
.btn.--btn-ht-468 {
  height: 46.8rem;
}
.btn.--btn-wt-468 {
  width: 46.8rem;
}
.btn.--btn-ht-469 {
  height: 46.9rem;
}
.btn.--btn-wt-469 {
  width: 46.9rem;
}
.btn.--btn-ht-470 {
  height: 47rem;
}
.btn.--btn-wt-470 {
  width: 47rem;
}
.btn.--btn-ht-471 {
  height: 47.1rem;
}
.btn.--btn-wt-471 {
  width: 47.1rem;
}
.btn.--btn-ht-472 {
  height: 47.2rem;
}
.btn.--btn-wt-472 {
  width: 47.2rem;
}
.btn.--btn-ht-473 {
  height: 47.3rem;
}
.btn.--btn-wt-473 {
  width: 47.3rem;
}
.btn.--btn-ht-474 {
  height: 47.4rem;
}
.btn.--btn-wt-474 {
  width: 47.4rem;
}
.btn.--btn-ht-475 {
  height: 47.5rem;
}
.btn.--btn-wt-475 {
  width: 47.5rem;
}
.btn.--btn-ht-476 {
  height: 47.6rem;
}
.btn.--btn-wt-476 {
  width: 47.6rem;
}
.btn.--btn-ht-477 {
  height: 47.7rem;
}
.btn.--btn-wt-477 {
  width: 47.7rem;
}
.btn.--btn-ht-478 {
  height: 47.8rem;
}
.btn.--btn-wt-478 {
  width: 47.8rem;
}
.btn.--btn-ht-479 {
  height: 47.9rem;
}
.btn.--btn-wt-479 {
  width: 47.9rem;
}
.btn.--btn-ht-480 {
  height: 48rem;
}
.btn.--btn-wt-480 {
  width: 48rem;
}
.btn.--btn-ht-481 {
  height: 48.1rem;
}
.btn.--btn-wt-481 {
  width: 48.1rem;
}
.btn.--btn-ht-482 {
  height: 48.2rem;
}
.btn.--btn-wt-482 {
  width: 48.2rem;
}
.btn.--btn-ht-483 {
  height: 48.3rem;
}
.btn.--btn-wt-483 {
  width: 48.3rem;
}
.btn.--btn-ht-484 {
  height: 48.4rem;
}
.btn.--btn-wt-484 {
  width: 48.4rem;
}
.btn.--btn-ht-485 {
  height: 48.5rem;
}
.btn.--btn-wt-485 {
  width: 48.5rem;
}
.btn.--btn-ht-486 {
  height: 48.6rem;
}
.btn.--btn-wt-486 {
  width: 48.6rem;
}
.btn.--btn-ht-487 {
  height: 48.7rem;
}
.btn.--btn-wt-487 {
  width: 48.7rem;
}
.btn.--btn-ht-488 {
  height: 48.8rem;
}
.btn.--btn-wt-488 {
  width: 48.8rem;
}
.btn.--btn-ht-489 {
  height: 48.9rem;
}
.btn.--btn-wt-489 {
  width: 48.9rem;
}
.btn.--btn-ht-490 {
  height: 49rem;
}
.btn.--btn-wt-490 {
  width: 49rem;
}
.btn.--btn-ht-491 {
  height: 49.1rem;
}
.btn.--btn-wt-491 {
  width: 49.1rem;
}
.btn.--btn-ht-492 {
  height: 49.2rem;
}
.btn.--btn-wt-492 {
  width: 49.2rem;
}
.btn.--btn-ht-493 {
  height: 49.3rem;
}
.btn.--btn-wt-493 {
  width: 49.3rem;
}
.btn.--btn-ht-494 {
  height: 49.4rem;
}
.btn.--btn-wt-494 {
  width: 49.4rem;
}
.btn.--btn-ht-495 {
  height: 49.5rem;
}
.btn.--btn-wt-495 {
  width: 49.5rem;
}
.btn.--btn-ht-496 {
  height: 49.6rem;
}
.btn.--btn-wt-496 {
  width: 49.6rem;
}
.btn.--btn-ht-497 {
  height: 49.7rem;
}
.btn.--btn-wt-497 {
  width: 49.7rem;
}
.btn.--btn-ht-498 {
  height: 49.8rem;
}
.btn.--btn-wt-498 {
  width: 49.8rem;
}
.btn.--btn-ht-499 {
  height: 49.9rem;
}
.btn.--btn-wt-499 {
  width: 49.9rem;
}
.btn.--btn-ht-500 {
  height: 50rem;
}
.btn.--btn-wt-500 {
  width: 50rem;
}
.btn.--btn-ht-501 {
  height: 50.1rem;
}
.btn.--btn-wt-501 {
  width: 50.1rem;
}
.btn.--btn-ht-502 {
  height: 50.2rem;
}
.btn.--btn-wt-502 {
  width: 50.2rem;
}
.btn.--btn-ht-503 {
  height: 50.3rem;
}
.btn.--btn-wt-503 {
  width: 50.3rem;
}
.btn.--btn-ht-504 {
  height: 50.4rem;
}
.btn.--btn-wt-504 {
  width: 50.4rem;
}
.btn.--btn-ht-505 {
  height: 50.5rem;
}
.btn.--btn-wt-505 {
  width: 50.5rem;
}
.btn.--btn-ht-506 {
  height: 50.6rem;
}
.btn.--btn-wt-506 {
  width: 50.6rem;
}
.btn.--btn-ht-507 {
  height: 50.7rem;
}
.btn.--btn-wt-507 {
  width: 50.7rem;
}
.btn.--btn-ht-508 {
  height: 50.8rem;
}
.btn.--btn-wt-508 {
  width: 50.8rem;
}
.btn.--btn-ht-509 {
  height: 50.9rem;
}
.btn.--btn-wt-509 {
  width: 50.9rem;
}
.btn.--btn-ht-510 {
  height: 51rem;
}
.btn.--btn-wt-510 {
  width: 51rem;
}
.btn.--btn-ht-511 {
  height: 51.1rem;
}
.btn.--btn-wt-511 {
  width: 51.1rem;
}
.btn.--btn-ht-512 {
  height: 51.2rem;
}
.btn.--btn-wt-512 {
  width: 51.2rem;
}
.btn.--btn-ht-513 {
  height: 51.3rem;
}
.btn.--btn-wt-513 {
  width: 51.3rem;
}
.btn.--btn-ht-514 {
  height: 51.4rem;
}
.btn.--btn-wt-514 {
  width: 51.4rem;
}
.btn.--btn-ht-515 {
  height: 51.5rem;
}
.btn.--btn-wt-515 {
  width: 51.5rem;
}
.btn.--btn-ht-516 {
  height: 51.6rem;
}
.btn.--btn-wt-516 {
  width: 51.6rem;
}
.btn.--btn-ht-517 {
  height: 51.7rem;
}
.btn.--btn-wt-517 {
  width: 51.7rem;
}
.btn.--btn-ht-518 {
  height: 51.8rem;
}
.btn.--btn-wt-518 {
  width: 51.8rem;
}
.btn.--btn-ht-519 {
  height: 51.9rem;
}
.btn.--btn-wt-519 {
  width: 51.9rem;
}
.btn.--btn-ht-520 {
  height: 52rem;
}
.btn.--btn-wt-520 {
  width: 52rem;
}
.btn.--btn-ht-521 {
  height: 52.1rem;
}
.btn.--btn-wt-521 {
  width: 52.1rem;
}
.btn.--btn-ht-522 {
  height: 52.2rem;
}
.btn.--btn-wt-522 {
  width: 52.2rem;
}
.btn.--btn-ht-523 {
  height: 52.3rem;
}
.btn.--btn-wt-523 {
  width: 52.3rem;
}
.btn.--btn-ht-524 {
  height: 52.4rem;
}
.btn.--btn-wt-524 {
  width: 52.4rem;
}
.btn.--btn-ht-525 {
  height: 52.5rem;
}
.btn.--btn-wt-525 {
  width: 52.5rem;
}
.btn.--btn-ht-526 {
  height: 52.6rem;
}
.btn.--btn-wt-526 {
  width: 52.6rem;
}
.btn.--btn-ht-527 {
  height: 52.7rem;
}
.btn.--btn-wt-527 {
  width: 52.7rem;
}
.btn.--btn-ht-528 {
  height: 52.8rem;
}
.btn.--btn-wt-528 {
  width: 52.8rem;
}
.btn.--btn-ht-529 {
  height: 52.9rem;
}
.btn.--btn-wt-529 {
  width: 52.9rem;
}
.btn.--btn-ht-530 {
  height: 53rem;
}
.btn.--btn-wt-530 {
  width: 53rem;
}
.btn.--btn-ht-531 {
  height: 53.1rem;
}
.btn.--btn-wt-531 {
  width: 53.1rem;
}
.btn.--btn-ht-532 {
  height: 53.2rem;
}
.btn.--btn-wt-532 {
  width: 53.2rem;
}
.btn.--btn-ht-533 {
  height: 53.3rem;
}
.btn.--btn-wt-533 {
  width: 53.3rem;
}
.btn.--btn-ht-534 {
  height: 53.4rem;
}
.btn.--btn-wt-534 {
  width: 53.4rem;
}
.btn.--btn-ht-535 {
  height: 53.5rem;
}
.btn.--btn-wt-535 {
  width: 53.5rem;
}
.btn.--btn-ht-536 {
  height: 53.6rem;
}
.btn.--btn-wt-536 {
  width: 53.6rem;
}
.btn.--btn-ht-537 {
  height: 53.7rem;
}
.btn.--btn-wt-537 {
  width: 53.7rem;
}
.btn.--btn-ht-538 {
  height: 53.8rem;
}
.btn.--btn-wt-538 {
  width: 53.8rem;
}
.btn.--btn-ht-539 {
  height: 53.9rem;
}
.btn.--btn-wt-539 {
  width: 53.9rem;
}
.btn.--btn-ht-540 {
  height: 54rem;
}
.btn.--btn-wt-540 {
  width: 54rem;
}
.btn.--btn-ht-541 {
  height: 54.1rem;
}
.btn.--btn-wt-541 {
  width: 54.1rem;
}
.btn.--btn-ht-542 {
  height: 54.2rem;
}
.btn.--btn-wt-542 {
  width: 54.2rem;
}
.btn.--btn-ht-543 {
  height: 54.3rem;
}
.btn.--btn-wt-543 {
  width: 54.3rem;
}
.btn.--btn-ht-544 {
  height: 54.4rem;
}
.btn.--btn-wt-544 {
  width: 54.4rem;
}
.btn.--btn-ht-545 {
  height: 54.5rem;
}
.btn.--btn-wt-545 {
  width: 54.5rem;
}
.btn.--btn-ht-546 {
  height: 54.6rem;
}
.btn.--btn-wt-546 {
  width: 54.6rem;
}
.btn.--btn-ht-547 {
  height: 54.7rem;
}
.btn.--btn-wt-547 {
  width: 54.7rem;
}
.btn.--btn-ht-548 {
  height: 54.8rem;
}
.btn.--btn-wt-548 {
  width: 54.8rem;
}
.btn.--btn-ht-549 {
  height: 54.9rem;
}
.btn.--btn-wt-549 {
  width: 54.9rem;
}
.btn.--btn-ht-550 {
  height: 55rem;
}
.btn.--btn-wt-550 {
  width: 55rem;
}
.btn.--btn-ht-551 {
  height: 55.1rem;
}
.btn.--btn-wt-551 {
  width: 55.1rem;
}
.btn.--btn-ht-552 {
  height: 55.2rem;
}
.btn.--btn-wt-552 {
  width: 55.2rem;
}
.btn.--btn-ht-553 {
  height: 55.3rem;
}
.btn.--btn-wt-553 {
  width: 55.3rem;
}
.btn.--btn-ht-554 {
  height: 55.4rem;
}
.btn.--btn-wt-554 {
  width: 55.4rem;
}
.btn.--btn-ht-555 {
  height: 55.5rem;
}
.btn.--btn-wt-555 {
  width: 55.5rem;
}
.btn.--btn-ht-556 {
  height: 55.6rem;
}
.btn.--btn-wt-556 {
  width: 55.6rem;
}
.btn.--btn-ht-557 {
  height: 55.7rem;
}
.btn.--btn-wt-557 {
  width: 55.7rem;
}
.btn.--btn-ht-558 {
  height: 55.8rem;
}
.btn.--btn-wt-558 {
  width: 55.8rem;
}
.btn.--btn-ht-559 {
  height: 55.9rem;
}
.btn.--btn-wt-559 {
  width: 55.9rem;
}
.btn.--btn-ht-560 {
  height: 56rem;
}
.btn.--btn-wt-560 {
  width: 56rem;
}
.btn.--btn-ht-561 {
  height: 56.1rem;
}
.btn.--btn-wt-561 {
  width: 56.1rem;
}
.btn.--btn-ht-562 {
  height: 56.2rem;
}
.btn.--btn-wt-562 {
  width: 56.2rem;
}
.btn.--btn-ht-563 {
  height: 56.3rem;
}
.btn.--btn-wt-563 {
  width: 56.3rem;
}
.btn.--btn-ht-564 {
  height: 56.4rem;
}
.btn.--btn-wt-564 {
  width: 56.4rem;
}
.btn.--btn-ht-565 {
  height: 56.5rem;
}
.btn.--btn-wt-565 {
  width: 56.5rem;
}
.btn.--btn-ht-566 {
  height: 56.6rem;
}
.btn.--btn-wt-566 {
  width: 56.6rem;
}
.btn.--btn-ht-567 {
  height: 56.7rem;
}
.btn.--btn-wt-567 {
  width: 56.7rem;
}
.btn.--btn-ht-568 {
  height: 56.8rem;
}
.btn.--btn-wt-568 {
  width: 56.8rem;
}
.btn.--btn-ht-569 {
  height: 56.9rem;
}
.btn.--btn-wt-569 {
  width: 56.9rem;
}
.btn.--btn-ht-570 {
  height: 57rem;
}
.btn.--btn-wt-570 {
  width: 57rem;
}
.btn.--btn-ht-571 {
  height: 57.1rem;
}
.btn.--btn-wt-571 {
  width: 57.1rem;
}
.btn.--btn-ht-572 {
  height: 57.2rem;
}
.btn.--btn-wt-572 {
  width: 57.2rem;
}
.btn.--btn-ht-573 {
  height: 57.3rem;
}
.btn.--btn-wt-573 {
  width: 57.3rem;
}
.btn.--btn-ht-574 {
  height: 57.4rem;
}
.btn.--btn-wt-574 {
  width: 57.4rem;
}
.btn.--btn-ht-575 {
  height: 57.5rem;
}
.btn.--btn-wt-575 {
  width: 57.5rem;
}
.btn.--btn-ht-576 {
  height: 57.6rem;
}
.btn.--btn-wt-576 {
  width: 57.6rem;
}
.btn.--btn-ht-577 {
  height: 57.7rem;
}
.btn.--btn-wt-577 {
  width: 57.7rem;
}
.btn.--btn-ht-578 {
  height: 57.8rem;
}
.btn.--btn-wt-578 {
  width: 57.8rem;
}
.btn.--btn-ht-579 {
  height: 57.9rem;
}
.btn.--btn-wt-579 {
  width: 57.9rem;
}
.btn.--btn-ht-580 {
  height: 58rem;
}
.btn.--btn-wt-580 {
  width: 58rem;
}
.btn.--btn-ht-581 {
  height: 58.1rem;
}
.btn.--btn-wt-581 {
  width: 58.1rem;
}
.btn.--btn-ht-582 {
  height: 58.2rem;
}
.btn.--btn-wt-582 {
  width: 58.2rem;
}
.btn.--btn-ht-583 {
  height: 58.3rem;
}
.btn.--btn-wt-583 {
  width: 58.3rem;
}
.btn.--btn-ht-584 {
  height: 58.4rem;
}
.btn.--btn-wt-584 {
  width: 58.4rem;
}
.btn.--btn-ht-585 {
  height: 58.5rem;
}
.btn.--btn-wt-585 {
  width: 58.5rem;
}
.btn.--btn-ht-586 {
  height: 58.6rem;
}
.btn.--btn-wt-586 {
  width: 58.6rem;
}
.btn.--btn-ht-587 {
  height: 58.7rem;
}
.btn.--btn-wt-587 {
  width: 58.7rem;
}
.btn.--btn-ht-588 {
  height: 58.8rem;
}
.btn.--btn-wt-588 {
  width: 58.8rem;
}
.btn.--btn-ht-589 {
  height: 58.9rem;
}
.btn.--btn-wt-589 {
  width: 58.9rem;
}
.btn.--btn-ht-590 {
  height: 59rem;
}
.btn.--btn-wt-590 {
  width: 59rem;
}
.btn.--btn-ht-591 {
  height: 59.1rem;
}
.btn.--btn-wt-591 {
  width: 59.1rem;
}
.btn.--btn-ht-592 {
  height: 59.2rem;
}
.btn.--btn-wt-592 {
  width: 59.2rem;
}
.btn.--btn-ht-593 {
  height: 59.3rem;
}
.btn.--btn-wt-593 {
  width: 59.3rem;
}
.btn.--btn-ht-594 {
  height: 59.4rem;
}
.btn.--btn-wt-594 {
  width: 59.4rem;
}
.btn.--btn-ht-595 {
  height: 59.5rem;
}
.btn.--btn-wt-595 {
  width: 59.5rem;
}
.btn.--btn-ht-596 {
  height: 59.6rem;
}
.btn.--btn-wt-596 {
  width: 59.6rem;
}
.btn.--btn-ht-597 {
  height: 59.7rem;
}
.btn.--btn-wt-597 {
  width: 59.7rem;
}
.btn.--btn-ht-598 {
  height: 59.8rem;
}
.btn.--btn-wt-598 {
  width: 59.8rem;
}
.btn.--btn-ht-599 {
  height: 59.9rem;
}
.btn.--btn-wt-599 {
  width: 59.9rem;
}
.btn.--btn-ht-600 {
  height: 60rem;
}
.btn.--btn-wt-600 {
  width: 60rem;
}
.btn.--btn-ht-601 {
  height: 60.1rem;
}
.btn.--btn-wt-601 {
  width: 60.1rem;
}
.btn.--btn-ht-602 {
  height: 60.2rem;
}
.btn.--btn-wt-602 {
  width: 60.2rem;
}
.btn.--btn-ht-603 {
  height: 60.3rem;
}
.btn.--btn-wt-603 {
  width: 60.3rem;
}
.btn.--btn-ht-604 {
  height: 60.4rem;
}
.btn.--btn-wt-604 {
  width: 60.4rem;
}
.btn.--btn-ht-605 {
  height: 60.5rem;
}
.btn.--btn-wt-605 {
  width: 60.5rem;
}
.btn.--btn-ht-606 {
  height: 60.6rem;
}
.btn.--btn-wt-606 {
  width: 60.6rem;
}
.btn.--btn-ht-607 {
  height: 60.7rem;
}
.btn.--btn-wt-607 {
  width: 60.7rem;
}
.btn.--btn-ht-608 {
  height: 60.8rem;
}
.btn.--btn-wt-608 {
  width: 60.8rem;
}
.btn.--btn-ht-609 {
  height: 60.9rem;
}
.btn.--btn-wt-609 {
  width: 60.9rem;
}
.btn.--btn-ht-610 {
  height: 61rem;
}
.btn.--btn-wt-610 {
  width: 61rem;
}
.btn.--btn-ht-611 {
  height: 61.1rem;
}
.btn.--btn-wt-611 {
  width: 61.1rem;
}
.btn.--btn-ht-612 {
  height: 61.2rem;
}
.btn.--btn-wt-612 {
  width: 61.2rem;
}
.btn.--btn-ht-613 {
  height: 61.3rem;
}
.btn.--btn-wt-613 {
  width: 61.3rem;
}
.btn.--btn-ht-614 {
  height: 61.4rem;
}
.btn.--btn-wt-614 {
  width: 61.4rem;
}
.btn.--btn-ht-615 {
  height: 61.5rem;
}
.btn.--btn-wt-615 {
  width: 61.5rem;
}
.btn.--btn-ht-616 {
  height: 61.6rem;
}
.btn.--btn-wt-616 {
  width: 61.6rem;
}
.btn.--btn-ht-617 {
  height: 61.7rem;
}
.btn.--btn-wt-617 {
  width: 61.7rem;
}
.btn.--btn-ht-618 {
  height: 61.8rem;
}
.btn.--btn-wt-618 {
  width: 61.8rem;
}
.btn.--btn-ht-619 {
  height: 61.9rem;
}
.btn.--btn-wt-619 {
  width: 61.9rem;
}
.btn.--btn-ht-620 {
  height: 62rem;
}
.btn.--btn-wt-620 {
  width: 62rem;
}
.btn.--btn-ht-621 {
  height: 62.1rem;
}
.btn.--btn-wt-621 {
  width: 62.1rem;
}
.btn.--btn-ht-622 {
  height: 62.2rem;
}
.btn.--btn-wt-622 {
  width: 62.2rem;
}
.btn.--btn-ht-623 {
  height: 62.3rem;
}
.btn.--btn-wt-623 {
  width: 62.3rem;
}
.btn.--btn-ht-624 {
  height: 62.4rem;
}
.btn.--btn-wt-624 {
  width: 62.4rem;
}
.btn.--btn-ht-625 {
  height: 62.5rem;
}
.btn.--btn-wt-625 {
  width: 62.5rem;
}
.btn.--btn-ht-626 {
  height: 62.6rem;
}
.btn.--btn-wt-626 {
  width: 62.6rem;
}
.btn.--btn-ht-627 {
  height: 62.7rem;
}
.btn.--btn-wt-627 {
  width: 62.7rem;
}
.btn.--btn-ht-628 {
  height: 62.8rem;
}
.btn.--btn-wt-628 {
  width: 62.8rem;
}
.btn.--btn-ht-629 {
  height: 62.9rem;
}
.btn.--btn-wt-629 {
  width: 62.9rem;
}
.btn.--btn-ht-630 {
  height: 63rem;
}
.btn.--btn-wt-630 {
  width: 63rem;
}
.btn.--btn-ht-631 {
  height: 63.1rem;
}
.btn.--btn-wt-631 {
  width: 63.1rem;
}
.btn.--btn-ht-632 {
  height: 63.2rem;
}
.btn.--btn-wt-632 {
  width: 63.2rem;
}
.btn.--btn-ht-633 {
  height: 63.3rem;
}
.btn.--btn-wt-633 {
  width: 63.3rem;
}
.btn.--btn-ht-634 {
  height: 63.4rem;
}
.btn.--btn-wt-634 {
  width: 63.4rem;
}
.btn.--btn-ht-635 {
  height: 63.5rem;
}
.btn.--btn-wt-635 {
  width: 63.5rem;
}
.btn.--btn-ht-636 {
  height: 63.6rem;
}
.btn.--btn-wt-636 {
  width: 63.6rem;
}
.btn.--btn-ht-637 {
  height: 63.7rem;
}
.btn.--btn-wt-637 {
  width: 63.7rem;
}
.btn.--btn-ht-638 {
  height: 63.8rem;
}
.btn.--btn-wt-638 {
  width: 63.8rem;
}
.btn.--btn-ht-639 {
  height: 63.9rem;
}
.btn.--btn-wt-639 {
  width: 63.9rem;
}
.btn.--btn-ht-640 {
  height: 64rem;
}
.btn.--btn-wt-640 {
  width: 64rem;
}
.btn.--btn-ht-641 {
  height: 64.1rem;
}
.btn.--btn-wt-641 {
  width: 64.1rem;
}
.btn.--btn-ht-642 {
  height: 64.2rem;
}
.btn.--btn-wt-642 {
  width: 64.2rem;
}
.btn.--btn-ht-643 {
  height: 64.3rem;
}
.btn.--btn-wt-643 {
  width: 64.3rem;
}
.btn.--btn-ht-644 {
  height: 64.4rem;
}
.btn.--btn-wt-644 {
  width: 64.4rem;
}
.btn.--btn-ht-645 {
  height: 64.5rem;
}
.btn.--btn-wt-645 {
  width: 64.5rem;
}
.btn.--btn-ht-646 {
  height: 64.6rem;
}
.btn.--btn-wt-646 {
  width: 64.6rem;
}
.btn.--btn-ht-647 {
  height: 64.7rem;
}
.btn.--btn-wt-647 {
  width: 64.7rem;
}
.btn.--btn-ht-648 {
  height: 64.8rem;
}
.btn.--btn-wt-648 {
  width: 64.8rem;
}
.btn.--btn-ht-649 {
  height: 64.9rem;
}
.btn.--btn-wt-649 {
  width: 64.9rem;
}
.btn.--btn-ht-650 {
  height: 65rem;
}
.btn.--btn-wt-650 {
  width: 65rem;
}
.btn.--btn-ht-651 {
  height: 65.1rem;
}
.btn.--btn-wt-651 {
  width: 65.1rem;
}
.btn.--btn-ht-652 {
  height: 65.2rem;
}
.btn.--btn-wt-652 {
  width: 65.2rem;
}
.btn.--btn-ht-653 {
  height: 65.3rem;
}
.btn.--btn-wt-653 {
  width: 65.3rem;
}
.btn.--btn-ht-654 {
  height: 65.4rem;
}
.btn.--btn-wt-654 {
  width: 65.4rem;
}
.btn.--btn-ht-655 {
  height: 65.5rem;
}
.btn.--btn-wt-655 {
  width: 65.5rem;
}
.btn.--btn-ht-656 {
  height: 65.6rem;
}
.btn.--btn-wt-656 {
  width: 65.6rem;
}
.btn.--btn-ht-657 {
  height: 65.7rem;
}
.btn.--btn-wt-657 {
  width: 65.7rem;
}
.btn.--btn-ht-658 {
  height: 65.8rem;
}
.btn.--btn-wt-658 {
  width: 65.8rem;
}
.btn.--btn-ht-659 {
  height: 65.9rem;
}
.btn.--btn-wt-659 {
  width: 65.9rem;
}
.btn.--btn-ht-660 {
  height: 66rem;
}
.btn.--btn-wt-660 {
  width: 66rem;
}
.btn.--btn-ht-661 {
  height: 66.1rem;
}
.btn.--btn-wt-661 {
  width: 66.1rem;
}
.btn.--btn-ht-662 {
  height: 66.2rem;
}
.btn.--btn-wt-662 {
  width: 66.2rem;
}
.btn.--btn-ht-663 {
  height: 66.3rem;
}
.btn.--btn-wt-663 {
  width: 66.3rem;
}
.btn.--btn-ht-664 {
  height: 66.4rem;
}
.btn.--btn-wt-664 {
  width: 66.4rem;
}
.btn.--btn-ht-665 {
  height: 66.5rem;
}
.btn.--btn-wt-665 {
  width: 66.5rem;
}
.btn.--btn-ht-666 {
  height: 66.6rem;
}
.btn.--btn-wt-666 {
  width: 66.6rem;
}
.btn.--btn-ht-667 {
  height: 66.7rem;
}
.btn.--btn-wt-667 {
  width: 66.7rem;
}
.btn.--btn-ht-668 {
  height: 66.8rem;
}
.btn.--btn-wt-668 {
  width: 66.8rem;
}
.btn.--btn-ht-669 {
  height: 66.9rem;
}
.btn.--btn-wt-669 {
  width: 66.9rem;
}
.btn.--btn-ht-670 {
  height: 67rem;
}
.btn.--btn-wt-670 {
  width: 67rem;
}
.btn.--btn-ht-671 {
  height: 67.1rem;
}
.btn.--btn-wt-671 {
  width: 67.1rem;
}
.btn.--btn-ht-672 {
  height: 67.2rem;
}
.btn.--btn-wt-672 {
  width: 67.2rem;
}
.btn.--btn-ht-673 {
  height: 67.3rem;
}
.btn.--btn-wt-673 {
  width: 67.3rem;
}
.btn.--btn-ht-674 {
  height: 67.4rem;
}
.btn.--btn-wt-674 {
  width: 67.4rem;
}
.btn.--btn-ht-675 {
  height: 67.5rem;
}
.btn.--btn-wt-675 {
  width: 67.5rem;
}
.btn.--btn-ht-676 {
  height: 67.6rem;
}
.btn.--btn-wt-676 {
  width: 67.6rem;
}
.btn.--btn-ht-677 {
  height: 67.7rem;
}
.btn.--btn-wt-677 {
  width: 67.7rem;
}
.btn.--btn-ht-678 {
  height: 67.8rem;
}
.btn.--btn-wt-678 {
  width: 67.8rem;
}
.btn.--btn-ht-679 {
  height: 67.9rem;
}
.btn.--btn-wt-679 {
  width: 67.9rem;
}
.btn.--btn-ht-680 {
  height: 68rem;
}
.btn.--btn-wt-680 {
  width: 68rem;
}
.btn.--btn-ht-681 {
  height: 68.1rem;
}
.btn.--btn-wt-681 {
  width: 68.1rem;
}
.btn.--btn-ht-682 {
  height: 68.2rem;
}
.btn.--btn-wt-682 {
  width: 68.2rem;
}
.btn.--btn-ht-683 {
  height: 68.3rem;
}
.btn.--btn-wt-683 {
  width: 68.3rem;
}
.btn.--btn-ht-684 {
  height: 68.4rem;
}
.btn.--btn-wt-684 {
  width: 68.4rem;
}
.btn.--btn-ht-685 {
  height: 68.5rem;
}
.btn.--btn-wt-685 {
  width: 68.5rem;
}
.btn.--btn-ht-686 {
  height: 68.6rem;
}
.btn.--btn-wt-686 {
  width: 68.6rem;
}
.btn.--btn-ht-687 {
  height: 68.7rem;
}
.btn.--btn-wt-687 {
  width: 68.7rem;
}
.btn.--btn-ht-688 {
  height: 68.8rem;
}
.btn.--btn-wt-688 {
  width: 68.8rem;
}
.btn.--btn-ht-689 {
  height: 68.9rem;
}
.btn.--btn-wt-689 {
  width: 68.9rem;
}
.btn.--btn-ht-690 {
  height: 69rem;
}
.btn.--btn-wt-690 {
  width: 69rem;
}
.btn.--btn-ht-691 {
  height: 69.1rem;
}
.btn.--btn-wt-691 {
  width: 69.1rem;
}
.btn.--btn-ht-692 {
  height: 69.2rem;
}
.btn.--btn-wt-692 {
  width: 69.2rem;
}
.btn.--btn-ht-693 {
  height: 69.3rem;
}
.btn.--btn-wt-693 {
  width: 69.3rem;
}
.btn.--btn-ht-694 {
  height: 69.4rem;
}
.btn.--btn-wt-694 {
  width: 69.4rem;
}
.btn.--btn-ht-695 {
  height: 69.5rem;
}
.btn.--btn-wt-695 {
  width: 69.5rem;
}
.btn.--btn-ht-696 {
  height: 69.6rem;
}
.btn.--btn-wt-696 {
  width: 69.6rem;
}
.btn.--btn-ht-697 {
  height: 69.7rem;
}
.btn.--btn-wt-697 {
  width: 69.7rem;
}
.btn.--btn-ht-698 {
  height: 69.8rem;
}
.btn.--btn-wt-698 {
  width: 69.8rem;
}
.btn.--btn-ht-699 {
  height: 69.9rem;
}
.btn.--btn-wt-699 {
  width: 69.9rem;
}
.btn.--btn-ht-700 {
  height: 70rem;
}
.btn.--btn-wt-700 {
  width: 70rem;
}
.btn.--btn-ht-701 {
  height: 70.1rem;
}
.btn.--btn-wt-701 {
  width: 70.1rem;
}
.btn.--btn-ht-702 {
  height: 70.2rem;
}
.btn.--btn-wt-702 {
  width: 70.2rem;
}
.btn.--btn-ht-703 {
  height: 70.3rem;
}
.btn.--btn-wt-703 {
  width: 70.3rem;
}
.btn.--btn-ht-704 {
  height: 70.4rem;
}
.btn.--btn-wt-704 {
  width: 70.4rem;
}
.btn.--btn-ht-705 {
  height: 70.5rem;
}
.btn.--btn-wt-705 {
  width: 70.5rem;
}
.btn.--btn-ht-706 {
  height: 70.6rem;
}
.btn.--btn-wt-706 {
  width: 70.6rem;
}
.btn.--btn-ht-707 {
  height: 70.7rem;
}
.btn.--btn-wt-707 {
  width: 70.7rem;
}
.btn.--btn-ht-708 {
  height: 70.8rem;
}
.btn.--btn-wt-708 {
  width: 70.8rem;
}
.btn.--btn-ht-709 {
  height: 70.9rem;
}
.btn.--btn-wt-709 {
  width: 70.9rem;
}
.btn.--btn-ht-710 {
  height: 71rem;
}
.btn.--btn-wt-710 {
  width: 71rem;
}
.btn.--btn-ht-711 {
  height: 71.1rem;
}
.btn.--btn-wt-711 {
  width: 71.1rem;
}
.btn.--btn-ht-712 {
  height: 71.2rem;
}
.btn.--btn-wt-712 {
  width: 71.2rem;
}
.btn.--btn-ht-713 {
  height: 71.3rem;
}
.btn.--btn-wt-713 {
  width: 71.3rem;
}
.btn.--btn-ht-714 {
  height: 71.4rem;
}
.btn.--btn-wt-714 {
  width: 71.4rem;
}
.btn.--btn-ht-715 {
  height: 71.5rem;
}
.btn.--btn-wt-715 {
  width: 71.5rem;
}
.btn.--btn-ht-716 {
  height: 71.6rem;
}
.btn.--btn-wt-716 {
  width: 71.6rem;
}
.btn.--btn-ht-717 {
  height: 71.7rem;
}
.btn.--btn-wt-717 {
  width: 71.7rem;
}
.btn.--btn-ht-718 {
  height: 71.8rem;
}
.btn.--btn-wt-718 {
  width: 71.8rem;
}
.btn.--btn-ht-719 {
  height: 71.9rem;
}
.btn.--btn-wt-719 {
  width: 71.9rem;
}
.btn.--btn-ht-720 {
  height: 72rem;
}
.btn.--btn-wt-720 {
  width: 72rem;
}
.btn.--btn-ht-721 {
  height: 72.1rem;
}
.btn.--btn-wt-721 {
  width: 72.1rem;
}
.btn.--btn-ht-722 {
  height: 72.2rem;
}
.btn.--btn-wt-722 {
  width: 72.2rem;
}
.btn.--btn-ht-723 {
  height: 72.3rem;
}
.btn.--btn-wt-723 {
  width: 72.3rem;
}
.btn.--btn-ht-724 {
  height: 72.4rem;
}
.btn.--btn-wt-724 {
  width: 72.4rem;
}
.btn.--btn-ht-725 {
  height: 72.5rem;
}
.btn.--btn-wt-725 {
  width: 72.5rem;
}
.btn.--btn-ht-726 {
  height: 72.6rem;
}
.btn.--btn-wt-726 {
  width: 72.6rem;
}
.btn.--btn-ht-727 {
  height: 72.7rem;
}
.btn.--btn-wt-727 {
  width: 72.7rem;
}
.btn.--btn-ht-728 {
  height: 72.8rem;
}
.btn.--btn-wt-728 {
  width: 72.8rem;
}
.btn.--btn-ht-729 {
  height: 72.9rem;
}
.btn.--btn-wt-729 {
  width: 72.9rem;
}
.btn.--btn-ht-730 {
  height: 73rem;
}
.btn.--btn-wt-730 {
  width: 73rem;
}
.btn.--btn-ht-731 {
  height: 73.1rem;
}
.btn.--btn-wt-731 {
  width: 73.1rem;
}
.btn.--btn-ht-732 {
  height: 73.2rem;
}
.btn.--btn-wt-732 {
  width: 73.2rem;
}
.btn.--btn-ht-733 {
  height: 73.3rem;
}
.btn.--btn-wt-733 {
  width: 73.3rem;
}
.btn.--btn-ht-734 {
  height: 73.4rem;
}
.btn.--btn-wt-734 {
  width: 73.4rem;
}
.btn.--btn-ht-735 {
  height: 73.5rem;
}
.btn.--btn-wt-735 {
  width: 73.5rem;
}
.btn.--btn-ht-736 {
  height: 73.6rem;
}
.btn.--btn-wt-736 {
  width: 73.6rem;
}
.btn.--btn-ht-737 {
  height: 73.7rem;
}
.btn.--btn-wt-737 {
  width: 73.7rem;
}
.btn.--btn-ht-738 {
  height: 73.8rem;
}
.btn.--btn-wt-738 {
  width: 73.8rem;
}
.btn.--btn-ht-739 {
  height: 73.9rem;
}
.btn.--btn-wt-739 {
  width: 73.9rem;
}
.btn.--btn-ht-740 {
  height: 74rem;
}
.btn.--btn-wt-740 {
  width: 74rem;
}
.btn.--btn-ht-741 {
  height: 74.1rem;
}
.btn.--btn-wt-741 {
  width: 74.1rem;
}
.btn.--btn-ht-742 {
  height: 74.2rem;
}
.btn.--btn-wt-742 {
  width: 74.2rem;
}
.btn.--btn-ht-743 {
  height: 74.3rem;
}
.btn.--btn-wt-743 {
  width: 74.3rem;
}
.btn.--btn-ht-744 {
  height: 74.4rem;
}
.btn.--btn-wt-744 {
  width: 74.4rem;
}
.btn.--btn-ht-745 {
  height: 74.5rem;
}
.btn.--btn-wt-745 {
  width: 74.5rem;
}
.btn.--btn-ht-746 {
  height: 74.6rem;
}
.btn.--btn-wt-746 {
  width: 74.6rem;
}
.btn.--btn-ht-747 {
  height: 74.7rem;
}
.btn.--btn-wt-747 {
  width: 74.7rem;
}
.btn.--btn-ht-748 {
  height: 74.8rem;
}
.btn.--btn-wt-748 {
  width: 74.8rem;
}
.btn.--btn-ht-749 {
  height: 74.9rem;
}
.btn.--btn-wt-749 {
  width: 74.9rem;
}
.btn.--btn-ht-750 {
  height: 75rem;
}
.btn.--btn-wt-750 {
  width: 75rem;
}
.btn.--btn-ht-751 {
  height: 75.1rem;
}
.btn.--btn-wt-751 {
  width: 75.1rem;
}
.btn.--btn-ht-752 {
  height: 75.2rem;
}
.btn.--btn-wt-752 {
  width: 75.2rem;
}
.btn.--btn-ht-753 {
  height: 75.3rem;
}
.btn.--btn-wt-753 {
  width: 75.3rem;
}
.btn.--btn-ht-754 {
  height: 75.4rem;
}
.btn.--btn-wt-754 {
  width: 75.4rem;
}
.btn.--btn-ht-755 {
  height: 75.5rem;
}
.btn.--btn-wt-755 {
  width: 75.5rem;
}
.btn.--btn-ht-756 {
  height: 75.6rem;
}
.btn.--btn-wt-756 {
  width: 75.6rem;
}
.btn.--btn-ht-757 {
  height: 75.7rem;
}
.btn.--btn-wt-757 {
  width: 75.7rem;
}
.btn.--btn-ht-758 {
  height: 75.8rem;
}
.btn.--btn-wt-758 {
  width: 75.8rem;
}
.btn.--btn-ht-759 {
  height: 75.9rem;
}
.btn.--btn-wt-759 {
  width: 75.9rem;
}
.btn.--btn-ht-760 {
  height: 76rem;
}
.btn.--btn-wt-760 {
  width: 76rem;
}
.btn.--btn-ht-761 {
  height: 76.1rem;
}
.btn.--btn-wt-761 {
  width: 76.1rem;
}
.btn.--btn-ht-762 {
  height: 76.2rem;
}
.btn.--btn-wt-762 {
  width: 76.2rem;
}
.btn.--btn-ht-763 {
  height: 76.3rem;
}
.btn.--btn-wt-763 {
  width: 76.3rem;
}
.btn.--btn-ht-764 {
  height: 76.4rem;
}
.btn.--btn-wt-764 {
  width: 76.4rem;
}
.btn.--btn-ht-765 {
  height: 76.5rem;
}
.btn.--btn-wt-765 {
  width: 76.5rem;
}
.btn.--btn-ht-766 {
  height: 76.6rem;
}
.btn.--btn-wt-766 {
  width: 76.6rem;
}
.btn.--btn-ht-767 {
  height: 76.7rem;
}
.btn.--btn-wt-767 {
  width: 76.7rem;
}
.btn.--btn-ht-768 {
  height: 76.8rem;
}
.btn.--btn-wt-768 {
  width: 76.8rem;
}
.btn.--btn-ht-769 {
  height: 76.9rem;
}
.btn.--btn-wt-769 {
  width: 76.9rem;
}
.btn.--btn-ht-770 {
  height: 77rem;
}
.btn.--btn-wt-770 {
  width: 77rem;
}
.btn.--btn-ht-771 {
  height: 77.1rem;
}
.btn.--btn-wt-771 {
  width: 77.1rem;
}
.btn.--btn-ht-772 {
  height: 77.2rem;
}
.btn.--btn-wt-772 {
  width: 77.2rem;
}
.btn.--btn-ht-773 {
  height: 77.3rem;
}
.btn.--btn-wt-773 {
  width: 77.3rem;
}
.btn.--btn-ht-774 {
  height: 77.4rem;
}
.btn.--btn-wt-774 {
  width: 77.4rem;
}
.btn.--btn-ht-775 {
  height: 77.5rem;
}
.btn.--btn-wt-775 {
  width: 77.5rem;
}
.btn.--btn-ht-776 {
  height: 77.6rem;
}
.btn.--btn-wt-776 {
  width: 77.6rem;
}
.btn.--btn-ht-777 {
  height: 77.7rem;
}
.btn.--btn-wt-777 {
  width: 77.7rem;
}
.btn.--btn-ht-778 {
  height: 77.8rem;
}
.btn.--btn-wt-778 {
  width: 77.8rem;
}
.btn.--btn-ht-779 {
  height: 77.9rem;
}
.btn.--btn-wt-779 {
  width: 77.9rem;
}
.btn.--btn-ht-780 {
  height: 78rem;
}
.btn.--btn-wt-780 {
  width: 78rem;
}
.btn.--btn-ht-781 {
  height: 78.1rem;
}
.btn.--btn-wt-781 {
  width: 78.1rem;
}
.btn.--btn-ht-782 {
  height: 78.2rem;
}
.btn.--btn-wt-782 {
  width: 78.2rem;
}
.btn.--btn-ht-783 {
  height: 78.3rem;
}
.btn.--btn-wt-783 {
  width: 78.3rem;
}
.btn.--btn-ht-784 {
  height: 78.4rem;
}
.btn.--btn-wt-784 {
  width: 78.4rem;
}
.btn.--btn-ht-785 {
  height: 78.5rem;
}
.btn.--btn-wt-785 {
  width: 78.5rem;
}
.btn.--btn-ht-786 {
  height: 78.6rem;
}
.btn.--btn-wt-786 {
  width: 78.6rem;
}
.btn.--btn-ht-787 {
  height: 78.7rem;
}
.btn.--btn-wt-787 {
  width: 78.7rem;
}
.btn.--btn-ht-788 {
  height: 78.8rem;
}
.btn.--btn-wt-788 {
  width: 78.8rem;
}
.btn.--btn-ht-789 {
  height: 78.9rem;
}
.btn.--btn-wt-789 {
  width: 78.9rem;
}
.btn.--btn-ht-790 {
  height: 79rem;
}
.btn.--btn-wt-790 {
  width: 79rem;
}
.btn.--btn-ht-791 {
  height: 79.1rem;
}
.btn.--btn-wt-791 {
  width: 79.1rem;
}
.btn.--btn-ht-792 {
  height: 79.2rem;
}
.btn.--btn-wt-792 {
  width: 79.2rem;
}
.btn.--btn-ht-793 {
  height: 79.3rem;
}
.btn.--btn-wt-793 {
  width: 79.3rem;
}
.btn.--btn-ht-794 {
  height: 79.4rem;
}
.btn.--btn-wt-794 {
  width: 79.4rem;
}
.btn.--btn-ht-795 {
  height: 79.5rem;
}
.btn.--btn-wt-795 {
  width: 79.5rem;
}
.btn.--btn-ht-796 {
  height: 79.6rem;
}
.btn.--btn-wt-796 {
  width: 79.6rem;
}
.btn.--btn-ht-797 {
  height: 79.7rem;
}
.btn.--btn-wt-797 {
  width: 79.7rem;
}
.btn.--btn-ht-798 {
  height: 79.8rem;
}
.btn.--btn-wt-798 {
  width: 79.8rem;
}
.btn.--btn-ht-799 {
  height: 79.9rem;
}
.btn.--btn-wt-799 {
  width: 79.9rem;
}
.btn.--btn-ht-800 {
  height: 80rem;
}
.btn.--btn-wt-800 {
  width: 80rem;
}
.btn.--btn-ht-801 {
  height: 80.1rem;
}
.btn.--btn-wt-801 {
  width: 80.1rem;
}
.btn.--btn-ht-802 {
  height: 80.2rem;
}
.btn.--btn-wt-802 {
  width: 80.2rem;
}
.btn.--btn-ht-803 {
  height: 80.3rem;
}
.btn.--btn-wt-803 {
  width: 80.3rem;
}
.btn.--btn-ht-804 {
  height: 80.4rem;
}
.btn.--btn-wt-804 {
  width: 80.4rem;
}
.btn.--btn-ht-805 {
  height: 80.5rem;
}
.btn.--btn-wt-805 {
  width: 80.5rem;
}
.btn.--btn-ht-806 {
  height: 80.6rem;
}
.btn.--btn-wt-806 {
  width: 80.6rem;
}
.btn.--btn-ht-807 {
  height: 80.7rem;
}
.btn.--btn-wt-807 {
  width: 80.7rem;
}
.btn.--btn-ht-808 {
  height: 80.8rem;
}
.btn.--btn-wt-808 {
  width: 80.8rem;
}
.btn.--btn-ht-809 {
  height: 80.9rem;
}
.btn.--btn-wt-809 {
  width: 80.9rem;
}
.btn.--btn-ht-810 {
  height: 81rem;
}
.btn.--btn-wt-810 {
  width: 81rem;
}
.btn.--btn-ht-811 {
  height: 81.1rem;
}
.btn.--btn-wt-811 {
  width: 81.1rem;
}
.btn.--btn-ht-812 {
  height: 81.2rem;
}
.btn.--btn-wt-812 {
  width: 81.2rem;
}
.btn.--btn-ht-813 {
  height: 81.3rem;
}
.btn.--btn-wt-813 {
  width: 81.3rem;
}
.btn.--btn-ht-814 {
  height: 81.4rem;
}
.btn.--btn-wt-814 {
  width: 81.4rem;
}
.btn.--btn-ht-815 {
  height: 81.5rem;
}
.btn.--btn-wt-815 {
  width: 81.5rem;
}
.btn.--btn-ht-816 {
  height: 81.6rem;
}
.btn.--btn-wt-816 {
  width: 81.6rem;
}
.btn.--btn-ht-817 {
  height: 81.7rem;
}
.btn.--btn-wt-817 {
  width: 81.7rem;
}
.btn.--btn-ht-818 {
  height: 81.8rem;
}
.btn.--btn-wt-818 {
  width: 81.8rem;
}
.btn.--btn-ht-819 {
  height: 81.9rem;
}
.btn.--btn-wt-819 {
  width: 81.9rem;
}
.btn.--btn-ht-820 {
  height: 82rem;
}
.btn.--btn-wt-820 {
  width: 82rem;
}
.btn.--btn-ht-821 {
  height: 82.1rem;
}
.btn.--btn-wt-821 {
  width: 82.1rem;
}
.btn.--btn-ht-822 {
  height: 82.2rem;
}
.btn.--btn-wt-822 {
  width: 82.2rem;
}
.btn.--btn-ht-823 {
  height: 82.3rem;
}
.btn.--btn-wt-823 {
  width: 82.3rem;
}
.btn.--btn-ht-824 {
  height: 82.4rem;
}
.btn.--btn-wt-824 {
  width: 82.4rem;
}
.btn.--btn-ht-825 {
  height: 82.5rem;
}
.btn.--btn-wt-825 {
  width: 82.5rem;
}
.btn.--btn-ht-826 {
  height: 82.6rem;
}
.btn.--btn-wt-826 {
  width: 82.6rem;
}
.btn.--btn-ht-827 {
  height: 82.7rem;
}
.btn.--btn-wt-827 {
  width: 82.7rem;
}
.btn.--btn-ht-828 {
  height: 82.8rem;
}
.btn.--btn-wt-828 {
  width: 82.8rem;
}
.btn.--btn-ht-829 {
  height: 82.9rem;
}
.btn.--btn-wt-829 {
  width: 82.9rem;
}
.btn.--btn-ht-830 {
  height: 83rem;
}
.btn.--btn-wt-830 {
  width: 83rem;
}
.btn.--btn-ht-831 {
  height: 83.1rem;
}
.btn.--btn-wt-831 {
  width: 83.1rem;
}
.btn.--btn-ht-832 {
  height: 83.2rem;
}
.btn.--btn-wt-832 {
  width: 83.2rem;
}
.btn.--btn-ht-833 {
  height: 83.3rem;
}
.btn.--btn-wt-833 {
  width: 83.3rem;
}
.btn.--btn-ht-834 {
  height: 83.4rem;
}
.btn.--btn-wt-834 {
  width: 83.4rem;
}
.btn.--btn-ht-835 {
  height: 83.5rem;
}
.btn.--btn-wt-835 {
  width: 83.5rem;
}
.btn.--btn-ht-836 {
  height: 83.6rem;
}
.btn.--btn-wt-836 {
  width: 83.6rem;
}
.btn.--btn-ht-837 {
  height: 83.7rem;
}
.btn.--btn-wt-837 {
  width: 83.7rem;
}
.btn.--btn-ht-838 {
  height: 83.8rem;
}
.btn.--btn-wt-838 {
  width: 83.8rem;
}
.btn.--btn-ht-839 {
  height: 83.9rem;
}
.btn.--btn-wt-839 {
  width: 83.9rem;
}
.btn.--btn-ht-840 {
  height: 84rem;
}
.btn.--btn-wt-840 {
  width: 84rem;
}
.btn.--btn-ht-841 {
  height: 84.1rem;
}
.btn.--btn-wt-841 {
  width: 84.1rem;
}
.btn.--btn-ht-842 {
  height: 84.2rem;
}
.btn.--btn-wt-842 {
  width: 84.2rem;
}
.btn.--btn-ht-843 {
  height: 84.3rem;
}
.btn.--btn-wt-843 {
  width: 84.3rem;
}
.btn.--btn-ht-844 {
  height: 84.4rem;
}
.btn.--btn-wt-844 {
  width: 84.4rem;
}
.btn.--btn-ht-845 {
  height: 84.5rem;
}
.btn.--btn-wt-845 {
  width: 84.5rem;
}
.btn.--btn-ht-846 {
  height: 84.6rem;
}
.btn.--btn-wt-846 {
  width: 84.6rem;
}
.btn.--btn-ht-847 {
  height: 84.7rem;
}
.btn.--btn-wt-847 {
  width: 84.7rem;
}
.btn.--btn-ht-848 {
  height: 84.8rem;
}
.btn.--btn-wt-848 {
  width: 84.8rem;
}
.btn.--btn-ht-849 {
  height: 84.9rem;
}
.btn.--btn-wt-849 {
  width: 84.9rem;
}
.btn.--btn-ht-850 {
  height: 85rem;
}
.btn.--btn-wt-850 {
  width: 85rem;
}
.btn.--btn-ht-851 {
  height: 85.1rem;
}
.btn.--btn-wt-851 {
  width: 85.1rem;
}
.btn.--btn-ht-852 {
  height: 85.2rem;
}
.btn.--btn-wt-852 {
  width: 85.2rem;
}
.btn.--btn-ht-853 {
  height: 85.3rem;
}
.btn.--btn-wt-853 {
  width: 85.3rem;
}
.btn.--btn-ht-854 {
  height: 85.4rem;
}
.btn.--btn-wt-854 {
  width: 85.4rem;
}
.btn.--btn-ht-855 {
  height: 85.5rem;
}
.btn.--btn-wt-855 {
  width: 85.5rem;
}
.btn.--btn-ht-856 {
  height: 85.6rem;
}
.btn.--btn-wt-856 {
  width: 85.6rem;
}
.btn.--btn-ht-857 {
  height: 85.7rem;
}
.btn.--btn-wt-857 {
  width: 85.7rem;
}
.btn.--btn-ht-858 {
  height: 85.8rem;
}
.btn.--btn-wt-858 {
  width: 85.8rem;
}
.btn.--btn-ht-859 {
  height: 85.9rem;
}
.btn.--btn-wt-859 {
  width: 85.9rem;
}
.btn.--btn-ht-860 {
  height: 86rem;
}
.btn.--btn-wt-860 {
  width: 86rem;
}
.btn.--btn-ht-861 {
  height: 86.1rem;
}
.btn.--btn-wt-861 {
  width: 86.1rem;
}
.btn.--btn-ht-862 {
  height: 86.2rem;
}
.btn.--btn-wt-862 {
  width: 86.2rem;
}
.btn.--btn-ht-863 {
  height: 86.3rem;
}
.btn.--btn-wt-863 {
  width: 86.3rem;
}
.btn.--btn-ht-864 {
  height: 86.4rem;
}
.btn.--btn-wt-864 {
  width: 86.4rem;
}
.btn.--btn-ht-865 {
  height: 86.5rem;
}
.btn.--btn-wt-865 {
  width: 86.5rem;
}
.btn.--btn-ht-866 {
  height: 86.6rem;
}
.btn.--btn-wt-866 {
  width: 86.6rem;
}
.btn.--btn-ht-867 {
  height: 86.7rem;
}
.btn.--btn-wt-867 {
  width: 86.7rem;
}
.btn.--btn-ht-868 {
  height: 86.8rem;
}
.btn.--btn-wt-868 {
  width: 86.8rem;
}
.btn.--btn-ht-869 {
  height: 86.9rem;
}
.btn.--btn-wt-869 {
  width: 86.9rem;
}
.btn.--btn-ht-870 {
  height: 87rem;
}
.btn.--btn-wt-870 {
  width: 87rem;
}
.btn.--btn-ht-871 {
  height: 87.1rem;
}
.btn.--btn-wt-871 {
  width: 87.1rem;
}
.btn.--btn-ht-872 {
  height: 87.2rem;
}
.btn.--btn-wt-872 {
  width: 87.2rem;
}
.btn.--btn-ht-873 {
  height: 87.3rem;
}
.btn.--btn-wt-873 {
  width: 87.3rem;
}
.btn.--btn-ht-874 {
  height: 87.4rem;
}
.btn.--btn-wt-874 {
  width: 87.4rem;
}
.btn.--btn-ht-875 {
  height: 87.5rem;
}
.btn.--btn-wt-875 {
  width: 87.5rem;
}
.btn.--btn-ht-876 {
  height: 87.6rem;
}
.btn.--btn-wt-876 {
  width: 87.6rem;
}
.btn.--btn-ht-877 {
  height: 87.7rem;
}
.btn.--btn-wt-877 {
  width: 87.7rem;
}
.btn.--btn-ht-878 {
  height: 87.8rem;
}
.btn.--btn-wt-878 {
  width: 87.8rem;
}
.btn.--btn-ht-879 {
  height: 87.9rem;
}
.btn.--btn-wt-879 {
  width: 87.9rem;
}
.btn.--btn-ht-880 {
  height: 88rem;
}
.btn.--btn-wt-880 {
  width: 88rem;
}
.btn.--btn-ht-881 {
  height: 88.1rem;
}
.btn.--btn-wt-881 {
  width: 88.1rem;
}
.btn.--btn-ht-882 {
  height: 88.2rem;
}
.btn.--btn-wt-882 {
  width: 88.2rem;
}
.btn.--btn-ht-883 {
  height: 88.3rem;
}
.btn.--btn-wt-883 {
  width: 88.3rem;
}
.btn.--btn-ht-884 {
  height: 88.4rem;
}
.btn.--btn-wt-884 {
  width: 88.4rem;
}
.btn.--btn-ht-885 {
  height: 88.5rem;
}
.btn.--btn-wt-885 {
  width: 88.5rem;
}
.btn.--btn-ht-886 {
  height: 88.6rem;
}
.btn.--btn-wt-886 {
  width: 88.6rem;
}
.btn.--btn-ht-887 {
  height: 88.7rem;
}
.btn.--btn-wt-887 {
  width: 88.7rem;
}
.btn.--btn-ht-888 {
  height: 88.8rem;
}
.btn.--btn-wt-888 {
  width: 88.8rem;
}
.btn.--btn-ht-889 {
  height: 88.9rem;
}
.btn.--btn-wt-889 {
  width: 88.9rem;
}
.btn.--btn-ht-890 {
  height: 89rem;
}
.btn.--btn-wt-890 {
  width: 89rem;
}
.btn.--btn-ht-891 {
  height: 89.1rem;
}
.btn.--btn-wt-891 {
  width: 89.1rem;
}
.btn.--btn-ht-892 {
  height: 89.2rem;
}
.btn.--btn-wt-892 {
  width: 89.2rem;
}
.btn.--btn-ht-893 {
  height: 89.3rem;
}
.btn.--btn-wt-893 {
  width: 89.3rem;
}
.btn.--btn-ht-894 {
  height: 89.4rem;
}
.btn.--btn-wt-894 {
  width: 89.4rem;
}
.btn.--btn-ht-895 {
  height: 89.5rem;
}
.btn.--btn-wt-895 {
  width: 89.5rem;
}
.btn.--btn-ht-896 {
  height: 89.6rem;
}
.btn.--btn-wt-896 {
  width: 89.6rem;
}
.btn.--btn-ht-897 {
  height: 89.7rem;
}
.btn.--btn-wt-897 {
  width: 89.7rem;
}
.btn.--btn-ht-898 {
  height: 89.8rem;
}
.btn.--btn-wt-898 {
  width: 89.8rem;
}
.btn.--btn-ht-899 {
  height: 89.9rem;
}
.btn.--btn-wt-899 {
  width: 89.9rem;
}
.btn.--btn-ht-900 {
  height: 90rem;
}
.btn.--btn-wt-900 {
  width: 90rem;
}
.btn.--btn-ht-901 {
  height: 90.1rem;
}
.btn.--btn-wt-901 {
  width: 90.1rem;
}
.btn.--btn-ht-902 {
  height: 90.2rem;
}
.btn.--btn-wt-902 {
  width: 90.2rem;
}
.btn.--btn-ht-903 {
  height: 90.3rem;
}
.btn.--btn-wt-903 {
  width: 90.3rem;
}
.btn.--btn-ht-904 {
  height: 90.4rem;
}
.btn.--btn-wt-904 {
  width: 90.4rem;
}
.btn.--btn-ht-905 {
  height: 90.5rem;
}
.btn.--btn-wt-905 {
  width: 90.5rem;
}
.btn.--btn-ht-906 {
  height: 90.6rem;
}
.btn.--btn-wt-906 {
  width: 90.6rem;
}
.btn.--btn-ht-907 {
  height: 90.7rem;
}
.btn.--btn-wt-907 {
  width: 90.7rem;
}
.btn.--btn-ht-908 {
  height: 90.8rem;
}
.btn.--btn-wt-908 {
  width: 90.8rem;
}
.btn.--btn-ht-909 {
  height: 90.9rem;
}
.btn.--btn-wt-909 {
  width: 90.9rem;
}
.btn.--btn-ht-910 {
  height: 91rem;
}
.btn.--btn-wt-910 {
  width: 91rem;
}
.btn.--btn-ht-911 {
  height: 91.1rem;
}
.btn.--btn-wt-911 {
  width: 91.1rem;
}
.btn.--btn-ht-912 {
  height: 91.2rem;
}
.btn.--btn-wt-912 {
  width: 91.2rem;
}
.btn.--btn-ht-913 {
  height: 91.3rem;
}
.btn.--btn-wt-913 {
  width: 91.3rem;
}
.btn.--btn-ht-914 {
  height: 91.4rem;
}
.btn.--btn-wt-914 {
  width: 91.4rem;
}
.btn.--btn-ht-915 {
  height: 91.5rem;
}
.btn.--btn-wt-915 {
  width: 91.5rem;
}
.btn.--btn-ht-916 {
  height: 91.6rem;
}
.btn.--btn-wt-916 {
  width: 91.6rem;
}
.btn.--btn-ht-917 {
  height: 91.7rem;
}
.btn.--btn-wt-917 {
  width: 91.7rem;
}
.btn.--btn-ht-918 {
  height: 91.8rem;
}
.btn.--btn-wt-918 {
  width: 91.8rem;
}
.btn.--btn-ht-919 {
  height: 91.9rem;
}
.btn.--btn-wt-919 {
  width: 91.9rem;
}
.btn.--btn-ht-920 {
  height: 92rem;
}
.btn.--btn-wt-920 {
  width: 92rem;
}
.btn.--btn-ht-921 {
  height: 92.1rem;
}
.btn.--btn-wt-921 {
  width: 92.1rem;
}
.btn.--btn-ht-922 {
  height: 92.2rem;
}
.btn.--btn-wt-922 {
  width: 92.2rem;
}
.btn.--btn-ht-923 {
  height: 92.3rem;
}
.btn.--btn-wt-923 {
  width: 92.3rem;
}
.btn.--btn-ht-924 {
  height: 92.4rem;
}
.btn.--btn-wt-924 {
  width: 92.4rem;
}
.btn.--btn-ht-925 {
  height: 92.5rem;
}
.btn.--btn-wt-925 {
  width: 92.5rem;
}
.btn.--btn-ht-926 {
  height: 92.6rem;
}
.btn.--btn-wt-926 {
  width: 92.6rem;
}
.btn.--btn-ht-927 {
  height: 92.7rem;
}
.btn.--btn-wt-927 {
  width: 92.7rem;
}
.btn.--btn-ht-928 {
  height: 92.8rem;
}
.btn.--btn-wt-928 {
  width: 92.8rem;
}
.btn.--btn-ht-929 {
  height: 92.9rem;
}
.btn.--btn-wt-929 {
  width: 92.9rem;
}
.btn.--btn-ht-930 {
  height: 93rem;
}
.btn.--btn-wt-930 {
  width: 93rem;
}
.btn.--btn-ht-931 {
  height: 93.1rem;
}
.btn.--btn-wt-931 {
  width: 93.1rem;
}
.btn.--btn-ht-932 {
  height: 93.2rem;
}
.btn.--btn-wt-932 {
  width: 93.2rem;
}
.btn.--btn-ht-933 {
  height: 93.3rem;
}
.btn.--btn-wt-933 {
  width: 93.3rem;
}
.btn.--btn-ht-934 {
  height: 93.4rem;
}
.btn.--btn-wt-934 {
  width: 93.4rem;
}
.btn.--btn-ht-935 {
  height: 93.5rem;
}
.btn.--btn-wt-935 {
  width: 93.5rem;
}
.btn.--btn-ht-936 {
  height: 93.6rem;
}
.btn.--btn-wt-936 {
  width: 93.6rem;
}
.btn.--btn-ht-937 {
  height: 93.7rem;
}
.btn.--btn-wt-937 {
  width: 93.7rem;
}
.btn.--btn-ht-938 {
  height: 93.8rem;
}
.btn.--btn-wt-938 {
  width: 93.8rem;
}
.btn.--btn-ht-939 {
  height: 93.9rem;
}
.btn.--btn-wt-939 {
  width: 93.9rem;
}
.btn.--btn-ht-940 {
  height: 94rem;
}
.btn.--btn-wt-940 {
  width: 94rem;
}
.btn.--btn-ht-941 {
  height: 94.1rem;
}
.btn.--btn-wt-941 {
  width: 94.1rem;
}
.btn.--btn-ht-942 {
  height: 94.2rem;
}
.btn.--btn-wt-942 {
  width: 94.2rem;
}
.btn.--btn-ht-943 {
  height: 94.3rem;
}
.btn.--btn-wt-943 {
  width: 94.3rem;
}
.btn.--btn-ht-944 {
  height: 94.4rem;
}
.btn.--btn-wt-944 {
  width: 94.4rem;
}
.btn.--btn-ht-945 {
  height: 94.5rem;
}
.btn.--btn-wt-945 {
  width: 94.5rem;
}
.btn.--btn-ht-946 {
  height: 94.6rem;
}
.btn.--btn-wt-946 {
  width: 94.6rem;
}
.btn.--btn-ht-947 {
  height: 94.7rem;
}
.btn.--btn-wt-947 {
  width: 94.7rem;
}
.btn.--btn-ht-948 {
  height: 94.8rem;
}
.btn.--btn-wt-948 {
  width: 94.8rem;
}
.btn.--btn-ht-949 {
  height: 94.9rem;
}
.btn.--btn-wt-949 {
  width: 94.9rem;
}
.btn.--btn-ht-950 {
  height: 95rem;
}
.btn.--btn-wt-950 {
  width: 95rem;
}
.btn.--btn-ht-951 {
  height: 95.1rem;
}
.btn.--btn-wt-951 {
  width: 95.1rem;
}
.btn.--btn-ht-952 {
  height: 95.2rem;
}
.btn.--btn-wt-952 {
  width: 95.2rem;
}
.btn.--btn-ht-953 {
  height: 95.3rem;
}
.btn.--btn-wt-953 {
  width: 95.3rem;
}
.btn.--btn-ht-954 {
  height: 95.4rem;
}
.btn.--btn-wt-954 {
  width: 95.4rem;
}
.btn.--btn-ht-955 {
  height: 95.5rem;
}
.btn.--btn-wt-955 {
  width: 95.5rem;
}
.btn.--btn-ht-956 {
  height: 95.6rem;
}
.btn.--btn-wt-956 {
  width: 95.6rem;
}
.btn.--btn-ht-957 {
  height: 95.7rem;
}
.btn.--btn-wt-957 {
  width: 95.7rem;
}
.btn.--btn-ht-958 {
  height: 95.8rem;
}
.btn.--btn-wt-958 {
  width: 95.8rem;
}
.btn.--btn-ht-959 {
  height: 95.9rem;
}
.btn.--btn-wt-959 {
  width: 95.9rem;
}
.btn.--btn-ht-960 {
  height: 96rem;
}
.btn.--btn-wt-960 {
  width: 96rem;
}
.btn.--btn-ht-961 {
  height: 96.1rem;
}
.btn.--btn-wt-961 {
  width: 96.1rem;
}
.btn.--btn-ht-962 {
  height: 96.2rem;
}
.btn.--btn-wt-962 {
  width: 96.2rem;
}
.btn.--btn-ht-963 {
  height: 96.3rem;
}
.btn.--btn-wt-963 {
  width: 96.3rem;
}
.btn.--btn-ht-964 {
  height: 96.4rem;
}
.btn.--btn-wt-964 {
  width: 96.4rem;
}
.btn.--btn-ht-965 {
  height: 96.5rem;
}
.btn.--btn-wt-965 {
  width: 96.5rem;
}
.btn.--btn-ht-966 {
  height: 96.6rem;
}
.btn.--btn-wt-966 {
  width: 96.6rem;
}
.btn.--btn-ht-967 {
  height: 96.7rem;
}
.btn.--btn-wt-967 {
  width: 96.7rem;
}
.btn.--btn-ht-968 {
  height: 96.8rem;
}
.btn.--btn-wt-968 {
  width: 96.8rem;
}
.btn.--btn-ht-969 {
  height: 96.9rem;
}
.btn.--btn-wt-969 {
  width: 96.9rem;
}
.btn.--btn-ht-970 {
  height: 97rem;
}
.btn.--btn-wt-970 {
  width: 97rem;
}
.btn.--btn-ht-971 {
  height: 97.1rem;
}
.btn.--btn-wt-971 {
  width: 97.1rem;
}
.btn.--btn-ht-972 {
  height: 97.2rem;
}
.btn.--btn-wt-972 {
  width: 97.2rem;
}
.btn.--btn-ht-973 {
  height: 97.3rem;
}
.btn.--btn-wt-973 {
  width: 97.3rem;
}
.btn.--btn-ht-974 {
  height: 97.4rem;
}
.btn.--btn-wt-974 {
  width: 97.4rem;
}
.btn.--btn-ht-975 {
  height: 97.5rem;
}
.btn.--btn-wt-975 {
  width: 97.5rem;
}
.btn.--btn-ht-976 {
  height: 97.6rem;
}
.btn.--btn-wt-976 {
  width: 97.6rem;
}
.btn.--btn-ht-977 {
  height: 97.7rem;
}
.btn.--btn-wt-977 {
  width: 97.7rem;
}
.btn.--btn-ht-978 {
  height: 97.8rem;
}
.btn.--btn-wt-978 {
  width: 97.8rem;
}
.btn.--btn-ht-979 {
  height: 97.9rem;
}
.btn.--btn-wt-979 {
  width: 97.9rem;
}
.btn.--btn-ht-980 {
  height: 98rem;
}
.btn.--btn-wt-980 {
  width: 98rem;
}
.btn.--btn-ht-981 {
  height: 98.1rem;
}
.btn.--btn-wt-981 {
  width: 98.1rem;
}
.btn.--btn-ht-982 {
  height: 98.2rem;
}
.btn.--btn-wt-982 {
  width: 98.2rem;
}
.btn.--btn-ht-983 {
  height: 98.3rem;
}
.btn.--btn-wt-983 {
  width: 98.3rem;
}
.btn.--btn-ht-984 {
  height: 98.4rem;
}
.btn.--btn-wt-984 {
  width: 98.4rem;
}
.btn.--btn-ht-985 {
  height: 98.5rem;
}
.btn.--btn-wt-985 {
  width: 98.5rem;
}
.btn.--btn-ht-986 {
  height: 98.6rem;
}
.btn.--btn-wt-986 {
  width: 98.6rem;
}
.btn.--btn-ht-987 {
  height: 98.7rem;
}
.btn.--btn-wt-987 {
  width: 98.7rem;
}
.btn.--btn-ht-988 {
  height: 98.8rem;
}
.btn.--btn-wt-988 {
  width: 98.8rem;
}
.btn.--btn-ht-989 {
  height: 98.9rem;
}
.btn.--btn-wt-989 {
  width: 98.9rem;
}
.btn.--btn-ht-990 {
  height: 99rem;
}
.btn.--btn-wt-990 {
  width: 99rem;
}
.btn.--btn-ht-991 {
  height: 99.1rem;
}
.btn.--btn-wt-991 {
  width: 99.1rem;
}
.btn.--btn-ht-992 {
  height: 99.2rem;
}
.btn.--btn-wt-992 {
  width: 99.2rem;
}
.btn.--btn-ht-993 {
  height: 99.3rem;
}
.btn.--btn-wt-993 {
  width: 99.3rem;
}
.btn.--btn-ht-994 {
  height: 99.4rem;
}
.btn.--btn-wt-994 {
  width: 99.4rem;
}
.btn.--btn-ht-995 {
  height: 99.5rem;
}
.btn.--btn-wt-995 {
  width: 99.5rem;
}
.btn.--btn-ht-996 {
  height: 99.6rem;
}
.btn.--btn-wt-996 {
  width: 99.6rem;
}
.btn.--btn-ht-997 {
  height: 99.7rem;
}
.btn.--btn-wt-997 {
  width: 99.7rem;
}
.btn.--btn-ht-998 {
  height: 99.8rem;
}
.btn.--btn-wt-998 {
  width: 99.8rem;
}
.btn.--btn-ht-999 {
  height: 99.9rem;
}
.btn.--btn-wt-999 {
  width: 99.9rem;
}
.btn.--btn-ht-1000 {
  height: 100rem;
}
.btn.--btn-wt-1000 {
  width: 100rem;
}

.--margin-5 {
  margin: 0.5rem;
}

.--mr-5 {
  margin-right: 0.5rem;
}

.--ml-5 {
  margin-left: 0.5rem;
}

.--mt-5 {
  margin-top: 0.5rem;
}

.--mb-5 {
  margin-bottom: 0.5rem;
}

.--padding-5 {
  padding: 0.5rem;
}

.--pr-5 {
  padding-right: 0.5rem;
}

.--pl-5 {
  padding-left: 0.5rem;
}

.--pt-5 {
  padding-top: 0.5rem;
}

.--pb-5 {
  padding-bottom: 0.5rem;
}

.--margin-10 {
  margin: 1rem;
}

.--mr-10 {
  margin-right: 1rem;
}

.--ml-10 {
  margin-left: 1rem;
}

.--mt-10 {
  margin-top: 1rem;
}

.--mb-10 {
  margin-bottom: 1rem;
}

.--padding-10 {
  padding: 1rem;
}

.--pr-10 {
  padding-right: 1rem;
}

.--pl-10 {
  padding-left: 1rem;
}

.--pt-10 {
  padding-top: 1rem;
}

.--pb-10 {
  padding-bottom: 1rem;
}

.--margin-15 {
  margin: 1.5rem;
}

.--mr-15 {
  margin-right: 1.5rem;
}

.--ml-15 {
  margin-left: 1.5rem;
}

.--mt-15 {
  margin-top: 1.5rem;
}

.--mb-15 {
  margin-bottom: 1.5rem;
}

.--padding-15 {
  padding: 1.5rem;
}

.--pr-15 {
  padding-right: 1.5rem;
}

.--pl-15 {
  padding-left: 1.5rem;
}

.--pt-15 {
  padding-top: 1.5rem;
}

.--pb-15 {
  padding-bottom: 1.5rem;
}

.--margin-20 {
  margin: 2rem;
}

.--mr-20 {
  margin-right: 2rem;
}

.--ml-20 {
  margin-left: 2rem;
}

.--mt-20 {
  margin-top: 2rem;
}

.--mb-20 {
  margin-bottom: 2rem;
}

.--padding-20 {
  padding: 2rem;
}

.--pr-20 {
  padding-right: 2rem;
}

.--pl-20 {
  padding-left: 2rem;
}

.--pt-20 {
  padding-top: 2rem;
}

.--pb-20 {
  padding-bottom: 2rem;
}

.--margin-25 {
  margin: 2.5rem;
}

.--mr-25 {
  margin-right: 2.5rem;
}

.--ml-25 {
  margin-left: 2.5rem;
}

.--mt-25 {
  margin-top: 2.5rem;
}

.--mb-25 {
  margin-bottom: 2.5rem;
}

.--padding-25 {
  padding: 2.5rem;
}

.--pr-25 {
  padding-right: 2.5rem;
}

.--pl-25 {
  padding-left: 2.5rem;
}

.--pt-25 {
  padding-top: 2.5rem;
}

.--pb-25 {
  padding-bottom: 2.5rem;
}

.--margin-30 {
  margin: 3rem;
}

.--mr-30 {
  margin-right: 3rem;
}

.--ml-30 {
  margin-left: 3rem;
}

.--mt-30 {
  margin-top: 3rem;
}

.--mb-30 {
  margin-bottom: 3rem;
}

.--padding-30 {
  padding: 3rem;
}

.--pr-30 {
  padding-right: 3rem;
}

.--pl-30 {
  padding-left: 3rem;
}

.--pt-30 {
  padding-top: 3rem;
}

.--pb-30 {
  padding-bottom: 3rem;
}

.--margin-35 {
  margin: 3.5rem;
}

.--mr-35 {
  margin-right: 3.5rem;
}

.--ml-35 {
  margin-left: 3.5rem;
}

.--mt-35 {
  margin-top: 3.5rem;
}

.--mb-35 {
  margin-bottom: 3.5rem;
}

.--padding-35 {
  padding: 3.5rem;
}

.--pr-35 {
  padding-right: 3.5rem;
}

.--pl-35 {
  padding-left: 3.5rem;
}

.--pt-35 {
  padding-top: 3.5rem;
}

.--pb-35 {
  padding-bottom: 3.5rem;
}

.--margin-40 {
  margin: 4rem;
}

.--mr-40 {
  margin-right: 4rem;
}

.--ml-40 {
  margin-left: 4rem;
}

.--mt-40 {
  margin-top: 4rem;
}

.--mb-40 {
  margin-bottom: 4rem;
}

.--padding-40 {
  padding: 4rem;
}

.--pr-40 {
  padding-right: 4rem;
}

.--pl-40 {
  padding-left: 4rem;
}

.--pt-40 {
  padding-top: 4rem;
}

.--pb-40 {
  padding-bottom: 4rem;
}

.--margin-45 {
  margin: 4.5rem;
}

.--mr-45 {
  margin-right: 4.5rem;
}

.--ml-45 {
  margin-left: 4.5rem;
}

.--mt-45 {
  margin-top: 4.5rem;
}

.--mb-45 {
  margin-bottom: 4.5rem;
}

.--padding-45 {
  padding: 4.5rem;
}

.--pr-45 {
  padding-right: 4.5rem;
}

.--pl-45 {
  padding-left: 4.5rem;
}

.--pt-45 {
  padding-top: 4.5rem;
}

.--pb-45 {
  padding-bottom: 4.5rem;
}

.--margin-50 {
  margin: 5rem;
}

.--mr-50 {
  margin-right: 5rem;
}

.--ml-50 {
  margin-left: 5rem;
}

.--mt-50 {
  margin-top: 5rem;
}

.--mb-50 {
  margin-bottom: 5rem;
}

.--padding-50 {
  padding: 5rem;
}

.--pr-50 {
  padding-right: 5rem;
}

.--pl-50 {
  padding-left: 5rem;
}

.--pt-50 {
  padding-top: 5rem;
}

.--pb-50 {
  padding-bottom: 5rem;
}

.--margin-55 {
  margin: 5.5rem;
}

.--mr-55 {
  margin-right: 5.5rem;
}

.--ml-55 {
  margin-left: 5.5rem;
}

.--mt-55 {
  margin-top: 5.5rem;
}

.--mb-55 {
  margin-bottom: 5.5rem;
}

.--padding-55 {
  padding: 5.5rem;
}

.--pr-55 {
  padding-right: 5.5rem;
}

.--pl-55 {
  padding-left: 5.5rem;
}

.--pt-55 {
  padding-top: 5.5rem;
}

.--pb-55 {
  padding-bottom: 5.5rem;
}

.--margin-60 {
  margin: 6rem;
}

.--mr-60 {
  margin-right: 6rem;
}

.--ml-60 {
  margin-left: 6rem;
}

.--mt-60 {
  margin-top: 6rem;
}

.--mb-60 {
  margin-bottom: 6rem;
}

.--padding-60 {
  padding: 6rem;
}

.--pr-60 {
  padding-right: 6rem;
}

.--pl-60 {
  padding-left: 6rem;
}

.--pt-60 {
  padding-top: 6rem;
}

.--pb-60 {
  padding-bottom: 6rem;
}

.--margin-65 {
  margin: 6.5rem;
}

.--mr-65 {
  margin-right: 6.5rem;
}

.--ml-65 {
  margin-left: 6.5rem;
}

.--mt-65 {
  margin-top: 6.5rem;
}

.--mb-65 {
  margin-bottom: 6.5rem;
}

.--padding-65 {
  padding: 6.5rem;
}

.--pr-65 {
  padding-right: 6.5rem;
}

.--pl-65 {
  padding-left: 6.5rem;
}

.--pt-65 {
  padding-top: 6.5rem;
}

.--pb-65 {
  padding-bottom: 6.5rem;
}

.--margin-70 {
  margin: 7rem;
}

.--mr-70 {
  margin-right: 7rem;
}

.--ml-70 {
  margin-left: 7rem;
}

.--mt-70 {
  margin-top: 7rem;
}

.--mb-70 {
  margin-bottom: 7rem;
}

.--padding-70 {
  padding: 7rem;
}

.--pr-70 {
  padding-right: 7rem;
}

.--pl-70 {
  padding-left: 7rem;
}

.--pt-70 {
  padding-top: 7rem;
}

.--pb-70 {
  padding-bottom: 7rem;
}

.--margin-75 {
  margin: 7.5rem;
}

.--mr-75 {
  margin-right: 7.5rem;
}

.--ml-75 {
  margin-left: 7.5rem;
}

.--mt-75 {
  margin-top: 7.5rem;
}

.--mb-75 {
  margin-bottom: 7.5rem;
}

.--padding-75 {
  padding: 7.5rem;
}

.--pr-75 {
  padding-right: 7.5rem;
}

.--pl-75 {
  padding-left: 7.5rem;
}

.--pt-75 {
  padding-top: 7.5rem;
}

.--pb-75 {
  padding-bottom: 7.5rem;
}

.--margin-80 {
  margin: 8rem;
}

.--mr-80 {
  margin-right: 8rem;
}

.--ml-80 {
  margin-left: 8rem;
}

.--mt-80 {
  margin-top: 8rem;
}

.--mb-80 {
  margin-bottom: 8rem;
}

.--padding-80 {
  padding: 8rem;
}

.--pr-80 {
  padding-right: 8rem;
}

.--pl-80 {
  padding-left: 8rem;
}

.--pt-80 {
  padding-top: 8rem;
}

.--pb-80 {
  padding-bottom: 8rem;
}

.--margin-85 {
  margin: 8.5rem;
}

.--mr-85 {
  margin-right: 8.5rem;
}

.--ml-85 {
  margin-left: 8.5rem;
}

.--mt-85 {
  margin-top: 8.5rem;
}

.--mb-85 {
  margin-bottom: 8.5rem;
}

.--padding-85 {
  padding: 8.5rem;
}

.--pr-85 {
  padding-right: 8.5rem;
}

.--pl-85 {
  padding-left: 8.5rem;
}

.--pt-85 {
  padding-top: 8.5rem;
}

.--pb-85 {
  padding-bottom: 8.5rem;
}

.--margin-90 {
  margin: 9rem;
}

.--mr-90 {
  margin-right: 9rem;
}

.--ml-90 {
  margin-left: 9rem;
}

.--mt-90 {
  margin-top: 9rem;
}

.--mb-90 {
  margin-bottom: 9rem;
}

.--padding-90 {
  padding: 9rem;
}

.--pr-90 {
  padding-right: 9rem;
}

.--pl-90 {
  padding-left: 9rem;
}

.--pt-90 {
  padding-top: 9rem;
}

.--pb-90 {
  padding-bottom: 9rem;
}

.--margin-95 {
  margin: 9.5rem;
}

.--mr-95 {
  margin-right: 9.5rem;
}

.--ml-95 {
  margin-left: 9.5rem;
}

.--mt-95 {
  margin-top: 9.5rem;
}

.--mb-95 {
  margin-bottom: 9.5rem;
}

.--padding-95 {
  padding: 9.5rem;
}

.--pr-95 {
  padding-right: 9.5rem;
}

.--pl-95 {
  padding-left: 9.5rem;
}

.--pt-95 {
  padding-top: 9.5rem;
}

.--pb-95 {
  padding-bottom: 9.5rem;
}

.--margin-100 {
  margin: 10rem;
}

.--mr-100 {
  margin-right: 10rem;
}

.--ml-100 {
  margin-left: 10rem;
}

.--mt-100 {
  margin-top: 10rem;
}

.--mb-100 {
  margin-bottom: 10rem;
}

.--padding-100 {
  padding: 10rem;
}

.--pr-100 {
  padding-right: 10rem;
}

.--pl-100 {
  padding-left: 10rem;
}

.--pt-100 {
  padding-top: 10rem;
}

.--pb-100 {
  padding-bottom: 10rem;
}

.--margin-105 {
  margin: 10.5rem;
}

.--mr-105 {
  margin-right: 10.5rem;
}

.--ml-105 {
  margin-left: 10.5rem;
}

.--mt-105 {
  margin-top: 10.5rem;
}

.--mb-105 {
  margin-bottom: 10.5rem;
}

.--padding-105 {
  padding: 10.5rem;
}

.--pr-105 {
  padding-right: 10.5rem;
}

.--pl-105 {
  padding-left: 10.5rem;
}

.--pt-105 {
  padding-top: 10.5rem;
}

.--pb-105 {
  padding-bottom: 10.5rem;
}

.--margin-110 {
  margin: 11rem;
}

.--mr-110 {
  margin-right: 11rem;
}

.--ml-110 {
  margin-left: 11rem;
}

.--mt-110 {
  margin-top: 11rem;
}

.--mb-110 {
  margin-bottom: 11rem;
}

.--padding-110 {
  padding: 11rem;
}

.--pr-110 {
  padding-right: 11rem;
}

.--pl-110 {
  padding-left: 11rem;
}

.--pt-110 {
  padding-top: 11rem;
}

.--pb-110 {
  padding-bottom: 11rem;
}

.--margin-115 {
  margin: 11.5rem;
}

.--mr-115 {
  margin-right: 11.5rem;
}

.--ml-115 {
  margin-left: 11.5rem;
}

.--mt-115 {
  margin-top: 11.5rem;
}

.--mb-115 {
  margin-bottom: 11.5rem;
}

.--padding-115 {
  padding: 11.5rem;
}

.--pr-115 {
  padding-right: 11.5rem;
}

.--pl-115 {
  padding-left: 11.5rem;
}

.--pt-115 {
  padding-top: 11.5rem;
}

.--pb-115 {
  padding-bottom: 11.5rem;
}

.--margin-120 {
  margin: 12rem;
}

.--mr-120 {
  margin-right: 12rem;
}

.--ml-120 {
  margin-left: 12rem;
}

.--mt-120 {
  margin-top: 12rem;
}

.--mb-120 {
  margin-bottom: 12rem;
}

.--padding-120 {
  padding: 12rem;
}

.--pr-120 {
  padding-right: 12rem;
}

.--pl-120 {
  padding-left: 12rem;
}

.--pt-120 {
  padding-top: 12rem;
}

.--pb-120 {
  padding-bottom: 12rem;
}

.--margin-125 {
  margin: 12.5rem;
}

.--mr-125 {
  margin-right: 12.5rem;
}

.--ml-125 {
  margin-left: 12.5rem;
}

.--mt-125 {
  margin-top: 12.5rem;
}

.--mb-125 {
  margin-bottom: 12.5rem;
}

.--padding-125 {
  padding: 12.5rem;
}

.--pr-125 {
  padding-right: 12.5rem;
}

.--pl-125 {
  padding-left: 12.5rem;
}

.--pt-125 {
  padding-top: 12.5rem;
}

.--pb-125 {
  padding-bottom: 12.5rem;
}

.--margin-130 {
  margin: 13rem;
}

.--mr-130 {
  margin-right: 13rem;
}

.--ml-130 {
  margin-left: 13rem;
}

.--mt-130 {
  margin-top: 13rem;
}

.--mb-130 {
  margin-bottom: 13rem;
}

.--padding-130 {
  padding: 13rem;
}

.--pr-130 {
  padding-right: 13rem;
}

.--pl-130 {
  padding-left: 13rem;
}

.--pt-130 {
  padding-top: 13rem;
}

.--pb-130 {
  padding-bottom: 13rem;
}

.--margin-135 {
  margin: 13.5rem;
}

.--mr-135 {
  margin-right: 13.5rem;
}

.--ml-135 {
  margin-left: 13.5rem;
}

.--mt-135 {
  margin-top: 13.5rem;
}

.--mb-135 {
  margin-bottom: 13.5rem;
}

.--padding-135 {
  padding: 13.5rem;
}

.--pr-135 {
  padding-right: 13.5rem;
}

.--pl-135 {
  padding-left: 13.5rem;
}

.--pt-135 {
  padding-top: 13.5rem;
}

.--pb-135 {
  padding-bottom: 13.5rem;
}

.--margin-140 {
  margin: 14rem;
}

.--mr-140 {
  margin-right: 14rem;
}

.--ml-140 {
  margin-left: 14rem;
}

.--mt-140 {
  margin-top: 14rem;
}

.--mb-140 {
  margin-bottom: 14rem;
}

.--padding-140 {
  padding: 14rem;
}

.--pr-140 {
  padding-right: 14rem;
}

.--pl-140 {
  padding-left: 14rem;
}

.--pt-140 {
  padding-top: 14rem;
}

.--pb-140 {
  padding-bottom: 14rem;
}

.--margin-145 {
  margin: 14.5rem;
}

.--mr-145 {
  margin-right: 14.5rem;
}

.--ml-145 {
  margin-left: 14.5rem;
}

.--mt-145 {
  margin-top: 14.5rem;
}

.--mb-145 {
  margin-bottom: 14.5rem;
}

.--padding-145 {
  padding: 14.5rem;
}

.--pr-145 {
  padding-right: 14.5rem;
}

.--pl-145 {
  padding-left: 14.5rem;
}

.--pt-145 {
  padding-top: 14.5rem;
}

.--pb-145 {
  padding-bottom: 14.5rem;
}

.--margin-150 {
  margin: 15rem;
}

.--mr-150 {
  margin-right: 15rem;
}

.--ml-150 {
  margin-left: 15rem;
}

.--mt-150 {
  margin-top: 15rem;
}

.--mb-150 {
  margin-bottom: 15rem;
}

.--padding-150 {
  padding: 15rem;
}

.--pr-150 {
  padding-right: 15rem;
}

.--pl-150 {
  padding-left: 15rem;
}

.--pt-150 {
  padding-top: 15rem;
}

.--pb-150 {
  padding-bottom: 15rem;
}

.--margin-155 {
  margin: 15.5rem;
}

.--mr-155 {
  margin-right: 15.5rem;
}

.--ml-155 {
  margin-left: 15.5rem;
}

.--mt-155 {
  margin-top: 15.5rem;
}

.--mb-155 {
  margin-bottom: 15.5rem;
}

.--padding-155 {
  padding: 15.5rem;
}

.--pr-155 {
  padding-right: 15.5rem;
}

.--pl-155 {
  padding-left: 15.5rem;
}

.--pt-155 {
  padding-top: 15.5rem;
}

.--pb-155 {
  padding-bottom: 15.5rem;
}

.--margin-160 {
  margin: 16rem;
}

.--mr-160 {
  margin-right: 16rem;
}

.--ml-160 {
  margin-left: 16rem;
}

.--mt-160 {
  margin-top: 16rem;
}

.--mb-160 {
  margin-bottom: 16rem;
}

.--padding-160 {
  padding: 16rem;
}

.--pr-160 {
  padding-right: 16rem;
}

.--pl-160 {
  padding-left: 16rem;
}

.--pt-160 {
  padding-top: 16rem;
}

.--pb-160 {
  padding-bottom: 16rem;
}

.--margin-165 {
  margin: 16.5rem;
}

.--mr-165 {
  margin-right: 16.5rem;
}

.--ml-165 {
  margin-left: 16.5rem;
}

.--mt-165 {
  margin-top: 16.5rem;
}

.--mb-165 {
  margin-bottom: 16.5rem;
}

.--padding-165 {
  padding: 16.5rem;
}

.--pr-165 {
  padding-right: 16.5rem;
}

.--pl-165 {
  padding-left: 16.5rem;
}

.--pt-165 {
  padding-top: 16.5rem;
}

.--pb-165 {
  padding-bottom: 16.5rem;
}

.--margin-170 {
  margin: 17rem;
}

.--mr-170 {
  margin-right: 17rem;
}

.--ml-170 {
  margin-left: 17rem;
}

.--mt-170 {
  margin-top: 17rem;
}

.--mb-170 {
  margin-bottom: 17rem;
}

.--padding-170 {
  padding: 17rem;
}

.--pr-170 {
  padding-right: 17rem;
}

.--pl-170 {
  padding-left: 17rem;
}

.--pt-170 {
  padding-top: 17rem;
}

.--pb-170 {
  padding-bottom: 17rem;
}

.--margin-175 {
  margin: 17.5rem;
}

.--mr-175 {
  margin-right: 17.5rem;
}

.--ml-175 {
  margin-left: 17.5rem;
}

.--mt-175 {
  margin-top: 17.5rem;
}

.--mb-175 {
  margin-bottom: 17.5rem;
}

.--padding-175 {
  padding: 17.5rem;
}

.--pr-175 {
  padding-right: 17.5rem;
}

.--pl-175 {
  padding-left: 17.5rem;
}

.--pt-175 {
  padding-top: 17.5rem;
}

.--pb-175 {
  padding-bottom: 17.5rem;
}

.--margin-180 {
  margin: 18rem;
}

.--mr-180 {
  margin-right: 18rem;
}

.--ml-180 {
  margin-left: 18rem;
}

.--mt-180 {
  margin-top: 18rem;
}

.--mb-180 {
  margin-bottom: 18rem;
}

.--padding-180 {
  padding: 18rem;
}

.--pr-180 {
  padding-right: 18rem;
}

.--pl-180 {
  padding-left: 18rem;
}

.--pt-180 {
  padding-top: 18rem;
}

.--pb-180 {
  padding-bottom: 18rem;
}

.--margin-185 {
  margin: 18.5rem;
}

.--mr-185 {
  margin-right: 18.5rem;
}

.--ml-185 {
  margin-left: 18.5rem;
}

.--mt-185 {
  margin-top: 18.5rem;
}

.--mb-185 {
  margin-bottom: 18.5rem;
}

.--padding-185 {
  padding: 18.5rem;
}

.--pr-185 {
  padding-right: 18.5rem;
}

.--pl-185 {
  padding-left: 18.5rem;
}

.--pt-185 {
  padding-top: 18.5rem;
}

.--pb-185 {
  padding-bottom: 18.5rem;
}

.--margin-190 {
  margin: 19rem;
}

.--mr-190 {
  margin-right: 19rem;
}

.--ml-190 {
  margin-left: 19rem;
}

.--mt-190 {
  margin-top: 19rem;
}

.--mb-190 {
  margin-bottom: 19rem;
}

.--padding-190 {
  padding: 19rem;
}

.--pr-190 {
  padding-right: 19rem;
}

.--pl-190 {
  padding-left: 19rem;
}

.--pt-190 {
  padding-top: 19rem;
}

.--pb-190 {
  padding-bottom: 19rem;
}

.--margin-195 {
  margin: 19.5rem;
}

.--mr-195 {
  margin-right: 19.5rem;
}

.--ml-195 {
  margin-left: 19.5rem;
}

.--mt-195 {
  margin-top: 19.5rem;
}

.--mb-195 {
  margin-bottom: 19.5rem;
}

.--padding-195 {
  padding: 19.5rem;
}

.--pr-195 {
  padding-right: 19.5rem;
}

.--pl-195 {
  padding-left: 19.5rem;
}

.--pt-195 {
  padding-top: 19.5rem;
}

.--pb-195 {
  padding-bottom: 19.5rem;
}

.--margin-200 {
  margin: 20rem;
}

.--mr-200 {
  margin-right: 20rem;
}

.--ml-200 {
  margin-left: 20rem;
}

.--mt-200 {
  margin-top: 20rem;
}

.--mb-200 {
  margin-bottom: 20rem;
}

.--padding-200 {
  padding: 20rem;
}

.--pr-200 {
  padding-right: 20rem;
}

.--pl-200 {
  padding-left: 20rem;
}

.--pt-200 {
  padding-top: 20rem;
}

.--pb-200 {
  padding-bottom: 20rem;
}

.--margin-205 {
  margin: 20.5rem;
}

.--mr-205 {
  margin-right: 20.5rem;
}

.--ml-205 {
  margin-left: 20.5rem;
}

.--mt-205 {
  margin-top: 20.5rem;
}

.--mb-205 {
  margin-bottom: 20.5rem;
}

.--padding-205 {
  padding: 20.5rem;
}

.--pr-205 {
  padding-right: 20.5rem;
}

.--pl-205 {
  padding-left: 20.5rem;
}

.--pt-205 {
  padding-top: 20.5rem;
}

.--pb-205 {
  padding-bottom: 20.5rem;
}

.--margin-210 {
  margin: 21rem;
}

.--mr-210 {
  margin-right: 21rem;
}

.--ml-210 {
  margin-left: 21rem;
}

.--mt-210 {
  margin-top: 21rem;
}

.--mb-210 {
  margin-bottom: 21rem;
}

.--padding-210 {
  padding: 21rem;
}

.--pr-210 {
  padding-right: 21rem;
}

.--pl-210 {
  padding-left: 21rem;
}

.--pt-210 {
  padding-top: 21rem;
}

.--pb-210 {
  padding-bottom: 21rem;
}

.--margin-215 {
  margin: 21.5rem;
}

.--mr-215 {
  margin-right: 21.5rem;
}

.--ml-215 {
  margin-left: 21.5rem;
}

.--mt-215 {
  margin-top: 21.5rem;
}

.--mb-215 {
  margin-bottom: 21.5rem;
}

.--padding-215 {
  padding: 21.5rem;
}

.--pr-215 {
  padding-right: 21.5rem;
}

.--pl-215 {
  padding-left: 21.5rem;
}

.--pt-215 {
  padding-top: 21.5rem;
}

.--pb-215 {
  padding-bottom: 21.5rem;
}

.--margin-220 {
  margin: 22rem;
}

.--mr-220 {
  margin-right: 22rem;
}

.--ml-220 {
  margin-left: 22rem;
}

.--mt-220 {
  margin-top: 22rem;
}

.--mb-220 {
  margin-bottom: 22rem;
}

.--padding-220 {
  padding: 22rem;
}

.--pr-220 {
  padding-right: 22rem;
}

.--pl-220 {
  padding-left: 22rem;
}

.--pt-220 {
  padding-top: 22rem;
}

.--pb-220 {
  padding-bottom: 22rem;
}

.--margin-225 {
  margin: 22.5rem;
}

.--mr-225 {
  margin-right: 22.5rem;
}

.--ml-225 {
  margin-left: 22.5rem;
}

.--mt-225 {
  margin-top: 22.5rem;
}

.--mb-225 {
  margin-bottom: 22.5rem;
}

.--padding-225 {
  padding: 22.5rem;
}

.--pr-225 {
  padding-right: 22.5rem;
}

.--pl-225 {
  padding-left: 22.5rem;
}

.--pt-225 {
  padding-top: 22.5rem;
}

.--pb-225 {
  padding-bottom: 22.5rem;
}

.--margin-230 {
  margin: 23rem;
}

.--mr-230 {
  margin-right: 23rem;
}

.--ml-230 {
  margin-left: 23rem;
}

.--mt-230 {
  margin-top: 23rem;
}

.--mb-230 {
  margin-bottom: 23rem;
}

.--padding-230 {
  padding: 23rem;
}

.--pr-230 {
  padding-right: 23rem;
}

.--pl-230 {
  padding-left: 23rem;
}

.--pt-230 {
  padding-top: 23rem;
}

.--pb-230 {
  padding-bottom: 23rem;
}

.--margin-235 {
  margin: 23.5rem;
}

.--mr-235 {
  margin-right: 23.5rem;
}

.--ml-235 {
  margin-left: 23.5rem;
}

.--mt-235 {
  margin-top: 23.5rem;
}

.--mb-235 {
  margin-bottom: 23.5rem;
}

.--padding-235 {
  padding: 23.5rem;
}

.--pr-235 {
  padding-right: 23.5rem;
}

.--pl-235 {
  padding-left: 23.5rem;
}

.--pt-235 {
  padding-top: 23.5rem;
}

.--pb-235 {
  padding-bottom: 23.5rem;
}

.--margin-240 {
  margin: 24rem;
}

.--mr-240 {
  margin-right: 24rem;
}

.--ml-240 {
  margin-left: 24rem;
}

.--mt-240 {
  margin-top: 24rem;
}

.--mb-240 {
  margin-bottom: 24rem;
}

.--padding-240 {
  padding: 24rem;
}

.--pr-240 {
  padding-right: 24rem;
}

.--pl-240 {
  padding-left: 24rem;
}

.--pt-240 {
  padding-top: 24rem;
}

.--pb-240 {
  padding-bottom: 24rem;
}

.--margin-245 {
  margin: 24.5rem;
}

.--mr-245 {
  margin-right: 24.5rem;
}

.--ml-245 {
  margin-left: 24.5rem;
}

.--mt-245 {
  margin-top: 24.5rem;
}

.--mb-245 {
  margin-bottom: 24.5rem;
}

.--padding-245 {
  padding: 24.5rem;
}

.--pr-245 {
  padding-right: 24.5rem;
}

.--pl-245 {
  padding-left: 24.5rem;
}

.--pt-245 {
  padding-top: 24.5rem;
}

.--pb-245 {
  padding-bottom: 24.5rem;
}

.--margin-250 {
  margin: 25rem;
}

.--mr-250 {
  margin-right: 25rem;
}

.--ml-250 {
  margin-left: 25rem;
}

.--mt-250 {
  margin-top: 25rem;
}

.--mb-250 {
  margin-bottom: 25rem;
}

.--padding-250 {
  padding: 25rem;
}

.--pr-250 {
  padding-right: 25rem;
}

.--pl-250 {
  padding-left: 25rem;
}

.--pt-250 {
  padding-top: 25rem;
}

.--pb-250 {
  padding-bottom: 25rem;
}

.--p-none {
  padding: 0 !important;
}

.--m-none {
  margin: 0 !important;
}

.--m-auto {
  margin: 0 auto;
}

.--mt-auto {
  margin-top: auto;
}

.--mb-auto {
  margin-bottom: auto;
}

.--ml-auto {
  margin-left: auto;
}

.--mr-auto {
  margin-right: auto;
}

.--font-9 {
  font-size: 0.9rem;
}

.--line-9 {
  line-height: 0.9rem;
}

.--font-10 {
  font-size: 1rem;
}

.--line-10 {
  line-height: 1rem;
}

.--font-11 {
  font-size: 1.1rem;
}

.--line-11 {
  line-height: 1.1rem;
}

.--font-12 {
  font-size: 1.2rem;
}

.--line-12 {
  line-height: 1.2rem;
}

.--font-13 {
  font-size: 1.3rem;
}

.--line-13 {
  line-height: 1.3rem;
}

.--font-14 {
  font-size: 1.4rem;
}

.--line-14 {
  line-height: 1.4rem;
}

.--font-15 {
  font-size: 1.5rem;
}

.--line-15 {
  line-height: 1.5rem;
}

.--font-16 {
  font-size: 1.6rem;
}

.--line-16 {
  line-height: 1.6rem;
}

.--font-17 {
  font-size: 1.7rem;
}

.--line-17 {
  line-height: 1.7rem;
}

.--font-18 {
  font-size: 1.8rem;
}

.--line-18 {
  line-height: 1.8rem;
}

.--font-19 {
  font-size: 1.9rem;
}

.--line-19 {
  line-height: 1.9rem;
}

.--font-20 {
  font-size: 2rem;
}

.--line-20 {
  line-height: 2rem;
}

.--font-21 {
  font-size: 2.1rem;
}

.--line-21 {
  line-height: 2.1rem;
}

.--font-22 {
  font-size: 2.2rem;
}

.--line-22 {
  line-height: 2.2rem;
}

.--font-23 {
  font-size: 2.3rem;
}

.--line-23 {
  line-height: 2.3rem;
}

.--font-24 {
  font-size: 2.4rem;
}

.--line-24 {
  line-height: 2.4rem;
}

.--font-25 {
  font-size: 2.5rem;
}

.--line-25 {
  line-height: 2.5rem;
}

.--font-26 {
  font-size: 2.6rem;
}

.--line-26 {
  line-height: 2.6rem;
}

.--font-27 {
  font-size: 2.7rem;
}

.--line-27 {
  line-height: 2.7rem;
}

.--font-28 {
  font-size: 2.8rem;
}

.--line-28 {
  line-height: 2.8rem;
}

.--font-29 {
  font-size: 2.9rem;
}

.--line-29 {
  line-height: 2.9rem;
}

.--font-30 {
  font-size: 3rem;
}

.--line-30 {
  line-height: 3rem;
}

.--font-31 {
  font-size: 3.1rem;
}

.--line-31 {
  line-height: 3.1rem;
}

.--font-32 {
  font-size: 3.2rem;
}

.--line-32 {
  line-height: 3.2rem;
}

.--font-33 {
  font-size: 3.3rem;
}

.--line-33 {
  line-height: 3.3rem;
}

.--font-34 {
  font-size: 3.4rem;
}

.--line-34 {
  line-height: 3.4rem;
}

.--font-35 {
  font-size: 3.5rem;
}

.--line-35 {
  line-height: 3.5rem;
}

.--font-36 {
  font-size: 3.6rem;
}

.--line-36 {
  line-height: 3.6rem;
}

.--font-37 {
  font-size: 3.7rem;
}

.--line-37 {
  line-height: 3.7rem;
}

.--font-38 {
  font-size: 3.8rem;
}

.--line-38 {
  line-height: 3.8rem;
}

.--font-39 {
  font-size: 3.9rem;
}

.--line-39 {
  line-height: 3.9rem;
}

.--font-40 {
  font-size: 4rem;
}

.--line-40 {
  line-height: 4rem;
}

.--font-41 {
  font-size: 4.1rem;
}

.--line-41 {
  line-height: 4.1rem;
}

.--font-42 {
  font-size: 4.2rem;
}

.--line-42 {
  line-height: 4.2rem;
}

.--font-43 {
  font-size: 4.3rem;
}

.--line-43 {
  line-height: 4.3rem;
}

.--font-44 {
  font-size: 4.4rem;
}

.--line-44 {
  line-height: 4.4rem;
}

.--font-45 {
  font-size: 4.5rem;
}

.--line-45 {
  line-height: 4.5rem;
}

.--font-46 {
  font-size: 4.6rem;
}

.--line-46 {
  line-height: 4.6rem;
}

.--font-47 {
  font-size: 4.7rem;
}

.--line-47 {
  line-height: 4.7rem;
}

.--font-48 {
  font-size: 4.8rem;
}

.--line-48 {
  line-height: 4.8rem;
}

.--font-49 {
  font-size: 4.9rem;
}

.--line-49 {
  line-height: 4.9rem;
}

.--font-50 {
  font-size: 5rem;
}

.--line-50 {
  line-height: 5rem;
}

.--font-51 {
  font-size: 5.1rem;
}

.--line-51 {
  line-height: 5.1rem;
}

.--font-52 {
  font-size: 5.2rem;
}

.--line-52 {
  line-height: 5.2rem;
}

.--font-53 {
  font-size: 5.3rem;
}

.--line-53 {
  line-height: 5.3rem;
}

.--font-54 {
  font-size: 5.4rem;
}

.--line-54 {
  line-height: 5.4rem;
}

.--font-55 {
  font-size: 5.5rem;
}

.--line-55 {
  line-height: 5.5rem;
}

.--font-56 {
  font-size: 5.6rem;
}

.--line-56 {
  line-height: 5.6rem;
}

.--font-57 {
  font-size: 5.7rem;
}

.--line-57 {
  line-height: 5.7rem;
}

.--font-58 {
  font-size: 5.8rem;
}

.--line-58 {
  line-height: 5.8rem;
}

.--font-59 {
  font-size: 5.9rem;
}

.--line-59 {
  line-height: 5.9rem;
}

.--font-60 {
  font-size: 6rem;
}

.--line-60 {
  line-height: 6rem;
}

.--font-61 {
  font-size: 6.1rem;
}

.--line-61 {
  line-height: 6.1rem;
}

.--font-62 {
  font-size: 6.2rem;
}

.--line-62 {
  line-height: 6.2rem;
}

.--font-63 {
  font-size: 6.3rem;
}

.--line-63 {
  line-height: 6.3rem;
}

.--font-64 {
  font-size: 6.4rem;
}

.--line-64 {
  line-height: 6.4rem;
}

.--font-65 {
  font-size: 6.5rem;
}

.--line-65 {
  line-height: 6.5rem;
}

.--font-66 {
  font-size: 6.6rem;
}

.--line-66 {
  line-height: 6.6rem;
}

.--font-67 {
  font-size: 6.7rem;
}

.--line-67 {
  line-height: 6.7rem;
}

.--font-68 {
  font-size: 6.8rem;
}

.--line-68 {
  line-height: 6.8rem;
}

.--font-69 {
  font-size: 6.9rem;
}

.--line-69 {
  line-height: 6.9rem;
}

.--font-70 {
  font-size: 7rem;
}

.--line-70 {
  line-height: 7rem;
}

.--font-71 {
  font-size: 7.1rem;
}

.--line-71 {
  line-height: 7.1rem;
}

.--font-72 {
  font-size: 7.2rem;
}

.--line-72 {
  line-height: 7.2rem;
}

.--vht-1 {
  height: 1vh;
}

.--vwt-1 {
  width: 1vw;
}

.--vht-2 {
  height: 2vh;
}

.--vwt-2 {
  width: 2vw;
}

.--vht-3 {
  height: 3vh;
}

.--vwt-3 {
  width: 3vw;
}

.--vht-4 {
  height: 4vh;
}

.--vwt-4 {
  width: 4vw;
}

.--vht-5 {
  height: 5vh;
}

.--vwt-5 {
  width: 5vw;
}

.--vht-6 {
  height: 6vh;
}

.--vwt-6 {
  width: 6vw;
}

.--vht-7 {
  height: 7vh;
}

.--vwt-7 {
  width: 7vw;
}

.--vht-8 {
  height: 8vh;
}

.--vwt-8 {
  width: 8vw;
}

.--vht-9 {
  height: 9vh;
}

.--vwt-9 {
  width: 9vw;
}

.--vht-10 {
  height: 10vh;
}

.--vwt-10 {
  width: 10vw;
}

.--vht-11 {
  height: 11vh;
}

.--vwt-11 {
  width: 11vw;
}

.--vht-12 {
  height: 12vh;
}

.--vwt-12 {
  width: 12vw;
}

.--vht-13 {
  height: 13vh;
}

.--vwt-13 {
  width: 13vw;
}

.--vht-14 {
  height: 14vh;
}

.--vwt-14 {
  width: 14vw;
}

.--vht-15 {
  height: 15vh;
}

.--vwt-15 {
  width: 15vw;
}

.--vht-16 {
  height: 16vh;
}

.--vwt-16 {
  width: 16vw;
}

.--vht-17 {
  height: 17vh;
}

.--vwt-17 {
  width: 17vw;
}

.--vht-18 {
  height: 18vh;
}

.--vwt-18 {
  width: 18vw;
}

.--vht-19 {
  height: 19vh;
}

.--vwt-19 {
  width: 19vw;
}

.--vht-20 {
  height: 20vh;
}

.--vwt-20 {
  width: 20vw;
}

.--vht-21 {
  height: 21vh;
}

.--vwt-21 {
  width: 21vw;
}

.--vht-22 {
  height: 22vh;
}

.--vwt-22 {
  width: 22vw;
}

.--vht-23 {
  height: 23vh;
}

.--vwt-23 {
  width: 23vw;
}

.--vht-24 {
  height: 24vh;
}

.--vwt-24 {
  width: 24vw;
}

.--vht-25 {
  height: 25vh;
}

.--vwt-25 {
  width: 25vw;
}

.--vht-26 {
  height: 26vh;
}

.--vwt-26 {
  width: 26vw;
}

.--vht-27 {
  height: 27vh;
}

.--vwt-27 {
  width: 27vw;
}

.--vht-28 {
  height: 28vh;
}

.--vwt-28 {
  width: 28vw;
}

.--vht-29 {
  height: 29vh;
}

.--vwt-29 {
  width: 29vw;
}

.--vht-30 {
  height: 30vh;
}

.--vwt-30 {
  width: 30vw;
}

.--vht-31 {
  height: 31vh;
}

.--vwt-31 {
  width: 31vw;
}

.--vht-32 {
  height: 32vh;
}

.--vwt-32 {
  width: 32vw;
}

.--vht-33 {
  height: 33vh;
}

.--vwt-33 {
  width: 33vw;
}

.--vht-34 {
  height: 34vh;
}

.--vwt-34 {
  width: 34vw;
}

.--vht-35 {
  height: 35vh;
}

.--vwt-35 {
  width: 35vw;
}

.--vht-36 {
  height: 36vh;
}

.--vwt-36 {
  width: 36vw;
}

.--vht-37 {
  height: 37vh;
}

.--vwt-37 {
  width: 37vw;
}

.--vht-38 {
  height: 38vh;
}

.--vwt-38 {
  width: 38vw;
}

.--vht-39 {
  height: 39vh;
}

.--vwt-39 {
  width: 39vw;
}

.--vht-40 {
  height: 40vh;
}

.--vwt-40 {
  width: 40vw;
}

.--vht-41 {
  height: 41vh;
}

.--vwt-41 {
  width: 41vw;
}

.--vht-42 {
  height: 42vh;
}

.--vwt-42 {
  width: 42vw;
}

.--vht-43 {
  height: 43vh;
}

.--vwt-43 {
  width: 43vw;
}

.--vht-44 {
  height: 44vh;
}

.--vwt-44 {
  width: 44vw;
}

.--vht-45 {
  height: 45vh;
}

.--vwt-45 {
  width: 45vw;
}

.--vht-46 {
  height: 46vh;
}

.--vwt-46 {
  width: 46vw;
}

.--vht-47 {
  height: 47vh;
}

.--vwt-47 {
  width: 47vw;
}

.--vht-48 {
  height: 48vh;
}

.--vwt-48 {
  width: 48vw;
}

.--vht-49 {
  height: 49vh;
}

.--vwt-49 {
  width: 49vw;
}

.--vht-50 {
  height: 50vh;
}

.--vwt-50 {
  width: 50vw;
}

.--vht-51 {
  height: 51vh;
}

.--vwt-51 {
  width: 51vw;
}

.--vht-52 {
  height: 52vh;
}

.--vwt-52 {
  width: 52vw;
}

.--vht-53 {
  height: 53vh;
}

.--vwt-53 {
  width: 53vw;
}

.--vht-54 {
  height: 54vh;
}

.--vwt-54 {
  width: 54vw;
}

.--vht-55 {
  height: 55vh;
}

.--vwt-55 {
  width: 55vw;
}

.--vht-56 {
  height: 56vh;
}

.--vwt-56 {
  width: 56vw;
}

.--vht-57 {
  height: 57vh;
}

.--vwt-57 {
  width: 57vw;
}

.--vht-58 {
  height: 58vh;
}

.--vwt-58 {
  width: 58vw;
}

.--vht-59 {
  height: 59vh;
}

.--vwt-59 {
  width: 59vw;
}

.--vht-60 {
  height: 60vh;
}

.--vwt-60 {
  width: 60vw;
}

.--vht-61 {
  height: 61vh;
}

.--vwt-61 {
  width: 61vw;
}

.--vht-62 {
  height: 62vh;
}

.--vwt-62 {
  width: 62vw;
}

.--vht-63 {
  height: 63vh;
}

.--vwt-63 {
  width: 63vw;
}

.--vht-64 {
  height: 64vh;
}

.--vwt-64 {
  width: 64vw;
}

.--vht-65 {
  height: 65vh;
}

.--vwt-65 {
  width: 65vw;
}

.--vht-66 {
  height: 66vh;
}

.--vwt-66 {
  width: 66vw;
}

.--vht-67 {
  height: 67vh;
}

.--vwt-67 {
  width: 67vw;
}

.--vht-68 {
  height: 68vh;
}

.--vwt-68 {
  width: 68vw;
}

.--vht-69 {
  height: 69vh;
}

.--vwt-69 {
  width: 69vw;
}

.--vht-70 {
  height: 70vh;
}

.--vwt-70 {
  width: 70vw;
}

.--vht-71 {
  height: 71vh;
}

.--vwt-71 {
  width: 71vw;
}

.--vht-72 {
  height: 72vh;
}

.--vwt-72 {
  width: 72vw;
}

.--vht-73 {
  height: 73vh;
}

.--vwt-73 {
  width: 73vw;
}

.--vht-74 {
  height: 74vh;
}

.--vwt-74 {
  width: 74vw;
}

.--vht-75 {
  height: 75vh;
}

.--vwt-75 {
  width: 75vw;
}

.--vht-76 {
  height: 76vh;
}

.--vwt-76 {
  width: 76vw;
}

.--vht-77 {
  height: 77vh;
}

.--vwt-77 {
  width: 77vw;
}

.--vht-78 {
  height: 78vh;
}

.--vwt-78 {
  width: 78vw;
}

.--vht-79 {
  height: 79vh;
}

.--vwt-79 {
  width: 79vw;
}

.--vht-80 {
  height: 80vh;
}

.--vwt-80 {
  width: 80vw;
}

.--vht-81 {
  height: 81vh;
}

.--vwt-81 {
  width: 81vw;
}

.--vht-82 {
  height: 82vh;
}

.--vwt-82 {
  width: 82vw;
}

.--vht-83 {
  height: 83vh;
}

.--vwt-83 {
  width: 83vw;
}

.--vht-84 {
  height: 84vh;
}

.--vwt-84 {
  width: 84vw;
}

.--vht-85 {
  height: 85vh;
}

.--vwt-85 {
  width: 85vw;
}

.--vht-86 {
  height: 86vh;
}

.--vwt-86 {
  width: 86vw;
}

.--vht-87 {
  height: 87vh;
}

.--vwt-87 {
  width: 87vw;
}

.--vht-88 {
  height: 88vh;
}

.--vwt-88 {
  width: 88vw;
}

.--vht-89 {
  height: 89vh;
}

.--vwt-89 {
  width: 89vw;
}

.--vht-90 {
  height: 90vh;
}

.--vwt-90 {
  width: 90vw;
}

.--vht-91 {
  height: 91vh;
}

.--vwt-91 {
  width: 91vw;
}

.--vht-92 {
  height: 92vh;
}

.--vwt-92 {
  width: 92vw;
}

.--vht-93 {
  height: 93vh;
}

.--vwt-93 {
  width: 93vw;
}

.--vht-94 {
  height: 94vh;
}

.--vwt-94 {
  width: 94vw;
}

.--vht-95 {
  height: 95vh;
}

.--vwt-95 {
  width: 95vw;
}

.--vht-96 {
  height: 96vh;
}

.--vwt-96 {
  width: 96vw;
}

.--vht-97 {
  height: 97vh;
}

.--vwt-97 {
  width: 97vw;
}

.--vht-98 {
  height: 98vh;
}

.--vwt-98 {
  width: 98vw;
}

.--vht-99 {
  height: 99vh;
}

.--vwt-99 {
  width: 99vw;
}

.--vht-100 {
  height: 100vh;
}

.--vwt-100 {
  width: 100vw;
}

.--ht-5 {
  height: 0.5rem;
}

.--wt-5 {
  width: 0.5rem;
}

.--ht-10 {
  height: 1rem;
}

.--wt-10 {
  width: 1rem;
}

.--ht-15 {
  height: 1.5rem;
}

.--wt-15 {
  width: 1.5rem;
}

.--ht-20 {
  height: 2rem;
}

.--wt-20 {
  width: 2rem;
}

.--ht-25 {
  height: 2.5rem;
}

.--wt-25 {
  width: 2.5rem;
}

.--ht-30 {
  height: 3rem;
}

.--wt-30 {
  width: 3rem;
}

.--ht-35 {
  height: 3.5rem;
}

.--wt-35 {
  width: 3.5rem;
}

.--ht-40 {
  height: 4rem;
}

.--wt-40 {
  width: 4rem;
}

.--ht-45 {
  height: 4.5rem;
}

.--wt-45 {
  width: 4.5rem;
}

.--ht-50 {
  height: 5rem;
}

.--wt-50 {
  width: 5rem;
}

.--ht-55 {
  height: 5.5rem;
}

.--wt-55 {
  width: 5.5rem;
}

.--ht-60 {
  height: 6rem;
}

.--wt-60 {
  width: 6rem;
}

.--ht-65 {
  height: 6.5rem;
}

.--wt-65 {
  width: 6.5rem;
}

.--ht-70 {
  height: 7rem;
}

.--wt-70 {
  width: 7rem;
}

.--ht-75 {
  height: 7.5rem;
}

.--wt-75 {
  width: 7.5rem;
}

.--ht-80 {
  height: 8rem;
}

.--wt-80 {
  width: 8rem;
}

.--ht-85 {
  height: 8.5rem;
}

.--wt-85 {
  width: 8.5rem;
}

.--ht-90 {
  height: 9rem;
}

.--wt-90 {
  width: 9rem;
}

.--ht-95 {
  height: 9.5rem;
}

.--wt-95 {
  width: 9.5rem;
}

.--ht-100 {
  height: 10rem;
}

.--wt-100 {
  width: 10rem;
}

.--ht-105 {
  height: 10.5rem;
}

.--wt-105 {
  width: 10.5rem;
}

.--ht-110 {
  height: 11rem;
}

.--wt-110 {
  width: 11rem;
}

.--ht-115 {
  height: 11.5rem;
}

.--wt-115 {
  width: 11.5rem;
}

.--ht-120 {
  height: 12rem;
}

.--wt-120 {
  width: 12rem;
}

.--ht-125 {
  height: 12.5rem;
}

.--wt-125 {
  width: 12.5rem;
}

.--ht-130 {
  height: 13rem;
}

.--wt-130 {
  width: 13rem;
}

.--ht-135 {
  height: 13.5rem;
}

.--wt-135 {
  width: 13.5rem;
}

.--ht-140 {
  height: 14rem;
}

.--wt-140 {
  width: 14rem;
}

.--ht-145 {
  height: 14.5rem;
}

.--wt-145 {
  width: 14.5rem;
}

.--ht-150 {
  height: 15rem;
}

.--wt-150 {
  width: 15rem;
}

.--ht-155 {
  height: 15.5rem;
}

.--wt-155 {
  width: 15.5rem;
}

.--ht-160 {
  height: 16rem;
}

.--wt-160 {
  width: 16rem;
}

.--ht-165 {
  height: 16.5rem;
}

.--wt-165 {
  width: 16.5rem;
}

.--ht-170 {
  height: 17rem;
}

.--wt-170 {
  width: 17rem;
}

.--ht-175 {
  height: 17.5rem;
}

.--wt-175 {
  width: 17.5rem;
}

.--ht-180 {
  height: 18rem;
}

.--wt-180 {
  width: 18rem;
}

.--ht-185 {
  height: 18.5rem;
}

.--wt-185 {
  width: 18.5rem;
}

.--ht-190 {
  height: 19rem;
}

.--wt-190 {
  width: 19rem;
}

.--ht-195 {
  height: 19.5rem;
}

.--wt-195 {
  width: 19.5rem;
}

.--ht-200 {
  height: 20rem;
}

.--wt-200 {
  width: 20rem;
}

.--ht-205 {
  height: 20.5rem;
}

.--wt-205 {
  width: 20.5rem;
}

.--ht-210 {
  height: 21rem;
}

.--wt-210 {
  width: 21rem;
}

.--ht-215 {
  height: 21.5rem;
}

.--wt-215 {
  width: 21.5rem;
}

.--ht-220 {
  height: 22rem;
}

.--wt-220 {
  width: 22rem;
}

.--ht-225 {
  height: 22.5rem;
}

.--wt-225 {
  width: 22.5rem;
}

.--ht-230 {
  height: 23rem;
}

.--wt-230 {
  width: 23rem;
}

.--ht-235 {
  height: 23.5rem;
}

.--wt-235 {
  width: 23.5rem;
}

.--ht-240 {
  height: 24rem;
}

.--wt-240 {
  width: 24rem;
}

.--ht-245 {
  height: 24.5rem;
}

.--wt-245 {
  width: 24.5rem;
}

.--ht-250 {
  height: 25rem;
}

.--wt-250 {
  width: 25rem;
}

.--ht-255 {
  height: 25.5rem;
}

.--wt-255 {
  width: 25.5rem;
}

.--ht-260 {
  height: 26rem;
}

.--wt-260 {
  width: 26rem;
}

.--ht-265 {
  height: 26.5rem;
}

.--wt-265 {
  width: 26.5rem;
}

.--ht-270 {
  height: 27rem;
}

.--wt-270 {
  width: 27rem;
}

.--ht-275 {
  height: 27.5rem;
}

.--wt-275 {
  width: 27.5rem;
}

.--ht-280 {
  height: 28rem;
}

.--wt-280 {
  width: 28rem;
}

.--ht-285 {
  height: 28.5rem;
}

.--wt-285 {
  width: 28.5rem;
}

.--ht-290 {
  height: 29rem;
}

.--wt-290 {
  width: 29rem;
}

.--ht-295 {
  height: 29.5rem;
}

.--wt-295 {
  width: 29.5rem;
}

.--ht-300 {
  height: 30rem;
}

.--wt-300 {
  width: 30rem;
}

.--ht-305 {
  height: 30.5rem;
}

.--wt-305 {
  width: 30.5rem;
}

.--ht-310 {
  height: 31rem;
}

.--wt-310 {
  width: 31rem;
}

.--ht-315 {
  height: 31.5rem;
}

.--wt-315 {
  width: 31.5rem;
}

.--ht-320 {
  height: 32rem;
}

.--wt-320 {
  width: 32rem;
}

.--ht-325 {
  height: 32.5rem;
}

.--wt-325 {
  width: 32.5rem;
}

.--ht-330 {
  height: 33rem;
}

.--wt-330 {
  width: 33rem;
}

.--ht-335 {
  height: 33.5rem;
}

.--wt-335 {
  width: 33.5rem;
}

.--ht-340 {
  height: 34rem;
}

.--wt-340 {
  width: 34rem;
}

.--ht-345 {
  height: 34.5rem;
}

.--wt-345 {
  width: 34.5rem;
}

.--ht-350 {
  height: 35rem;
}

.--wt-350 {
  width: 35rem;
}

.--ht-355 {
  height: 35.5rem;
}

.--wt-355 {
  width: 35.5rem;
}

.--ht-360 {
  height: 36rem;
}

.--wt-360 {
  width: 36rem;
}

.--ht-365 {
  height: 36.5rem;
}

.--wt-365 {
  width: 36.5rem;
}

.--ht-370 {
  height: 37rem;
}

.--wt-370 {
  width: 37rem;
}

.--ht-375 {
  height: 37.5rem;
}

.--wt-375 {
  width: 37.5rem;
}

.--ht-380 {
  height: 38rem;
}

.--wt-380 {
  width: 38rem;
}

.--ht-385 {
  height: 38.5rem;
}

.--wt-385 {
  width: 38.5rem;
}

.--ht-390 {
  height: 39rem;
}

.--wt-390 {
  width: 39rem;
}

.--ht-395 {
  height: 39.5rem;
}

.--wt-395 {
  width: 39.5rem;
}

.--ht-400 {
  height: 40rem;
}

.--wt-400 {
  width: 40rem;
}

.--ht-405 {
  height: 40.5rem;
}

.--wt-405 {
  width: 40.5rem;
}

.--ht-410 {
  height: 41rem;
}

.--wt-410 {
  width: 41rem;
}

.--ht-415 {
  height: 41.5rem;
}

.--wt-415 {
  width: 41.5rem;
}

.--ht-420 {
  height: 42rem;
}

.--wt-420 {
  width: 42rem;
}

.--ht-425 {
  height: 42.5rem;
}

.--wt-425 {
  width: 42.5rem;
}

.--ht-430 {
  height: 43rem;
}

.--wt-430 {
  width: 43rem;
}

.--ht-435 {
  height: 43.5rem;
}

.--wt-435 {
  width: 43.5rem;
}

.--ht-440 {
  height: 44rem;
}

.--wt-440 {
  width: 44rem;
}

.--ht-445 {
  height: 44.5rem;
}

.--wt-445 {
  width: 44.5rem;
}

.--ht-450 {
  height: 45rem;
}

.--wt-450 {
  width: 45rem;
}

.--ht-455 {
  height: 45.5rem;
}

.--wt-455 {
  width: 45.5rem;
}

.--ht-460 {
  height: 46rem;
}

.--wt-460 {
  width: 46rem;
}

.--ht-465 {
  height: 46.5rem;
}

.--wt-465 {
  width: 46.5rem;
}

.--ht-470 {
  height: 47rem;
}

.--wt-470 {
  width: 47rem;
}

.--ht-475 {
  height: 47.5rem;
}

.--wt-475 {
  width: 47.5rem;
}

.--ht-480 {
  height: 48rem;
}

.--wt-480 {
  width: 48rem;
}

.--ht-485 {
  height: 48.5rem;
}

.--wt-485 {
  width: 48.5rem;
}

.--ht-490 {
  height: 49rem;
}

.--wt-490 {
  width: 49rem;
}

.--ht-495 {
  height: 49.5rem;
}

.--wt-495 {
  width: 49.5rem;
}

.--ht-500 {
  height: 50rem;
}

.--wt-500 {
  width: 50rem;
}

.--ht-505 {
  height: 50.5rem;
}

.--wt-505 {
  width: 50.5rem;
}

.--ht-510 {
  height: 51rem;
}

.--wt-510 {
  width: 51rem;
}

.--ht-515 {
  height: 51.5rem;
}

.--wt-515 {
  width: 51.5rem;
}

.--ht-520 {
  height: 52rem;
}

.--wt-520 {
  width: 52rem;
}

.--ht-525 {
  height: 52.5rem;
}

.--wt-525 {
  width: 52.5rem;
}

.--ht-530 {
  height: 53rem;
}

.--wt-530 {
  width: 53rem;
}

.--ht-535 {
  height: 53.5rem;
}

.--wt-535 {
  width: 53.5rem;
}

.--ht-540 {
  height: 54rem;
}

.--wt-540 {
  width: 54rem;
}

.--ht-545 {
  height: 54.5rem;
}

.--wt-545 {
  width: 54.5rem;
}

.--ht-550 {
  height: 55rem;
}

.--wt-550 {
  width: 55rem;
}

.--ht-555 {
  height: 55.5rem;
}

.--wt-555 {
  width: 55.5rem;
}

.--ht-560 {
  height: 56rem;
}

.--wt-560 {
  width: 56rem;
}

.--ht-565 {
  height: 56.5rem;
}

.--wt-565 {
  width: 56.5rem;
}

.--ht-570 {
  height: 57rem;
}

.--wt-570 {
  width: 57rem;
}

.--ht-575 {
  height: 57.5rem;
}

.--wt-575 {
  width: 57.5rem;
}

.--ht-580 {
  height: 58rem;
}

.--wt-580 {
  width: 58rem;
}

.--ht-585 {
  height: 58.5rem;
}

.--wt-585 {
  width: 58.5rem;
}

.--ht-590 {
  height: 59rem;
}

.--wt-590 {
  width: 59rem;
}

.--ht-595 {
  height: 59.5rem;
}

.--wt-595 {
  width: 59.5rem;
}

.--ht-600 {
  height: 60rem;
}

.--wt-600 {
  width: 60rem;
}

.--ht-605 {
  height: 60.5rem;
}

.--wt-605 {
  width: 60.5rem;
}

.--ht-610 {
  height: 61rem;
}

.--wt-610 {
  width: 61rem;
}

.--ht-615 {
  height: 61.5rem;
}

.--wt-615 {
  width: 61.5rem;
}

.--ht-620 {
  height: 62rem;
}

.--wt-620 {
  width: 62rem;
}

.--ht-625 {
  height: 62.5rem;
}

.--wt-625 {
  width: 62.5rem;
}

.--ht-630 {
  height: 63rem;
}

.--wt-630 {
  width: 63rem;
}

.--ht-635 {
  height: 63.5rem;
}

.--wt-635 {
  width: 63.5rem;
}

.--ht-640 {
  height: 64rem;
}

.--wt-640 {
  width: 64rem;
}

.--ht-645 {
  height: 64.5rem;
}

.--wt-645 {
  width: 64.5rem;
}

.--ht-650 {
  height: 65rem;
}

.--wt-650 {
  width: 65rem;
}

.--ht-655 {
  height: 65.5rem;
}

.--wt-655 {
  width: 65.5rem;
}

.--ht-660 {
  height: 66rem;
}

.--wt-660 {
  width: 66rem;
}

.--ht-665 {
  height: 66.5rem;
}

.--wt-665 {
  width: 66.5rem;
}

.--ht-670 {
  height: 67rem;
}

.--wt-670 {
  width: 67rem;
}

.--ht-675 {
  height: 67.5rem;
}

.--wt-675 {
  width: 67.5rem;
}

.--ht-680 {
  height: 68rem;
}

.--wt-680 {
  width: 68rem;
}

.--ht-685 {
  height: 68.5rem;
}

.--wt-685 {
  width: 68.5rem;
}

.--ht-690 {
  height: 69rem;
}

.--wt-690 {
  width: 69rem;
}

.--ht-695 {
  height: 69.5rem;
}

.--wt-695 {
  width: 69.5rem;
}

.--ht-700 {
  height: 70rem;
}

.--wt-700 {
  width: 70rem;
}

.--ht-705 {
  height: 70.5rem;
}

.--wt-705 {
  width: 70.5rem;
}

.--ht-710 {
  height: 71rem;
}

.--wt-710 {
  width: 71rem;
}

.--ht-715 {
  height: 71.5rem;
}

.--wt-715 {
  width: 71.5rem;
}

.--ht-720 {
  height: 72rem;
}

.--wt-720 {
  width: 72rem;
}

.--ht-725 {
  height: 72.5rem;
}

.--wt-725 {
  width: 72.5rem;
}

.--ht-730 {
  height: 73rem;
}

.--wt-730 {
  width: 73rem;
}

.--ht-735 {
  height: 73.5rem;
}

.--wt-735 {
  width: 73.5rem;
}

.--ht-740 {
  height: 74rem;
}

.--wt-740 {
  width: 74rem;
}

.--ht-745 {
  height: 74.5rem;
}

.--wt-745 {
  width: 74.5rem;
}

.--ht-750 {
  height: 75rem;
}

.--wt-750 {
  width: 75rem;
}

.--ht-755 {
  height: 75.5rem;
}

.--wt-755 {
  width: 75.5rem;
}

.--ht-760 {
  height: 76rem;
}

.--wt-760 {
  width: 76rem;
}

.--ht-765 {
  height: 76.5rem;
}

.--wt-765 {
  width: 76.5rem;
}

.--ht-770 {
  height: 77rem;
}

.--wt-770 {
  width: 77rem;
}

.--ht-775 {
  height: 77.5rem;
}

.--wt-775 {
  width: 77.5rem;
}

.--ht-780 {
  height: 78rem;
}

.--wt-780 {
  width: 78rem;
}

.--ht-785 {
  height: 78.5rem;
}

.--wt-785 {
  width: 78.5rem;
}

.--ht-790 {
  height: 79rem;
}

.--wt-790 {
  width: 79rem;
}

.--ht-795 {
  height: 79.5rem;
}

.--wt-795 {
  width: 79.5rem;
}

.--ht-800 {
  height: 80rem;
}

.--wt-800 {
  width: 80rem;
}

.--ht-805 {
  height: 80.5rem;
}

.--wt-805 {
  width: 80.5rem;
}

.--ht-810 {
  height: 81rem;
}

.--wt-810 {
  width: 81rem;
}

.--ht-815 {
  height: 81.5rem;
}

.--wt-815 {
  width: 81.5rem;
}

.--ht-820 {
  height: 82rem;
}

.--wt-820 {
  width: 82rem;
}

.--ht-825 {
  height: 82.5rem;
}

.--wt-825 {
  width: 82.5rem;
}

.--ht-830 {
  height: 83rem;
}

.--wt-830 {
  width: 83rem;
}

.--ht-835 {
  height: 83.5rem;
}

.--wt-835 {
  width: 83.5rem;
}

.--ht-840 {
  height: 84rem;
}

.--wt-840 {
  width: 84rem;
}

.--ht-845 {
  height: 84.5rem;
}

.--wt-845 {
  width: 84.5rem;
}

.--ht-850 {
  height: 85rem;
}

.--wt-850 {
  width: 85rem;
}

.--ht-855 {
  height: 85.5rem;
}

.--wt-855 {
  width: 85.5rem;
}

.--ht-860 {
  height: 86rem;
}

.--wt-860 {
  width: 86rem;
}

.--ht-865 {
  height: 86.5rem;
}

.--wt-865 {
  width: 86.5rem;
}

.--ht-870 {
  height: 87rem;
}

.--wt-870 {
  width: 87rem;
}

.--ht-875 {
  height: 87.5rem;
}

.--wt-875 {
  width: 87.5rem;
}

.--ht-880 {
  height: 88rem;
}

.--wt-880 {
  width: 88rem;
}

.--ht-885 {
  height: 88.5rem;
}

.--wt-885 {
  width: 88.5rem;
}

.--ht-890 {
  height: 89rem;
}

.--wt-890 {
  width: 89rem;
}

.--ht-895 {
  height: 89.5rem;
}

.--wt-895 {
  width: 89.5rem;
}

.--ht-900 {
  height: 90rem;
}

.--wt-900 {
  width: 90rem;
}

.--ht-905 {
  height: 90.5rem;
}

.--wt-905 {
  width: 90.5rem;
}

.--ht-910 {
  height: 91rem;
}

.--wt-910 {
  width: 91rem;
}

.--ht-915 {
  height: 91.5rem;
}

.--wt-915 {
  width: 91.5rem;
}

.--ht-920 {
  height: 92rem;
}

.--wt-920 {
  width: 92rem;
}

.--ht-925 {
  height: 92.5rem;
}

.--wt-925 {
  width: 92.5rem;
}

.--ht-930 {
  height: 93rem;
}

.--wt-930 {
  width: 93rem;
}

.--ht-935 {
  height: 93.5rem;
}

.--wt-935 {
  width: 93.5rem;
}

.--ht-940 {
  height: 94rem;
}

.--wt-940 {
  width: 94rem;
}

.--ht-945 {
  height: 94.5rem;
}

.--wt-945 {
  width: 94.5rem;
}

.--ht-950 {
  height: 95rem;
}

.--wt-950 {
  width: 95rem;
}

.--ht-955 {
  height: 95.5rem;
}

.--wt-955 {
  width: 95.5rem;
}

.--ht-960 {
  height: 96rem;
}

.--wt-960 {
  width: 96rem;
}

.--ht-965 {
  height: 96.5rem;
}

.--wt-965 {
  width: 96.5rem;
}

.--ht-970 {
  height: 97rem;
}

.--wt-970 {
  width: 97rem;
}

.--ht-975 {
  height: 97.5rem;
}

.--wt-975 {
  width: 97.5rem;
}

.--ht-980 {
  height: 98rem;
}

.--wt-980 {
  width: 98rem;
}

.--ht-985 {
  height: 98.5rem;
}

.--wt-985 {
  width: 98.5rem;
}

.--ht-990 {
  height: 99rem;
}

.--wt-990 {
  width: 99rem;
}

.--ht-995 {
  height: 99.5rem;
}

.--wt-995 {
  width: 99.5rem;
}

.--ht-1000 {
  height: 100rem;
}

.--wt-1000 {
  width: 100rem;
}

.--br-top-1 {
  border-top: 1px solid;
}

.--br-bottom-1 {
  border-bottom: 1px solid;
}

.--br-left-1 {
  border-left: 1px solid;
}

.--br-right-1 {
  border-right: 1px solid;
}

.--br-full-1 {
  border: 1px solid;
}

.--br-top-2 {
  border-top: 2px solid;
}

.--br-bottom-2 {
  border-bottom: 2px solid;
}

.--br-left-2 {
  border-left: 2px solid;
}

.--br-right-2 {
  border-right: 2px solid;
}

.--br-full-2 {
  border: 2px solid;
}

.--br-top-3 {
  border-top: 3px solid;
}

.--br-bottom-3 {
  border-bottom: 3px solid;
}

.--br-left-3 {
  border-left: 3px solid;
}

.--br-right-3 {
  border-right: 3px solid;
}

.--br-full-3 {
  border: 3px solid;
}

.--br-top-4 {
  border-top: 4px solid;
}

.--br-bottom-4 {
  border-bottom: 4px solid;
}

.--br-left-4 {
  border-left: 4px solid;
}

.--br-right-4 {
  border-right: 4px solid;
}

.--br-full-4 {
  border: 4px solid;
}

.--br-top-5 {
  border-top: 5px solid;
}

.--br-bottom-5 {
  border-bottom: 5px solid;
}

.--br-left-5 {
  border-left: 5px solid;
}

.--br-right-5 {
  border-right: 5px solid;
}

.--br-full-5 {
  border: 5px solid;
}

.--br-top-6 {
  border-top: 6px solid;
}

.--br-bottom-6 {
  border-bottom: 6px solid;
}

.--br-left-6 {
  border-left: 6px solid;
}

.--br-right-6 {
  border-right: 6px solid;
}

.--br-full-6 {
  border: 6px solid;
}

.--br-top-7 {
  border-top: 7px solid;
}

.--br-bottom-7 {
  border-bottom: 7px solid;
}

.--br-left-7 {
  border-left: 7px solid;
}

.--br-right-7 {
  border-right: 7px solid;
}

.--br-full-7 {
  border: 7px solid;
}

.--br-top-8 {
  border-top: 8px solid;
}

.--br-bottom-8 {
  border-bottom: 8px solid;
}

.--br-left-8 {
  border-left: 8px solid;
}

.--br-right-8 {
  border-right: 8px solid;
}

.--br-full-8 {
  border: 8px solid;
}

.--br-top-9 {
  border-top: 9px solid;
}

.--br-bottom-9 {
  border-bottom: 9px solid;
}

.--br-left-9 {
  border-left: 9px solid;
}

.--br-right-9 {
  border-right: 9px solid;
}

.--br-full-9 {
  border: 9px solid;
}

.--br-top-10 {
  border-top: 10px solid;
}

.--br-bottom-10 {
  border-bottom: 10px solid;
}

.--br-left-10 {
  border-left: 10px solid;
}

.--br-right-10 {
  border-right: 10px solid;
}

.--br-full-10 {
  border: 10px solid;
}

.--br-none {
  border: none !important;
}

.--br-size-1 {
  border-width: 1px;
}

.--br-size-2 {
  border-width: 2px;
}

.--br-size-3 {
  border-width: 3px;
}

.--br-size-4 {
  border-width: 4px;
}

.--br-size-5 {
  border-width: 5px;
}

.--br-size-6 {
  border-width: 6px;
}

.--br-size-7 {
  border-width: 7px;
}

.--br-size-8 {
  border-width: 8px;
}

.--br-size-9 {
  border-width: 9px;
}

.--br-size-10 {
  border-width: 10px;
}

html,
body {
  padding: 0;
  margin: 0;
  font-size: 10px;
  font-family: Roboto, "Noto Sans CJK KR", "Oswald", sans-serif;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  text-align: inherit;
  font-size: 1.2rem;
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }

  body {
    color: white;
    background: black;
  }
}
ul, li {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

strong, b {
  font-weight: bold;
}

input:not([type=checkbox], [type=radio]), button {
  -webkit-appearance: none;
  border: none;
  background: none;
}

button {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

p {
  margin: 0;
}

input {
  outline: none;
}

table {
  border-collapse: collapse;
}

:root {
  scroll-behavior: unset;
}

img {
  max-width: 100%;
  width: auto;
  max-height: 100%;
}

a:hover {
  color: var(--theme2);
  transition-duration: 0.1s;
}

html {
  background-color: var(--base-bg);
}

* {
  word-break: normal;
  color: var(--default-font);
  background-color: transparent;
}

svg path {
  color: inherit;
}

body {
  background-color: var(--base-bg);
}

.peeks-container {
  max-width: 120rem;
  width: 120rem;
  margin: 0 auto;
}
.peeks-container .peeks-row {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: inherit;
  grid-auto-flow: column;
}

#gl__header .gl__header_info {
  width: 100%;
  border-bottom: 2px solid var(--theme);
  padding: 0 1rem;
}
#gl__header .gl__header_info .logo {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: flex-start;
  align-items: center;
  height: 7rem;
}
#gl__header .gl__header_info .logo img {
  width: 15.4rem;
  height: 4rem;
}
#gl__header .gl__header_info > div:nth-child(1) {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
}
#gl__header .gl__header_info > div:nth-child(1) .notice-landing {
  margin-left: 8rem;
  margin-top: 1rem;
}
#gl__header .gl__header_info > div:nth-child(2) {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 4rem;
  padding-bottom: 1rem;
}
#gl__header .gl__header_info > div:nth-child(2) .gl__navigation {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: inherit;
  grid-auto-flow: column;
  grid-gap: 3rem;
  font-size: 1.5rem;
  font-weight: revert;
  font-weight: bold;
}
#gl__header .gl__header_info > div:nth-child(2) .gl__navigation a {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  text-align: inherit;
  letter-spacing: -0.12rem;
}
#gl__header .gl__header_info > div:nth-child(2) .gl__navigation a:hover {
  color: var(--theme2);
  transition-duration: 0.1s;
}

.notice-landing > div {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 1rem;
  padding: 0 0.6rem;
  cursor: pointer;
}
.notice-landing > div .type {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--danger);
  border: 1px solid var(--danger);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: white;
  padding: 0 0.6rem;
  font-size: 1rem;
  font-weight: revert;
  border-radius: 0.2rem;
}
.notice-landing > div .title {
  font-size: 1.2rem;
  font-weight: revert;
  transition-duration: 0.1s;
}
.notice-landing > div:hover .title {
  color: var(--theme2);
  transition-duration: 0.1s;
}

#gl__user {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  margin-left: auto;
}
#gl__user a:hover {
  text-decoration: underline;
}
#gl__user .user:first-child {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 1rem;
}
#gl__user .user-goods {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 1rem;
  margin-top: 0.3rem;
}
#gl__user .user-goods > div {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 0.4rem;
}
#gl__user .user-goods > div i {
  font-size: 1.6rem;
}
#gl__user .user-goods > div a {
  font-size: 1.2rem;
  letter-spacing: -0.072rem;
}
#gl__user .login {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 2rem;
}
#gl__user .login > div {
  position: relative;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 0.8rem;
}
#gl__user .login > div .fa-power-off {
  font-size: 1.4rem;
  font-weight: revert;
}
#gl__user .login > div:after {
  content: "";
  position: absolute;
  top: calc(50% - 0.2rem);
  right: -1.2rem;
  z-index: 1;
  width: 0.4rem;
  height: 0.4rem;
  background-color: #CED1D7;
}

#gl__footer {
  border-top: 1px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 0px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
  border-color: var(--theme);
  margin-top: 4rem;
  padding: 4rem 1rem 4.8rem;
}
#gl__footer > div {
  max-width: 46rem;
  margin: 0 auto;
}
#gl__footer > div * {
  font-size: 1.1rem;
  font-weight: revert;
  color: var(--footer-font);
}
#gl__footer > div header {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 2rem;
}
#gl__footer > div .policy {
  margin-top: 2.6rem;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 2rem;
}
@media (max-width: 757px) {
  #gl__footer > div .policy {
    flex-wrap: wrap;
  }
}
#gl__footer > div .policy li {
  color: var(--default-font);
}
@media (min-width: 758px) {
  #gl__footer > div .policy li {
    font-size: 1.2rem;
    font-weight: bold;
  }
}
@media (max-width: 757px) {
  #gl__footer > div .policy li {
    font-size: 1rem;
    font-weight: bold;
  }
}
#gl__footer > div .policy li a {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  text-align: inherit;
}

#divide-aside-main {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  margin-top: 2rem;
  grid-gap: 1rem;
}
#divide-aside-main #chat__layout {
  width: 35rem;
  max-height: 60.4rem;
}
#divide-aside-main #main__container {
  width: 84rem;
}

#chat__layout {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
}
@media (min-width: 758px) {
  #chat__layout {
    grid-template-columns: 0.3rem 34.7rem;
  }
}
@media (max-width: 757px) {
  #chat__layout {
    grid-template-columns: 0.3rem calc(100vw - 0.3rem);
  }
}
#chat__layout > .super-chat-room {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  grid-gap: 0.2rem;
  padding: 0.2rem 0;
  background-color: var(--super-chat-room-background-color);
}
#chat__layout > .super-chat-room .chat-room-items {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 4.7rem;
  height: 4.7rem;
  padding: 0.2rem;
  background-size: cover;
  background-repeat: no-repeat;
}
#chat__layout > .super-chat-room .chat-room-items img {
  width: 100%;
  height: 100%;
}
#chat__layout > #chat-layout {
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 0px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
  background-color: white;
}
#chat__layout > #chat-layout ul.chat-tab {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  justify-content: flex-start;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  height: 3.7rem;
  border-top: 0px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
}
#chat__layout > #chat-layout ul.chat-tab > li {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  height: calc(3.7rem + .1rem);
  font-size: 1.2rem;
  font-weight: revert;
  grid-gap: 0.5rem;
  border-top: 0px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
  cursor: pointer;
  user-select: none;
}
#chat__layout > #chat-layout ul.chat-tab > li.select {
  border-top: 0px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 2px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
  color: var(--select-chat-tab);
  border-bottom-color: var(--select-chat-tab);
}
#chat__layout > #chat-layout ul.chat-tab > li.select [class*=fa-] {
  color: var(--select-chat-tab) !important;
}
#chat__layout > #chat-layout main#chat-area {
  position: relative;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 10px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 10px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
  border-color: white;
  padding: 0 0.2rem 0 1rem;
}
@media (min-width: 758px) {
  #chat__layout > #chat-layout main#chat-area {
    height: calc(calc(60.2rem - 3.7rem - 10rem) + .1rem);
  }
}
@media (max-width: 757px) {
  #chat__layout > #chat-layout main#chat-area {
    height: calc(100vh - 4.2rem - 10rem);
  }
}
#chat__layout > #chat-layout main#chat-area > ul {
  position: relative;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  flex-direction: column;
  grid-gap: 0.7rem;
  width: 100%;
  height: 100%;
  padding: 1rem 0 1rem;
  overflow-y: auto;
}
#chat__layout > #chat-layout main#chat-area > ul::-webkit-scrollbar {
  display: block !important;
  width: 6px;
}
#chat__layout > #chat-layout main#chat-area > ul::-webkit-scrollbar-track-piece {
  background-color: #F3F3F3;
}
#chat__layout > #chat-layout main#chat-area > ul::-webkit-scrollbar-thumb {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-radius: revert;
  background-image: linear-gradient(90deg, transparent 0%, transparent 25%, #D3D3D3 25%, #D3D3D3 75%, transparent 75%, transparent 100%);
}
#chat__layout > #chat-layout main#chat-area > ul > li {
  padding-right: 0.1rem;
  line-height: 1.7rem;
}
#chat__layout > #chat-layout main#chat-area > ul > li.info {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}
#chat__layout > #chat-layout main#chat-area > ul > li > span:first-child {
  letter-spacing: -0.12rem;
  color: var(--chat-user-strong);
  font-size: 1.3rem;
  font-weight: bold;
  cursor: pointer;
}
#chat__layout > #chat-layout main#chat-area > ul > li > span:first-child i.--rank {
  margin-bottom: -0.5rem;
}
#chat__layout > #chat-layout main#chat-area > ul > li > span:last-child {
  margin-left: 0.5rem;
  color: var(--chat-msg-p);
  font-size: 1.2rem;
  font-weight: revert;
  word-break: break-all;
}
#chat__layout > #chat-layout > footer {
  height: 10rem;
  padding: 1rem 2rem 0 1rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
}
#chat__layout > #chat-layout > footer * {
  color: var(--chat-input-default);
}
#chat__layout > #chat-layout > footer form {
  height: 100%;
}
#chat__layout > #chat-layout > footer form > p {
  color: var(--default-font);
  font-size: 1.3rem;
  font-weight: revert;
  font-weight: bold;
}
#chat__layout > #chat-layout > footer form > input {
  width: 100%;
  height: 3rem;
  color: black;
  border-bottom: 1px solid var(--black);
}
#chat__layout > #chat-layout > footer form > input::-webkit-input-placeholder {
  color: var(--chat-input-default);
}
#chat__layout > #chat-layout > footer form > div {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  height: 4rem;
}
#chat__layout > #chat-layout > footer form > div .fa-face-laugh {
  font-size: 2.3rem;
  font-weight: revert;
}
#chat__layout > #chat-layout > footer form > div > div {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  grid-gap: 1.4rem;
}
#chat__layout > #chat-layout > footer form > div > div .text-count {
  font-size: 1.3rem;
  font-weight: revert;
  letter-spacing: 0.013rem;
}
#chat__layout > #chat-layout > footer form > div > div .fa-paper-plane {
  font-size: 1.5rem;
  font-weight: revert;
}

[class*=-layout] + [class*=-layout] header.layout-header {
  margin-top: 1rem;
  padding-top: 2.5rem;
  box-sizing: content-box;
  border-top: 2px solid var(--theme);
}

header.layout-header {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  height: 3.2rem;
  padding: 0 0.6rem;
}
header.layout-header i.header-i {
  font-size: 2.4rem;
  font-weight: revert;
}
header.layout-header strong {
  margin-left: 0.7rem;
  color: var(--theme2);
  font-size: 1.4rem;
  font-weight: bold;
  text-shadow: 0.1rem 0.1rem 0.3rem rgba(68, 68, 68, 0.1803921569);
}
header.layout-header span {
  margin-left: 1rem;
  color: #9394AD;
  font-size: 1.2rem;
  font-weight: bold;
}

#sports-tab {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1rem;
  margin-top: 1rem;
  position: relative;
}
#sports-tab > button {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 8.3rem;
  height: 2.7rem;
  border-radius: 0.4rem;
  grid-gap: 0.4rem;
  margin-left: auto;
  color: var(--black);
  font-size: 1.2rem;
  font-weight: bold;
}
#sports-tab > button > i {
  margin-top: -0.3rem;
}
#sports-tab ul {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 2rem;
  height: 3.8rem;
}
#sports-tab ul li {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% + 0.2rem);
  padding: 0 0.5rem;
  grid-gap: 0.5rem;
  font-family: "Noto Sans CJK KR";
  border-bottom: 2px solid transparent;
}
#sports-tab ul li.select {
  border-bottom-color: var(--select-sports-tab);
}
#sports-tab ul li strong {
  font-size: 1.3rem;
  font-weight: revert;
}
#sports-tab ul li span {
  font-size: 1.3rem;
  font-weight: revert;
  opacity: 0.75;
}

.sports-more {
  position: absolute;
  top: 3.8rem;
  left: -0.1rem;
  width: calc(100% + 0.2rem);
  background-color: var(--white);
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  box-shadow: 1px 1px 6px var(--black) 36;
  overflow: hidden;
  animation-duration: 0.2s;
  opacity: 0;
}
.sports-more.on {
  opacity: 1;
  animation: sports-more-slide-down 0.2s forwards;
}
.sports-more.off {
  opacity: 1;
  animation: sports-more-slide-up 0.2s forwards;
}

@keyframes sports-more-slide-down {
  from {
    height: 0rem;
  }
  to {
    height: 20rem;
  }
}
@keyframes sports-more-slide-up {
  from {
    height: 20rem;
  }
  to {
    height: 0rem;
  }
}
#sports-board {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  justify-content: space-between;
  align-items: flex-start;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.6rem;
  margin-top: 1rem;
  background-color: var(--gray);
}
#sports-board table td {
  height: 2.4rem;
  padding: 0 0.4rem;
  color: #444444;
  text-align: center;
  border: 1px solid #D6D6D6;
  background-color: var(--white);
}
#sports-board table td.bookmark {
  width: 1.8rem;
}
#sports-board table td.match {
  width: 4.2rem;
}
#sports-board table td.team {
  width: 20rem;
}
#sports-board table td.score {
  width: 3rem;
  font-family: "Oswald";
}
#sports-board table td.score:nth-child(2) {
  font-weight: bold;
}

#sports-board + #minigame-tab,
.sports-table + #minigame-tab {
  margin-top: 1rem;
}

#minigame-tab {
  height: 4rem;
  margin-bottom: 1rem;
}
#minigame-tab > ul {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  grid-gap: 3rem;
  height: 100%;
}
#minigame-tab > ul > li {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  min-width: 8rem;
  height: calc(100% + 0.2rem);
  color: var(--minigame-tab-default);
  border-bottom: 2px solid transparent;
}
#minigame-tab > ul > li.select {
  color: var(--minigame-tab-select);
  border-bottom-color: var(--theme);
}
#minigame-tab > ul > li:hover {
  color: var(--theme2);
  transition-duration: 0.1s;
}

#minigame-contents {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  grid-gap: 0.6rem;
  height: 25.5rem;
  margin-top: 1rem;
  padding: 1.2rem 0.8rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
}
#minigame-contents > div {
  height: 23.2rem;
  padding: 0.1rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  border-color: #A8831D;
  background-color: var(--minigame-pick-contents-bg);
}
#minigame-contents > div > .inner {
  height: 23rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  border-color: #5B4300;
  background-color: var(--minigame-pick-contents-inner-bg);
  padding: 0.3rem 0.5rem;
}
#minigame-contents > div header {
  color: white;
  font-size: 1.6rem;
  font-weight: revert;
  text-shadow: 1px 1px 2px var(--black);
}

.user-pick {
  width: 41.8rem;
  padding: 0.5rem 1.6rem;
}
.user-pick main {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  flex-direction: column;
  grid-gap: 1rem;
  margin-top: 1.2rem;
  color: white;
  text-shadow: 1px 1px 3px var(--black) 80;
}
.user-pick main .pick-bar {
  position: relative;
  width: 38.6rem;
  height: 2.6rem;
  margin: 0 auto;
}
.user-pick main .pick-bar .pick-item {
  position: absolute;
  width: 7.2rem;
  height: 3rem;
  top: calc(50% - 1.4rem);
  left: calc(50% - 3.6rem);
  z-index: 2;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.3rem;
  font-weight: revert;
  letter-spacing: -0.086rem;
  text-shadow: 1px 1px 3px var(--black) 80;
  box-shadow: 1px 1px 2px var(--black) 69;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
}
.user-pick main .pick-bar .pick-item.--bg-gb-odd {
  border-color: #00D7FF;
}
.user-pick main .pick-bar .pick-item.--bg-gb-even {
  border-color: #FF0000;
}
.user-pick main .pick-bar .pick-count {
  position: absolute;
  top: 0;
  left: calc(50% - 16.75rem);
  z-index: 2;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  width: 33.5rem;
  height: 100%;
  padding: 0 0.5rem;
}
.user-pick main .pick-bar .pick-count > p {
  color: white;
  letter-spacing: -0.07rem;
  text-shadow: 1px 1px 3px var(--black) 80;
}
.user-pick main .pick-bar .absolute-bar {
  position: absolute;
  width: 33.5rem;
  height: 100%;
  position: absolute;
  top: 0;
  left: calc(50% - 16.75rem);
  z-index: 1;
  z-index: 1;
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 7rem;
  background-color: black;
}
.user-pick main .pick-bar .absolute-bar > div > span {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  width: 2.8rem;
  height: 2.8rem;
  font-size: 1.5rem;
  font-weight: revert;
  letter-spacing: -0.087rem;
}
.user-pick main .pick-bar .absolute-bar > div:first-child {
  direction: rtl;
  border: 1px solid #00D7FF;
}
.user-pick main .pick-bar .absolute-bar > div:first-child > span {
  position: absolute;
  top: 0.1rem;
  left: -3rem;
  z-index: 1;
}
.user-pick main .pick-bar .absolute-bar > div:last-child {
  border: 1px solid #FF0000;
}
.user-pick main .pick-bar .absolute-bar > div:last-child > span {
  position: absolute;
  top: 0.1rem;
  right: -3rem;
  z-index: 1;
}
.user-pick main .pick-bar .absolute-bar.o > div:first-child > span {
  color: var(--gb-odd);
}
.user-pick main .pick-bar .absolute-bar.l > div:last-child > span {
  color: var(--gb-even);
}
.user-pick main .pick-bar .absolute-bar .odd-under,
.user-pick main .pick-bar .absolute-bar .even-over {
  height: 2.6rem;
}
.user-pick main .pick-bar .absolute-bar .odd-under.odd-under,
.user-pick main .pick-bar .absolute-bar .even-over.odd-under {
  background-color: var(--gb-odd);
}
.user-pick main .pick-bar .absolute-bar .odd-under.even-over,
.user-pick main .pick-bar .absolute-bar .even-over.even-over {
  background-color: var(--gb-even);
}
.user-pick main .pick-sum {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  width: 33.5rem;
  margin: 0 auto;
  grid-gap: 1.1rem;
}
.user-pick main .pick-sum .sum-layer {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
}
.user-pick main .pick-sum .sum-layer > div:first-child {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  border-color: rgba(255, 255, 255, 0.5019607843);
  color: #FFCC33;
  font-size: 1.5rem;
  font-weight: bold;
  text-shadow: 0.1rem 0.1rem 0.3rem var(--black) 80;
}
.user-pick main .pick-sum .sum-layer > div:last-child {
  width: 7.5rem;
  height: 2.6rem;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  margin-left: -0.1rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  border-color: rgba(255, 255, 255, 0.5019607843);
}

.pick-result {
  width: 41.8rem;
}
.pick-result .inner main {
  height: 19.5rem;
  padding-top: 0.5rem;
  padding-right: 0.6rem;
  overflow-y: scroll;
}
.pick-result .inner main::-webkit-scrollbar {
  width: 0.4rem;
}
.pick-result .inner main::-webkit-scrollbar-track-piece {
  background-color: var(--game-board-result-dark-color);
  box-shadow: inset 0.1rem 0.1rem 0rem rgba(255, 255, 255, 0.1882352941);
  padding: 0 1rem;
}
.pick-result .inner main::-webkit-scrollbar-thumb {
  background-color: var(--game-board-result-header-color);
}
.pick-result .inner main ul {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  flex-direction: column;
  grid-gap: 0.6rem;
}
.pick-result .inner main ul li.result-bar {
  height: 5rem;
  padding: 0.3rem 0.8rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  border-color: #8F591B;
  background-color: #181208;
}
.pick-result .inner main ul li.result-bar > p {
  font-size: 1.3rem;
  font-weight: revert;
  color: white;
  letter-spacing: -0.055rem;
}
.pick-result .inner main ul li.result-bar > div {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
}
.pick-result .inner main ul li.result-bar > div > .balls {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 0.5rem;
}
.pick-result .inner main ul li.result-bar > div > .balls > span {
  width: 2rem;
  height: 2rem;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  font-size: 1.2rem;
  font-weight: revert;
  color: #FFFFFF;
  text-shadow: 0.1rem 0.1rem 0.1rem #404040;
  border: 1px solid;
  border-radius: 50%;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-1 {
  background-color: #FFC400;
  border-color: #FFF347;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-2 {
  background-color: #FFC400;
  border-color: #FFF347;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-3 {
  background-color: #FFC400;
  border-color: #FFF347;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-4 {
  background-color: #FFC400;
  border-color: #FFF347;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-5 {
  background-color: #FFC400;
  border-color: #FFF347;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-6 {
  background-color: #FFC400;
  border-color: #FFF347;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-7 {
  background-color: #FFC400;
  border-color: #FFF347;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-8 {
  background-color: #006FFF;
  border-color: #92A4FF;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-9 {
  background-color: #006FFF;
  border-color: #92A4FF;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-10 {
  background-color: #006FFF;
  border-color: #92A4FF;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-11 {
  background-color: #006FFF;
  border-color: #92A4FF;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-12 {
  background-color: #006FFF;
  border-color: #92A4FF;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-13 {
  background-color: #006FFF;
  border-color: #92A4FF;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-14 {
  background-color: #006FFF;
  border-color: #92A4FF;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-15 {
  background-color: #E50000;
  border-color: #FF6868;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-16 {
  background-color: #E50000;
  border-color: #FF6868;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-17 {
  background-color: #E50000;
  border-color: #FF6868;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-18 {
  background-color: #E50000;
  border-color: #FF6868;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-19 {
  background-color: #E50000;
  border-color: #FF6868;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-20 {
  background-color: #E50000;
  border-color: #FF6868;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-21 {
  background-color: #E50000;
  border-color: #FF6868;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-22 {
  background-color: #00D907;
  border-color: #87FF7E;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-23 {
  background-color: #00D907;
  border-color: #87FF7E;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-24 {
  background-color: #00D907;
  border-color: #87FF7E;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-25 {
  background-color: #00D907;
  border-color: #87FF7E;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-26 {
  background-color: #00D907;
  border-color: #87FF7E;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-27 {
  background-color: #00D907;
  border-color: #87FF7E;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.num-28 {
  background-color: #00D907;
  border-color: #87FF7E;
}
.pick-result .inner main ul li.result-bar > div > .balls > span.powerball {
  background-color: #313131;
  border-color: #AFAFAF;
}
.pick-result .inner main ul li.result-bar > div .ranges {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 0.4rem;
}
.pick-result .inner main ul li.result-bar > div .ranges .rect {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 3.6rem;
  height: 2rem;
  font-size: 1.2rem;
  font-weight: revert;
  color: white;
  text-shadow: 0.1rem 0.1rem 0.3rem var(--black) 80;
  border: 0.1rem solid rgba(255, 255, 255, 0.1882352941);
}
.pick-result .inner main ul li.result-bar > div .ranges .rect.under, .pick-result .inner main ul li.result-bar > div .ranges .rect.odd, .pick-result .inner main ul li.result-bar > div .ranges .rect.medium {
  background-color: var(--odd);
}
.pick-result .inner main ul li.result-bar > div .ranges .rect.over, .pick-result .inner main ul li.result-bar > div .ranges .rect.even, .pick-result .inner main ul li.result-bar > div .ranges .rect.large {
  background-color: var(--even);
}
.pick-result .inner main ul li.result-bar > div .ranges .rect.small {
  background-color: var(--small);
}

i.balls {
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: transparent;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
i.balls.normal-1 {
  background-image: url("./balls/SQS_BALL (1).png");
}
i.balls.normal-2 {
  background-image: url("./balls/SQS_BALL (2).png");
}
i.balls.normal-3 {
  background-image: url("./balls/SQS_BALL (3).png");
}
i.balls.normal-4 {
  background-image: url("./balls/SQS_BALL (4).png");
}
i.balls.normal-5 {
  background-image: url("./balls/SQS_BALL (5).png");
}
i.balls.normal-6 {
  background-image: url("./balls/SQS_BALL (6).png");
}
i.balls.normal-7 {
  background-image: url("./balls/SQS_BALL (7).png");
}
i.balls.normal-8 {
  background-image: url("./balls/SQS_BALL (8).png");
}
i.balls.normal-9 {
  background-image: url("./balls/SQS_BALL (9).png");
}
i.balls.normal-10 {
  background-image: url("./balls/SQS_BALL (10).png");
}
i.balls.normal-11 {
  background-image: url("./balls/SQS_BALL (11).png");
}
i.balls.normal-12 {
  background-image: url("./balls/SQS_BALL (12).png");
}
i.balls.normal-13 {
  background-image: url("./balls/SQS_BALL (13).png");
}
i.balls.normal-14 {
  background-image: url("./balls/SQS_BALL (14).png");
}
i.balls.normal-15 {
  background-image: url("./balls/SQS_BALL (15).png");
}
i.balls.normal-16 {
  background-image: url("./balls/SQS_BALL (16).png");
}
i.balls.normal-17 {
  background-image: url("./balls/SQS_BALL (17).png");
}
i.balls.normal-18 {
  background-image: url("./balls/SQS_BALL (18).png");
}
i.balls.normal-19 {
  background-image: url("./balls/SQS_BALL (19).png");
}
i.balls.normal-20 {
  background-image: url("./balls/SQS_BALL (20).png");
}
i.balls.normal-21 {
  background-image: url("./balls/SQS_BALL (21).png");
}
i.balls.normal-22 {
  background-image: url("./balls/SQS_BALL (22).png");
}
i.balls.normal-23 {
  background-image: url("./balls/SQS_BALL (23).png");
}
i.balls.normal-24 {
  background-image: url("./balls/SQS_BALL (24).png");
}
i.balls.normal-25 {
  background-image: url("./balls/SQS_BALL (25).png");
}
i.balls.normal-26 {
  background-image: url("./balls/SQS_BALL (26).png");
}
i.balls.normal-27 {
  background-image: url("./balls/SQS_BALL (27).png");
}
i.balls.normal-28 {
  background-image: url("./balls/SQS_BALL (28).png");
}
i.balls.power-1 {
  background-image: url("./balls/SQS_POWER_BALL (1).png");
}
i.balls.power-2 {
  background-image: url("./balls/SQS_POWER_BALL (2).png");
}
i.balls.power-3 {
  background-image: url("./balls/SQS_POWER_BALL (3).png");
}
i.balls.power-4 {
  background-image: url("./balls/SQS_POWER_BALL (4).png");
}
i.balls.power-5 {
  background-image: url("./balls/SQS_POWER_BALL (5).png");
}
i.balls.power-6 {
  background-image: url("./balls/SQS_POWER_BALL (6).png");
}
i.balls.power-7 {
  background-image: url("./balls/SQS_POWER_BALL (7).png");
}
i.balls.power-8 {
  background-image: url("./balls/SQS_POWER_BALL (8).png");
}
i.balls.power-9 {
  background-image: url("./balls/SQS_POWER_BALL (9).png");
}

#powerball-cell-layout {
  max-width: 84rem;
  padding: 1rem;
}
#powerball-cell-layout * {
  font-family: "Noto Sans CJK KR";
}
#powerball-cell-layout.landing .powerball-result-table,
#powerball-cell-layout.landing .powerball-result-table-mo {
  margin-top: 0;
  height: 28.5rem;
  overflow-y: scroll;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  border-color: var(--theme);
}
#powerball-cell-layout .powerball-tab {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  justify-content: flex-start;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  padding: 2rem 0;
}
#powerball-cell-layout .powerball-tab > li {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
#powerball-cell-layout .powerball-tab > li > span {
  padding: 0.6rem 2rem;
  border-top: 0px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 2px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
  font-size: 1.4rem;
  font-weight: bold;
  border-color: transparent;
}
#powerball-cell-layout .powerball-tab > li.select span {
  border-color: var(--theme);
}
#powerball-cell-layout .mobile-open-user-pick {
  width: 80vw;
  max-width: 30rem;
  height: 3.8rem;
  margin: 0 auto 2rem;
  border-radius: 4rem;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
}
#powerball-cell-layout .user-pick-search-form {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 1rem;
}
#powerball-cell-layout .user-pick-search-form > select, #powerball-cell-layout .user-pick-search-form input, #powerball-cell-layout .user-pick-search-form button {
  width: 10rem;
  height: 3.4rem;
  background-color: var(--default);
  font-size: 1.2rem;
  font-weight: revert;
  padding: 0 1rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
}
#powerball-cell-layout .user-pick-table {
  margin-top: 2rem;
}
#powerball-cell-layout .user-pick-table table {
  width: 100%;
}
#powerball-cell-layout .user-pick-table table tr {
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
}
#powerball-cell-layout .user-pick-table table tr th, #powerball-cell-layout .user-pick-table table tr td {
  height: 2.8rem;
  text-align: center;
}
#powerball-cell-layout .user-pick-table table tr td.pick-type span.POWERBALL {
  color: var(--even);
}
#powerball-cell-layout .user-pick-table table tr td.pick-type span.BALLS {
  color: var(--odd);
}
#powerball-cell-layout .user-pick-table table tr td.pick-value span {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  width: 4.5rem;
  height: 2.4rem;
  color: white;
  font-size: 1.2rem;
  font-weight: normal;
}
#powerball-cell-layout .user-pick-table table tr td.pick-value span.ODD, #powerball-cell-layout .user-pick-table table tr td.pick-value span.UNDER, #powerball-cell-layout .user-pick-table table tr td.pick-value span.MEDIUM {
  background-color: var(--odd);
}
#powerball-cell-layout .user-pick-table table tr td.pick-value span.EVEN, #powerball-cell-layout .user-pick-table table tr td.pick-value span.OVER, #powerball-cell-layout .user-pick-table table tr td.pick-value span.LARGE {
  background-color: var(--even);
}
#powerball-cell-layout .user-pick-table table tr td.pick-value span.SMALL {
  background-color: var(--small);
}

.landing-go-minigame-layout {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  padding: 1rem;
  color: var(--theme2);
  text-decoration: underline;
}

#public-powerball-cell {
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  border-color: var(--powerball-cell-border-color);
  border-radius: 0.8rem;
}
@media (max-width: 757px) {
  #public-powerball-cell {
    background-color: var(--white);
  }
}
#public-powerball-cell .powerball-cell-tab {
  position: relative;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.6rem;
  grid-gap: 0.6rem;
  border-top: 0px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
  border-color: var(--powerball-cell-border-color);
  overflow: hidden;
}
@media (max-width: 757px) {
  #public-powerball-cell .powerball-cell-tab {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}
#public-powerball-cell .powerball-cell-tab .over-select-tab {
  content: "";
  display: block;
  position: absolute;
  bottom: -0.4rem;
  left: 0.6rem;
  min-width: 13.2rem;
  height: 0.8rem;
  background-color: var(--powerball-cell-tab-underbar-color);
  border-radius: 0.4rem;
  transition-duration: 0.2s;
}
@media (max-width: 757px) {
  #public-powerball-cell .powerball-cell-tab .over-select-tab {
    display: none;
  }
}
#public-powerball-cell .powerball-cell-tab > button {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  min-width: 13.2rem;
  height: 7rem;
  color: var(--powerball-cell-tab-font-color);
  font-size: 1.6rem;
  font-weight: bold;
}
@media (max-width: 757px) {
  #public-powerball-cell .powerball-cell-tab > button {
    height: 4.5rem;
    font-size: 1.2rem;
    font-weight: revert;
  }
}
#public-powerball-cell .powerball-cell-tab > button.select {
  color: var(--black);
}
@media (max-width: 757px) {
  #public-powerball-cell .powerball-cell-tab > button.select {
    height: 3rem;
    color: white;
    font-weight: bold;
    background-color: #0080FF;
    border-radius: 0.8rem;
  }
}
#public-powerball-cell .additional {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: stretch;
  grid-gap: 1rem;
  padding: 0 2.5rem 0 1.7rem;
  border-top: 0px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
  border-color: var(--powerball-cell-border-color);
}
@media (max-width: 757px) {
  #public-powerball-cell .additional {
    padding: 1.5rem 1rem;
    flex-wrap: wrap;
    grid-gap: 0.8rem;
  }
}
#public-powerball-cell .additional > div {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 1rem;
}
@media (max-width: 757px) {
  #public-powerball-cell .additional > div {
    display: flex;
    display: -o-flex;
    display: -ms-flex;
    display: -moz-flex;
    display: -webkit-flex;
    justify-content: flex-start;
    align-items: center;
  }
}
#public-powerball-cell .additional > div > span.bar {
  width: 0.2rem;
  height: 2rem;
  background-color: #DCDCDC;
  border-radius: 0.1rem;
}
#public-powerball-cell .additional > div div {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.6rem;
  height: 6rem;
}
@media (max-width: 757px) {
  #public-powerball-cell .additional > div div {
    height: 3rem;
  }
}
#public-powerball-cell .additional > div div span.round {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  color: white;
  font-size: 1.4rem;
  font-weight: revert;
  white-space: nowrap;
  letter-spacing: -0.12rem;
  text-indent: -0.12rem;
  border-radius: 50%;
}
#public-powerball-cell .additional > div div span.round.LEFT_3_EVEN, #public-powerball-cell .additional > div div span.round.RIGHT_3_ODD, #public-powerball-cell .additional > div div span.round.LEFT_4_ODD, #public-powerball-cell .additional > div div span.round.RIGHT_4_EVEN {
  width: 4rem;
  height: 4rem;
}
#public-powerball-cell .additional > div div span.round.UNDER, #public-powerball-cell .additional > div div span.round.MEDIUM, #public-powerball-cell .additional > div div span.round.LEFT, #public-powerball-cell .additional > div div span.round.LINE3, #public-powerball-cell .additional > div div span.round.LEFT_4_ODD, #public-powerball-cell .additional > div div span.round.ODD {
  background-color: var(--odd);
}
#public-powerball-cell .additional > div div span.round.RIGHT_3_ODD {
  background-color: var(--powerball-cell-right3-color);
}
#public-powerball-cell .additional > div div span.round.OVER, #public-powerball-cell .additional > div div span.round.LARGE, #public-powerball-cell .additional > div div span.round.RIGHT, #public-powerball-cell .additional > div div span.round.LINE4, #public-powerball-cell .additional > div div span.round.LEFT_3_EVEN, #public-powerball-cell .additional > div div span.round.EVEN {
  background-color: var(--even);
}
#public-powerball-cell .additional > div div span.round.RIGHT_4_EVEN {
  background-color: var(--powerball-cell-right4-color);
}
#public-powerball-cell .additional > div div span.round.SMALL {
  background-color: var(--small);
}
#public-powerball-cell .additional > div div span.round.Rjrdla {
  background-color: #767676;
}
#public-powerball-cell .additional > div div span.round.vhdekd {
  background-color: #A333C8;
}
#public-powerball-cell .additional > div div span.round.dusthr {
  background-color: #BE7200;
}
#public-powerball-cell .powerball-cell-table {
  padding: 1rem 1rem 0.8rem;
}
#public-powerball-cell .powerball-cell-table .scroll-area {
  overflow-x: auto;
  padding-bottom: 0.4rem;
  direction: rtl;
}
#public-powerball-cell .powerball-cell-table .scroll-area::-webkit-scrollbar {
  height: 0.8rem;
}
#public-powerball-cell .powerball-cell-table .scroll-area::-webkit-scrollbar-track-piece {
  background-color: #E6E6E6;
  padding: 0 1rem;
}
#public-powerball-cell .powerball-cell-table .scroll-area::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: #B8B8B8;
}
#public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
}
#public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  flex-direction: column;
  /* col */
}
#public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col > .in-layout {
  position: relative;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  flex-direction: column-reverse;
}
#public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col > .in-layout .down-bar {
  position: absolute;
  top: 0;
  left: 0.3rem;
  width: 2.4rem;
  background-color: red;
  border-radius: 0 0 1.2rem 1.2rem;
  z-index: 1;
}
#public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col > .in-layout span {
  border-right-color: var(--powerball-cell-border);
}
#public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col > .in-layout span.in {
  color: white;
  font-size: 1rem;
  font-weight: revert;
  z-index: 2;
}
#public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col span {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 3.1rem;
  height: 3rem;
  border-right: 1px solid;
  user-select: none;
}
#public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col span.head {
  background-color: var(--powerball-cell-title-bg);
  border-right-color: white !important;
}
#public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col span.length, #public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col span.round {
  color: #595A5A;
  background-color: var(--powerball-cell-title-bg);
  border-right-color: #D1D1D1;
}
#public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col span.length.length, #public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col span.round.length {
  font-weight: bold;
  border-bottom: 1px solid #D1D1D1;
}
#public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col.ODD .head, #public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col.MEDIUM .head, #public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col.UNDER .head, #public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col.LEFT .head, #public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col.LINE3 .head {
  color: var(--powerball-cell-odd-color);
  border-bottom: 1px solid;
  border-color: var(--powerball-cell-odd-color);
}
#public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col.ODD .down-bar, #public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col.MEDIUM .down-bar, #public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col.UNDER .down-bar, #public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col.LEFT .down-bar, #public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col.LINE3 .down-bar {
  background: transparent linear-gradient(180deg, #329aff 0%, #85caff 100%) 0% 0% no-repeat padding-box;
  box-shadow: 2px 0 1px #bfe2ff;
}
#public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col.EVEN .head, #public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col.LARGE .head, #public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col.OVER .head, #public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col.RIGHT .head, #public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col.LINE4 .head {
  color: var(--powerball-cell-even-color);
  border-bottom: 1px solid;
  border-color: var(--powerball-cell-even-color);
}
#public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col.EVEN .down-bar, #public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col.LARGE .down-bar, #public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col.OVER .down-bar, #public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col.RIGHT .down-bar, #public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col.LINE4 .down-bar {
  background: transparent linear-gradient(180deg, #ff5656 0%, #ff9c9c 100%) 0% 0% no-repeat padding-box;
  box-shadow: 2px 0 1px #ffbbbb;
}
#public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col.SMALL .head {
  color: var(--powerball-cell-small-color);
  border-bottom: 1px solid;
  border-color: var(--powerball-cell-small-color);
}
#public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col.SMALL .down-bar {
  background: transparent linear-gradient(180deg, #00BC44 0%, #27F100 100%) 0% 0% no-repeat padding-box;
  box-shadow: 2px 0 1px #6efd51;
}
#public-powerball-cell .powerball-cell-table .scroll-area .scroll-layout .col:nth-child(even) span:not(:first-child) {
  border-right-color: var(--powerball-cell-border-bold);
}
#public-powerball-cell .powerball-cell-table.ball .col span.in .round, #public-powerball-cell .powerball-cell-table.ladder .col span.in .round {
  width: 2.8rem;
  height: 2.8rem;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
}
#public-powerball-cell .powerball-cell-table.ball .col.ODD span.in .round, #public-powerball-cell .powerball-cell-table.ball .col.MEDIUM span.in .round, #public-powerball-cell .powerball-cell-table.ball .col.UNDER span.in .round, #public-powerball-cell .powerball-cell-table.ball .col.LEFT span.in .round, #public-powerball-cell .powerball-cell-table.ball .col.LINE3 span.in .round, #public-powerball-cell .powerball-cell-table.ball .col.LEFT_4_ODD span.in .round, #public-powerball-cell .powerball-cell-table.ladder .col.ODD span.in .round, #public-powerball-cell .powerball-cell-table.ladder .col.MEDIUM span.in .round, #public-powerball-cell .powerball-cell-table.ladder .col.UNDER span.in .round, #public-powerball-cell .powerball-cell-table.ladder .col.LEFT span.in .round, #public-powerball-cell .powerball-cell-table.ladder .col.LINE3 span.in .round, #public-powerball-cell .powerball-cell-table.ladder .col.LEFT_4_ODD span.in .round {
  background-color: var(--powerball-cell-odd-color);
}
#public-powerball-cell .powerball-cell-table.ball .col.RIGHT_3_ODD span.in .round, #public-powerball-cell .powerball-cell-table.ladder .col.RIGHT_3_ODD span.in .round {
  background-color: var(--powerball-cell-right3-color);
}
#public-powerball-cell .powerball-cell-table.ball .col.EVEN span.in .round, #public-powerball-cell .powerball-cell-table.ball .col.LARGE span.in .round, #public-powerball-cell .powerball-cell-table.ball .col.OVER span.in .round, #public-powerball-cell .powerball-cell-table.ball .col.RIGHT span.in .round, #public-powerball-cell .powerball-cell-table.ball .col.LINE4 span.in .round, #public-powerball-cell .powerball-cell-table.ball .col.LEFT_3_EVEN span.in .round, #public-powerball-cell .powerball-cell-table.ladder .col.EVEN span.in .round, #public-powerball-cell .powerball-cell-table.ladder .col.LARGE span.in .round, #public-powerball-cell .powerball-cell-table.ladder .col.OVER span.in .round, #public-powerball-cell .powerball-cell-table.ladder .col.RIGHT span.in .round, #public-powerball-cell .powerball-cell-table.ladder .col.LINE4 span.in .round, #public-powerball-cell .powerball-cell-table.ladder .col.LEFT_3_EVEN span.in .round {
  background-color: var(--powerball-cell-even-color);
}
#public-powerball-cell .powerball-cell-table.ball .col.RIGHT_4_EVEN span.in .round, #public-powerball-cell .powerball-cell-table.ladder .col.RIGHT_4_EVEN span.in .round {
  background-color: var(--powerball-cell-right4-color);
}
#public-powerball-cell .powerball-cell-table.ball .col.SMALL span.in .round, #public-powerball-cell .powerball-cell-table.ladder .col.SMALL span.in .round {
  background-color: var(--powerball-cell-small-color);
}

#powerball-result-graph {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  margin-top: 1rem;
}
@media (min-width: 758px) {
  #powerball-result-graph {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 0.8rem;
    grid-row-gap: 0.6rem;
  }
}
@media (max-width: 757px) {
  #powerball-result-graph {
    grid-auto-flow: row;
    grid-gap: 1rem;
  }
}
#powerball-result-graph.ladder > div {
  padding: 1rem 0;
}
@media (min-width: 758px) {
  #powerball-result-graph.ladder > div:nth-child(3) {
    grid-column: 1;
    grid-row: unset;
  }
}
#powerball-result-graph.ladder > div:nth-child(3) main {
  height: auto !important;
}
@media (min-width: 758px) {
  #powerball-result-graph.ladder > div:nth-child(4) {
    grid-column: 2;
    grid-row: 1/span 3;
  }
}
#powerball-result-graph > div {
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  border-color: var(--powerball-cell-border-color);
  border-radius: 0.8rem;
  background-color: var(--white);
}
@media (min-width: 758px) {
  #powerball-result-graph > div:nth-child(3) {
    grid-column: 2;
    grid-row: 1/span 2;
  }
}
#powerball-result-graph > div header {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  color: #595A5A;
  font-weight: bold;
}
#powerball-result-graph > div main {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  grid-gap: 4rem;
}
@media (max-width: 757px) {
  #powerball-result-graph > div main {
    padding: 0.8rem 0;
  }
}
#powerball-result-graph > div main .graph-circle.overunder {
  position: relative;
  width: 15.6rem;
  height: 15.6rem;
  border-radius: 50%;
  background: #E8E8E8 0% 0% no-repeat padding-box;
  box-shadow: inset 3px 3px 10px var(--black) 1 C, 0px 0px 6px rgba(255, 0, 0, 0);
}
#powerball-result-graph > div main .graph-circle.overunder .inner {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 0.7rem;
  position: absolute;
  top: calc(50% - 4.8rem);
  left: calc(50% - 4.8rem);
  width: 9.6rem;
  height: 9.6rem;
  background-color: var(--white);
  border-radius: 50%;
  box-shadow: 3px 3px 10px var(--black) 1 C;
  z-index: 2;
}
#powerball-result-graph > div main .graph-circle.overunder .inner.blue:after {
  background-color: rgba(33, 133, 208, 0.3137254902);
}
#powerball-result-graph > div main .graph-circle.overunder .inner.red:after {
  background-color: rgba(219, 40, 40, 0.3137254902);
}
#powerball-result-graph > div main .graph-circle.overunder .inner:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 4.2rem;
  height: 0.2rem;
  top: calc(50% - 0.1rem);
  left: calc(50% - 2.1rem);
  border-radius: 0.2rem;
}
#powerball-result-graph > div main .graph-circle.overunder .inner p {
  text-align: center;
  line-height: 1.2;
}
#powerball-result-graph > div main .graph-circle.overunder .inner p.item-tit {
  font-size: 1.5rem;
  font-weight: bold;
}
#powerball-result-graph > div main .graph-circle.overunder .inner p.item-tit.blue {
  color: #2185D0;
}
#powerball-result-graph > div main .graph-circle.overunder .inner p.item-tit.red {
  color: #DB2828;
}
#powerball-result-graph > div main .graph-circle.overunder .inner p.item-tit.default {
  color: #a5a7a8;
}
#powerball-result-graph > div main .graph-circle.overunder .inner p.item-contents {
  grid-gap: 0.5rem;
}
#powerball-result-graph > div main .graph-circle.overunder .inner p.item-contents span {
  font-size: 1rem;
  font-weight: revert;
}
#powerball-result-graph > div main .graph-circle.overunder .inner p.item-contents span:nth-child(2) {
  margin-left: 0.5rem;
}
#powerball-result-graph > div main .graph-circle.overunder .circle_progress_wrap {
  position: relative;
  width: 15.6rem;
  height: 15.6rem;
}
#powerball-result-graph > div main .graph-circle.overunder .circle_progress_wrap .circle_progress.blue {
  transform: rotate(90deg);
}
#powerball-result-graph > div main .graph-circle.overunder .circle_progress_wrap .circle_progress.blue .from {
  stop-color: #006AB9;
}
#powerball-result-graph > div main .graph-circle.overunder .circle_progress_wrap .circle_progress.blue .to {
  stop-color: #2185D0;
}
#powerball-result-graph > div main .graph-circle.overunder .circle_progress_wrap .circle_progress.red {
  transform: rotate(-90deg);
}
#powerball-result-graph > div main .graph-circle.overunder .circle_progress_wrap .circle_progress.red .from {
  stop-color: #BE0000;
}
#powerball-result-graph > div main .graph-circle.overunder .circle_progress_wrap .circle_progress.red .to {
  stop-color: #F94D21;
}
#powerball-result-graph > div main .graph-circle.overunder .circle_progress_wrap .circle_progress .bar {
  fill: none;
  stroke-linecap: round;
}
#powerball-result-graph > div:nth-child(3) main, #powerball-result-graph > div:nth-child(4) main {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 3rem;
  height: 100%;
}
#powerball-result-graph > div:nth-child(3) main .graph-circle.l-m-s, #powerball-result-graph > div:nth-child(4) main .graph-circle.l-m-s {
  position: relative;
  width: 22.2rem;
  height: 22.2rem;
  background-color: red;
  box-shadow: inset 2px 2px 8px rgba(255, 255, 255, 0.4784313725);
  border-radius: 50%;
}
#powerball-result-graph > div:nth-child(3) main .graph-circle.l-m-s .inner, #powerball-result-graph > div:nth-child(4) main .graph-circle.l-m-s .inner {
  position: absolute;
  top: calc(50% - 7.5rem);
  left: calc(50% - 7.5rem);
  width: 15rem;
  height: 15rem;
  z-index: 2;
  background-color: var(--white);
  border-radius: 50%;
}
#powerball-result-graph > div:nth-child(3) main .graph-circle.l-m-s .graph, #powerball-result-graph > div:nth-child(4) main .graph-circle.l-m-s .graph {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: conic-gradient(#2185D0 0%, #2185D0 33%, #DB2828 33%, #DB2828 66%, #22DC00 66%, #22DC00 100%);
  border-radius: 50%;
  z-index: 1;
}
@media (max-width: 757px) {
  #powerball-result-graph > div:nth-child(3) main .graph-circle.l-m-s .graph:after, #powerball-result-graph > div:nth-child(4) main .graph-circle.l-m-s .graph:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    transform: scale(0.9);
    background-color: transparent;
    box-shadow: inset 0.2rem 0.2rem 0.8rem rgba(255, 255, 255, 0.4784313725);
    border-radius: 50%;
  }
}
#powerball-result-graph > div:nth-child(3) main .graph-count, #powerball-result-graph > div:nth-child(4) main .graph-count {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  flex-direction: column;
  grid-gap: 1rem;
}
#powerball-result-graph > div:nth-child(3) main .graph-count > div, #powerball-result-graph > div:nth-child(4) main .graph-count > div {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 0.6rem;
}
#powerball-result-graph > div:nth-child(3) main .graph-count > div > span, #powerball-result-graph > div:nth-child(4) main .graph-count > div > span {
  font-size: 1rem;
  font-weight: revert;
}
#powerball-result-graph > div:nth-child(3) main .graph-count > div > p, #powerball-result-graph > div:nth-child(4) main .graph-count > div > p {
  font-size: 1.5rem;
  font-weight: bold;
}
#powerball-result-graph > div:nth-child(3) main .graph-count > div > p > span, #powerball-result-graph > div:nth-child(4) main .graph-count > div > p > span {
  font-size: 1.2rem;
  font-weight: revert;
}

@media (min-width: 758px) {
  .ladder-result-table-mo,
.ladder-result-table,
.powerball-result-table {
    margin-top: 4rem;
  }
}
@media (max-width: 757px) {
  .ladder-result-table-mo:not(.ladder-result-table-mo),
.ladder-result-table:not(.ladder-result-table-mo),
.powerball-result-table:not(.ladder-result-table-mo) {
    display: none;
  }
}
.ladder-result-table-mo table,
.ladder-result-table table,
.powerball-result-table table {
  width: 100%;
}
.ladder-result-table-mo th,
.ladder-result-table th,
.powerball-result-table th {
  height: 3.6rem;
}
.ladder-result-table-mo td,
.ladder-result-table td,
.powerball-result-table td {
  height: 4.2rem;
}
.ladder-result-table-mo th, .ladder-result-table-mo td,
.ladder-result-table th,
.ladder-result-table td,
.powerball-result-table th,
.powerball-result-table td {
  background-color: var(--white);
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  border-color: #F3F3F3;
  color: var(--default-font);
  text-align: center;
}
.ladder-result-table-mo th.result, .ladder-result-table-mo td.result,
.ladder-result-table th.result,
.ladder-result-table td.result,
.powerball-result-table th.result,
.powerball-result-table td.result {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--black);
}
.ladder-result-table-mo th .round, .ladder-result-table-mo td .round,
.ladder-result-table th .round,
.ladder-result-table td .round,
.powerball-result-table th .round,
.powerball-result-table td .round {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  font-size: 1.4rem;
  font-weight: revert;
}
.ladder-result-table-mo th .round.ODD, .ladder-result-table-mo th .round.UNDER, .ladder-result-table-mo th .round.LEFT, .ladder-result-table-mo th .round.LINE3, .ladder-result-table-mo td .round.ODD, .ladder-result-table-mo td .round.UNDER, .ladder-result-table-mo td .round.LEFT, .ladder-result-table-mo td .round.LINE3,
.ladder-result-table th .round.ODD,
.ladder-result-table th .round.UNDER,
.ladder-result-table th .round.LEFT,
.ladder-result-table th .round.LINE3,
.ladder-result-table td .round.ODD,
.ladder-result-table td .round.UNDER,
.ladder-result-table td .round.LEFT,
.ladder-result-table td .round.LINE3,
.powerball-result-table th .round.ODD,
.powerball-result-table th .round.UNDER,
.powerball-result-table th .round.LEFT,
.powerball-result-table th .round.LINE3,
.powerball-result-table td .round.ODD,
.powerball-result-table td .round.UNDER,
.powerball-result-table td .round.LEFT,
.powerball-result-table td .round.LINE3 {
  color: var(--blue);
  border-color: var(--blue);
}
.ladder-result-table-mo th .round.EVEN, .ladder-result-table-mo th .round.OVER, .ladder-result-table-mo th .round.RIGHT, .ladder-result-table-mo th .round.LINE4, .ladder-result-table-mo td .round.EVEN, .ladder-result-table-mo td .round.OVER, .ladder-result-table-mo td .round.RIGHT, .ladder-result-table-mo td .round.LINE4,
.ladder-result-table th .round.EVEN,
.ladder-result-table th .round.OVER,
.ladder-result-table th .round.RIGHT,
.ladder-result-table th .round.LINE4,
.ladder-result-table td .round.EVEN,
.ladder-result-table td .round.OVER,
.ladder-result-table td .round.RIGHT,
.ladder-result-table td .round.LINE4,
.powerball-result-table th .round.EVEN,
.powerball-result-table th .round.OVER,
.powerball-result-table th .round.RIGHT,
.powerball-result-table th .round.LINE4,
.powerball-result-table td .round.EVEN,
.powerball-result-table td .round.OVER,
.powerball-result-table td .round.RIGHT,
.powerball-result-table td .round.LINE4 {
  color: var(--red);
  border-color: var(--red);
}
.ladder-result-table-mo th .round.SMALL, .ladder-result-table-mo td .round.SMALL,
.ladder-result-table th .round.SMALL,
.ladder-result-table td .round.SMALL,
.powerball-result-table th .round.SMALL,
.powerball-result-table td .round.SMALL {
  color: var(--small);
  border-color: var(--small);
}
.ladder-result-table-mo th .round.MEDIUM, .ladder-result-table-mo td .round.MEDIUM,
.ladder-result-table th .round.MEDIUM,
.ladder-result-table td .round.MEDIUM,
.powerball-result-table th .round.MEDIUM,
.powerball-result-table td .round.MEDIUM {
  color: var(--medium);
  border-color: var(--medium);
}
.ladder-result-table-mo th .round.LARGE, .ladder-result-table-mo td .round.LARGE,
.ladder-result-table th .round.LARGE,
.ladder-result-table td .round.LARGE,
.powerball-result-table th .round.LARGE,
.powerball-result-table td .round.LARGE {
  color: var(--large);
  border-color: var(--large);
}
.ladder-result-table-mo th .round.section, .ladder-result-table-mo td .round.section,
.ladder-result-table th .round.section,
.ladder-result-table td .round.section,
.powerball-result-table th .round.section,
.powerball-result-table td .round.section {
  color: #9103E3;
  border-color: #9103E3;
}

@media (min-width: 758px) {
  .powerball-result-table-mo {
    display: none;
  }
}
@media (max-width: 757px) {
  .powerball-result-table-mo {
    margin-top: 1rem;
    overflow-x: scroll;
  }
}
.powerball-result-table-mo table {
  table-layout: fixed;
}
.powerball-result-table-mo table th, .powerball-result-table-mo table td {
  padding: 0.2rem 0.4rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  border-color: #F2F2F2;
  font-size: 1.1rem;
  font-weight: revert;
  text-align: center;
  white-space: nowrap;
}
.powerball-result-table-mo table th {
  font-size: 1.1rem;
  font-weight: normal;
}
.powerball-result-table-mo table td:nth-child(1) {
  width: 6rem;
}
.powerball-result-table-mo table td:nth-child(2), .powerball-result-table-mo table td:nth-child(3), .powerball-result-table-mo table td:nth-child(4), .powerball-result-table-mo table td:nth-child(5), .powerball-result-table-mo table td:nth-child(6), .powerball-result-table-mo table td:nth-child(7) {
  width: 3.3rem;
}
.powerball-result-table-mo table td:nth-child(8), .powerball-result-table-mo table td:nth-child(9), .powerball-result-table-mo table td:nth-child(10), .powerball-result-table-mo table td:nth-child(11) {
  width: 9.1rem;
}
.powerball-result-table-mo table td.result {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--black);
}
.powerball-result-table-mo table td .round {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  font-size: 1.2rem;
  font-weight: revert;
}
.powerball-result-table-mo table td .round.odd, .powerball-result-table-mo table td .round.under {
  color: var(--blue);
  border-color: var(--blue);
}
.powerball-result-table-mo table td .round.even, .powerball-result-table-mo table td .round.over {
  color: var(--red);
  border-color: var(--red);
}
.powerball-result-table-mo table td .round.small {
  color: var(--small);
  border-color: var(--small);
}
.powerball-result-table-mo table td .round.medium {
  color: var(--medium);
  border-color: var(--medium);
}
.powerball-result-table-mo table td .round.large {
  color: var(--large);
  border-color: var(--large);
}
.powerball-result-table-mo table td .round.section {
  color: #9103E3;
  border-color: #9103E3;
}

.sports-calendar {
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  padding: 0 1rem;
  margin-top: 1rem;
}
.sports-calendar > header, .sports-calendar > li, .sports-calendar > footer {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
}
.sports-calendar > header, .sports-calendar > footer {
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  width: 7.4rem;
  height: 2.8rem;
}
.sports-calendar > ul {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  grid-gap: 2rem;
}
.sports-calendar > ul > header, .sports-calendar > ul > li, .sports-calendar > ul > footer {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
}
.sports-calendar > ul > header, .sports-calendar > ul > footer {
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  width: 7.4rem;
  height: 2.8rem;
}
.sports-calendar > ul > li {
  cursor: pointer;
  min-width: 7.4rem;
  height: 4rem;
  border-top: 2px solid var(--layout-border-color);
  border-right: 2px solid var(--layout-border-color);
  border-bottom: 2px solid var(--layout-border-color);
  border-left: 2px solid var(--layout-border-color);
  border-color: transparent;
}
.sports-calendar > ul > li.select {
  border-bottom-color: var(--minigame-tab-select-border);
}

#sports-tab + .ready img {
  margin-top: 1rem;
  max-width: none;
}

.sports-calendar + .sports-table {
  margin-top: 1rem;
}

.sports-table header {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  height: 3.2rem;
  grid-gap: 0.4rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 2px solid var(--layout-border-color);
  padding-left: 1rem;
  background-color: #F2F2F2;
  border-color: transparent;
  border-left-color: #444444;
}
.sports-table header .close {
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 2rem;
  background-color: var(--white);
  border-color: #D6D6D6;
  border-radius: 0.6rem;
  margin-left: auto;
}
.sports-table table {
  width: 100%;
}
.sports-table table thead th {
  color: #A8A8A8;
}
.sports-table table thead th:not(:first-child) {
  border-left-color: transparent;
}
.sports-table table thead th:not(:last-child) {
  border-right-color: transparent;
}
.sports-table table th, .sports-table table td {
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  height: 2.3rem;
  padding: 0 0.4rem;
  text-align: center;
}
.sports-table.baseball thead th:nth-child(6) {
  width: 24.7rem;
}
.sports-table.baseball thead th:nth-child(7) {
  width: 1.9rem;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Oswald";
}
.sports-table.baseball thead th:nth-child(8) {
  width: 1.9rem;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Oswald";
}
.sports-table.baseball thead th:nth-child(9) {
  width: 1.9rem;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Oswald";
}
.sports-table.baseball thead th:nth-child(10) {
  width: 1.9rem;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Oswald";
}
.sports-table.baseball thead th:nth-child(11) {
  width: 1.9rem;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Oswald";
}
.sports-table.baseball thead th:nth-child(12) {
  width: 1.9rem;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Oswald";
}
.sports-table.baseball thead th:nth-child(13) {
  width: 1.9rem;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Oswald";
}
.sports-table.baseball thead th:nth-child(14) {
  width: 1.9rem;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Oswald";
}
.sports-table.baseball thead th:nth-child(15) {
  width: 1.9rem;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Oswald";
}
.sports-table.baseball thead th:nth-child(16) {
  width: 1.9rem;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Oswald";
}
.sports-table.baseball thead th:nth-child(17) {
  width: 1.9rem;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Oswald";
}
.sports-table.baseball thead th:nth-child(18) {
  width: 1.9rem;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Oswald";
}
.sports-table.baseball thead th:nth-child(19) {
  width: 1.9rem;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Oswald";
}
.sports-table.baseball thead th:nth-child(20) {
  width: 1.9rem;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Oswald";
}
.sports-table.baseball thead th:nth-child(21) {
  width: 1.9rem;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Oswald";
}
.sports-table.baseball thead th:nth-child(22) {
  width: 1.9rem;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Oswald";
}

#community-board-head {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  grid-template-columns: 1fr 1fr;
}
#community-board-head .board-box:nth-child(2) {
  border-left-width: 0;
}

#community-board-head + .board-tab {
  margin-top: 1rem;
}

.board-tab {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  grid-gap: 3rem;
  margin-bottom: 2rem;
  overflow-x: auto;
}
.board-tab a {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  border-top: 0px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 2px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
  border-color: transparent;
  min-width: 10rem;
  height: 4rem;
  color: #9394AD;
}
.board-tab a:hover {
  color: var(--theme2);
  transition-duration: 0.1s;
}
.board-tab a.select {
  color: black;
  font-weight: bold;
  border-color: var(--theme);
}

.category-tab {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  height: 4rem;
  padding: 0 1.5rem;
  grid-gap: 1rem;
}
.category-tab > .nav-link {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem 0.8rem;
  font-size: 1.2rem;
  font-weight: revert;
  background-color: #F5F5F5;
  color: #9394AD;
}
.category-tab > .nav-link.select {
  font-size: 1.2rem;
  font-weight: bold;
  background-color: var(--theme);
  color: var(--black);
}

.board-layout > .pc-landing-community-area {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  grid-column-gap: 3rem;
  grid-row-gap: 0rem;
  grid-template-columns: 1fr 1fr;
}
.board-layout > .pc-landing-community-area .board-box {
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  width: auto;
}

.board-box {
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  width: 42rem;
  height: 26.5rem;
  overflow: hidden;
}
.board-box > * {
  padding: 0 1rem;
}
.board-box > header {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 0px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
  color: var(--theme2);
  height: 4rem;
  user-select: none;
  cursor: pointer;
}
.board-box > header > ul {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}
.board-box > header > ul > li {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 100%;
  cursor: pointer;
  color: #9394AD;
  border-top: 0px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 2px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
  border-color: transparent;
}
.board-box > header > ul > li.select {
  border-bottom-color: var(--theme);
  color: black;
  font-weight: bold;
}
.board-box > main {
  padding: 1rem;
}
.board-box > main > ul {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  flex-direction: column;
  grid-gap: 0.4rem;
}
.board-box > main > ul.picture, .board-box > main > ul.video {
  flex-direction: row;
  flex-wrap: wrap;
}
.board-box > main > ul.all li {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  justify-content: flex-start;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
}
.board-box > main > ul.all li p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.board-box > main > ul > li {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}
.board-box > main > ul > li img {
  width: 9rem;
  height: 9rem;
}
.board-box > main > ul.ready:after {
  content: "준비중 입니다.";
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  height: 20rem;
  font-size: 2rem;
  font-weight: bold;
  color: #d6d6d6;
}

.warning {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 2.8rem 1.4rem;
}
.warning * {
  font-size: 1.3rem;
  font-weight: revert;
  letter-spacing: calc(0.2rem * .1);
}
.warning header {
  margin-bottom: 1.2rem;
  font-weight: bold;
  color: #FF004E;
}
.warning main {
  color: #444444;
}

.board-table + .board-table {
  margin-top: 4rem;
}

.board-tab + .board-table {
  margin-top: 1rem;
}

.board-table .user-namecard {
  width: 12rem;
}
.board-table .list-search-form {
  position: relative;
}
.board-table .list-search-form .form-title {
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  grid-template-columns: 5.8rem calc(100% - 5.8rem - 4.8rem) 4.8rem;
  width: 26.2rem;
  height: 3rem;
  margin: 2rem auto 0;
}
.board-table .list-search-form .form-title > p, .board-table .list-search-form .form-title > .btn {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-weight: revert;
  letter-spacing: -0.101rem;
}
.board-table .list-search-form .form-title input {
  border-top: 0px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 0px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  padding: 0 0.4rem;
}
.board-table .list-search-form .form-title > .btn {
  background-color: #F3F3F3;
}
.board-table .list-search-form .write-new {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--theme);
  border: 1px solid var(--theme);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: #555555;
  width: 8.5rem;
  height: 3rem;
}
.board-table .board-search-form {
  padding: 1rem;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
}
.board-table .board-search-form label {
  display: inline-block;
}
.board-table .board-search-form label.form-radio {
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
}
.board-table .board-search-form label.form-radio:first-child {
  border-right-width: 0;
}
.board-table .board-search-form label.form-radio:last-child {
  border-left-width: 0;
}
.board-table .board-search-form label.form-radio input[type=radio] {
  display: none;
}
.board-table .board-search-form label.form-radio .btn {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
  height: 3rem;
  font-size: 1.1rem;
  font-weight: revert;
  letter-spacing: -0.101rem;
  background-color: #FFFFFF;
}
.board-table .board-search-form label.form-radio input[type=radio]:checked + .btn {
  background-color: #F3F3F3;
}
.board-table .board-search-form label.form-date-range {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
}
.board-table .board-search-form label.form-title {
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  display: inline-grid;
  display: -ms-inline-grid;
  display: -moz-inline-grid;
  grid-template-columns: 4.8rem 9rem 4.8rem;
  height: 3rem;
}
.board-table .board-search-form label.form-title > p, .board-table .board-search-form label.form-title > .btn {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-weight: revert;
  letter-spacing: -0.101rem;
}
.board-table .board-search-form label.form-title input {
  border-top: 0px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 0px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  padding: 0 0.4rem;
}
.board-table .board-search-form label.form-title > .btn {
  background-color: #F3F3F3;
}
.board-table .board-search-form .form-date-range {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
}
.board-table .board-search-form .form-date-range > div {
  border-top: 1px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  padding: 0.8rem;
  grid-gap: 0.2rem;
}
.board-table .board-search-form .form-date-range > div:last-child {
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
}
.board-table .board-search-form .form-date-range > div:nth-child(2), .board-table .board-search-form .form-date-range > div:nth-child(4) {
  background-color: #F3F3F3;
}
.board-table .board-search-form .form-date-range > div input {
  width: 6.5rem;
  text-align: center;
  color: var(--black);
  font-size: 1.1rem;
  font-weight: bold;
}
.board-table .board-search-form .form-date-range > div .fa-calendar {
  color: #9394AD;
  font-size: 1.4rem;
  font-weight: revert;
}
.board-table .board-search-form .btn {
  width: 4.8rem;
  border-radius: 0;
  font-size: 1.1rem;
  font-weight: revert;
}
.board-table .write-new {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--default);
  border: 1px solid var(--default);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: #555555;
  border-color: #D6D6D6;
  height: 3rem;
  font-size: 1.1rem;
  font-weight: revert;
  letter-spacing: -0.101rem;
}
.board-table > table {
  margin-top: 1rem;
  width: 100%;
}
.board-table > table.rank td:nth-child(1) {
  text-align: center;
}
.board-table > table.rank td:last-child {
  text-align: center;
}
.board-table > table th, .board-table > table td {
  border-top: 0px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
}
.board-table > table th:not(:first-child, :last-child), .board-table > table td:not(:first-child, :last-child) {
  border-left-width: 0;
  border-right-width: 0;
}
.board-table > table th:first-child, .board-table > table td:first-child {
  border-right-width: 0;
}
.board-table > table th:last-child, .board-table > table td:last-child {
  border-left-width: 0;
}
.board-table > table thead th {
  height: 2.4rem;
  text-align: center;
}
.board-table > table tbody td {
  height: 3.8rem;
}
@media (min-width: 758px) {
  .board-table > table tbody td {
    padding: 1rem;
  }
}
@media (max-width: 757px) {
  .board-table > table tbody td {
    padding: 0 0.4rem;
  }
}
.board-table > table tbody td.change {
  font-weight: bold;
}
.board-table > table tbody td.change.sub {
  color: var(--red);
}
.board-table > table tbody td.change.add {
  color: var(--blue);
}
.board-table > table.default tbody td:nth-child(1) {
  width: 6rem;
  color: var(--black);
  text-align: center;
}
.board-table > table.default tbody td:nth-child(1) b.free {
  color: var(--board-free-color);
}
.board-table > table.default tbody td:nth-child(1) b.humor {
  color: var(--board-humor-color);
}
.board-table > table.default tbody td:nth-child(1) b.picture {
  color: var(--board-picture-color);
}
.board-table > table.default tbody td:nth-child(1) b.video {
  color: var(--board-video-color);
}
.board-table > table.default tbody td:nth-child(1) b.sports {
  color: var(--board-sports-color);
}
.board-table > table.default tbody td:nth-child(2) {
  width: auto;
  cursor: pointer;
}
.board-table > table.default tbody td:nth-child(2):hover {
  color: var(--theme2);
  transition-duration: 0.1s;
}
.board-table > table.default tbody td:nth-child(2) > a {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}
.board-table > table.default tbody td:nth-child(3) {
  width: 14rem;
  text-align: left;
  position: relative;
}
.board-table > table.default tbody td:nth-child(3):before {
  content: "";
  position: absolute;
  top: calc(50% - 1.1rem);
  left: -0.1rem;
  height: 2.2rem;
  border-left: 2px solid var(--layout-border-color);
}
.board-table > table.default tbody td:nth-child(4) {
  width: 10rem;
  position: relative;
}
.board-table > table.default tbody td:nth-child(4):before {
  content: "";
  position: absolute;
  top: calc(50% - 1.1rem);
  left: -0.1rem;
  height: 2.2rem;
  border-left: 2px solid var(--layout-border-color);
}
.board-table > table.rank thead th:nth-child(1) {
  width: 6.4rem;
}
.board-table > table.rank thead th:nth-child(2) {
  width: 14rem;
}
.board-table > table.rank thead th:nth-child(3) {
  width: 7rem;
}
.board-table > table.rank tbody td:nth-child(2) {
  text-align: left;
}
.board-table > table.rank tbody td:nth-child(3) {
  text-align: center;
}
.board-table > table.rank tbody td:nth-child(4) {
  text-align: right;
}
.board-table > table.rank tbody td:nth-child(5) {
  text-align: center;
}
.board-table > table.analysis tbody td:nth-child(2) {
  text-align: left;
  cursor: pointer;
}
.board-table .board-picture {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  margin-top: 1rem;
}
.board-table .board-picture > li {
  position: relative;
  width: 20rem;
  height: 20rem;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.board-table .board-picture > li .thumbnail-link {
  display: block;
  width: 100%;
  height: 100%;
}
.board-table .board-picture > li > img {
  width: 100%;
}
.board-table .board-picture > li.empty {
  position: relative;
  background-color: #dbdbdb;
}
.board-table .board-picture > li.empty:after {
  content: "!";
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(50% - 9rem);
  left: calc(50% - 9rem);
  z-index: 1;
  width: 18rem;
  height: 18rem;
  background-color: #eaeaea;
  border-radius: 50%;
  color: #acacac;
  font-size: 6rem;
  font-weight: bold;
  pointer-events: none;
}
.board-table .board-picture > li .detail-info {
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
  padding: 1.2rem 0.8rem;
  background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0.6588235294) 0%, rgba(0, 0, 0, 0.3294117647) 100%) 0% 0% no-repeat padding-box;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  flex-direction: column;
  grid-gap: 0.8rem;
  color: #D6D6D6;
  transform: translateY(100%);
  transition-duration: 0.2s;
  pointer-events: none;
}
.board-table .board-picture > li .detail-info * {
  color: var(--white);
}
.board-table .board-picture > li:hover .detail-info {
  transform: translateY(0%);
}

.user-namecard {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 0.5rem;
  max-width: 12rem;
}
.user-namecard .user-icon {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  background-color: #D6D6D6;
  border-radius: 50%;
  border: 2px solid #D6D6D6;
  overflow: hidden;
}
.user-namecard .user-icon .fa-user {
  color: white !important;
  font-size: 1.2rem;
  font-weight: revert;
}
.user-namecard .--rank {
  font-size: 2.6rem;
  font-weight: revert;
}
.user-namecard .--rank[class*=admin-] {
  width: 2.6rem;
  background-size: contain;
  font-size: 2rem;
  font-weight: revert;
}

.user-board-namecard {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 0.5rem;
}
.user-board-namecard .user-icon {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 3.2rem;
  height: 3.2rem;
  background-color: #D6D6D6;
  border-radius: 50%;
  border: 2px solid #D6D6D6;
  overflow: hidden;
}
.user-board-namecard .user-icon .fa-user {
  color: white !important;
  font-size: 1.92rem;
  font-weight: revert;
}
.user-board-namecard > div {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  flex-direction: column;
}
.user-board-namecard > div span:first-child {
  font-weight: bold;
}
.pagination {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  grid-gap: 1rem;
}
.pagination:last-child {
  margin-bottom: 10rem;
}
.pagination > li .page-link {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  font-size: 1.2rem;
  font-weight: revert;
  color: #9394AD;
  border-color: #D6D6D6;
}
.pagination > li.active .page-link {
  color: black;
  background-color: #F3F3F3;
}

#main__container .login-form {
  margin: 10rem auto 0;
}

.login-form {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  flex-direction: column;
  max-width: 48rem;
  width: calc(100% - 4rem);
  margin: 0 auto;
  padding: 0 0 5rem;
}
@media (min-width: 758px) {
  .login-form {
    border-top: 1px solid var(--layout-border-color);
    border-right: 1px solid var(--layout-border-color);
    border-bottom: 1px solid var(--layout-border-color);
    border-left: 1px solid var(--layout-border-color);
  }
}
@media (max-width: 757px) {
  .login-form {
    margin: 0 auto;
  }
}
.login-form > * {
  margin: 0 auto;
}
@media (min-width: 758px) {
  .login-form > * {
    width: calc(100% - 4rem);
  }
}
@media (max-width: 757px) {
  .login-form > * {
    width: 100%;
  }
}
.login-form > header {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  height: 14rem;
  flex-direction: column;
  border-bottom: 1px solid var(--theme);
}
.login-form > header img {
  transform: scale(1.4);
}
.login-form > main {
  margin-top: 4.5rem;
}
.login-form > main form {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  flex-direction: column;
}
@media (min-width: 758px) {
  .login-form > main form .form-row {
    display: grid;
    display: -ms-grid;
    display: -moz-grid;
    justify-content: flex-start;
    align-items: center;
    grid-template-columns: 11rem 29rem;
    grid-column-gap: 1rem;
  }
}
@media (max-width: 757px) {
  .login-form > main form .form-row {
    display: flex;
    display: -o-flex;
    display: -ms-flex;
    display: -moz-flex;
    display: -webkit-flex;
    flex-direction: column;
    grid-gap: 0.5rem;
  }
}
.login-form > main form .form-row label {
  cursor: pointer;
}
.login-form > main form .form-row strong {
  white-space: nowrap;
}
@media (min-width: 758px) {
  .login-form > main form .form-row strong {
    font-size: 1.6rem;
    font-weight: bold;
    text-align: right;
  }
}
@media (max-width: 757px) {
  .login-form > main form .form-row strong {
    font-size: 1.4rem;
    font-weight: bold;
    text-align: left;
    padding-left: 0.5rem;
  }
}
.login-form > main form .form-row input[type=text], .login-form > main form .form-row input[type=password] {
  height: 3.8rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  border-color: var(--theme);
  padding: 0 1.4rem;
  border-radius: 0.6rem;
}
.login-form > main form .form-row input + .form-text {
  height: 1em;
  grid-column: 2/span 1;
  padding-left: 1rem;
}
.login-form > main form .form-row .remember {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 0.5rem;
}
.login-form > main form .form-row .remember .form-checkbox {
  position: relative;
  font-size: 1.6rem;
  font-weight: revert;
}
.login-form > main form button {
  height: 3.6rem;
  color: white;
  font-size: 1.6rem;
  font-weight: bold;
  border-radius: 0.4rem;
}
.login-form > main form button:nth-of-type(1) {
  margin-top: 4.8rem;
}
.login-form footer {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.2rem;
  grid-gap: 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #444444;
}
.login-form footer > * {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  text-align: inherit;
}
.login-form.exp-change-form > header {
  border-bottom-color: #D6D6D6 !important;
  border-bottom-style: dashed !important;
}
.login-form.exp-change-form .form-row {
  position: relative;
}
.login-form.exp-change-form .form-row .absolute-btn {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 0.6rem;
  position: absolute;
  top: 0;
  right: 3rem;
  z-index: 1;
}
.login-form.exp-change-form .form-row .absolute-btn .btn {
  width: 6.7rem;
  height: 2.6rem;
  font-size: 1.1rem;
  font-weight: revert;
}
.login-form.exp-change-form input[type=text] {
  border-color: #9394AD !important;
  text-align: left;
}
.login-form.exp-change-form input[type=text].mypoint {
  color: var(--blue);
}
.board-detail {
  margin-top: 1rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
}
.board-detail > header {
  border-top: 0px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
}
.board-detail > header h1 {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  height: 4rem;
  border-top: 0px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
  font-size: 1.6rem;
  font-weight: bold;
  padding: 0 2rem;
  margin-bottom: 0;
}
.board-detail > header h1 > span {
  margin-top: 0.5rem;
  padding-left: 0.5rem;
  color: var(--theme2);
}
.board-detail > header > div {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  padding: 0 2rem;
  background-color: #F2F2F2;
}
.board-detail > main {
  padding: 3rem 2rem;
  overflow: hidden;
}
.board-detail > main iframe {
  width: 100%;
  height: 45rem;
}
.board-detail > main img {
  max-width: 100%;
}
.board-detail > footer {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 2.6rem 2rem 0;
}
.board-detail > footer .board-btn {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 0.7rem;
}
.board-detail > footer .board-btn button {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 3.4rem;
  height: 1.8rem;
}
.board-detail > footer .board-btn button.btn {
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  background-color: #F3F3F3;
  border-radius: 0.4rem;
}

.comments {
  margin-top: 1rem;
}
.comments .comment-box {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  flex-direction: column;
  padding: 1.2rem 2.4rem 0 2.4rem;
  min-height: 12rem;
}
.comments .comment-box:not(:first-child) {
  border-top: 1px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 0px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
}
.comments .comment-box > main {
  margin-top: 1rem;
  padding-left: 3.6rem;
  font-size: 1.6rem;
  font-weight: revert;
  white-space: pre-line;
}
.comments .comment-box > footer {
  margin-top: auto;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 2.6rem 2rem 0;
  padding: 0 2.6rem 1.4rem 0;
}
.comments .comment-box > footer .board-btn {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 0.7rem;
}
.comments .comment-box > footer .board-btn button {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 3.4rem;
  height: 1.8rem;
}
.comments .comment-box > footer .board-btn button.btn {
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  background-color: #F3F3F3;
  border-radius: 0.4rem;
}
.comments > .comment-input-form {
  border-top: 1px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 0px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
  background-color: #F3F3F3;
  height: 12rem;
  padding: 1.5rem 3rem;
}
.comments > .comment-input-form form {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  grid-template-columns: 66rem 12rem;
  height: 9rem;
}
.comments > .comment-input-form form textarea {
  padding: 1rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  background-color: var(--white);
  resize: none;
  outline: none;
}
.comments > .comment-input-form form button {
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  color: #444444;
  font-size: 1.4rem;
  font-weight: revert;
}

.edit-board {
  margin-top: 1rem;
}
.edit-board iframe {
  width: 100%;
  height: 100%;
}
.edit-board form .edit-form-title {
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  padding: 1rem;
}
.edit-board form .edit-form-title input[type=text] {
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  width: 100%;
  height: 3.6rem;
  padding: 0 0.8rem;
  font-size: 1.6rem;
  font-weight: revert;
}
.edit-board form .edit-form-content {
  margin-top: 1rem;
}
.edit-board form .edit-form-content .ql-toolbar {
  background-color: #F3F3F3;
}
.edit-board form .edit-form-content .ql-container {
  height: 42rem;
}
.edit-board form .edit-form-btn {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  grid-gap: 2rem;
}
.edit-board form .edit-form-btn .write-new {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
  height: 3rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  background-color: #F3F3F3;
  font-size: 1.1rem;
  font-weight: revert;
  letter-spacing: -0.101rem;
}

.board-market > ul {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  grid-gap: 1rem;
}
.board-market > ul .market-items {
  width: 20rem;
  height: 37rem;
  overflow: hidden;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
}
.board-market > ul .market-items > header {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 20rem;
  border-top: 0px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
}
.board-market > ul .market-items > header .bonus {
  margin-top: 0.5rem;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.7rem;
  background-color: var(--theme);
  font-size: 1.1rem;
  font-weight: bold;
}
.board-market > ul .market-items > header .descriptions {
  margin-top: 0.5rem;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 17.6rem;
  padding: 0.5rem 0.8rem;
  font-size: 1.1rem;
  font-weight: revert;
  text-align: center;
}
.board-market > ul .market-items > main {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  flex-direction: column;
}
.board-market > ul .market-items > main > div {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  height: 4rem;
  padding: 0 1.2rem 0 0.7rem;
  border-top: 0px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
}
.board-market > ul .market-items > main > div .inner {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 0.4rem;
  width: 100%;
  height: 100%;
  color: #9394AD;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}
.board-market > ul .market-items > main > div .inner.checked {
  color: var(--theme2);
}
.board-market > ul .market-items > button {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4.8rem;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 0 !important;
}
.board-market > ul .market-items > button.active {
  color: white;
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--theme2);
  border: 1px solid var(--theme2);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
}
.board-market > ul .market-items > button.active:hover {
  background-color: transparent;
  color: var(--theme2);
}
.board-market > ul .market-items > button.disabled {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--disabled);
  border: 1px solid var(--disabled);
  border-radius: 0rem;
  cursor: default !important;
  pointer-events: none !important;
  user-select: none;
  color: #D6D6D6;
}

.item-counter {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
}
.item-counter > div {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  user-select: none;
  color: #9394AD;
}
.item-counter > div:nth-child(2) {
  border-top: 0px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 0px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
}
.item-counter > div:nth-child(2) input[type=number] {
  width: 100%;
  height: 100%;
  text-align: center;
}
.item-counter > div:nth-child(1), .item-counter > div:nth-child(3) {
  font-size: 1.6rem;
  font-weight: bold;
  cursor: pointer;
}

.board-profile .box {
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
}
.board-profile .box:first-child {
  margin-top: 1rem;
}
.board-profile .box:not(:last-child) {
  margin-bottom: 1rem;
}
.board-profile .box .p-row {
  position: relative;
  min-height: 5.6rem;
  padding: 2rem;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.board-profile .box .p-row:not(:last-child) {
  border-top: 0px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
  border-style: dashed;
}
.board-profile .box .p-row .subject {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  text-align: center;
}
.board-profile .box .p-row .content {
  padding-left: 4rem;
}
.board-profile .box .p-row .content.edit-image {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  grid-template-columns: 28.9rem 36.1rem;
  grid-gap: 1rem;
}
.board-profile .box .p-row .content.edit-image > div:first-child .profile-box {
  width: 8rem;
  height: 8rem;
  background-color: #F2F2F2;
  text-align: center;
}
.board-profile .box .p-row .content.edit-image > div:first-child .profile-box > img {
  width: 100%;
  height: 100%;
}
.board-profile .box .p-row .content.edit-image > div:first-child .banner-box {
  width: 28.9rem;
  height: 8rem;
  background-color: #F2F2F2;
}
.board-profile .box .p-row .content.edit-image > div:first-child .banner-box > img {
  width: 100%;
  height: 100%;
}
.board-profile .box .p-row .content.edit-image > div:first-child > p {
  margin-top: 2rem;
  font-size: 1.2rem;
  font-weight: bold;
}
.board-profile .box .p-row .content.push-alarm {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5rem;
}
.board-profile .box.profile-1 .btn-area {
  position: absolute;
  bottom: calc(0% + 1.6rem);
  right: 2rem;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 1.2rem;
}
.board-profile .box.profile-1 .btn-area .btn {
  width: 7.7rem;
  height: 3.2rem;
  border-radius: 0;
  font-size: 1.2rem;
  font-weight: revert;
}
.board-profile .box.profile-2 .btn-area {
  margin-left: auto;
}
.board-profile .box.profile-2 .btn-area .btn {
  width: 7rem;
  height: 2rem;
  border-radius: 0;
  padding: 0;
}
.board-profile .box.profile-2 .btn-area .btn > a {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1.1rem;
  font-weight: revert;
}
.board-profile .box.profile-3 .p-row {
  align-items: center;
  height: 5.6rem;
  padding: 0 2rem;
}
.board-profile .box.profile-3 .p-row > * {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
}
.board-profile .box.profile-3 .p-row .btn-area {
  margin-left: auto;
}
.board-profile .box.profile-3 .p-row .btn-area .btn {
  width: 7.7rem;
  height: 3.2rem;
  border-radius: 0;
  font-size: 1.2rem;
  font-weight: revert;
}

.btn > a {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.switch-area {
  position: relative;
  width: 3.2em;
  height: 2em;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  background-color: #9394AD;
  border-color: #9394AD;
  border-radius: 1em;
  border-width: 0.2rem;
  transition-duration: 0.1s;
  cursor: pointer;
}
.switch-area > .switch-btn {
  position: absolute;
  top: 0.04em;
  left: 0;
  display: block;
  width: 1.6em;
  height: 1.6em;
  background-color: var(--white);
  border-radius: 50%;
  transition-duration: 0.1s;
}
.switch-area.on {
  background-color: var(--theme2);
  border-color: var(--theme2);
}
.switch-area.on > .switch-btn {
  left: calc(1.6em - 0.4rem);
}

.board-message > header {
  margin-top: 2rem;
}
.board-message > header .message-warning {
  padding-left: 2rem;
}
.board-message > header .message-warning li {
  list-style: disc;
}
.board-message > header .message-count {
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  height: 5.6rem;
  padding: 0 2rem;
}
.board-message > header .message-count strong {
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}
.board-message > header .message-count p {
  margin-left: 3rem;
  font-size: 1.2rem;
  font-weight: bold;
}
.board-message > header .message-count .btn {
  height: 3.2rem;
  padding: 0 0.8rem;
  font-size: 1.1rem;
  font-weight: revert;
  margin-left: auto;
}
.board-message > main form > .d-flex {
  margin-top: 2rem;
  padding-left: 1rem;
}
.board-message > main form > .d-flex .target-nickname {
  width: 12rem;
  height: 2.8rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  padding: 0 0.4rem;
}
.board-message > main form textarea {
  width: 100%;
  height: 20rem;
  padding: 1rem;
  margin-top: 1rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  border-radius: 0.4rem;
  resize: none;
}
.board-message > main form .btn.send {
  width: 12rem;
  height: 3.2rem;
  margin-top: 1rem;
  margin-left: auto;
  font-size: 1.4rem;
  font-weight: revert;
}
@keyframes article-slide-down {
  from {
    max-height: 0;
  }
  to {
    height: auto;
    max-height: 100rem;
  }
}
.board-message table th, .board-message table td {
  text-align: center;
}
.board-message table tbody tr td {
  cursor: default;
}
.board-message table tbody tr td:nth-child(3) {
  cursor: pointer;
}

.board-user header > .my-cash {
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  height: 5.6rem;
  padding: 0 2rem;
}
.board-user header > .my-cash strong {
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}
.board-user header > .my-cash p {
  margin-left: 3rem;
  font-size: 1.2rem;
  font-weight: bold;
}
.board-user header > .my-cash .btn {
  height: 3.2rem;
  padding: 0 0.8rem;
  font-size: 1.1rem;
  font-weight: revert;
  margin-left: auto;
}
.board-user table th, .board-user table td {
  text-align: center;
}
.board-user.inventory header > [class*=my-] p {
  color: var(--pink);
}
.board-user.exp header > [class*=my-] p {
  color: var(--blue);
}
.board-user.exp table {
  width: 100%;
}
.board-user.exp table th, .board-user.exp table td {
  text-align: center;
  height: 3.8rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
}
.board-user.exp table thead th {
  background-color: #F5F5F5;
}
.board-user > main header {
  margin-top: 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  padding-left: 0.8rem;
  padding-bottom: 0.8rem;
  border-top: 0px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
  border-color: var(--theme);
}
.board-user.inventory .item-list {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 1rem;
  flex-wrap: wrap;
  background-color: #F5F5F5;
  padding: 1rem 0.5rem;
}
.board-user.inventory .item-list li {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 20rem;
  height: 20rem;
  padding: 1rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  background-color: var(--white);
}
.board-user.inventory .item-list li * {
  color: var(--black);
}
.board-user.inventory .item-list li .item-name {
  margin-top: 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
}
.board-user.inventory .item-list li .count {
  margin-top: 0.5rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  border-style: dashed;
  padding: 0.5rem 0.8rem;
  font-size: 1.1rem;
  font-weight: bold;
}
.board-user.inventory .item-list li button {
  width: 100%;
  margin-top: auto;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  background-color: var(--theme);
  font-size: 1.2rem;
  font-weight: revert;
}
.board-user.inventory table thead th:nth-child(1) {
  width: 4.2rem;
}
.board-user.inventory table thead th:nth-child(2) {
  width: 11rem;
}
.board-user.inventory table thead th:nth-child(4) {
  width: 16rem;
}
.board-user.inventory table thead th:nth-child(5) {
  width: 19rem;
}

.rank-card {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
}
.rank-card > .--rank {
  font-size: 2.6rem;
  font-weight: revert;
}
.rank-card > span {
  width: 4.4rem;
  text-align: center;
}

.next-rank-exp-bar {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: flex-end;
  margin-left: auto;
  margin-right: 6rem;
  grid-gap: 1rem;
}
.next-rank-exp-bar .rank-info {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.next-rank-exp-bar .rank-info .--rank {
  font-size: 2.6rem;
  font-weight: revert;
}
.next-rank-exp-bar .rank-info > span {
  font-size: 1.1rem;
  font-weight: bold;
}
.next-rank-exp-bar .bar-info {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.next-rank-exp-bar .bar-info .bar {
  position: relative;
  width: 40rem;
  height: 0.8rem;
  background-color: #D6D6D6;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  border-color: #444444;
  border-radius: 5.8rem;
  overflow: hidden;
}
.next-rank-exp-bar .bar-info .bar .gauge {
  position: absolute;
  height: 100%;
  background-color: var(--theme2);
}

.board-table.friend .my-friend {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  margin-bottom: 5rem;
  height: 5.6rem;
  padding-left: 5.5rem;
  grid-gap: 4rem;
}
.board-table.friend .my-friend > div {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 1rem;
  font-weight: bold;
}
.board-table.friend .my-friend > div:nth-child(1) b {
  color: var(--blue);
}
.board-table.friend .my-friend > div:nth-child(2) b, .board-table.friend .my-friend > div:nth-child(3) b {
  color: var(--red);
}

.chat-overlay.user-pick-overlay {
  z-index: 100;
}
.chat-overlay.user-pick-overlay main {
  transform: translateX(0);
}
.chat-overlay.room main {
  transform: translateX(18.4rem);
}
.chat-overlay main {
  transform: translateX(-8rem);
  width: 18.4rem;
  background-color: var(--white);
  box-shadow: 3px 3px 12px var(--black) 52;
}
.chat-overlay main header {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  height: 2.6rem;
  padding: 0 0.5rem;
  background-color: var(--theme);
}
.chat-overlay main header .user-namecard {
  font-size: 1.3rem;
  font-weight: bold;
}
.chat-overlay main header .user-namecard .--rank {
  font-size: 2rem;
  font-weight: revert;
}
.chat-overlay main header .userinfo {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
}
.chat-overlay main header .userinfo .--rank {
  font-size: 2rem;
  font-weight: revert;
}
.chat-overlay main header .userinfo p {
  font-size: 1.3rem;
  font-weight: bold;
}
.chat-overlay main header .fa-rectangle-xmark {
  font-size: 1.8rem;
  font-weight: revert;
}
.chat-overlay main ul {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  flex-direction: column;
  background-color: var(--white);
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  border-color: var(--theme);
  overflow: hidden;
}
.chat-overlay main ul li {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  height: 2.6rem;
  padding: 0 0.3rem;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
}
.chat-overlay main ul li:not(:last-child) {
  border-top: 0px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
  border-style: dashed;
}
.chat-overlay main ul li i {
  font-size: 2.6rem;
  font-weight: revert;
}

.edit-image-area {
  height: 30rem;
}

.chat-room-list > header {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  height: 3.4rem;
  border-top: 0px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
  padding: 0 0.4rem 0 2rem;
}
.chat-room-list > header > strong {
  color: var(--black);
}
.chat-room-list table {
  margin-top: 2rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
}
.chat-room-list table th, .chat-room-list table td {
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  padding: 0 1rem;
}
.chat-room-list table tr {
  border-top: 0px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
}
.chat-room-list table th:nth-child(1) {
  width: 6rem;
}
.chat-room-list table th:nth-child(3) {
  width: 8rem;
}
.chat-room-list table th:nth-child(4) {
  width: 8rem;
}
.chat-room-list table th:nth-child(5) {
  width: 12rem;
}
.chat-room-list table th:nth-child(6) {
  width: 6rem;
}
.chat-room-list table td:nth-child(2) {
  cursor: pointer;
}

.room-popup-template {
  width: 35rem;
  height: 64.5rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  margin: 0 auto;
}
.room-popup-template > header {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  height: 3.6rem;
  padding: 0 1rem;
  font-size: 1.2rem;
  font-weight: bold;
}
.room-popup-template > main {
  height: calc(100% - 3.6rem);
}
.room-popup-template > main form {
  height: 100%;
}
.room-popup-template > main form .create-room-modal-body {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  flex-direction: column;
  grid-gap: 5rem;
  padding: 0 2rem 2rem;
  height: 100%;
}
.room-popup-template > main form .create-room-modal-body .d-row {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  flex-direction: column;
  grid-gap: 1rem;
}
.room-popup-template > main form .create-room-modal-body .d-row:first-child {
  margin-top: 16.4rem;
}
.room-popup-template > main form .create-room-modal-body .d-row strong {
  color: var(--black);
  font-size: 1.6rem;
  font-weight: revert;
}
.room-popup-template > main form .create-room-modal-body .d-row > div.radio {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}
.room-popup-template > main form .create-room-modal-body .d-row > div.radio input[type=radio] + .btn {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--default);
  border: 1px solid var(--default);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: #555555;
  border-color: #D6D6D6;
}
.room-popup-template > main form .create-room-modal-body .d-row > div.radio input[type=radio]:checked + .btn {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  background-color: var(--theme);
  border: 1px solid var(--theme);
  border-radius: 0rem;
  cursor: pointer;
  user-select: none;
  color: #555555;
}
.room-popup-template > main form .create-room-modal-body .d-row > div.radio .btn {
  width: 100%;
  height: 3rem;
}
.room-popup-template > main form .create-room-modal-body .d-row > div.input input[type=text] {
  width: 100%;
  height: 3.8rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  border-color: #9394AD;
  border-radius: 0.6rem;
  padding: 0 0.8rem;
}
.room-popup-template > main form .create-room-modal-body .d-row > div label .btn {
  height: 3rem;
}
.room-popup-template > main form .create-room-modal-body > footer {
  margin-top: auto;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  flex-direction: column;
  grid-gap: 1rem;
}
.room-popup-template > main form .create-room-modal-body > footer .btn {
  height: 3.6rem;
  font-size: 1.6rem;
  font-weight: bold;
  border-radius: 0.4rem;
}
.room-popup-template > main .host {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  height: 3.6rem;
  padding: 0 1rem;
}
.room-popup-template > main .host .user-namecard {
  font-weight: bold;
}
.room-popup-template > main .host > div {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
}
.room-popup-template > main .host > div .close {
  font-size: 2.6rem;
  font-weight: revert;
}
.room-popup-template > main .chat-room-tab {
  height: 3.6rem;
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 1rem;
}
.room-popup-template > main .chat-room-tab > li {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  border-top: 0px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
  border-color: #D6D6D6;
  cursor: pointer;
}
.room-popup-template > main .chat-room-tab > li.select {
  color: var(--black);
  font-size: 1.2rem;
  font-weight: bold;
  border-top: 0px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 2px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
  border-color: var(--theme2);
}
.room-popup-template > main .chat-room-body {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  flex-direction: column;
  height: calc(100% - (3.6rem * 2));
}
.room-popup-template > main .chat-room-body > .chat-area {
  height: 100%;
  padding: 2rem 1rem 1rem;
  overflow-y: auto;
}
.room-popup-template > main .chat-room-body > .chat-area::-webkit-scrollbar {
  display: block !important;
  width: 6px;
}
.room-popup-template > main .chat-room-body > .chat-area::-webkit-scrollbar-track-piece {
  background-color: #F3F3F3;
}
.room-popup-template > main .chat-room-body > .chat-area::-webkit-scrollbar-thumb {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-radius: revert;
  background-image: linear-gradient(90deg, transparent 0%, transparent 25%, #D3D3D3 25%, #D3D3D3 75%, transparent 75%, transparent 100%);
}
.room-popup-template > main .chat-room-body > .chat-area > ul {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  flex-direction: column;
  grid-gap: 0.7rem;
}
.room-popup-template > main .chat-room-body > .chat-area > ul li.chat > span:first-child {
  letter-spacing: -0.12rem;
  color: var(--chat-user-strong);
  font-size: 1.3rem;
  font-weight: bold;
  cursor: pointer;
}
.room-popup-template > main .chat-room-body > .chat-area > ul li.chat > span:first-child i.--rank {
  font-size: 2rem;
  font-weight: revert;
  margin-bottom: -0.5rem;
}
.room-popup-template > main .chat-room-body > .chat-area > ul li.chat > span:last-child {
  margin-left: 0.5rem;
  color: var(--chat-msg-p);
  font-size: 1.2rem;
  font-weight: revert;
  word-break: break-all;
}
.room-popup-template > main .chat-room-body > .chat-area > ul li.info {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  text-align: center;
  color: var(--chat-input-default);
}
.room-popup-template > main .chat-room-body > .chat-room-attendant {
  height: 100%;
  overflow-y: auto;
}
.room-popup-template > main .chat-room-body > .chat-room-attendant::-webkit-scrollbar {
  display: block !important;
  width: 6px;
}
.room-popup-template > main .chat-room-body > .chat-room-attendant::-webkit-scrollbar-track-piece {
  background-color: #F3F3F3;
}
.room-popup-template > main .chat-room-body > .chat-room-attendant::-webkit-scrollbar-thumb {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-radius: revert;
  background-image: linear-gradient(90deg, transparent 0%, transparent 25%, #D3D3D3 25%, #D3D3D3 75%, transparent 75%, transparent 100%);
}
.room-popup-template > main .chat-room-body > .chat-room-attendant ul {
  padding: 2rem 1rem 0;
}
.room-popup-template > main .chat-room-body > .chat-room-attendant ul li {
  width: 100%;
  height: 3.6rem;
  cursor: pointer;
}
.room-popup-template > main .chat-room-body > .chat-room-attendant ul li div {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
}
.room-popup-template > main .chat-room-body > .chat-room-attendant ul li div strong {
  font-size: 1.3rem;
  font-weight: bold;
}
.room-popup-template > main .chat-room-body > footer {
  margin-top: auto;
  height: 10.4rem;
  padding: 1rem 0.5rem 0 0.5rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 0px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
  background-color: var(--white);
}
.room-popup-template > main .chat-room-body > footer * {
  color: var(--chat-input-default);
}
.room-popup-template > main .chat-room-body > footer > p {
  color: var(--default-font);
  font-size: 1.3rem;
  font-weight: revert;
  font-weight: bold;
}
.room-popup-template > main .chat-room-body > footer > input {
  width: 100%;
  height: 3rem;
  color: black;
  border-bottom: 1px solid var(--black);
}
.room-popup-template > main .chat-room-body > footer > input::-webkit-input-placeholder {
  color: var(--chat-input-default);
}
.room-popup-template > main .chat-room-body > footer > div {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  height: 4rem;
}
.room-popup-template > main .chat-room-body > footer > div .fa-face-laugh {
  font-size: 2.3rem;
  font-weight: revert;
}
.room-popup-template > main .chat-room-body > footer > div > div {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  grid-gap: 1.4rem;
}
.room-popup-template > main .chat-room-body > footer > div > div .text-count {
  font-size: 1.3rem;
  font-weight: revert;
  letter-spacing: 0.013rem;
}
.room-popup-template > main .chat-room-body > footer > div > div .fa-paper-plane {
  font-size: 1.5rem;
  font-weight: revert;
}

.create-room-modal {
  width: 35rem;
  height: 64.5rem;
}

aside .dummy-pick {
  margin-top: 4rem;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  border-color: #f3f3f3;
}
aside .dummy-pick > header {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: #F2F2F2;
  height: 3.8rem;
}
aside .dummy-pick > main > div {
  padding: 1.7rem 2rem;
}
aside .dummy-pick > main > div:nth-child(2) {
  border-top: 1px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 0px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
  border-color: #F2F2F2;
}
aside .dummy-pick > main > div > header {
  margin-bottom: 1.2rem;
  text-align: center;
  color: var(--black);
  font-size: 1.2rem;
  font-weight: revert;
}
aside .dummy-pick > main > div > ul {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  grid-gap: 1rem;
}
aside .dummy-pick > main > div > ul.col-4 {
  grid-template-columns: repeat(4, 1fr);
}
aside .dummy-pick > main > div > ul.col-3 {
  grid-template-columns: repeat(3, 1fr);
}
aside .dummy-pick > main > div > ul li {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  width: 100%;
  height: 3rem;
  background-color: #F3F3F3;
  cursor: pointer;
  user-select: none;
}
aside .dummy-pick > main > div > ul + ul {
  margin-top: 1rem;
}
aside .dummy-pick > footer {
  user-select: none;
  cursor: pointer;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  height: 3.6rem;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: var(--theme);
}

.board-policy > pre {
  white-space: pre-line;
}
.board-policy strong {
  display: block;
}
.board-policy strong:not(:first-child) {
  margin-top: 2.4rem;
  margin-bottom: 0.5rem;
}
.board-policy dl {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  grid-gap: 0.8rem;
  margin-bottom: 0rem;
}
.board-policy dl * {
  font-size: 1.2rem;
}
.board-policy dd {
  margin-bottom: 0rem;
}

.room-close-modal > p {
  font-size: 2rem;
  font-weight: bold;
}
#happyball-layout > .empty {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 60rem;
  background-color: #F4F4F4;
}
#happyball-layout > .empty * {
  background-color: transparent;
}
#happyball-layout > .empty img {
  margin-bottom: 3rem;
}
#happyball-layout > .empty p:nth-child(2) {
  font-size: 1.5rem;
  font-weight: bold;
  color: #000000;
  margin-bottom: 1rem;
}
#happyball-layout > .empty p:nth-child(3) {
  font-size: 1.2rem;
  font-weight: bold;
}

.new-exp-table {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  grid-template-columns: 1fr 1fr;
}
.new-exp-table > table:last-child th:first-child, .new-exp-table > table:last-child td:first-child {
  border-left-width: 0;
}

.react-datepicker__header {
  padding-top: 0.4rem;
}
.react-datepicker__header .react-datepicker__current-month {
  font-size: 1.3rem;
  font-weight: bold;
}

.react-datepicker__day, .react-datepicker__day-name {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  margin: 0;
}
.react-datepicker__day:not(.react-datepicker__day--outside-month, .react-datepicker__day--selected), .react-datepicker__day-name:not(.react-datepicker__day--outside-month, .react-datepicker__day--selected) {
  background-color: #F2F2F2;
}
.react-datepicker__day.react-datepicker__day--outside-month, .react-datepicker__day-name.react-datepicker__day--outside-month {
  color: #AAAAAA;
}

.sports-iframe-cover > iframe {
  margin-top: 2rem;
  overflow: hidden;
}

iframe#sports_iframe {
  width: 84rem;
}

.game-pick {
  min-height: 32rem;
  margin-top: 4rem;
  background-color: white;
}
.game-pick header, .game-pick footer > button {
  width: 100%;
  height: 3.8rem;
  font-size: 1.2rem;
  font-weight: bold;
}
.game-pick header {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.5rem;
  background-color: var(--theme);
}
.game-pick footer > button[type=submit] {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: bold;
  cursor: pointer;
}
.game-pick main {
  min-height: 22.8rem;
  border-top: 0px solid var(--layout-border-color);
  border-right: 1px solid var(--layout-border-color);
  border-bottom: 0px solid var(--layout-border-color);
  border-left: 1px solid var(--layout-border-color);
  border-color: var(--default);
}
.game-pick main.pick-block-main {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: revert;
}
.game-pick main.pick-chosen-main {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 1rem;
}
.game-pick main.pick-chosen-main > p {
  font-size: 1.4rem;
  font-weight: revert;
}
.game-pick main.pick-chosen-main > div {
  width: 25rem;
  height: 10rem;
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
  background-color: #eaeaea;
  border-radius: 8pt;
}
.game-pick main.pick-chosen-main > div > span {
  font-size: 1.4rem;
  font-weight: bold;
}
.game-pick main.pick-chosen-main > div > span:last-child {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 7.5rem;
  height: 3rem;
  font-size: 1.2rem;
  font-weight: revert;
  color: white;
}
.game-pick main.pick-chosen-main > div > span:last-child.ODD, .game-pick main.pick-chosen-main > div > span:last-child.UNDER, .game-pick main.pick-chosen-main > div > span:last-child.MEDIUM, .game-pick main.pick-chosen-main > div > span:last-child.LEFT, .game-pick main.pick-chosen-main > div > span:last-child.THREE, .game-pick main.pick-chosen-main > div > span:last-child.LEFT_THREE_EVEN, .game-pick main.pick-chosen-main > div > span:last-child.LEFT_FOUR_ODD {
  background-color: var(--odd);
}
.game-pick main.pick-chosen-main > div > span:last-child.EVEN, .game-pick main.pick-chosen-main > div > span:last-child.OVER, .game-pick main.pick-chosen-main > div > span:last-child.LARGE, .game-pick main.pick-chosen-main > div > span:last-child.RIGHT, .game-pick main.pick-chosen-main > div > span:last-child.FOUR, .game-pick main.pick-chosen-main > div > span:last-child.RIGHT_THREE_ODD, .game-pick main.pick-chosen-main > div > span:last-child.RIGHT_FOUR_EVEN {
  background-color: var(--even);
}
.game-pick main.pick-chosen-main > div > span:last-child.SMALL {
  background-color: var(--small);
}
.game-pick main.pick-form-main > div {
  padding: 1.2rem 2rem;
}
.game-pick main.pick-form-main > div:last-child {
  border-top: 1px solid var(--layout-border-color);
  border-right: 0px solid var(--layout-border-color);
  border-bottom: 0px solid var(--layout-border-color);
  border-left: 0px solid var(--layout-border-color);
  border-color: var(--default);
}
.game-pick main.pick-form-main > div p, .game-pick main.pick-form-main > div .pick {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
}
.game-pick main.pick-form-main > div p {
  font-size: 1.4rem;
  font-weight: revert;
  margin-bottom: 1.2rem;
}
.game-pick main.pick-form-main > div .cell {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  grid-gap: 1rem;
}
.game-pick main.pick-form-main > div .cell.col2 {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(2, 7rem);
}
.game-pick main.pick-form-main > div .cell.col3 {
  grid-template-columns: repeat(3, 1fr);
}
.game-pick main.pick-form-main > div .cell.col4 {
  grid-template-columns: repeat(4, 1fr);
}
.game-pick main.pick-form-main > div .cell + .cell {
  margin-top: 1rem;
}
.game-pick main.pick-form-main > div .cell label {
  width: 100%;
  height: 3.4rem;
}
.game-pick main.pick-form-main > div .cell label input[type=radio] {
  display: none;
}
.game-pick main.pick-form-main > div .cell label .pick {
  width: 100%;
  height: 100%;
  background-color: var(--default);
  cursor: pointer;
  transition-duration: 0.1s;
}
.game-pick main.pick-form-main > div .cell label .pick:hover.ODD, .game-pick main.pick-form-main > div .cell label .pick:hover.UNDER, .game-pick main.pick-form-main > div .cell label .pick:hover.LEFT, .game-pick main.pick-form-main > div .cell label .pick:hover.THREE, .game-pick main.pick-form-main > div .cell label .pick:hover.LEFT_THREE_EVEN, .game-pick main.pick-form-main > div .cell label .pick:hover.LEFT_FOUR_ODD {
  color: white;
  background-color: var(--odd);
}
.game-pick main.pick-form-main > div .cell label .pick:hover.EVEN, .game-pick main.pick-form-main > div .cell label .pick:hover.OVER, .game-pick main.pick-form-main > div .cell label .pick:hover.RIGHT, .game-pick main.pick-form-main > div .cell label .pick:hover.FOUR, .game-pick main.pick-form-main > div .cell label .pick:hover.RIGHT_THREE_ODD, .game-pick main.pick-form-main > div .cell label .pick:hover.RIGHT_FOUR_EVEN {
  color: white;
  background-color: var(--even);
}
.game-pick main.pick-form-main > div .cell label .pick:hover.SMALL {
  color: white;
  background-color: var(--small);
}
.game-pick main.pick-form-main > div .cell label .pick:hover.MEDIUM {
  color: white;
  background-color: var(--medium);
}
.game-pick main.pick-form-main > div .cell label .pick:hover.LARGE {
  color: white;
  background-color: var(--large);
}
.game-pick main.pick-form-main > div .cell label input[name=game_pick]:checked + .pick.ODD, .game-pick main.pick-form-main > div .cell label input[name=game_pick]:checked + .pick.UNDER, .game-pick main.pick-form-main > div .cell label input[name=game_pick]:checked + .pick.LEFT, .game-pick main.pick-form-main > div .cell label input[name=game_pick]:checked + .pick.THREE, .game-pick main.pick-form-main > div .cell label input[name=game_pick]:checked + .pick.LEFT_THREE_EVEN, .game-pick main.pick-form-main > div .cell label input[name=game_pick]:checked + .pick.LEFT_FOUR_ODD {
  color: white;
  background-color: var(--odd);
}
.game-pick main.pick-form-main > div .cell label input[name=game_pick]:checked + .pick.EVEN, .game-pick main.pick-form-main > div .cell label input[name=game_pick]:checked + .pick.OVER, .game-pick main.pick-form-main > div .cell label input[name=game_pick]:checked + .pick.RIGHT, .game-pick main.pick-form-main > div .cell label input[name=game_pick]:checked + .pick.FOUR, .game-pick main.pick-form-main > div .cell label input[name=game_pick]:checked + .pick.RIGHT_THREE_ODD, .game-pick main.pick-form-main > div .cell label input[name=game_pick]:checked + .pick.RIGHT_FOUR_EVEN {
  color: white;
  background-color: var(--even);
}
.game-pick main.pick-form-main > div .cell label input[name=game_pick]:checked + .pick.SMALL {
  color: white;
  background-color: var(--small);
}
.game-pick main.pick-form-main > div .cell label input[name=game_pick]:checked + .pick.MEDIUM {
  color: white;
  background-color: var(--medium);
}
.game-pick main.pick-form-main > div .cell label input[name=game_pick]:checked + .pick.LARGE {
  color: white;
  background-color: var(--large);
}


