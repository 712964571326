@import "mixin";

// color
@each $color, $code in $color-sheet {
  .--color-#{$color}   { color: var(--#{$color}) !important; }
  .--bg-#{$color}      { background-color: var(--#{$color}) !important; }
}
@for $i from 0 through $color-sheet-num {
  .--color-#{$i} { color: var(--color-#{$i}) !important; }
  .--bg-#{$i}    { background-color: var(--color-#{$i}) !important; }
}
@each $i in $color-sheet-hex {
  .--color-#{$i} { color: var(--color-#{$i}) !important; }
  .--bg-#{$i}    { background-color: var(--color-#{$i}) !important; }
}
@for $i from 1 through 100 {
  .--radius-#{$i} { border-radius: #{$i}px; }
}

i[class*="fa-"],
i[class*="fas-"] {
  color: inherit;
}


// display
.d-block   { display: block }
.d-inblock { display: inline-block }

.d-flex   { @include d-flex; }
.d-inflex { @include d-inflex }

.d-grid   { @include d-grid; }
.d-ingrid { @include d-ingrid }

.--fl-content-between { justify-content: space-between; }
.--fl-content-around  { justify-content: space-around; }
.--fl-content-center  { justify-content: center; }
.--fl-content-stretch { justify-content: stretch; }
.--fl-content-start   { justify-content: flex-start; }
.--fl-content-end     { justify-content: flex-end; }

.--fl-items-stretch { align-items: stretch; }
.--fl-items-center  { align-items: center; }
.--fl-items-start   { align-items: flex-start; }
.--fl-items-end     { align-items: flex-end; }

$fl-contents: (
        "between": space-between
, "around":  space-around
, "center":  center
, "stretch":  stretch
, "start":  flex-start
, "end":  flex-end
);
$fl-items: (
        "stretch"  : stretch
, "center" : center
, "start"  : flex-start
, "end"    : flex-end
);
@each $ck, $content in $fl-contents {
  @each $ik, $items in $fl-items {
    .--fl-#{$ck}-#{$ik} {
      justify-content: #{$content};
      align-items: #{$items};
    }
  }
}

.--fl-direction-col { flex-direction: column }
.--fl-direction-row { flex-direction: row }

.--fl-wrap        { flex-wrap: wrap }
.--fl-revers-wrap { flex-wrap: wrap-reverse }

.--fl-wrap {
  @for $col from 1 through $col-range {
    &.--fl-wrap-col-#{$col} {
      & > * {
        width: calc(100% / #{$col});
      }
    }
  }
}

@for $col from 1 through $col-range {
  .--grid-col-#{$col} {
    grid-template-columns: repeat(#{$col}, 1fr);
  }
}
@include mobile {
  @for $col from 1 through $col-range {
    .--grid-col-#{$col}-mo {
      grid-template-columns: repeat(#{$col}, 1fr) !important;
    }
  }
}

@for $i from 1 through $gap-range {
  .--gap-#{$i} { grid-gap: calc(#{$i}rem / 10); }
}

.align-left   { text-align: left   !important; }
.align-center { text-align: center !important; }
.align-right  { text-align: right  !important; }

.pre-line { white-space: pre-line; }

.--bold    { font-weight: bold !important }
.--normal  { font-weight: normal !important }
.--lighter { font-weight: lighter !important }
.--relative { position: relative; }
.--pointer   { cursor: pointer; }
.--underline { text-decoration: underline }
.--hidden    { display: none !important; }
.--inherit {
  @include inherit;
}
.--ellipsis {
  @include ellipsis
}
.--disabled {
  user-select: none;
  pointer-events: none;
}
label {
  * { user-select: none; }
}

.form-checkbox {
  color: var(--theme);
  pointer-events: none;
  input[type=checkbox] {
    outline: none;
    -webkit-appearance: none;
  }
}
.form-radio {
  @include d-center;
}
::placeholder {
  color: #9394AD;
}
input[type=number] {
  // 화살표 제거
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.link {
  color: #0088FF !important;
}
.btn {
  @each $color, $code in $color-sheet {
    &.btn-#{$color} {
      @include btn-set($color);
      @include btn-hover($color);
      @include btn-active;
    }
    &.btn-outline-#{$color} {
      @include btn-outline-set($color);
      @include btn-outline-hover($color);
      @include btn-outline-active;
    }
  }

  @for $i from 1 through 1000 {
    $k: $i * 0.1;
    &.--btn-ht-#{$i} { height: #{$k}rem; }
    &.--btn-wt-#{$i} { width: #{$k}rem; }
  }
  cursor: pointer;
}

@for $i from 1 through $padding-margin-range {
  $k: $i * 5;
  $v: $k / 10;
  .--margin-#{$k} { margin : #{$v}rem;   }
  .--mr-#{$k} { margin-right : #{$v}rem; }
  .--ml-#{$k} { margin-left  : #{$v}rem; }
  .--mt-#{$k} { margin-top   : #{$v}rem; }
  .--mb-#{$k} { margin-bottom: #{$v}rem; }

  .--padding-#{$k} { padding : #{$v}rem;   }
  .--pr-#{$k} { padding-right : #{$v}rem; }
  .--pl-#{$k} { padding-left  : #{$v}rem; }
  .--pt-#{$k} { padding-top   : #{$v}rem; }
  .--pb-#{$k} { padding-bottom: #{$v}rem; }
}
.--p-none  { padding: 0 !important }
.--m-none  { margin: 0 !important }

.--m-auto  { margin: 0 auto; }
.--mt-auto { margin-top   : auto; }
.--mb-auto { margin-bottom: auto; }
.--ml-auto { margin-left  : auto; }
.--mr-auto { margin-right : auto; }


@for $i from 9 through $font-size-range {
  $k: ($i / 10);
  .--font-#{$i} { font-size: #{$k}rem; }
  .--line-#{$i} { line-height: #{$k}rem; }
}


@for $i from 1 through $viewport-range {
  .--vht-#{$i} { height: #{$i}vh };
  .--vwt-#{$i} { width: #{$i}vw };
}
@for $i from 1 through $width-height-range {
  $k: $i * 5 * 0.1;
  $n: $i * 5;
  .--ht-#{$n} { height: #{$k}rem };
  .--wt-#{$n} { width: #{$k}rem };
}


@for $i from 1 through $border-range {
  .--br-top-#{$i}    { border-top:    #{$i}px solid; }
  .--br-bottom-#{$i} { border-bottom: #{$i}px solid; }
  .--br-left-#{$i}   { border-left:   #{$i}px solid; }
  .--br-right-#{$i}  { border-right:  #{$i}px solid; }
  .--br-full-#{$i}   { border:  #{$i}px solid; }
}
.--br-none { border: none !important }
@for $i from 1 through $border-range {
  .--br-size-#{$i} { border-width: #{$i}px; }
}

