@import "variables";

@mixin mobile {
  @media (max-width: #{$break-point-desktop - 1px}) {
    @content;
  }
}

@mixin iframe-mobile {
  @media (max-width: 839px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$break-point-desktop}) {
    @content;
  }
}
@mixin hover-effect {
  color: var(--theme2);
  transition-duration: .1s;
}

// global container set
@mixin peeks-container {
  max-width: 120rem;
  width: 120rem;
  margin: 0 auto;
}
@mixin peeks-row {
  @include grid-set;
  flex-wrap: inherit;
  grid-auto-flow: column;
}
@mixin layout-border($top:0, $right:0, $bottom:0, $left:0) {
  @if($top == 0 and $right == 0 and $bottom == 0 and $left == 0){
    $top: 1;
    $right: 1;
    $bottom: 1;
    $left: 1;
  }
  border-top: #{$top}px solid var(--layout-border-color);
  border-right: #{$right}px solid var(--layout-border-color);
  border-bottom: #{$bottom}px solid var(--layout-border-color);
  border-left: #{$left}px solid var(--layout-border-color);
}
@mixin spacing($px) {
  letter-spacing: calc(#{$px}rem * .1);
}
@mixin absolute($top:0, $left:0, $index:1) {
  position: absolute;
  top: #{$top};
  left: #{$left};

  z-index: $index;
}
@mixin absolute-right($top:0, $right:0, $index:1) {
  position: absolute;
  top: #{$top};
  right: #{$right};

  z-index: $index;
}
@mixin rect($width: auto, $height: auto){
  @if($width != auto){
    width: #{$width * .1}rem;
  }
  @if($height != auto){
    height: #{$height * .1}rem;
  }
}
@mixin font($size: 12, $weight: revert){
  $replace-rem: $size / 10;
  font-size: #{$replace-rem}rem;
  font-weight: $weight;
}
@mixin numbers-ball {
  @include d-center;
  @include rect(20, 20);
  @include font(12);
  color: #FFFFFF;
  text-shadow: .1rem .1rem .1rem #404040;
  border: 1px solid;
  border-radius: 50%;

  @for $i from 1 through 7 {
    &.num-#{$i} {
      background-color: #FFC400;
      border-color: #FFF347;
    }
  }
  @for $i from 8 through 14 {
    &.num-#{$i} {
      background-color: #006FFF;
      border-color: #92A4FF;
    }
  }
  @for $i from 15 through 21 {
    &.num-#{$i} {
      background-color: #E50000;
      border-color: #FF6868;
    }
  }
  @for $i from 22 through 28 {
    &.num-#{$i} {
      background-color: #00D907;
      border-color: #87FF7E;
    }
  }
  &.powerball {
    background-color: #313131;
    border-color: #AFAFAF;
  }
}
@mixin box-shadow($x: 0, $y: 0, $b: 0, $shadow: #00000060){
  box-shadow: #{$x *0.1}rem #{$y *0.1}rem #{$b *0.1}rem $shadow;
}
@mixin text-shadow($x: 0, $y: 0, $b: 0, $shadow: #000000){
  text-shadow: #{$x *0.1}rem #{$y *0.1}rem #{$b *0.1}rem $shadow;
}
@mixin ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@mixin scroll-bar($bar-color : #D3D3D3, $margin-color :transparent, $track-color :#F3F3F3) {
  &::-webkit-scrollbar {
    display: block !important;
    width: 6px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: #{$track-color};
  }

  &::-webkit-scrollbar-thumb {
    border-top: 5px solid #{$margin-color};
    border-bottom: 5px solid #{$margin-color};
    border-radius: revert;
    background-image: linear-gradient(90deg,
            transparent 0%
    , transparent 25%
    , #{$bar-color} 25%
    , #{$bar-color} 75%
    , transparent 75%
    , transparent 100%);
  }
}
@mixin inherit {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  text-align: inherit;
}

@mixin flex-set($content: flex-start, $items: center){
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;

  justify-content: $content;
  align-items: $items;
}
@mixin in-flex-set($content: flex-start, $items: center){
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;

  justify-content: $content;
  align-items: $items;
}
@mixin d-center($inline: null) {
  @if($inline){
    @include in-flex-set(center, center)
  }@else{
    @include flex-set(center, center)
  }
}
@mixin d-flex {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: -webkit-flex;
}
@mixin d-inflex {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
}

@mixin hover-tab {
  &:hover {
    background-color: var(--theme-3);
  }
}


@mixin grid-set($content: flex-start, $items: center){
  display: grid;
  display: -ms-grid;
  display: -moz-grid;

  justify-content: $content;
  align-items: $items;
}
@mixin in-grid-set($content: flex-start, $items: center){
  display: inline-grid;
  display: -ms-inline-grid;
  display: -moz-inline-grid;

  justify-content: $content;
  align-items: $items;
}
@mixin d-grid {
  display: grid;
  display: -ms-grid;
  display: -moz-grid;
}
@mixin d-ingrid {
  display: inline-grid;
  display: -ms-inline-grid;
  display: -moz-inline-grid;
}

@mixin btn-set($color) {
  @include in-flex-set(center, center);
  padding: .3rem .8rem;
  background-color: var(--#{$color});
  border: 1px solid var(--#{$color});
  border-radius: 0rem;

  @if( $color == "disabled" ){
    cursor: default !important;
    pointer-events: none !important;
  }@else{
    cursor: pointer;
  }

  user-select: none;

  @if ( $color == "white"
    or $color == "background")
  {
    color: var(--color-0);
  }
  @else if( $color == 'default' )
  {
    color: #555555;
    border-color: #D6D6D6;
  }
  @else if( $color == 'theme' )
  {
    color: #555555;
  }
  @else if( $color == 'danger' )
  {
    color: white;
  }
}
@mixin btn-hover($color) {
  transition-duration: .2s;
  &:hover {
    background-color: transparent;

    @if ( $color == "white"
    or $color == "default"
    or $color == "background")
    {
      color: var(--color-0);
    }
    @else {
      color: var(--#{$color});
    }
  }
}
@mixin btn-active {
  &:active {
    transition-duration: .1s;
    transform: translateY(.2rem);
  }
}

@mixin btn-outline-set($color) {
  @include in-flex-set(center, center);
  padding: .3rem .8rem;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid var(--#{$color});
  border-radius: 0rem;
  cursor: pointer;

  user-select: none;

  @if ( $color == "white"
    or $color == "disabled"
    or $color == "default"
    or $color == "background")
  {
    color: var(--color-0);
  }
  @else {
    color: var(--#{$color});
  }
}
@mixin btn-outline-hover($color) {
  transition-duration: .2s;
  &:hover {
    background-color: var(--#{$color});
    color: var(--color-F);
  }
}
@mixin btn-outline-active {
  &:active {
    transform: translateY(.2rem);
  }
}