/* font */
@font-face {
  font-family: 'NanumGothic';
  font-style: normal;
  font-weight: 400;
  src: url(./font/NanumGothic.otf) format('opentype');
}

@font-face {
  font-family: 'NanumGothic';
  font-style: normal;
  font-weight: 700;
  src: url(./font/NanumGothicBold.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: 400;
  src: url(./font/NotoSansCJKkr-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: 700;
  src: url(./font/NotoSansCJKkr-Bold.otf) format('opentype');
}


@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: url(./font/Oswald-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: url(./font/Oswald-Bold.ttf) format('truetype');
}

@mixin Iurl($fileName) {
  $urls: "./svg/" + $fileName;
  background-image: url($urls)
}
i.--icon {
  display: inline-block;

  width: 1em;
  height: 1em;

  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;

  &.star-gold    { @include Iurl("star_gold.svg") }
  &.star-silver  { @include Iurl("star_silver.svg") }
  &.point-gold   { @include Iurl("point_gold.svg") }
  &.point-silver { @include Iurl("point_silver.svg") }
  &.cash         { @include Iurl("cash.svg") }

  &.star         { @include Iurl("i-star.svg") }
  &.friend       { @include Iurl("i-friend.svg") }
  &.onechat      { @include Iurl("i-onechat.svg") }
  &.userinfo     { @include Iurl("i-userinfo.svg") }

  &.message      { @include Iurl("Icon ionic-ios-mail.svg") }

  &.close        { @include Iurl("close.svg") }


  &.header-i {
    &.sports    { @include Iurl("tab header/Icon ionic-ios-basketball-2.svg") }
    &.minigame  { @include Iurl("tab header/Icon ionic-ios-basketball-1.svg") }
    &.community { @include Iurl("tab header/Icon ionic-ios-basketball.svg") }
    &.rank      { @include Iurl("tab header/Icon ionic-ios-rank.svg") }
    &.chat      { @include Iurl("tab header/Icon ionic-ios-chatbox.svg") }
    &.market    { @include Iurl("tab header/Icon ionic-ios-cart.svg") }
    &.home      { @include Iurl("tab header/Icon ionic-ios-home.svg") }
    &.customer_support { @include Iurl("tab header/Icon ionic-md-help-circle.svg") }
    &.my_page   { @include Iurl("tab header/Icon ionic-md-help-circle-1.svg") }
  }
}

@font-face {
  font-family: 'icomoon';
  src:  url('./font/icomoon.eot?dpqw3b');
  src:  url('./font/icomoon.eot?dpqw3b#iefix') format('embedded-opentype'),
  url('./font/icomoon.ttf?dpqw3b') format('truetype'),
  url('./font/icomoon.woff?dpqw3b') format('woff'),
  url('./font/icomoon.svg?dpqw3b#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i[class*="--i"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  color: var(--base-font-color);

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

i.--icon {
  //&.star:before { content: "\e92a"; color: #DDDDDD; }
  &.star.on:before { content: "\e92a"; color: var(--theme) }
  &.fold:before { content: "\e925"; }
  &.calendar:before { content: "\e926"; }
  &.user:before { content: "\e927"; }
  &.paste:before { content: "\e928"; }
  &.write:before { content: "\e929"; }
  &.arrow-left:before { content: "\e900"; }
  &.arrow-right:before { content: "\e901"; }
  &.base-000:before { content: "\e902"; }
  &.base-001:before { content: "\e903"; }
  &.base-011:before { content: "\e904"; }
  &.base-111:before { content: "\e905"; }
  &.base-010:before { content: "\e906"; }
  &.base-100:before { content: "\e907"; }
  &.base-101:before { content: "\e908"; }
  &.base-110:before { content: "\e909"; }
  &.arrow-down:before { content: "\e90a"; }
  &.arrow-bold-left:before { content: "\e910"; }
  &.arrow-bold-right:before { content: "\e911"; }

  &.lo {
    display: inline-block;

    width: 1em; height: 1em;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.chat-bubble {
      @include Iurl("Icon ionic-ios-chatbubbles.svg");
    }
    &.chat-bubble.active {
      @include Iurl("Icon ionic-ios-chatbubbles active.svg");
    }
    &.radio {
      @include Iurl("radio.svg");
    }
    &.radio.active {
      @include Iurl("radio active.svg");
    }
  }
}

i.--i {
  &.sports {
    &.soccer:before { content: "\e92b"; }
    &.baseball:before { content: "\e90b"; }
    &.basketball:before { content: "\e90c"; }
    &.volleyball:before { content: "\e90d"; }
    &.hockey:before { content: "\e90e"; }
    &.football:before { content: "\e90f"; }
    &.UFC:before { content: "\e912"; }
    &.Golf:before { content: "\e913"; }
    &.Dart:before { content: "\e914"; }
    &.Rugby:before { content: "\e915"; }
    &.Motor:before { content: "\e916"; }
    &.Boxing:before { content: "\e917"; }
    &.Beach.Vollyball:before { content: "\e918"; }
    &.Beachsoccer:before { content: "\e919"; }
    &.Cycling:before { content: "\e91a"; }
    &.Waterball:before { content: "\e91b"; }
    &.Snooker:before { content: "\e91c"; }
    &.Badminton:before { content: "\e91d"; }
    &.Cricket:before { content: "\e91e"; }
    &.Tabletennis:before { content: "\e91f"; }
    &.Tennis:before { content: "\e920"; }
    &.Footbal:before { content: "\e921"; }
    &.Floorball:before { content: "\e922"; }
    &.Handball:before { content: "\e923"; }
    &.Rugbyunion:before { content: "\e924"; }
  }
}

i.--rank {
  display: inline-block;

  width: 1em; height: 1em;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @for $i from 1 through 20 {
    &.level-#{$i} { @include Iurl("rank/Rank_ ("+#{$i}+").svg")}
  }
  &.admin-1 { @include  Iurl("rank/Rank_master.svg")}
  &.admin-2 { @include  Iurl("rank/Rank_master2.svg")}
}














