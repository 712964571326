@import "mixin";

html,
body {
  padding: 0;
  margin: 0;
  font-size: 10px;
  font-family: Roboto, "Noto Sans CJK KR", "Oswald", sans-serif;
  box-sizing: border-box;

  //word-break: break-all;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;

  @include inherit;
  font-size: 1.2rem;
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}
ul, li {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
strong, b {
  font-weight: bold;
}
input:not([type=checkbox], [type=radio]), button {
  -webkit-appearance: none;
  border: none;
  background: none;
}
button {
  cursor: pointer;
}
table {
  //table-layout: fixed;
  border-collapse: collapse;
}
p {
  margin: 0;
}
input {
  outline: none;
}
table {
  border-collapse: collapse;
}