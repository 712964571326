@import "mixin";

:root {
  --base-bg: #{map-get($color-sheet, "white")};
  --base-font: #{map-get($color-sheet, "default-font")};

  --shadow-inset  : #FFFFFF57;
  --shadow-outline: #0000009C;

  --bs-body-color: #555555;

  @each $color, $code in $color-sheet {
    --#{$color} : #{$code}
  }
  @each $color, $code in $layout-color-sheet {
    --#{$color} : #{$code}
  }

  $color-sheet-num: 9;
  @for $i from 0 through $color-sheet-num {
    $hash: "#";
    --color-#{$i}: #{$hash}#{$i}#{$i}#{$i}#{$i}#{$i}#{$i};
  }

  $color-sheet-hex: (A, B, C, D, E, F);
  @each $i in $color-sheet-hex {
    $hash: "#";
    --color-#{$i}: #{$hash}#{$i}#{$i}#{$i}#{$i}#{$i}#{$i};
  }

  --header-z :   #{$Header-fixed-z-index};
  --modal-z  :   #{$Modal-z-index};
  --alert-z  :   #{$alert-z-index};
}