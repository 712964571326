@import "variables";
@import "mixin";
@import "font";
@import "theme";
@import "public";
@import "common";

:root {
  scroll-behavior: unset;
}

img {
  max-width: 100%;
  width: auto;
  max-height: 100%;
}

a:hover {
  @include hover-effect;
}

$margin-layout: 1rem;
html {
  background-color: var(--base-bg);
}

* {
  word-break: normal;
  color: var(--default-font);
  background-color: transparent;
}

svg path {
  color: inherit;
}

body {
  background-color: var(--base-bg);
}

.peeks-container {
  @include peeks-container;

  .peeks-row {
    @include peeks-row;
  }
}

.__body {
  //width: 192rem;
}

#gl__header {
  .gl__header_info {
    width: 100%;
    border-bottom: 2px solid var(--theme);
    padding: 0 1rem;

    .logo {
      @include in-flex-set;
      height: 7rem;

      img {
        @include rect(154, 40);
      }
    }

    > div {
      &:nth-child(1) {
        @include flex-set;

        .notice-landing {
          margin-left: 8rem;
          margin-top: 1rem;
        }
      }

      &:nth-child(2) {
        @include flex-set(space-between, flex-end);
        height: 4rem;
        padding-bottom: 1rem;

        .gl__navigation {
          @include peeks-row;
          grid-gap: 3rem;
          @include font(15);
          font-weight: bold;

          a {
            @include inherit;
            letter-spacing: -.12rem;

            &:hover {
              @include hover-effect
            }
          }
        }
      }
    }
  }
}

.notice-landing {
  > div {
    @include flex-set;
    grid-gap: 1rem;
    padding: 0 .6rem;
    cursor: pointer;

    .type {
      @include btn-set(danger);
      padding: 0 .6rem;
      @include font(10);
      border-radius: .2rem;
    }

    .title {
      @include font(12);
      transition-duration: .1s;
    }

    &:hover {
      .title {
        @include hover-effect
      }
    }
  }
}

#gl__user {
  @include flex-set(flex-start, flex-end);
  flex-direction: column;
  margin-left: auto;

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  .user {
    &:first-child {
      @include flex-set;
      grid-gap: 1rem;
    }
  }

  .user-goods {
    @include flex-set;
    grid-gap: 1rem;
    margin-top: .3rem;

    > div {
      @include flex-set;
      grid-gap: .4rem;

      i {
        font-size: 1.6rem;
      }

      a {
        font-size: 1.2rem;
        letter-spacing: -.072rem
      }
    }
  }

  .login {
    @include flex-set;
    grid-gap: 2rem;

    > div {
      position: relative;
      @include flex-set;
      grid-gap: .8rem;

      .fa-power-off {
        @include font(14)
      }

      &:after {
        content: "";
        @include absolute-right(calc(50% - .4rem / 2), -1.2rem, 1);
        @include rect(4, 4);
        background-color: #CED1D7;
      }
    }
  }
}

#gl__footer {
  @include layout-border(1, 0, 0, 0);
  border-color: var(--theme);
  margin-top: 4rem;
  padding: 4rem 1rem 4.8rem;

  > div {
    max-width: 46rem;
    margin: 0 auto;

    * {
      @include font(11);
      color: var(--footer-font);
    }

    header {
      @include flex-set;
      grid-gap: 2rem;
    }

    .policy {
      margin-top: 2.6rem;
      @include flex-set;
      @include mobile {
        flex-wrap: wrap;
      }
      grid-gap: 2rem;

      li {
        color: var(--default-font);
        @include desktop {
          @include font(12, bold);
        }
        @include mobile {
          @include font(10, bold);
        }

        a {
          @include inherit;
        }
      }
    }
  }
}

#divide-aside-main {
  @include d-flex;
  margin-top: 2rem;
  grid-gap: #{$margin-layout};

  #chat__layout {
    width: 35rem;
    max-height: 60.4rem;
  }

  #main__container {
    width: 84rem;
  }
}

#chat__layout {
  @include d-grid;
  @include desktop {
    grid-template-columns: .3rem 34.7rem;
  }
  @include mobile {
    grid-template-columns: .3rem calc(100vw - .3rem);
  }

  > .super-chat-room {
    @include flex-set;
    flex-direction: column;
    grid-gap: .2rem;

    padding: .2rem 0;

    background-color: var(--super-chat-room-background-color);

    .chat-room-items {
      @include d-center;
      width: 4.7rem;
      height: 4.7rem;
      padding: .2rem;
      //border-radius: 50%;
      background-size: cover;
      background-repeat: no-repeat;

      img {
        width: 100%;
        height: 100%;
        //opacity: 0;
      }
    }
  }

  > #chat-layout {
    $height-tab: 3.7rem;
    $height-input: 10rem;
    $height-chat-area: calc(60.2rem - #{$height-tab} - #{$height-input});
    @include layout-border(1, 1, 0, 0);
    background-color: white;

    ul.chat-tab {
      @include grid-set;
      grid-template-columns: repeat(3, 1fr);
      height: #{$height-tab};
      @include layout-border(0, 0, 1, 0);

      > li {
        @include d-center;
        height: calc(#{$height-tab} + .1rem);
        @include font(12);
        grid-gap: .5rem;
        @include layout-border(0, 0, 1, 0);

        cursor: pointer;
        user-select: none;

        &.select {
          @include layout-border(0, 0, 2, 0);
          color: var(--select-chat-tab);
          border-bottom-color: var(--select-chat-tab);

          [class*="fa-"] {
            color: var(--select-chat-tab) !important;
          }
        }
      }
    }

    main#chat-area {
      position: relative;
      @include flex-set;
      @include layout-border(10, 0, 10, 0);
      border-color: white;
      @include desktop {
        height: calc(#{$height-chat-area} + .1rem);
      }
      @include mobile {
        height: calc(100vh - 4.2rem - 10rem);
      }
      padding: 0 .2rem 0 1rem;
      $chat-scroll-top-margin: 1rem;
      $chat-scroll-bot-margin: 1rem;

      > ul {
        position: relative;
        @include d-flex;
        flex-direction: column;
        grid-gap: .7rem;
        width: 100%;
        height: 100%;
        padding: #{$chat-scroll-top-margin} 0 #{$chat-scroll-bot-margin};
        @include scroll-bar;
        overflow-y: auto;

        > li {
          &.chat {
            //@include grid-set(flex-start, flex-start);
            //grid-auto-flow: column;
            //grid-gap: .5rem;
          }

          &.info {
            @include d-center;
            margin: 1rem 0;
          }

          padding-right: .1rem;
          line-height: 1.7rem;

          > span {
            &:first-child {
              letter-spacing: -0.12rem;
              color: var(--chat-user-strong);
              @include font(13, bold);
              cursor: pointer;
              i.--rank {
                margin-bottom: -.5rem;
              }
            }
            &:last-child {
              margin-left: .5rem;
              color: var(--chat-msg-p);
              @include font(12);
              word-break: break-all;
            }
          }
        }
      }
    }

    > footer {
      height: #{$height-input};
      padding: 1rem 2rem 0 1rem;

      @include layout-border(1, 0, 1, 0);
      //background-color: var(--super-chat-room-background-color2);
      * {
        color: var(--chat-input-default);
      }

      form {
        height: 100%;

        > p {
          color: var(--default-font);
          @include font(13);
          font-weight: bold
        }

        > input {
          width: 100%;
          height: 3rem;
          color: black;
          border-bottom: 1px solid var(--black);

          &::-webkit-input-placeholder {
            color: var(--chat-input-default);
          }
        }

        > div {
          @include d-flex;
          height: 4rem;

          .fa-face-laugh {
            @include font(23);
          }

          > div {
            @include d-flex;
            grid-gap: 1.4rem;

            .text-count {
              @include font(13);
              letter-spacing: .013rem;
            }

            .fa-paper-plane {
              @include font(15);
            }
          }
        }
      }
    }
  }
}

[class*="-layout"] + [class*="-layout"] {
  header.layout-header {
    margin-top: 1rem;
    padding-top: 2.5rem;
    box-sizing: content-box;
    border-top: 2px solid var(--theme);
  }
}

header.layout-header {
  @include flex-set;
  height: 3.2rem;
  padding: 0 .6rem;

  i.header-i {
    @include font(24)
  }

  strong {
    margin-left: .7rem;
    color: var(--theme2);
    @include font(14, bold);
    text-shadow: .1rem .1rem .3rem #4444442E;
  }

  span {
    margin-left: 1rem;
    color: #9394AD;
    @include font(12, bold);
  }
}

#sports-tab {
  @include flex-set;
  //@include layout-border(0,0,1,0);
  padding: 0 1rem;
  margin-top: $margin-layout;

  > button {
    @include d-center;
    width: 8.3rem;
    height: 2.7rem;
    border-radius: .4rem;
    grid-gap: .4rem;
    margin-left: auto;
    color: var(--black);
    @include font(12, bold);

    > i {
      margin-top: -.3rem;
    }
  }

  ul {
    @include flex-set;
    grid-gap: 2rem;
    height: 3.8rem;

    li {
      @include flex-set;
      height: calc(100% + .2rem);
      padding: 0 .5rem;
      grid-gap: .5rem;
      font-family: 'Noto Sans CJK KR';
      border-bottom: 2px solid transparent;

      &.select {
        border-bottom-color: var(--select-sports-tab);
      }

      strong {
        @include font(13);
      }

      span {
        @include font(13);
        opacity: .75;
      }
    }
  }

  position: relative;

}

.sports-more {
  position: absolute;

  top: 3.8rem;
  left: -.1rem;

  width: calc(100% + .2rem);

  background-color: var(--white);
  @include layout-border;
  box-shadow: 1px 1px 6px var(--black) 36;

  overflow: hidden;

  animation-duration: .2s;
  opacity: 0;

  &.on {
    opacity: 1;
    animation: sports-more-slide-down .2s forwards;
  }

  &.off {
    opacity: 1;
    animation: sports-more-slide-up .2s forwards;
  }
}

@keyframes sports-more-slide-down {
  from {
    height: 0rem
  }
  to {
    height: 20rem
  }
}

@keyframes sports-more-slide-up {
  from {
    height: 20rem
  }
  to {
    height: 0rem
  }
}

#sports-board {
  @include grid-set(space-between, flex-start);
  grid-template-columns: 1fr 1fr;
  grid-gap: .6rem;

  margin-top: #{$Layout-global-gap};
  background-color: var(--gray);

  table {
    td {
      height: 2.4rem;
      padding: 0 .4rem;

      color: #444444;
      text-align: center;

      border: 1px solid #D6D6D6;
      background-color: var(--white);

      &.bookmark {
        width: 1.8rem;
      }

      &.match {
        width: 4.2rem;
      }

      &.team {
        width: 20rem;
      }

      &.score {
        width: 3rem;
        font-family: 'Oswald';

        &:nth-child(2) {
          font-weight: bold;
        }
      }
    }
  }
}

#sports-board + #minigame-tab,
.sports-table + #minigame-tab {
  margin-top: #{$margin-layout};
}

#minigame-tab {
  //@include layout-border(0,0,1,0);
  height: 4rem;
  margin-bottom: 1rem;

  > ul {
    @include flex-set;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    grid-gap: 3rem;
    height: 100%;

    > li {
      @include d-center;
      min-width: 8rem;
      height: calc(100% + .2rem);

      color: var(--minigame-tab-default);
      border-bottom: 2px solid transparent;

      &.select {
        color: var(--minigame-tab-select);
        border-bottom-color: var(--theme);
      }

      &:hover {
        @include hover-effect
      }
    }
  }
}

#minigame-contents {
  @include d-flex;
  grid-gap: .6rem;

  height: 25.5rem;

  margin-top: #{$margin-layout};
  padding: 1.2rem .8rem;

  @include layout-border;

  > div {
    height: 23.2rem;
    padding: .1rem;
    @include layout-border;
    border-color: #A8831D;
    background-color: var(--minigame-pick-contents-bg);

    > .inner {
      height: 23rem;
      @include layout-border;
      border-color: #5B4300;
      background-color: var(--minigame-pick-contents-inner-bg);
      padding: .3rem .5rem;
    }

    header {
      color: white;
      @include font(16);
      text-shadow: 1px 1px 2px var(--black);
    }


  }
}

.user-pick {
  width: 41.8rem;
  padding: .5rem 1.6rem;

  main {
    @include d-flex;
    flex-direction: column;
    grid-gap: 1rem;
    margin-top: 1.2rem;

    color: white;
    text-shadow: 1px 1px 3px var(--black) 80;

    .pick-bar {
      position: relative;
      width: 38.6rem;
      height: 2.6rem;
      margin: 0 auto;

      .pick-item {
        position: absolute;
        @include rect(72, 30);

        top: calc(50% - (2.8rem / 2));
        left: calc(50% - (7.2rem / 2));
        z-index: 2;
        @include d-center;

        color: white;
        @include font(13);
        letter-spacing: -.086rem;
        text-shadow: 1px 1px 3px var(--black) 80;

        box-shadow: 1px 1px 2px var(--black) 69;
        @include layout-border;

        &.--bg-gb-odd {
          border-color: #00D7FF;
        }

        &.--bg-gb-even {
          border-color: #FF0000;
        }
      }

      .pick-count {
        @include absolute(0, calc(50% - (33.5rem / 2)), 2);
        @include flex-set(space-between, center);
        width: 33.5rem;
        height: 100%;
        padding: 0 .5rem;

        > p {
          color: white;
          letter-spacing: -.07rem;
          text-shadow: 1px 1px 3px var(--black) 80;
        }
      }

      .absolute-bar {
        position: absolute;
        width: 33.5rem;
        height: 100%;
        @include absolute(0, calc(50% - (33.5rem / 2)), 1);
        z-index: 1;
        @include d-grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 7rem;

        background-color: black;

        > div {
          $olt-top: .1rem;
          $olt-left: -3rem;

          > span {
            @include flex-set;
            @include rect(28, 28);
            @include font(15);
            letter-spacing: -.087rem;
          }

          &:first-child {
            direction: rtl;
            border: 1px solid #00D7FF;

            > span {
              @include absolute($olt-top, $olt-left, 1);
            }
          }

          &:last-child {
            border: 1px solid #FF0000;

            > span {
              @include absolute-right($olt-top, $olt-left, 1);
            }
          }
        }

        &.o {
          > div:first-child > span {
            color: var(--gb-odd)
          }
        }

        &.l {
          > div:last-child > span {
            color: var(--gb-even)
          }
        }

        .odd-under,
        .even-over {
          height: 2.6rem;

          &.odd-under {
            background-color: var(--gb-odd);
          }

          &.even-over {
            background-color: var(--gb-even);
          }
        }
      }
    }

    .pick-sum {
      @include flex-set;
      width: 33.5rem;
      margin: 0 auto;
      grid-gap: 1.1rem;

      .sum-layer {
        @include flex-set;

        > div {
          &:first-child {
            @include d-center;
            @include rect(30, 30);
            @include layout-border;
            border-color: #FFFFFF80;

            color: #FFCC33;
            @include font(15, bold);
            text-shadow: .1rem .1rem .3rem var(--black) 80;
          }

          &:last-child {
            @include rect(75, 26);
            @include d-center;
            margin-left: -.1rem;
            @include layout-border;
            border-color: #FFFFFF80;
          }
        }
      }
    }
  }
}

.pick-result {
  width: 41.8rem;

  .inner {
    main {
      height: 19.5rem;
      padding-top: .5rem;
      padding-right: .6rem;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: .4rem;
      }

      &::-webkit-scrollbar-track-piece {
        background-color: var(--game-board-result-dark-color);
        box-shadow: inset .1rem .1rem 0rem #FFFFFF30;
        padding: 0 1rem;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--game-board-result-header-color);
      }

      ul {
        @include d-flex;
        flex-direction: column;
        grid-gap: .6rem;

        li.result-bar {
          height: 5rem;
          padding: .3rem .8rem;
          @include layout-border;
          border-color: #8F591B;
          background-color: #181208;

          > p {
            @include font(13);
            color: white;
            letter-spacing: -.055rem;
          }

          > div {
            @include flex-set(space-between, center);

            > .balls {
              @include flex-set;
              grid-gap: .5rem;

              > span {
                @include rect(20, 20);
                @include flex-set(space-between, center);
                @include numbers-ball;
              }
            }

            .ranges {
              @include flex-set(space-between, center);
              grid-gap: .4rem;

              .rect {
                @include d-center;
                @include rect(36, 20);
                @include font(12);
                color: white;
                text-shadow: .1rem .1rem .3rem var(--black) 80;
                border: .1rem solid #FFFFFF30;

                &.under, &.odd, &.medium {
                  background-color: var(--odd);
                }

                &.over, &.even, &.large {
                  background-color: var(--even);
                }

                &.small {
                  background-color: var(--small);
                }
              }
            }
          }
        }
      }
    }
  }
}

$normal-ball-range: 28;
$power-ball-range: 9;
i.balls {
  display: inline-block;

  width: 1em;
  height: 1em;

  background-color: transparent;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  @for $i from 1 through $normal-ball-range {
    &.normal-#{$i} {
      $ball-image-url: "./balls/SQS_BALL (" + #{$i} + ").png";
      background-image: url($ball-image-url);
    }
  }
  @for $i from 1 through $power-ball-range {
    &.power-#{$i} {
      $ball-image-url: "./balls/SQS_POWER_BALL (" + #{$i} + ").png";
      background-image: url($ball-image-url);
    }
  }
}

#powerball-cell-layout {
  max-width: 84rem;
  padding: 1rem;
  //background-color: #F3F3F3;
  @include mobile {
    //background-color: var(--black);
  }

  * {
    font-family: "Noto Sans CJK KR";
  }

  &.landing {
    .powerball-result-table,
    .powerball-result-table-mo {
      margin-top: 0;
      height: 28.5rem;
      overflow-y: scroll;

      @include layout-border;
      border-color: var(--theme);
      //border-radius: .8rem;
    }
  }

  .powerball-tab {
    @include grid-set;
    grid-template-columns: 1fr 1fr;
    padding: 2rem 0;
    > li {
      @include d-center;
      cursor: pointer;
      > span {
        padding: .6rem 2rem;
        @include layout-border(0,0,2,0);
        @include font(14, bold);
        border-color: transparent;
      }
      &.select {
        span {
          border-color: var(--theme);
        }
      }
    }
  }
  .mobile-open-user-pick {
    width: 80vw;
    max-width: 30rem;
    height: 3.8rem;
    margin: 0 auto 2rem;
    border-radius: 4rem;
    @include d-center;
    @include font(14, bold);
  }
  .user-pick-search-form {
    @include flex-set;
    grid-gap: 1rem;

    > select, input, button {
      @include rect(100, 34);
      background-color: var(--default);
      @include font(12);
      padding: 0 1rem;
      @include layout-border;
    }
  }
  .user-pick-table {
    margin-top: 2rem;
    table {
      width: 100%;

      tr {
        @include layout-border;
        th, td {
          height: 2.8rem;
          text-align: center;
        }
        td {
          &.pick-type {
            span {
              &.POWERBALL { color: var(--even) }
              &.BALLS     { color: var(--odd) }
            }
          }
          &.pick-value {
            span {
              @include d-center(inline);
              @include rect(45, 24);
              color: white;
              @include font(12, normal);
              &.ODD, &.UNDER, &.MEDIUM {
                background-color: var(--odd)
              }
              &.EVEN, &.OVER, &.LARGE {
                background-color: var(--even)
              }
              &.SMALL {
                background-color: var(--small)
              }
            }
          }
        }
      }
    }
  }
}

@mixin powerball-layout {
  @include layout-border ;
  border-color: var(--powerball-cell-border-color);
  border-radius: .8rem;
}

.landing-go-minigame-layout {
  @include d-inflex;
  padding: 1rem;
  color: var(--theme2);
  text-decoration: underline;
}

#public-powerball-cell {
  @include powerball-layout;
  @include mobile {
    background-color: var(--white);
  }

  .powerball-cell-tab {
    position: relative;
    @include flex-set(space-between, center);
    @include mobile {
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    padding: 0 .6rem;
    grid-gap: .6rem;

    @include layout-border(0, 0, 1, 0);
    border-color: var(--powerball-cell-border-color);
    overflow: hidden;

    .over-select-tab {
      content: "";
      display: block;
      position: absolute;
      bottom: - .4rem;
      left: .6rem;

      min-width: 13.2rem;
      height: .8rem;
      background-color: var(--powerball-cell-tab-underbar-color);
      border-radius: .4rem;

      transition-duration: .2s;
      @include mobile {
        display: none;
      }
    }

    > button {
      @include d-center;
      min-width: 13.2rem;
      height: 7rem;
      @include mobile {
        height: 4.5rem;
        @include font(12)
      }

      color: var(--powerball-cell-tab-font-color);
      @include font(16, bold);

      &.select {
        color: var(--black);
        @include mobile {
          height: 3rem;

          color: white;
          font-weight: bold;

          background-color: #0080FF;
          border-radius: .8rem;

        }
      }
    }
  }

  .additional {
    @include flex-set(space-between, stretch);
    grid-gap: 1rem;
    @include mobile {
      padding: 1.5rem 1rem;
      flex-wrap: wrap;
      grid-gap: .8rem;
    }
    padding: 0 2.5rem 0 1.7rem;
    @include layout-border(0, 0, 1, 0);
    border-color: var(--powerball-cell-border-color);

    > div {
      @include flex-set;
      flex-wrap: wrap;
      @include mobile {
        @include flex-set;
      }
      grid-gap: 1rem;

      > span.bar {
        // bar
        width: .2rem;
        height: 2rem;

        background-color: #DCDCDC;
        border-radius: .1rem;
      }

      div {
        @include d-center;
        grid-gap: .6rem;
        height: 6rem;
        @include mobile {
          height: 3rem;
        }

        span.round {
          // 동그라미
          @include d-center;
          @include rect(30, 30);

          color: white;
          @include font(14);
          white-space: nowrap;
          letter-spacing: -.12rem;
          text-indent: -.12rem;
          border-radius: 50%;
          &.LEFT_3_EVEN,
          &.RIGHT_3_ODD,
          &.LEFT_4_ODD,
          &.RIGHT_4_EVEN {
            @include rect(40, 40)
          }

          &.UNDER, // 언더
          &.MEDIUM, // 중
          &.LEFT, // 좌
          &.LINE3, // 3줄
          &.LEFT_4_ODD, // 좌4홀
          &.ODD {
            background-color: var(--odd)
          }
          &.RIGHT_3_ODD {
            background-color: var(--powerball-cell-right3-color)
          }


          // 홀
          &.OVER, // 오버
          &.LARGE, // 대
          &.RIGHT, // 우
          &.LINE4, // 4줄
          &.LEFT_3_EVEN, // 좌3짝
          &.EVEN {
            background-color: var(--even)
          }
          &.RIGHT_4_EVEN {
            background-color: var(--powerball-cell-right4-color)
          }

          // 짝
          &.SMALL {
            background-color: var(--small)
          }

          // 소
          &.Rjrdla {
            background-color: #767676
          }

          // 꺽임
          &.vhdekd {
            background-color: #A333C8
          }

          // 퐁당
          &.dusthr {
            background-color: #BE7200
          }

          // 연속
        }
      }
    }
  }

  .powerball-cell-table {
    padding: 1rem 1rem .8rem;

    .scroll-area {
      overflow-x: auto;
      padding-bottom: .4rem;

      &::-webkit-scrollbar {
        height: .8rem;
      }

      &::-webkit-scrollbar-track-piece {
        background-color: #E6E6E6;
        padding: 0 1rem;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: .5rem;
        background-color: #B8B8B8;
        //background-image: linear-gradient(0deg,
        //        transparent 0%
        //, transparent 25%
        //, #B8B8B8 25%
        //, #B8B8B8 75%
        //, transparent 75%
        //, transparent 100%);
      }

      direction: rtl;
      .scroll-layout {
        @include d-flex;

        .col {
          @include d-flex;
          flex-direction: column;

          > .in-layout {
            position: relative;
            @include d-flex;
            flex-direction: column-reverse;

            .down-bar {
              position: absolute;
              top: 0;
              left: .3rem;
              width: 2.4rem;
              background-color: red;
              border-radius: 0 0 1.2rem 1.2rem;
              z-index: 1;
            }

            span {
              border-right-color: var(--powerball-cell-border);

              &.in {
                color: white;
                @include font(10);
                z-index: 2;
              }
            }
          }

          span {
            @include d-center;
            width: 3.1rem;
            height: 3rem;

            border-right: 1px solid;
            user-select: none;

            &.head {
              background-color: var(--powerball-cell-title-bg);
              border-right-color: white !important;
            }

            &.length,
            &.round {
              color: #595A5A;
              background-color: var(--powerball-cell-title-bg);
              border-right-color: #D1D1D1;

              &.length {
                font-weight: bold;
                border-bottom: 1px solid #D1D1D1;
              }
            }
          }

          /* col */
          &.ODD, &.MEDIUM, &.UNDER, &.LEFT, &.LINE3 {
            .head {
              color: var(--powerball-cell-odd-color);
              border-bottom: 1px solid;
              border-color: var(--powerball-cell-odd-color);
            }

            .down-bar {
              background: transparent linear-gradient(180deg, #329aff 0%, #85caff 100%) 0% 0% no-repeat padding-box;
              box-shadow: 2px 0 1px #bfe2ff;
            }
          }

          &.EVEN, &.LARGE, &.OVER, &.RIGHT, &.LINE4 {
            .head {
              color: var(--powerball-cell-even-color);
              border-bottom: 1px solid;
              border-color: var(--powerball-cell-even-color);
            }

            .down-bar {
              background: transparent linear-gradient(180deg, #ff5656 0%, #ff9c9c 100%) 0% 0% no-repeat padding-box;
              box-shadow: 2px 0 1px #ffbbbb;
            }
          }

          &.SMALL {
            .head {
              color: var(--powerball-cell-small-color);
              border-bottom: 1px solid;
              border-color: var(--powerball-cell-small-color);
            }

            .down-bar {
              background: transparent linear-gradient(180deg, #00BC44 0%, #27F100 100%) 0% 0% no-repeat padding-box;
              box-shadow: 2px 0 1px #6efd51;
            }
          }

          &:nth-child(even) {
            span:not(:first-child) {
              border-right-color: var(--powerball-cell-border-bold);
            }
          }
        }
      }
    }

    &.ball, &.ladder {
      .col {
        span.in {
          .round {
            @include rect(28, 28);
            @include d-center;
            border-radius: 50%;
            color: white;
          }
        }
        &.ODD, &.MEDIUM, &.UNDER, &.LEFT, &.LINE3, &.LEFT_4_ODD {
          span.in {
            .round {
              background-color: var(--powerball-cell-odd-color);
            }
          }
        }
        &.RIGHT_3_ODD {
          span.in {
            .round {
              background-color: var(--powerball-cell-right3-color);
            }
          }
        }

        &.EVEN, &.LARGE, &.OVER, &.RIGHT, &.LINE4, &.LEFT_3_EVEN {
          span.in {
            .round {
              background-color: var(--powerball-cell-even-color);
            }
          }
        }
        &.RIGHT_4_EVEN {
          span.in {
            .round {
              background-color: var(--powerball-cell-right4-color);
            }
          }
        }

        &.SMALL {
          span.in {
            .round {
              background-color: var(--powerball-cell-small-color);
            }
          }
        }
      }
    }
  }
}

#powerball-result-graph {
  @include d-grid;
  @include desktop {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: .8rem;
    grid-row-gap: .6rem;
  }
  @include mobile {
    grid-auto-flow: row;
    grid-gap: 1rem;
  }

  margin-top: #{$margin-layout};
  &.ladder {
    > div {
      padding: 1rem 0;
      &:nth-child(3) {
        @include desktop {
          grid-column: 1;
          grid-row: unset;
        }
        main {
          height: auto !important;
        }
      }
      &:nth-child(4) {
        @include desktop {
          grid-column: 2;
          grid-row: 1 / span 3;
        }
      }
    }
  }
  > div {
    @include powerball-layout;
    background-color: var(--white);

    &:nth-child(3) {
      @include desktop {
        grid-column: 2;
        grid-row: 1 / span 2;
      }
    }

    header {
      @include d-center;
      height: 3rem;

      color: #595A5A;
      font-weight: bold;
    }

    main {
      @include d-center;
      grid-gap: 4rem;
      @include mobile {
        padding: .8rem 0;
      }

      .graph-circle.overunder {
        position: relative;

        width: 15.6rem;
        height: 15.6rem;
        border-radius: 50%;
        background: #E8E8E8 0% 0% no-repeat padding-box;
        box-shadow: inset 3px 3px 10px var(--black) 1 C, 0px 0px 6px #FF000000;

        .inner {
          @include d-center;
          flex-direction: column;
          grid-gap: .7rem;

          position: absolute;

          top: calc(50% - (9.6rem / 2));
          left: calc(50% - (9.6rem / 2));

          width: 9.6rem;
          height: 9.6rem;

          background-color: var(--white);
          border-radius: 50%;
          box-shadow: 3px 3px 10px var(--black) 1 C;

          z-index: 2;

          &.blue:after {
            background-color: #2185D050;
          }

          &.red:after {
            background-color: #DB282850;
          }

          &:after {
            content: "";
            position: absolute;
            display: inline-block;
            width: 4.2rem;
            height: .2rem;
            top: calc(50% - .2rem / 2);
            left: calc(50% - 4.2rem / 2);
            border-radius: .2rem;
          }

          p {
            text-align: center;
            line-height: 1.2;

            &.item-tit {
              @include font(15, bold);

              &.blue {
                color: #2185D0;
              }

              &.red {
                color: #DB2828;
              }

              &.default {
                color: #a5a7a8;
              }
            }

            &.item-contents {
              grid-gap: .5rem;

              span {
                @include font(10);

                &:nth-child(2) {
                  margin-left: .5rem;
                }
              }
            }
          }
        }

        .circle_progress_wrap {
          position: relative;
          width: 15.6rem;
          height: 15.6rem;

          .circle_progress {
            &.blue {
              transform: rotate(90deg);

              .from {
                stop-color: #006AB9
              }

              .to {
                stop-color: #2185D0
              }
            }

            &.red {
              transform: rotate(-90deg);

              .from {
                stop-color: #BE0000
              }

              .to {
                stop-color: #F94D21
              }
            }

            .bar {
              fill: none;
              stroke-linecap: round;
            }
          }
        }
      }
    }

    &:nth-child(3), &:nth-child(4) {
      main {
        @include d-center;
        flex-direction: column;
        grid-gap: 3rem;
        height: 100%;

        .graph-circle.l-m-s {
          position: relative;

          width: 22.2rem;
          height: 22.2rem;

          background-color: red;
          box-shadow: inset 2px 2px 8px #FFFFFF7A;
          border-radius: 50%;

          .inner {
            position: absolute;

            top: calc(50% - 15rem / 2);
            left: calc(50% - 15rem / 2);

            width: 15rem;
            height: 15rem;

            z-index: 2;
            background-color: var(--white);
            border-radius: 50%;
          }

          .graph {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            background-image: conic-gradient(
                            #2185D0 0%,
                            #2185D0 33%,
                            #DB2828 33%,
                            #DB2828 66%,
                            #22DC00 66%,
                            #22DC00 100%
            );
            border-radius: 50%;
            z-index: 1;

            @include mobile {
              &:after {
                content: "";
                @include absolute(0, 0, 1);

                width: 100%;
                height: 100%;

                transform: scale(.9);

                background-color: transparent;
                box-shadow: inset .2rem .2rem .8rem #FFFFFF7A;
                border-radius: 50%;
              }
            }
          }
        }

        .graph-count {
          @include d-flex;
          flex-direction: column;
          grid-gap: 1rem;

          > div {
            @include flex-set;
            grid-gap: .6rem;

            > span {
              @include font(10);
            }

            > p {
              @include font(15, bold);

              > span {
                @include font(12);
              }
            }
          }
        }
      }
    }
  }
}

.ladder-result-table-mo,
.ladder-result-table,
.powerball-result-table {
  @include desktop {
    margin-top: 4rem;
  }
  &:not(.ladder-result-table-mo){
    @include mobile {
      display: none;
    }
  }

  table {
    width: 100%;
  }

  th {
    height: 3.6rem;
  }

  td {
    height: 4.2rem;
  }

  th, td {
    background-color: var(--white);
    @include layout-border(1, 1, 1, 1);
    border-color: #F3F3F3;

    color: var(--default-font);
    text-align: center;

    &.result {
      @include font(12, bold);
      color: var(--black);
    }

    @mixin round-cell($color) {
      color: #{$color};
      border-color: #{$color};
    }

    .round {
      @include d-center(inline);
      width: 3rem;
      height: 3rem;

      //border: 1px solid;
      //border-radius: 8rem;

      @include font(14);

      &.ODD, &.UNDER, &.LEFT, &.LINE3 {
        @include round-cell(var(--blue))
      }

      &.EVEN, &.OVER, &.RIGHT, &.LINE4 {
        @include round-cell(var(--red))
      }

      &.SMALL {
        @include round-cell(var(--small))
      }

      &.MEDIUM {
        @include round-cell(var(--medium))
      }

      &.LARGE {
        @include round-cell(var(--large))
      }

      &.section {
        @include round-cell(#9103E3)
      }
    }
  }
}

.powerball-result-table-mo {
  @include desktop {
    display: none;
  }
  @include mobile {
    margin-top: 1rem;
    overflow-x: scroll;
  }

  table {
    //width: max-content;
    table-layout: fixed;

    th, td {
      padding: .2rem .4rem;

      @include layout-border;
      border-color: #F2F2F2;

      @include font(11);
      text-align: center;
      white-space: nowrap;
    }

    th {
      @include font(11, normal);
    }

    td {
      &:nth-child(1) {
        width: 6rem;
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        width: 3.3rem;
      }

      &:nth-child(8),
      &:nth-child(9),
      &:nth-child(10),
      &:nth-child(11) {
        width: 9.1rem;
      }

      &.result {
        @include font(12, bold);
        color: var(--black);
      }

      @mixin round-cell($color) {
        color: #{$color};
        border-color: #{$color};
      }

      .round {
        @include d-center(inline);
        width: 3rem;
        height: 3rem;

        //border: 1px solid;
        //border-radius: 8rem;

        @include font(12);

        &.odd, &.under {
          @include round-cell(var(--blue))
        }

        &.even, &.over {
          @include round-cell(var(--red))
        }

        &.small {
          @include round-cell(var(--small))
        }

        &.medium {
          @include round-cell(var(--medium))
        }

        &.large {
          @include round-cell(var(--large))
        }

        &.section {
          @include round-cell(#9103E3)
        }
      }
    }
  }
}

@mixin calendar-option-mixin {
  @include flex-set(space-between, center);
  height: 4rem;
  > header, > li, > footer {
    @include d-center;
  }
  > header, > footer {
    @include layout-border;
    @include rect(74, 28);
  }
}

.sports-calendar {
  @include layout-border(1, 1, 1, 1);
  @include calendar-option-mixin;
  padding: 0 1rem;
  margin-top: $margin-layout;

  > ul {
    @include calendar-option-mixin;
    grid-gap: 2rem;

    > li {
      cursor: pointer;
      min-width: 7.4rem;
      height: 4rem;
      @include layout-border(2, 2, 2, 2);
      border-color: transparent;

      &.select {
        border-bottom-color: var(--minigame-tab-select-border);
      }
    }
  }
}

#sports-tab + .ready {
  img {
    margin-top: 1rem;
    max-width: none;
  }
}

.sports-calendar + .sports-table {
  margin-top: $margin-layout;
}

.sports-table {
  header {
    @include flex-set;
    height: 3.2rem;
    grid-gap: .4rem;
    @include layout-border(1, 1, 1, 2);
    padding-left: 1rem;
    background-color: #F2F2F2;
    border-color: transparent;
    border-left-color: #444444;

    .close {
      @include layout-border(1, 1, 1, 1);
      @include d-center;
      @include rect(40, 20);
      background-color: var(--white);
      border-color: #D6D6D6;
      border-radius: .6rem;
      margin-left: auto;
    }
  }

  table {
    width: 100%;

    thead th {
      color: #A8A8A8;

      &:not(:first-child) {
        border-left-color: transparent;
      }

      &:not(:last-child) {
        border-right-color: transparent;
      }
    }

    th, td {
      @include layout-border(1, 1, 1, 1);
      height: 2.3rem;
      padding: 0 .4rem;
      text-align: center;
    }
  }

  &.baseball {
    thead th {
      &:nth-child(6) {
        width: 24.7rem;
      }

      @for $i from 7 through 22 {
        &:nth-child(#{$i}) {
          width: 1.9rem;
          @include font(12, bold);
          font-family: "Oswald"
        }
      }
    }
  }
}

#community-board-head {
  @include d-grid;
  grid-template-columns: 1fr 1fr;

  .board-box {
    &:nth-child(2) {
      border-left-width: 0;
    }
  }
}

#community-board-head + .board-tab {
  margin-top: $margin-layout;
}

.board-tab {
  @include flex-set;
  width: 100%;
  grid-gap: 3rem;
  margin-bottom: 2rem;
  overflow-x: auto;

  a {
    @include d-center;
    @include layout-border(0, 0, 2, 0);
    border-color: transparent;
    min-width: 10rem;
    height: 4rem;

    color: #9394AD;

    &:hover {
      @include hover-effect
    }

    &.select {
      color: black;
      font-weight: bold;
      border-color: var(--theme);
    }
  }
}

.category-tab {
  @include flex-set;
  height: 4rem;
  padding: 0 1.5rem;
  grid-gap: 1rem;

  > .nav-link {
    @include d-center;
    padding: .6rem .8rem;

    @include font(12);
    background-color: #F5F5F5;
    color: #9394AD;

    &.select {
      @include font(12, bold);
      background-color: var(--theme);
      color: var(--black);
    }
  }
}

.board-layout {
  > .pc-landing-community-area {
    @include d-grid;
    grid-column-gap: 3rem;
    grid-row-gap: 0rem;
    grid-template-columns: 1fr 1fr;

    .board-box {
      @include layout-border(0, 0, 0, 0);
      width: auto;
    }
  }
}

.board-box {
  @include layout-border;
  width: 42rem;
  height: 26.5rem;
  overflow: hidden;

  > * {
    padding: 0 1rem;
  }

  > header {
    @include flex-set;
    @include layout-border(0, 0, 1, 0);
    color: var(--theme2);
    height: 4rem;
    user-select: none;
    cursor: pointer;

    > ul {
      @include flex-set(space-between, center);
      width: 100%;
      height: 100%;

      > li {
        @include d-center;
        width: 8rem;
        height: 100%;

        cursor: pointer;

        color: #9394AD;

        @include layout-border(0, 0, 2, 0);
        border-color: transparent;

        &.select {
          border-bottom-color: var(--theme);
          color: black;
          font-weight: bold;
        }
      }
    }
  }

  > main {
    padding: 1rem;

    > ul {
      @include d-flex;
      flex-direction: column;

      &.picture, &.video {
        flex-direction: row;
        flex-wrap: wrap;
      }

      &.all {
        li {
          @include grid-set;
          grid-auto-flow: column;
          grid-gap: .5rem;
          //grid-template-columns: 7rem auto;
          p {
            @include ellipsis
          }
        }
      }

      grid-gap: .4rem;

      > li {
        @include ellipsis;
        cursor: pointer;

        img {
          @include rect(90, 90)
        }
      }

      &.ready {
        &:after {
          content: "준비중 입니다.";
          @include d-center;
          height: 20rem;
          @include font(20, bold);
          color: #d6d6d6;
        }
      }
    }
  }
}

.warning {
  margin-top: $margin-layout;
  margin-bottom: $margin-layout;
  padding: 2.8rem 1.4rem;
  //@include layout-border;
  * {
    @include font(13);
    @include spacing(.2);
  }

  header {
    margin-bottom: 1.2rem;
    font-weight: bold;
    color: #FF004E;
  }

  main {
    color: #444444;
  }
}

.board-table + .board-table {
  margin-top: 4rem;
}

.board-tab + .board-table {
  margin-top: $margin-layout;
}

.board-table {
  //padding-bottom: 5rem;
  .user-namecard {
    width: 12rem;
  }
  .list-search-form {
    position: relative;

    .form-title {
      @include layout-border;
      @include d-grid;
      grid-template-columns: 5.8rem calc(100% - 5.8rem - 4.8rem) 4.8rem;
      @include rect(262, 30);
      margin: 2rem auto 0;

      > p, > .btn {
        @include d-center;
        @include font(11);

        letter-spacing: -.101rem;
      }
      select {
        //-webkit-appearance: none;
      }
      input {
        @include layout-border(0, 1, 0, 1);
        padding: 0 .4rem;
      }

      > .btn {
        background-color: #F3F3F3;
      }
    }

    .write-new {
      @include absolute-right(0, 0, 1);
      @include btn-set(theme);
      @include rect(85, 30);
    }
  }

  .board-search-form {
    padding: 1rem;
    @include flex-set(space-between, center);

    label {
      display: inline-block;

      &.form-radio {
        @include layout-border;

        &:first-child {
          border-right-width: 0;
        }

        &:last-child {
          border-left-width: 0;
        }

        input[type=radio] {
          display: none;
        }

        .btn {
          @include d-center;
          @include rect(48, 30);
          @include font(11);

          letter-spacing: -.101rem;
          background-color: #FFFFFF;
        }

        input[type=radio]:checked + .btn {
          background-color: #F3F3F3;
        }
      }

      &.form-date-range {
        @include flex-set;

      }

      &.form-title {
        @include layout-border;
        @include d-ingrid;
        grid-template-columns: 4.8rem 9rem 4.8rem;
        height: 3rem;

        > p, > .btn {
          @include d-center;
          @include font(11);

          letter-spacing: -.101rem;
        }

        input {
          @include layout-border(0, 1, 0, 1);
          padding: 0 .4rem;
        }

        > .btn {
          background-color: #F3F3F3;
        }
      }
    }

    .form-date-range {
      @include flex-set;

      > div {
        @include layout-border(1, 0, 1, 1);

        &:last-child {
          @include layout-border(1, 1, 1, 1);
        }

        &:nth-child(2),
        &:nth-child(4) {
          background-color: #F3F3F3;
        }

        @include d-center;
        height: 3rem;
        padding: .8rem;
        grid-gap: .2rem;

        input {
          width: 6.5rem;
          text-align: center;
          color: var(--black);
          @include font(11, bold);
        }

        .fa-calendar {
          color: #9394AD;
          @include font(14);
        }
      }
    }

    .btn {
      width: 4.8rem;
      border-radius: 0;
      @include font(11);
    }
  }

  .write-new {
    @include btn-set(default);
    height: 3rem;
    @include font(11);

    letter-spacing: -.101rem;
  }

  > table {
    margin-top: $margin-layout;
    width: 100%;

    &.rank {
      td {
        &:nth-child(1) {
          text-align: center;
        }

        &:last-child {
          text-align: center;
        }
      }
    }

    th, td {
      @include layout-border(0, 0, 1, 0);

      &:not(:first-child, :last-child) {
        border-left-width: 0;
        border-right-width: 0;
      }

      &:first-child {
        border-right-width: 0;
      }

      &:last-child {
        border-left-width: 0;
      }
    }

    thead th {
      height: 2.4rem;
      text-align: center;
    }

    tbody td {
      height: 3.8rem;
      @include desktop {
        padding: 1rem;
      }
      @include mobile {
        padding: 0 .4rem;
      }
      &.change {
        font-weight: bold;
        &.sub { color: var(--red) }
        &.add { color: var(--blue) }
      }
    }

    @mixin center-line {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: calc(50% - 2.2rem / 2);
        left: -.1rem;
        height: 2.2rem;
        border-left: 2px solid var(--layout-border-color);
      }
    }

    &.default {
      thead th {
      }

      tbody td {
        //번호
        &:nth-child(1) {
          width: 6rem;
          color: var(--black);
          text-align: center;

          b {
            &.free {
              color: var(--board-free-color)
            }

            &.humor {
              color: var(--board-humor-color)
            }

            &.picture {
              color: var(--board-picture-color)
            }

            &.video {
              color: var(--board-video-color)
            }

            &.sports {
              color: var(--board-sports-color)
            }
          }
        }

        //제목
        &:nth-child(2) {
          width: auto;
          cursor: pointer;

          &:hover {
            @include hover-effect
          }

          > a {
            @include flex-set;
            width: 100%;
            height: 100%;
          }
        }

        //작성자
        &:nth-child(3) {
          width: 14rem;
          text-align: left;
          @include center-line;
        }

        //날짜
        &:nth-child(4) {
          width: 10rem;
          @include center-line;
        }
      }
    }

    &.rank {
      thead th {
        &:nth-child(1) {
          width: 6.4rem;
        }

        &:nth-child(2) {
          width: 14rem;
        }

        &:nth-child(3) {
          width: 7rem;
        }
      }

      tbody td {
        &:nth-child(2) {
          text-align: left;
        }

        &:nth-child(3) {
          text-align: center;
        }

        &:nth-child(4) {
          text-align: right;
        }

        &:nth-child(5) {
          text-align: center;
        }
      }
    }

    &.analysis {
      tbody td {
        &:nth-child(2) {
          text-align: left;
          cursor: pointer;
        }
      }
    }
  }

  .board-picture {
    @include d-flex;
    flex-wrap: wrap;
    grid-gap: 1rem;
    margin-top: $margin-layout;

    > li {
      position: relative;
      @include rect(200, 200);
      overflow: hidden;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      .thumbnail-link {
        display: block;
        width: 100%;
        height: 100%;
      }

      > img {
        width: 100%;
      }

      &.empty {
        position: relative;
        background-color: #dbdbdb;

        &:after {
          content: "!";
          @include d-center;
          @include absolute(calc(50% - (18rem / 2)), calc(50% - (18rem / 2)), 1);
          @include rect(180, 180);
          background-color: #eaeaea;
          border-radius: 50%;
          color: #acacac;
          @include font(60, bold);
          pointer-events: none;
        }
      }

      .detail-info {
        position: absolute;
        bottom: 0;
        z-index: 1;
        width: 100%;
        padding: 1.2rem .8rem;
        background: transparent linear-gradient(180deg, #000000A8 0%, #00000054 100%) 0% 0% no-repeat padding-box;

        @include d-flex;
        flex-direction: column;
        grid-gap: .8rem;

        color: #D6D6D6;
        transform: translateY(100%);
        transition-duration: .2s;
        pointer-events: none;

        * {
          color: var(--white)
        }
      }

      &:hover {
        .detail-info {
          transform: translateY(0%);
        }
      }
    }
  }
}

@mixin namecard-mixin($iconSize: 20) {
  @include in-flex-set;
  grid-gap: .5rem;
  .user-icon {
    @include d-center;
    @include rect($iconSize, $iconSize);
    background-color: #D6D6D6;
    border-radius: 50%;
    border: 2px solid #D6D6D6;
    overflow: hidden;

    .fa-user {
      color: white !important;
      $font: $iconSize * 0.6;
      @include font($font);
    }
  }
}

.user-namecard {
  @include namecard-mixin(20);
  max-width: 12rem;
  .--rank {
    @include font(26);
    &[class*="admin-"] {
      width: 2.6rem;
      background-size: contain;
      @include font(20);
    }
  }
}

.user-board-namecard {
  @include namecard-mixin(32);

  > div {
    @include d-flex;
    flex-direction: column;

    span:first-child {
      font-weight: bold;
    }

    span:last-child {

    }
  }
}

.pagination {
  @include d-center;
  margin-top: 2rem;
  grid-gap: 1rem;

  &:last-child {
    margin-bottom: 10rem;
  }

  > li {
    .page-link {
      @include d-center;
      @include rect(30, 30);
      @include font(12);
      color: #9394AD;

      border-color: #D6D6D6;
    }

    &.active {
      .page-link {
        color: black;
        background-color: #F3F3F3;
      }
    }
  }
}

#main__container {
  .login-form {
    margin: 10rem auto 0;
  }
}

.login-form {
  @include d-flex;
  flex-direction: column;
  max-width: 48rem;
  width: calc(100% - 4rem);
  margin: 0 auto;
  @include desktop {
    @include layout-border;
  }
  @include mobile {
    margin: 0 auto;
  }
  padding: 0 0 5rem;

  > * {
    @include desktop {
      width: calc(100% - 4rem);
    }
    @include mobile {
      width: 100%;
    }
    margin: 0 auto;
  }

  > header {
    @include d-center;
    height: 14rem;
    flex-direction: column;

    border-bottom: 1px solid var(--theme);

    img {
      transform: scale(1.4)
    }
  }

  > main {
    margin-top: 4.5rem;

    form {
      @include d-flex;
      flex-direction: column;

      .form-row {
        @include desktop {
          @include grid-set;
          grid-template-columns: 11rem 29rem;
          grid-column-gap: 1rem;
        }
        @include mobile {
          @include d-flex;
          flex-direction: column;
          grid-gap: .5rem;
        }

        label {
          cursor: pointer;
        }

        strong {
          white-space: nowrap;
          @include desktop {
            @include font(16, bold);
            text-align: right;
          }
          @include mobile {
            @include font(14, bold);
            text-align: left;
            padding-left: .5rem;
          }
        }

        input {
          &[type=text],
          &[type=password] {
            height: 3.8rem;
            @include layout-border;
            border-color: var(--theme);
            padding: 0 1.4rem;
            border-radius: .6rem;
          }
        }

        input + .form-text {
          height: 1em;
          grid-column: 2 / span 1;
          padding-left: 1rem;
        }

        .remember {
          @include flex-set;
          grid-gap: .5rem;

          .form-checkbox {
            position: relative;
            @include font(16);
          }
        }
      }

      button {
        &:nth-of-type(1){
          margin-top: 4.8rem;
        }
        height: 3.6rem;

        color: white;
        @include font(16, bold);
        border-radius: .4rem;
      }
    }
  }

  footer {
    @include d-center;
    margin-top: 1.2rem;
    grid-gap: 2rem;

    @include font(12, bold);
    color: #444444;

    > * {
      @include inherit;
    }
  }

  &.exp-change-form {
    > header {
      border-bottom-color: #D6D6D6 !important;
      border-bottom-style: dashed !important;
    }

    .form-row {
      position: relative;

      .absolute-btn {
        @include d-center;
        height: 100%;
        padding: 0 .6rem;
        @include absolute-right(0, 3rem, 1);

        .btn {
          @include rect(67, 26);
          @include font(11)
        }
      }
    }

    input[type=text] {
      border-color: #9394AD !important;
      text-align: left;

      &.mypoint {
        color: var(--blue);
      }

      &.exp-changepoint {
      }
    }
  }
}

@mixin board-btn-mixin {
  @include flex-set(flex-end, center);
  padding: 0 2.6rem 2rem 0;
  .board-btn {
    @include flex-set;
    grid-gap: .7rem;

    button {
      @include d-center;
      padding: 0;
      @include rect(34, 18);

      &.btn {
        @include layout-border;
        background-color: #F3F3F3;
        border-radius: .4rem;


      }
    }
  }
}

.board-detail {
  margin-top: $margin-layout;
  @include layout-border(1, 0, 1, 0);

  > header {
    @include layout-border(0, 0, 1, 0);

    h1 {
      @include flex-set;
      height: 4rem;
      @include layout-border(0, 0, 1, 0);
      @include font(16, bold);
      padding: 0 2rem;
      margin-bottom: 0;

      > span {
        margin-top: .5rem;
        padding-left: .5rem;
        color: var(--theme2);
      }
    }

    > div {
      @include flex-set(space-between, center);
      height: 4rem;
      padding: 0 2rem;
      background-color: #F2F2F2;
    }
  }

  > main {
    padding: 3rem 2rem;
    overflow: hidden;

    iframe {
      width: 100%;
      height: 45rem;
    }

    img {
      max-width: 100%;
    }
  }

  > footer {
    @include board-btn-mixin
  }
}

.comments {
  margin-top: $margin-layout;

  .comment-box {
    @include d-flex;
    flex-direction: column;

    &:not(:first-child) {
      @include layout-border(1, 0, 0, 0);
    }

    padding: 1.2rem 2.4rem 0 2.4rem;
    min-height: 12rem;

    > header {

    }

    > main {
      margin-top: 1rem;
      padding-left: 3.6rem;
      @include font(16);
      white-space: pre-line;
    }

    > footer {
      margin-top: auto;
      @include board-btn-mixin;
      padding: 0 2.6rem 1.4rem 0;
    }
  }

  > .comment-input-form {
    @include layout-border(1, 0, 0, 0);
    background-color: #F3F3F3;
    height: 12rem;
    padding: 1.5rem 3rem;

    form {
      @include d-grid;
      grid-template-columns: 66rem 12rem;
      height: 9rem;

      textarea {
        padding: 1rem;
        @include layout-border;
        background-color: var(--white);
        resize: none;
        outline: none;
      }

      button {
        @include layout-border(1, 1, 1, 0);
        @include d-center;
        color: #444444;
        @include font(14)
      }
    }
  }
}

.edit-board {
  margin-top: $margin-layout;

  iframe {
    width: 100%;
    height: 100%;
  }

  form {
    .edit-form-title {
      @include layout-border;
      padding: 1rem;

      input[type=text] {
        @include layout-border;
        width: 100%;
        height: 3.6rem;
        padding: 0 .8rem;

        @include font(16)
      }
    }

    .edit-form-content {
      margin-top: $margin-layout;

      .ql-toolbar {
        background-color: #F3F3F3;
      }

      .ql-container {
        height: 42rem;
      }
    }

    .edit-form-btn {
      @include d-center;
      margin-top: 2rem;
      grid-gap: 2rem;

      .write-new {
        @include d-center;
        @include rect(48, 30);
        @include layout-border;
        background-color: #F3F3F3;
        @include font(11);

        letter-spacing: -.101rem;
      }
    }
  }
}

.board-market {

  > ul {
    @include flex-set(flex-start, flex-start);
    flex-wrap: wrap;
    grid-gap: 1rem;

    .market-items {
      width: 20rem;
      height: 37rem;
      overflow: hidden;
      @include layout-border;

      > header {
        @include d-center;
        flex-direction: column;
        height: 20rem;
        @include layout-border(0, 0, 1, 0);

        .name {
          //margin-top: 1rem;
        }

        .bonus {
          margin-top: .5rem;
          @include d-center;
          padding: .3rem .7rem;
          background-color: var(--theme);
          @include font(11, bold);
        }

        .descriptions {
          margin-top: .5rem;
          @include d-center;
          width: 17.6rem;
          padding: .5rem .8rem;
          //@include layout-border;
          @include font(11);

          text-align: center;
        }
      }

      > main {
        @include d-flex;
        flex-direction: column;

        > div {
          @include flex-set;
          height: 4rem;
          padding: 0 1.2rem 0 .7rem;
          @include layout-border(0, 0, 1, 0);

          .inner {
            @include flex-set;
            grid-gap: .4rem;
            width: 100%;
            height: 100%;
            color: #9394AD;
            @include font(12, bold);
            cursor: pointer;
            user-select: none;

            &.checked {
              color: var(--theme2)
            }
          }
        }
      }

      > button {
        @include d-center;
        width: 100%;
        height: 4.8rem;

        @include font(12, bold);
        border-radius: 0 !important;

        &.active {
          color: white;
          @include btn-set(theme2);
          @include btn-hover(theme2);
        }

        &.disabled {
          @include btn-set(disabled);
          color: #D6D6D6;
        }
      }
    }
  }
}

.item-counter {
  @include flex-set;
  @include layout-border;

  > div {
    @include d-center;
    @include rect(20, 20);
    user-select: none;
    color: #9394AD;

    &:nth-child(2) {
      @include layout-border(0, 1, 0, 1);

      input[type=number] {
        width: 100%;
        height: 100%;
        text-align: center;
      }
    }

    &:nth-child(1),
    &:nth-child(3) {
      @include font(16, bold);
      cursor: pointer;
    }
  }
}

.board-profile {
  * {
  }

  .box {
    &:first-child {
      margin-top: $margin-layout;
    }

    &:not(:last-child) {
      margin-bottom: $margin-layout;
    }

    @include layout-border;

    .p-row {
      position: relative;
      min-height: 5.6rem;
      padding: 2rem;
      @include flex-set(flex-start, flex-start);

      &:not(:last-child) {
        @include layout-border(0, 0, 1, 0);
        border-style: dashed;
      }

      .subject {
        @include d-center;
        width: 10rem;
        text-align: center;
      }

      .content {
        padding-left: 4rem;

        &.edit-image {
          @include d-grid;
          grid-template-columns: 28.9rem 36.1rem;
          grid-gap: 1rem;

          > div {
            &:first-child {
              .profile-box {
                @include rect(80, 80);
                background-color: #F2F2F2;
                text-align: center;

                > img {
                  width: 100%;
                  height: 100%;
                }
              }

              .banner-box {
                @include rect(289, 80);
                background-color: #F2F2F2;

                > img {
                  width: 100%;
                  height: 100%;
                }
              }

              > p {
                margin-top: 2rem;
                @include font(12, bold);
              }
            }
          }
        }

        &.push-alarm {
          @include flex-set;
          grid-gap: 5rem;
        }
      }
    }

    &.profile-1 {
      .btn-area {
        position: absolute;
        bottom: calc(0% + (3.2rem / 2));
        right: 2rem;
        @include flex-set;
        grid-gap: 1.2rem;

        .btn {
          @include rect(77, 32);
          border-radius: 0;
          @include font(12);
        }
      }
    }

    &.profile-2 {
      .btn-area {
        margin-left: auto;

        .btn {
          @include rect(70, 20);
          border-radius: 0;
          padding: 0;

          > a {
            @include d-center;
            width: 100%;
            height: 100%;
            @include font(11)
          }
        }
      }
    }

    &.profile-3 {
      .p-row {
        align-items: center;
        height: 5.6rem;
        padding: 0 2rem;

        > * {
          @include d-flex;
        }

        .btn-area {
          margin-left: auto;

          .btn {
            @include rect(77, 32);
            border-radius: 0;
            @include font(12)
          }
        }
      }
    }
  }
}

.btn > a {
  @include d-center;
  width: 100%;
  height: 100%;
}

.switch-area {
  position: relative;
  width: 3.2em;
  height: 2em;

  @include layout-border;
  background-color: #9394AD;
  border-color: #9394AD;
  border-radius: 1em;
  border-width: .2rem;
  transition-duration: .1s;

  cursor: pointer;

  > .switch-btn {
    position: absolute;
    top: .04em;
    left: 0;

    display: block;
    width: 1.6em;
    height: 1.6em;
    background-color: var(--white);
    border-radius: 50%;

    transition-duration: .1s;
  }

  &.on {
    background-color: var(--theme2);
    border-color: var(--theme2);

    > .switch-btn {
      left: calc(3.2em / 2 - .4rem);
    }
  }
}
.board-message {
  > header {
    margin-top: 2rem;
    .message-warning {
      padding-left: 2rem;
      li {
        list-style: disc;
      }
    }
    .message-count {
      margin-top: 2rem;
      margin-bottom: $margin-layout;
      @include layout-border;
      @include flex-set;
      height: 5.6rem;
      padding: 0 2rem;

      strong {
        text-align: center;
        @include font(12, bold);
      }

      p {
        margin-left: 3rem;
        @include font(12, bold);
      }

      .btn {
        height: 3.2rem;
        padding: 0 .8rem;
        @include font(11);
        margin-left: auto;
      }
    }
  }
  > main {
    form {
      > .d-flex {
        margin-top: 2rem;
        padding-left: 1rem;
        .target-nickname {
          @include rect(120, 28);
          @include layout-border;
          padding: 0 .4rem;
        }
      }
      textarea {
        width: 100%;
        height: 20rem;
        padding: 1rem;
        margin-top: 1rem;
        @include layout-border;
        border-radius: .4rem;

        resize: none;
      }
      .btn.send {
        @include rect(120, 32);
        margin-top: 1rem;
        margin-left: auto;
        @include font(14);
      }
    }
  }
  @keyframes article-slide-down {
    from { max-height: 0; }
    to   { height: auto; max-height: 100rem; }
  }
  table {
    th, td {
      text-align: center;
    }
    tbody {
      tr {
        td {
          cursor: default;
          &:nth-child(3) {
            cursor: pointer;
          }
        }
      }
    }
  }
}
.board-user {
  header > {
    .my-cash {
      margin-top: 2rem;
      margin-bottom: $margin-layout;
      @include layout-border;
      @include flex-set;
      height: 5.6rem;
      padding: 0 2rem;

      strong {
        text-align: center;
        @include font(12, bold);
      }

      p {
        margin-left: 3rem;
        @include font(12, bold);
      }

      .btn {
        height: 3.2rem;
        padding: 0 .8rem;
        @include font(11);
        margin-left: auto;
      }
    }
  }

  table {
    th, td {
      text-align: center;
    }
  }

  &.inventory {
    header > [class*="my-"] p {
      color: var(--pink);
    }
  }

  &.exp {
    header > [class*="my-"] p {
      color: var(--blue);
    }

    table {
      width: 100%;

      th, td {
        text-align: center;
        height: 3.8rem;
        @include layout-border;
      }

      thead th {
        background-color: #F5F5F5;
      }
    }
  }

  > main {
    header {
      margin-top: 2rem;
      @include font(12, bold);
      padding-left: .8rem;
      padding-bottom: .8rem;
      @include layout-border(0, 0, 1, 0);
      border-color: var(--theme);
    }
  }

  &.inventory {
    .item-list {
      @include flex-set;
      grid-gap: 1rem;
      flex-wrap: wrap;
      background-color: #F5F5F5;
      padding: 1rem .5rem;

      li {
        @include flex-set;
        flex-direction: column;
        @include rect(200, 200);
        padding: 1rem;
        @include layout-border;
        background-color: var(--white);

        * {
          color: var(--black);
        }

        .item-name {
          margin-top: .5rem;
          @include font(12, bold);
        }

        .count {
          margin-top: .5rem;
          @include layout-border;
          border-style: dashed;
          padding: .5rem .8rem;
          @include font(11, bold);
        }

        button {
          width: 100%;
          margin-top: auto;
          @include d-center;
          height: 3rem;
          background-color: var(--theme);
          @include font(12)
        }
      }
    }

    table {
      thead {
        th {
          &:nth-child(1) {
            width: 4.2rem;
          }

          &:nth-child(2) {
            width: 11rem;
          }

          &:nth-child(4) {
            width: 16rem;
          }

          &:nth-child(5) {
            width: 19rem;
          }
        }
      }
    }
  }
}

.rank-card {
  @include d-center;

  > .--rank {
    @include font(26);
  }

  > span {
    width: 4.4rem;
    text-align: center;
  }
}

.next-rank-exp-bar {
  @include flex-set(center, flex-end);
  margin-left: auto;
  margin-right: 6rem;
  grid-gap: 1rem;

  .rank-info {
    @include d-center;
    flex-direction: column;

    .--rank {
      @include font(26)
    }

    > span {
      @include font(11, bold)
    }
  }

  .bar-info {
    @include d-center;
    flex-direction: column;

    .bar {
      position: relative;
      width: 40rem;
      height: .8rem;
      background-color: #D6D6D6;
      @include layout-border;
      border-color: #444444;
      border-radius: 5.8rem;
      overflow: hidden;

      .gauge {
        position: absolute;
        height: 100%;
        background-color: var(--theme2);
      }
    }
  }
}

.board-table.friend {
  .my-friend {
    @include flex-set;
    @include layout-border;
    margin-bottom: 5rem;
    height: 5.6rem;
    padding-left: 5.5rem;
    grid-gap: 4rem;

    > div {
      @include flex-set;
      grid-gap: 1rem;
      font-weight: bold;

      &:nth-child(1) {
        b {
          color: var(--blue);
        }
      }

      &:nth-child(2),
      &:nth-child(3) {
        b {
          color: var(--red);
        }
      }
    }
  }
}
//
.chat-overlay {
  &.user-pick-overlay {
    z-index: 100;
    main {
      transform: translateX(0);
    }
  }
  &.room {
    main {
      transform: translateX(18.4rem);
    }
  }

  main {
    transform: translateX(-8rem);
    width: 18.4rem;
    background-color: var(--white);
    box-shadow: 3px 3px 12px var(--black) 52;

    header {
      @include flex-set(space-between, center);
      height: 2.6rem;
      padding: 0 .5rem;
      background-color: var(--theme);

      .user-namecard {
        @include font(13, bold);

        .--rank {
          @include font(20)
        }
      }

      .userinfo {
        @include flex-set;

        .--rank {
          @include font(20)
        }

        p {
          @include font(13, bold)
        }
      }

      .fa-rectangle-xmark {
        @include font(18);
      }
    }

    ul {
      @include d-flex;
      flex-direction: column;
      background-color: var(--white);
      @include layout-border;
      border-color: var(--theme);
      overflow: hidden;

      li {
        @include flex-set;
        height: 2.6rem;
        padding: 0 .3rem;
        @include font(12, bold);
        cursor: pointer;

        &:not(:last-child) {
          @include layout-border(0, 0, 1, 0);
          border-style: dashed;
        }

        i {
          @include font(26)
        }
      }
    }
  }
}

.edit-image-area {
  height: 30rem;
}

.chat-room-list {
  > header {
    @include flex-set(space-between, center);
    height: 3.4rem;
    @include layout-border(0, 0, 1, 0);
    padding: 0 .4rem 0 2rem;

    > strong {
      color: var(--black);
    }
  }

  table {
    margin-top: 2rem;
    @include layout-border(0, 0, 0, 0);

    th, td {
      @include layout-border(0, 0, 0, 0);
      padding: 0 1rem;
    }

    tr {
      @include layout-border(0, 0, 1, 0)
    }

    th {
      &:nth-child(1) {
        width: 6rem;
      }

      &:nth-child(3) {
        width: 8rem;
      }

      &:nth-child(4) {
        width: 8rem;
      }

      &:nth-child(5) {
        width: 12rem;
      }

      &:nth-child(6) {
        width: 6rem;
      }
    }

    td {
      &:nth-child(2) {
        cursor: pointer;
      }
    }
  }
}

.room-popup-template {
  width: 35rem;
  height: 64.5rem;
  @include layout-border;
  margin: 0 auto;

  > header {
    @include flex-set;
    height: 3.6rem;
    padding: 0 1rem;

    @include font(12, bold);
  }

  > main {
    height: calc(100% - 3.6rem);

    form {
      height: 100%;

      .create-room-modal-body {
        @include d-flex;
        flex-direction: column;
        grid-gap: 5rem;
        padding: 0 2rem 2rem;
        height: 100%;

        .d-row {
          @include d-flex;
          flex-direction: column;
          grid-gap: 1rem;

          &:first-child {
            margin-top: 16.4rem;
          }

          strong {
            color: var(--black);
            @include font(16)
          }

          > div {
            &.radio {
              @include d-grid;
              grid-template-columns: 1fr 1fr;
              grid-gap: 1rem;

              input[type=radio] {
                & + .btn {
                  @include btn-set(default)
                }

                &:checked + .btn {
                  @include btn-set(theme)
                }
              }

              .btn {
                width: 100%;
                height: 3rem;
              }
            }

            &.input {
              input[type=text] {
                width: 100%;
                height: 3.8rem;
                @include layout-border;
                border-color: #9394AD;
                border-radius: .6rem;
                padding: 0 .8rem;
              }
            }

            label {
              .btn {
                height: 3rem;
              }
            }
          }
        }

        > footer {
          margin-top: auto;
          @include d-flex;
          flex-direction: column;
          grid-gap: 1rem;

          .btn {
            height: 3.6rem;
            @include font(16, bold);
            border-radius: .4rem;

          }
        }
      }
    }


    $chat-header-height: 3.6rem;

    .host {
      @include flex-set(space-between, center);
      height: #{$chat-header-height};
      padding: 0 1rem;

      .user-namecard {
        font-weight: bold;
      }

      > div {
        @include d-center;
        user-select: none;
        cursor: pointer;

        .close {
          @include font(26)
        }
      }
    }

    .chat-room-tab {
      height: #{$chat-header-height};
      @include d-grid;
      grid-template-columns: 1fr 1fr;
      padding: 0 1rem;

      > li {
        @include d-center;
        @include layout-border(0, 0, 1, 0);
        border-color: #D6D6D6;
        cursor: pointer;

        &.select {
          color: var(--black);
          @include font(12, bold);
          @include layout-border(0, 0, 2, 0);
          border-color: var(--theme2)
        }
      }
    }

    .chat-room-body {
      @include d-flex;
      flex-direction: column;
      height: calc(100% - (#{$chat-header-height} * 2));

      > .chat-area {
        height: 100%;
        padding: 2rem 1rem 1rem;
        overflow-y: auto;
        @include scroll-bar;

        > ul {
          @include d-flex;
          flex-direction: column;
          grid-gap: .7rem;
          li {
            &.chat {
              > span {
                &:first-child {
                  letter-spacing: -0.12rem;
                  color: var(--chat-user-strong);
                  @include font(13, bold);
                  cursor: pointer;
                  i.--rank {
                    @include font(20);
                    margin-bottom: -.5rem;
                  }
                }
                &:last-child {
                  margin-left: .5rem;
                  color: var(--chat-msg-p);
                  @include font(12);
                  word-break: break-all;
                }
              }
            }

            &.info {
              @include d-center;
              margin: 2rem 0;
              text-align: center;
              color: var(--chat-input-default);
            }
          }
        }
      }

      > .chat-room-attendant {
        height: 100%;
        overflow-y: auto;
        @include scroll-bar;

        ul {
          padding: 2rem 1rem 0;

          li {
            width: 100%;
            height: 3.6rem;
            cursor: pointer;

            div {
              @include flex-set(space-between, center);

              strong {
                @include font(13, bold);
              }
            }
          }
        }
      }

      > footer {
        margin-top: auto;
        height: 10.4rem;
        padding: 1rem .5rem 0 .5rem;

        @include layout-border(1, 0, 0, 0);
        //background-color: var(--super-chat-room-background-color2);
        background-color: var(--white);

        * {
          color: var(--chat-input-default);
        }

        > p {
          color: var(--default-font);
          @include font(13);
          font-weight: bold
        }

        > input {
          width: 100%;
          height: 3rem;
          color: black;
          border-bottom: 1px solid var(--black);

          &::-webkit-input-placeholder {
            color: var(--chat-input-default);
          }
        }

        > div {
          @include d-flex;
          height: 4rem;

          .fa-face-laugh {
            @include font(23);
          }

          > div {
            @include d-flex;
            grid-gap: 1.4rem;

            .text-count {
              @include font(13);
              letter-spacing: .013rem;
            }

            .fa-paper-plane {
              @include font(15);
            }
          }
        }
      }
    }
  }
}

.create-room-modal {
  @include rect(350, 645);
}

aside {
  .dummy-pick {
    margin-top: 4rem;
    @include layout-border;
    border-color: #f3f3f3;

    > header {
      @include d-center;
      @include font(12, bold);
      background-color: #F2F2F2;
      height: 3.8rem;
    }

    > main {
      > div {
        padding: 1.7rem 2rem;

        &:nth-child(2) {
          @include layout-border(1, 0, 0, 0);
          border-color: #F2F2F2;
        }

        > header {
          margin-bottom: 1.2rem;
          text-align: center;
          color: var(--black);
          @include font(12)
        }

        > ul {
          @include grid-set;
          width: 100%;
          grid-gap: 1rem;

          &.col-4 {
            grid-template-columns: repeat(4, 1fr);
          }

          &.col-3 {
            grid-template-columns: repeat(3, 1fr);
          }

          li {
            @include d-center;
            @include layout-border;
            width: 100%;
            height: 3rem;
            background-color: #F3F3F3;
            cursor: pointer;
            user-select: none;
          }
        }

        > ul + ul {
          margin-top: 1rem;
        }
      }
    }

    > footer {
      user-select: none;
      cursor: pointer;
      @include d-center;
      height: 3.6rem;
      @include font(15, bold);
      background-color: var(--theme);
    }
  }
}
.board-policy {
  > pre {
    white-space: pre-line;
  }
  strong {
    display: block;
    &:not(:first-child) {
      margin-top: 2.4rem;
      margin-bottom: .5rem;

    }
  }
  dl {
    @include d-flex;
    grid-gap: .8rem;
    margin-bottom: 0rem;
    * { font-size: 1.2rem; }
  }
  dd {
    margin-bottom: 0rem;
  }
}

.room-close-modal {
  > p {
    @include font(20, bold);
  }

  > div {

  }
}

#happyball-layout {
  > .empty {
    @include d-center;
    flex-direction: column;
    height: 60rem;

    background-color: #F4F4F4;
    * { background-color: transparent; }
    img {
      margin-bottom: 3rem;
    }
    p {
      &:nth-child(2) {
        @include font(15, bold);
        color: #000000;

        margin-bottom: 1rem;
      }
      &:nth-child(3) {
        @include font(12, bold);
      }
    }
  }
}
.new-exp-table {
  @include d-grid;
  grid-template-columns: 1fr 1fr;
  > table {
    &:last-child {
      th, td {
        &:first-child {
          border-left-width: 0;
        }
      }
    }
  }
}

.react-datepicker__header {
  padding-top: .4rem;

  .react-datepicker__current-month {
    @include font(13, bold);
  }
}

.react-datepicker__day, .react-datepicker__day-name {
  @include d-center(inline);
  @include rect(30, 30);
  margin: 0;

  &:not(.react-datepicker__day--outside-month, .react-datepicker__day--selected) {
    background-color: #F2F2F2;
  }

  &.react-datepicker__day--outside-month {
    color: #AAAAAA;
  }
}

.sports-iframe-cover {
  > iframe {
    margin-top: 2rem;
    overflow: hidden;
  }
}

iframe#sports_iframe {
  width: 84rem;
}

.game-pick {
  min-height: 32rem;
  margin-top: 4rem;
  background-color: white;
  header, footer > button {
    width: 100%;
    height: 3.8rem;
    @include font(12, bold);
  }
  header {
    @include flex-set(space-between, center);
    padding: 0 2.5rem;
    background-color: var(--theme);
  }
  footer {
    > button[type=submit] {
      @include d-center;
      @include font(16, bold);
      cursor: pointer;
    }
  }
  main {
    min-height: 22.8rem;
    @include layout-border(0,1,0,1);
    border-color: var(--default);
    &.pick-block-main {
      @include d-center;
      @include font(14)
    }
    &.pick-chosen-main {
      @include d-center;
      flex-direction: column;
      grid-gap: 1rem;
      > p {
        @include font(14);
      }
      > div {
        @include rect(250, 100);
        @include flex-set(space-between);
        padding: 0 3rem;

        background-color: #eaeaea;
        border-radius: 8pt;
        > span {
          @include font(14, bold);
          &:last-child {
            @include d-center;
            @include rect(75, 30);
            @include font(12);
            color: white;

            &.ODD, &.UNDER, &.MEDIUM, &.LEFT, &.THREE, &.LEFT_THREE_EVEN, &.LEFT_FOUR_ODD {
              background-color: var(--odd);
            }
            &.EVEN, &.OVER, &.LARGE, &.RIGHT, &.FOUR, &.RIGHT_THREE_ODD, &.RIGHT_FOUR_EVEN  {
              background-color: var(--even);
            }
            &.SMALL {
              background-color: var(--small);
            }
          }
        }
      }
    }
    &.pick-form-main {
      > div {
        padding: 1.2rem 2rem;
        &:last-child {
          @include layout-border(1,0,0,0);
          border-color: var(--default);
        }
        p, .pick {
          @include d-center;
        }
        p {
          @include font(14);
          margin-bottom: 1.2rem;
        }
        .cell {
          @include d-grid;
          &.col2 {
            @include grid-set(center, center);
            grid-template-columns: repeat(2, 7rem);
          }
          &.col3 { grid-template-columns: repeat(3, 1fr) }
          &.col4 { grid-template-columns: repeat(4, 1fr) }
          grid-gap: 1rem;
          & + .cell {
            margin-top: 1rem;
          }
          @mixin on_change($pick) {
            color: white;
            @if( $pick == odd ){
              background-color: var(--odd);
            }@else if( $pick == even ){
              background-color: var(--even);
            }@else{
              background-color: var(--#{$pick});
            }
          }
          label {
            width: 100%;
            height: 3.4rem;
            input[type='radio'] {
              display: none;
            }
            .pick {
              width: 100%; height: 100%;
              background-color: var(--default);
              cursor: pointer;
              transition-duration: .1s;
              &:hover {
                &.ODD, &.UNDER, &.LEFT, &.THREE, &.LEFT_THREE_EVEN, &.LEFT_FOUR_ODD {
                  @include on_change(odd)
                }
                &.EVEN, &.OVER, &.RIGHT, &.FOUR, &.RIGHT_THREE_ODD, &.RIGHT_FOUR_EVEN {
                  @include on_change(even)
                }
                &.SMALL {
                  @include on_change(small)
                }
                &.MEDIUM {
                  @include on_change(medium)
                }
                &.LARGE  {
                  @include on_change(large)
                }
              }
            }
            input[name='game_pick']:checked + .pick {
              &.ODD, &.UNDER, &.LEFT, &.THREE, &.LEFT_THREE_EVEN, &.LEFT_FOUR_ODD {
                @include on_change(odd)
              }
              &.EVEN, &.OVER, &.RIGHT, &.FOUR, &.RIGHT_THREE_ODD, &.RIGHT_FOUR_EVEN {
                @include on_change(even)
              }
              &.SMALL {
                @include on_change(small)
              }
              &.MEDIUM {
                @include on_change(medium)
              }
              &.LARGE  {
                @include on_change(large)
              }
            }
          }
        }
      }
    }
  }
}